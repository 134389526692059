import React, { useEffect, useState } from 'react';
import { 
    Modal,
    Button,
    Row,
    Col,
    Form,
    Input,
    message,
    Layout,
    Radio,
    DatePicker,
    Select,
    Spin,
    Checkbox,
    Alert,
} from 'antd';
import CurrencyInput from 'react-currency-input';
import locale from 'antd/es/date-picker/locale/pt_BR';
import InputMask from 'react-input-mask';
import moment from 'moment';
import 'moment/locale/pt-br';
import { parseISO, format } from 'date-fns';

import api from '../../../../services/api';

const cleanFloatValue = require('../../../../utils/cleanFloatValue');

const { Search, TextArea } = Input;
const { Content } = Layout;
const { Option } = Select;

function Withdrawal(props) {
    const [loading, setLoading] = useState(false);

    const [performingWithdrawal, setPerformingWithdrawal] = useState(false);
    const [withdrawal_value, setWithdrawalValue] = useState(0);
    
    useEffect(() => {
        if(props.financial_account && props.visible){
            setLoading(true);
        }
    }, [props.visible]);
    
    async function performWithdrawal() {
      setPerformingWithdrawal(true);

      const data = {
          financial_account_id: props.financial_account.id,
          value: cleanFloatValue(withdrawal_value)
      };

      try {
          let response = await api.post('/financial_accounts/withdrawal', data);

          if(response.data.success) {
              message.success('Retirada realizada com sucesso');
          } else {
              message.error('Ocorreu um erro, aguarde alguns instantes e tente novamente');
          }

          setPerformingWithdrawal(false);

          props.afterSave();
          props.setVisibility(false);
      } catch (error) {
          message.error('Ocorreu um erro, aguarde alguns instantes e tente novamente');
          setPerformingWithdrawal(false);
      }
  }

    return (
      <Modal
          title="Selecione o valor a ser retirado"
          visible={props.visible}
          centered
          footer={[
              <Button 
                  key="back"
                  onClick={() => props.setVisibility(false)}
              >
                  Cancelar
              </Button>,
              <Button 
                  key="submit"
                  type="danger"
                  loading={performingWithdrawal}
                  onClick={ () => performWithdrawal() }
                  disabled = {( props.financial_account ? cleanFloatValue(withdrawal_value) > props.financial_account.balance : true )}
              >
                  Realizar retirada
              </Button>,
          ]}
          onCancel={() => props.setVisibility(false)}
      >
          <Row>
              <Col span={24}>
                  <Alert
                      message="Atenção: Essa retirada reduzirá o balanço da sua conta. A movimentação ficará registrada no extrato financeiro."
                      type="warning"
                      closable
                  />
              </Col>
          </Row>
            <Form layout='vertical'>
            <Row style={{ paddingTop: '15px' }}>
                <Col span={24}>
                    <Form.Item 
                        className="form-item" 
                        style={{ paddingLeft: 0, paddingRight: 0 }} 
                        label="Valor a ser retirado:"
                    >
                        <CurrencyInput
                            value={withdrawal_value}
                            onChangeEvent={ event => setWithdrawalValue(event.target.value)}
                            className="ant-input"
                            decimalSeparator=","
                            thousandSeparator="."
                            prefix="R$ "
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
      </Modal>
    );
}

export default Withdrawal;