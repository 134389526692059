import React from 'react'

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { PrivateRoute } from './components/Routes/PrivateRoute';
import { GuestRoute } from './components/Routes/GuestRoute';

import NotFound from './pages/errors/NotFound';

import Signin from './pages/Signin';
import Signup from './pages/Signup';
import ConfirmAccount from './pages/ConfirmAccount';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Dashboard from './pages/Dashboard';
import Settings from './pages/Settings';
import Users from './pages/Users';
import UsersAdd from './pages/UsersAdd';
import CostCenters from './pages/CostCenters';
import ProductBrands from './pages/ProductBrands';
import ProductCategories from './pages/ProductCategories';
import PriceLists from './pages/PriceLists';
import PriceListsProducts from './pages/PriceListsProducts';
import Billing from './pages/Billing';
import BillsToPay from './pages/BillsToPay';
import BillsToReceive from './pages/BillsToReceive';
import Employees from './pages/Employees';
import Contacts from './pages/Contacts';
import Services from './pages/Services';
import Products from './pages/Products';
import ProductsNew from './pages/ProductsNew';
import ProductsDetails from './pages/ProductsDetails';
import Stock from './pages/Stock';
import Sales from './pages/Sales';
import SaleAdd from './pages/SaleAdd';
import SaleDetails from './pages/SaleDetails';
import Supplies from './pages/Supplies';
import SupplyAdd from './pages/SupplyAdd';
import SupplyDetails from './pages/SupplyDetails';
import ServicesDetails from './pages/ServicesDetails';
import FinancialAccounts from './pages/FinancialAccounts';
import FinancialCategories from './pages/FinancialCategories';
import ChangeBillingPlan from './pages/ChangeBillingPlan';
import AuthHistory from './pages/AuthHistory';
import Subscription from './pages/Subscription';

import Transactions from './pages/Reports/Transactions';
import DRE from './pages/Reports/DRE';

const AppRoutes = () => {
    return <BrowserRouter>
        <Routes>
            {/* GUEST ROUTES */}
            <Route path="/" exact element={
                <GuestRoute redirectTo="/monitoramento" component={Signin}></GuestRoute>
            } />
            <Route path="/entrar" exact element={
                <GuestRoute redirectTo="/monitoramento" component={Signin}></GuestRoute>
            } />
            <Route path="/cadastre-se" exact element={
                <GuestRoute redirectTo="/monitoramento" component={Signup}></GuestRoute>
            } />
            <Route path="/recuperar-senha" exact element={
                <GuestRoute redirectTo="/monitoramento" component={ForgotPassword}></GuestRoute>
            } />
            <Route path="/recuperar-senha/:token" exact element={
                <GuestRoute redirectTo="/monitoramento" component={ResetPassword}></GuestRoute>
            } />
            <Route path="/primeiro-acesso/:token" exact element={
                <GuestRoute redirectTo="/monitoramento" component={ResetPassword}></GuestRoute>
            } />

            <Route path="/confirm/:token" exact element={<ConfirmAccount />} />

            {/* PRIVATE ROUTES */}
            <Route path="/configuracoes/assinatura/alterar" exact permissionKey="billing_edit" element={
                <PrivateRoute redirectTo="/entrar" component={ChangeBillingPlan}></PrivateRoute>
            } />
            <Route path="/configuracoes/historico-login" exact element={
                <PrivateRoute redirectTo="/entrar" component={AuthHistory}></PrivateRoute>
            } />
            <Route path="/monitoramento" exact element={
                <PrivateRoute redirectTo="/entrar" component={Dashboard}></PrivateRoute>
            } />
            <Route path="/configuracoes/empresa" permissionKey="account_edit" exact element={
                <PrivateRoute redirectTo="/entrar" component={Settings}></PrivateRoute>
            } />
            <Route path="/configuracoes/assinatura" permissionKey="billing_edit" exact element={
                <PrivateRoute redirectTo="/entrar" component={Billing}></PrivateRoute>
            } />
            <Route path="/configuracoes/usuarios/novo" permissionKey="users_edit" exact element={
                <PrivateRoute redirectTo="/entrar" component={UsersAdd}></PrivateRoute>
            } />
            <Route path="/configuracoes/usuarios/:id" permissionKey="users_edit" exact element={
                <PrivateRoute redirectTo="/entrar" component={UsersAdd}></PrivateRoute>
            } />
            <Route path="/configuracoes/usuarios" permissionKey="users_edit" exact element={
                <PrivateRoute redirectTo="/entrar" component={Users}></PrivateRoute>
            } />
            <Route path="/configuracoes/centros-de-custo" permissionKey="cost_centers_edit" exact element={
                <PrivateRoute redirectTo="/entrar" component={CostCenters}></PrivateRoute>
            } />
            <Route path="/configuracoes/marcas-de-produtos" permissionKey="brands_edit" exact element={
                <PrivateRoute redirectTo="/entrar" component={ProductBrands}></PrivateRoute>
            } />
            <Route path="/configuracoes/categorias-de-produtos" permissionKey="products_categories_edit" exact element={
                <PrivateRoute redirectTo="/entrar" component={ProductCategories}></PrivateRoute>
            } />
            <Route path="/listas-de-precos" permissionKey="prices_lists_edit" exact element={
                <PrivateRoute redirectTo="/entrar" component={PriceLists}></PrivateRoute>
            } />
            <Route path="/listas-de-precos/:id" permissionKey="prices_lists_edit" exact element={
                <PrivateRoute redirectTo="/entrar" component={PriceListsProducts}></PrivateRoute>
            } />
            <Route path="/contas-pagar" permissionKey="bills_edit" exact element={
                <PrivateRoute redirectTo="/entrar" component={BillsToPay}></PrivateRoute>
            } />
            <Route path="/contas-receber" permissionKey="bills_edit" exact element={
                <PrivateRoute redirectTo="/entrar" component={BillsToReceive}></PrivateRoute>
            } />
            <Route path="/extrato" permissionKey="transactions_view" exact element={
                <PrivateRoute redirectTo="/entrar" component={Transactions}></PrivateRoute>
            } />
            <Route path="/colaboradores" permissionKey="employees_edit" exact element={
                <PrivateRoute redirectTo="/entrar" component={Employees}></PrivateRoute>
            } />
            <Route path="/contatos" permissionKey="contacts_edit" exact element={
                <PrivateRoute redirectTo="/entrar" component={Contacts}></PrivateRoute>
            } />
            <Route path="/contatos/:type" permissionKey="contacts_edit" exact element={
                <PrivateRoute redirectTo="/entrar" component={Contacts}></PrivateRoute>
            } />
            <Route path="/contas-financeiras" permissionKey="financial_accounts_edit" exact element={
                <PrivateRoute redirectTo="/entrar" component={FinancialAccounts}></PrivateRoute>
            } />
            <Route path="/configuracoes/categorias-financeiras" permissionKey="financial_categories_edit" exact element={
                <PrivateRoute redirectTo="/entrar" component={FinancialCategories}></PrivateRoute>
            } />
            <Route path="/servicos" permissionKey="services_edit" exact element={
                <PrivateRoute redirectTo="/entrar" component={Services}></PrivateRoute>
            } />
            <Route path="/servicos/novo" permissionKey="services_edit" exact element={
                <PrivateRoute redirectTo="/entrar" component={ServicesDetails}></PrivateRoute>
            } />
            <Route path="/servicos/:id" permissionKey="services_edit" exact element={
                <PrivateRoute redirectTo="/entrar" component={ServicesDetails}></PrivateRoute>
            } />
            <Route path="/estoque" permissionKey="stock_edit" exact element={
                <PrivateRoute redirectTo="/entrar" component={Stock}></PrivateRoute>
            } />
            <Route path="/produtos" permissionKey="products_edit" exact element={
                <PrivateRoute redirectTo="/entrar" component={Products}></PrivateRoute>
            } />
            <Route path="/produtos/novo" permissionKey="products_edit" exact element={
                <PrivateRoute redirectTo="/entrar" component={ProductsNew}></PrivateRoute>
            } />
            <Route path="/produtos/:id" permissionKey="products_edit" exact element={
                <PrivateRoute redirectTo="/entrar" component={ProductsDetails}></PrivateRoute>
            } />
            <Route path="/vendas" permissionKey="sales_edit" exact element={
                <PrivateRoute redirectTo="/entrar" component={Sales}></PrivateRoute>
            } />
            <Route path="/vendas/novo" permissionKey="sales_edit" exact element={
                <PrivateRoute redirectTo="/entrar" component={SaleAdd}></PrivateRoute>
            } />
            <Route path="/vendas/:id" permissionKey="sales_edit" exact element={
                <PrivateRoute redirectTo="/entrar" component={SaleDetails}></PrivateRoute>
            } />
            <Route path="/compras" permissionKey="supplies_edit" exact element={
                <PrivateRoute redirectTo="/entrar" component={Supplies}></PrivateRoute>
            } />
            <Route path="/compras/novo" permissionKey="supplies_edit" exact element={
                <PrivateRoute redirectTo="/entrar" component={SupplyAdd}></PrivateRoute>
            } />
            <Route path="/compras/:id" permissionKey="supplies_edit" exact element={
                <PrivateRoute redirectTo="/entrar" component={SupplyDetails}></PrivateRoute>
            } />
            <Route path="/recorrencias" permissionKey="bills_edit" exact element={
                <PrivateRoute redirectTo="/entrar" component={Subscription}></PrivateRoute>
            } />
            <Route path="/relatorio/dre" permissionKey="reports_edit" exact element={
                <PrivateRoute redirectTo="/entrar" component={DRE}></PrivateRoute>
            } />

            {/* NOT FOUND ROUTE */}
            <Route path="*" element={<NotFound />} />
        </Routes>
    </BrowserRouter>
};

export default AppRoutes;