import React, { useState, useEffect } from 'react';
import {Link, useParams, useNavigate} from 'react-router-dom';
import { 
    Layout,
    Form,
    Breadcrumb,
    Input,
    Button,
    Row,
    Col,
    Select,
    message,
    Card,
    Switch,
    List,
} from 'antd';
import { 
    CheckOutlined,
    CloseOutlined,
} from '@ant-design/icons';

import Topbar from '../components/Topbar';
import Footbar from '../components/Footbar';
import Loading from '../components/Loading';

import 'antd/dist/antd.css';
import './styles/Main.css';

import api from '../services/api.js';

const { Content } = Layout;
const { Option } = Select;

export default function UsersAdd() {
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    
    const [roles, setRoles] = useState([]);
    const [selected_role, setSelectedRole] = useState({});
    
    const [name_disabled, setNameDisabled] = useState(false);
    const [email_disabled, setEmailDisabled] = useState(false);
    
    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [status, setStatus] = useState('A');

    let params   = useParams();
    let navigate = useNavigate();
    
    useEffect(() => {
        async function loadRoles(){
            setLoading(true);
            let response = await api.get(`/roles`);
            setRoles(response.data.data);
            setLoading(false);
        }

        async function loadUser() {
            if(params.id){
                setLoading(true);
                
                let response = await api.get(`/users/${params.id}`);
                let user = response.data.data;
                
                setNameDisabled(true);
                setEmailDisabled(true);
                
                setId(user.id);
                setSelectedRole(user.role);
                setName(user.name);
                setEmail(user.email);
                setStatus(user.status);
                
                setLoading(false);
            } else {
                setSelectedRole(roles[0]);
            }
        }

        checkLimit();
        loadRoles();
        loadUser();
    }, []);

    async function checkLimit() {
        setLoading(true);
        let response = await api.get('/billing/limits/users');
        let hasLimit = response.data.data.has_limit;
        
        setLoading(false);
        
        if(!hasLimit){
            navigate('/configuracoes/usuarios');
        }
    }

    async function saveUser() {
        setSaving(true);

        try {
            let response = {};
            if(id){
                response = await api.put(`/users/${id}`, {
                    role_id: selected_role.id,
                    status,
                });
            } else {
                response = await api.post('/users', {
                    role_id: selected_role.id,
                    name,
                    email,
                    status,
                });
            }

            if(response.data.success) {
                message.success('Usuário salvo com sucesso');
            } else {
                message.error('Ocorreu um erro, aguarde alguns instantes e tente novamente');
            }

            setSaving(false);

            navigate('/configuracoes/usuarios');
        } catch (error) {
            message.error('Ocorreu um erro, aguarde alguns instantes e tente novamente');
            setSaving(false);
        }
    }

    let loading_screen = '';
    if(loading){
        loading_screen = <Loading />
    }

    return (
        <Layout className="layout">
            { loading_screen }
            <Topbar />

            <Content className="fbm-main">
                <div id="main">
                    <Row>
                        <Col span={24} className="fbm-page-header">
                            <Breadcrumb className="breadcrumb" style={{ margin: '16px 0' }}>
                                <Breadcrumb.Item><Link to="/monitoramento">Monitoramento</Link></Breadcrumb.Item>
                                <Breadcrumb.Item><Link to="/configuracoes/usuarios">Usuários</Link></Breadcrumb.Item>
                                <Breadcrumb.Item>{id ? 'Editar Usuário' : 'Novo Usuário'}</Breadcrumb.Item>
                            </Breadcrumb>

                            <div className="actions">
                                <a href="/configuracoes/usuarios">
                                    <Button type="secondary-default">
                                        Cancelar
                                    </Button>
                                </a>
                                <Button 
                                    type="success"
                                    onClick={saveUser}
                                    loading={saving}
                                    disabled={(
                                        !email ||
                                        !name ||
                                        !status ||
                                        !selected_role.id
                                    )}
                                >
                                    Salvar
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ marginLeft: '-10px', marginRight: '-10px' }}>
                        <Col span={24} style={{ padding: '0 10px 10px 10px' }}>
                            <Card className="fbm-card" loading={loading}>
                                <Form layout='vertical'>
                                    <Row>
                                        <Col span={24} >
                                            <h5 className="page-title" style={{ fontWeight:'bold' }}>{id ? 'Editar Usuário' : 'Novo Usuário'}</h5>
                                        </Col>
                                        <Col span={24}>
                                            <p>
                                                Informe abaixo os dados do usuário.
                                                <br/>
                                                {id ? '' : 'O usuário receberá um e-mail com as instruções de acesso e a solicitação de definição de senha.'}
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={8}>
                                            <Form.Item
                                                className="form-item"
                                                label="Nome:"
                                                required={true}
                                                style={{ paddingLeft: 0 }}
                                            >
                                                <Input
                                                    id="name"
                                                    value={ name }
                                                    onChange={ event => setName(event.target.value) }
                                                    disabled={ name_disabled }
                                                    required
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={10}>
                                            <Form.Item
                                                className="form-item"
                                                label="Email:"
                                                required={true}
                                            >
                                                <Input
                                                    id="email"
                                                    value={ email }
                                                    onChange={ event => setEmail(event.target.value) }
                                                    disabled={ email_disabled }
                                                    required
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={8}>
                                            <Form.Item
                                                className="form-item"
                                                label="Perfil de acesso:"
                                                required={true}
                                                style={{ paddingLeft: 0 }}
                                            >
                                                <Select 
                                                    value={selected_role.id} 
                                                    onChange={ async (value) => {
                                                        roles.forEach(role => {
                                                            if(role.id === value){
                                                                setSelectedRole(role);
                                                            }
                                                        });
                                                    } }
                                                    loading={ loading }
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    { roles.map(item => (
                                                        <Option
                                                            key={item.id}
                                                            value={ item.id }
                                                        >
                                                            { item.name }
                                                        </Option>
                                                    )) }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={10}>
                                            <Form.Item
                                                className="form-item"
                                                label="Status:"
                                                required={true}
                                            >
                                                <Switch 
                                                    checkedChildren={<CheckOutlined />}
                                                    unCheckedChildren={<CloseOutlined />}
                                                    checked={( status === 'A' )}
                                                    disabled={saving}
                                                    onChange={(value) => {
                                                        if(value){
                                                            setStatus('A')
                                                        } else {
                                                            setStatus('I')
                                                        }
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                                <Row style={{paddingTop: '25px'}}>
                                    <Col span={24} >
                                        <h5 className="page-title" style={{ fontWeight:'bold' }}>{selected_role.name}</h5>
                                    </Col>
                                    <Col span={24}>
                                        <p>
                                            Visualize abaixo as permissões deste perfil..
                                        </p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <List
                                            itemLayout="horizontal"
                                            dataSource={selected_role ? selected_role.role_permission : []}
                                            renderItem={item => (
                                                <List.Item>
                                                    <List.Item.Meta
                                                        title={item.permission.name}
                                                        description={item.permission.description}
                                                    />
                                                </List.Item>
                                            )}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Content>

            <Footbar />
        </Layout>
    )
}