import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import { 
    Layout,
    Form,
    Breadcrumb,
    Input,
    Button,
    Row,
    Col,
    Select,
    message,
    Table,
    Modal,
    Tooltip,
    Card,
    Empty,
} from 'antd';
import { 
    PlusOutlined,
    EditOutlined,
    DeleteOutlined
} from '@ant-design/icons';

import Topbar from '../components/Topbar';
import Footbar from '../components/Footbar';
import SettingsSidebar from '../components/SettingsSidebar';

import 'antd/dist/antd.css';
import './styles/Main.css';
import './styles/Settings.css';

import api from '../services/api.js';

import brands_svg from '../assets/brands.svg';

const { Search } = Input;
const { Content } = Layout;
const { confirm } = Modal;
const { Option } = Select;

export default function ProductBrands() {
    // const [loading, setLoading] = useState(false);
    const [loadingTable, setLoadingTable] = useState(true);
    const [loadingBrand, setLoadingBrand] = useState(false);
    const [saving, setSaving] = useState(false);
    const [visibleModal, setVisibleModal] = useState(false);

    const [data, setData] = useState([]);
    const [tableLength, setTableLength] = useState(10);
    const [tablePagination, setTablePagination] = useState({current: 1, pageSize: tableLength});
    const [tableFilters, setTableFilters] = useState({});
    const [tableSorter, setTableSorter] = useState({ field: 'name', order: 'ascend' });
    const [search, setSearch] = useState('');
    
    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [note, setNote] = useState('');

    useEffect(() => {
        loadData();
    }, [tableLength]);

    async function reloadData(){
        loadData({current: 1, pageSize: tableLength});
    }

    async function loadData(
        pagination = tablePagination,
        filters = tableFilters,
        sorter = tableSorter
    ) {
        setLoadingTable(true);

        setTablePagination(pagination);
        setTableFilters(filters);
        if(!sorter.field){
            sorter = tableSorter
        }
        setTableSorter(sorter);

        let order = sorter.order;
        if(order === undefined) {
            order = 'asc';
        } else if(sorter.order === 'ascend') {
            order = 'asc';
        } else if(sorter.order === 'descend') {
            order = 'desc';
        }

        let response = await api.get(`/brands?page=${pagination.current}&per_page=${tableLength}&order=${order}&order_by=${sorter.field}&search=${search}`);

        let brands_list = response.data.data;

        setData(brands_list);
        setTablePagination({
            current: pagination.current,
            pageSize: tableLength,
            total: brands_list.total,
        });

        setLoadingTable(false);
    }

    function newBrand() {
        setId('');
        setName('');
        setNote('');

        setVisibleModal(true);
    }

    async function showBrand(brand_id) {
        setLoadingBrand(true);

        let response = await api.get(`/brands/${brand_id}`);

        let brand = response.data.data;

        setId(brand.id);
        setName(brand.name);
        setNote(brand.note);

        setVisibleModal(true);
        setLoadingBrand(false);
    }

    async function saveBrand() {
        setSaving(true);

        const data = {
            name,
            note,
        };

        try {
            let response = [];

            if(id)
                response = await api.put(`/brands/${id}`, data);
            else
                response = await api.post('/brands', data);

            if(response.data.success) {
                message.success('Marca salva com sucesso');
            } else {
                message.error('Ocorreu um erro, aguarde alguns instantes e tente novamente');
            }

            await loadData();
            setSaving(false);
            setVisibleModal(false);
        } catch (error) {
            message.error('Ocorreu um erro, aguarde alguns instantes e tente novamente');
            setSaving(false);
        }
    }

    function deleteBrand(brand_id) {
        confirm({
            title: 'Você tem certeza?',
            content: 'Esta ação não poderá ser desfeita!',
            okText: 'Deletar',
            okType: 'danger',
            cancelText: 'Cancelar',
            async onOk() {
                let response = await api.delete(`/brands/${brand_id}`);

                if(response.data.success) {
                    message.success('Marca deletada com sucesso');
                } else {
                    message.error('Ocorreu um erro, aguarde alguns instantes e tente novamente');
                }

                await loadData();
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }
    const columns = [
        {
            title: 'NOME',
            dataIndex: 'name',
            sorter: true,
            width: '30%',
        }, {
            title: 'DESCRIÇÃO',
            dataIndex: 'note',
            width: '50%',
        }, {
            title: 'AÇÕES',
            width: '20%',
            render: (brand => (
                <>
                    <Tooltip title="Editar" placement="left">
                        <Button 
                            type="secondary-default" 
                            icon={<EditOutlined />}
                            onClick={event => showBrand(brand.id)}
                            disabled={loadingBrand}
                            style={{
                                paddingTop: '4px',
                                paddingBottom: '4px',
                                paddingLeft: '10px',
                                paddingRight: '10px',
                                width:'auto',
                            }}
                        ></Button>
                    </Tooltip>
                    <Tooltip title="Deletar" placement="right">
                        <Button 
                            type="secondary-default"
                            icon={<DeleteOutlined />}
                            onClick={event => deleteBrand(brand.id)}
                            disabled={loadingBrand}
                            style={{ 
                                marginLeft: '5px',
                                paddingTop: '4px',
                                paddingBottom: '4px',
                                paddingLeft: '10px',
                                paddingRight: '10px',
                                width:'auto',
                                color: '#ff4d4f !important',
                            }}
                        ></Button>
                    </Tooltip>
                </>
            )),
        },
    ];

    // let loading_screen = '';
    // if(loading){
    //     loading_screen = <Loading />
    // }

    return (
        <Layout className="layout">
            {/* { loading_screen } */}
            <Topbar />

            <Content className="fbm-main">
                <div id="main">
                    <Row style={{ marginLeft: '-10px', marginRight: '-10px' }}>
                        <Col span={5} style={{ padding: '0 10px 10px 10px' }}>
                            <SettingsSidebar actived="products_brands" />
                        </Col>
                        <Col span={19} style={{ padding: '0 10px 10px 10px' }}>
                            <Card className="fbm-card">
                                <Row style={{ paddingBottom: '15px', borderBottomWidth: '0.5px', borderBottomColor: '#e8e8e8', borderBottomStyle: 'solid' }}>
                                    <Col className="fbm-card-header" span={24}>
                                        <h1 className="big-title">
                                            Marcas de Produtos
                                        </h1>
                                        <Button 
                                            type="success"
                                            onClick={newBrand}
                                            style={{marginLeft: '15px', borderRadius: '50%'}}
                                        >
                                            <PlusOutlined />
                                        </Button>
                                    </Col>
                                </Row>
                                <Row style={{ paddingTop: '15px', paddingBottom: '15px' }}>
                                    <Col span={24}>
                                        <div style={{ overflow: 'auto' }}>
                                            <Form.Item
                                                className="form-item"
                                                style={{ 
                                                    paddingTop: 0, 
                                                    paddingRight: 0,
                                                    paddingLeft: 0,
                                                    paddingBottom: 0,
                                                    width: '100px',
                                                    float: 'left',
                                                }}
                                            >
                                                <Select 
                                                    value={tableLength} 
                                                    onChange={ async (value) => setTableLength(value) }
                                                >
                                                    <Option key="10" value="10">10</Option>
                                                    <Option key="25" value="25">25</Option>
                                                    <Option key="50" value="50">50</Option>
                                                    <Option key="100" value="100">100</Option>
                                                </Select>
                                            </Form.Item>
                                            <Form.Item
                                                className="form-item"
                                                style={{ 
                                                    paddingTop: 0,
                                                    paddingRight: 0,
                                                    paddingLeft: 0,
                                                    paddingBottom: 0,
                                                    width: '200px',
                                                    float: 'right',
                                                }}
                                            >
                                                <Search
                                                    id="search"
                                                    placeholder="Pesquisar"
                                                    value={search}
                                                    onChange={ event => setSearch(event.target.value) }
                                                    onSearch={ value => reloadData() }
                                                    loading={ loadingTable }
                                                />
                                            </Form.Item>
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{ paddingBottom: '0' }}>
                                    <Col span={24}>
                                        <Table
                                            bordered={true}
                                            columns={columns}
                                            rowKey={record => record.id}
                                            dataSource={data.data}
                                            pagination={tablePagination}
                                            loading={loadingTable}
                                            onChange={loadData}
                                            pageSize={tableLength}
                                            locale={{ emptyText: <Empty description={<b>Nenhum registro encontrado...</b>} /> }}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Content>

            <Footbar />


            <Modal
                title="Marca"
                visible={visibleModal}
                centered
                footer={[
                    <Button 
                        key="back"
                        onClick={() => setVisibleModal(false)}
                    >
                        Cancelar
                    </Button>,
                    <Button 
                        key="submit"
                        type="primary"
                        loading={saving}
                        onClick={ () => saveBrand() }
                    >
                        Salvar
                    </Button>,
                ]}
                onCancel={() => setVisibleModal(false)}
            >
                <Form layout='vertical'>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                className="form-item"
                                label="Nome:"
                                required={true}
                                hasFeedback
                            >
                                <Input
                                    id="name"
                                    value={ name }
                                    onChange={ event => setName(event.target.value) }
                                    required
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                className="form-item"
                                label="Descrição:"
                            >
                                <Input
                                    id="note"
                                    value={ note }
                                    onChange={ event => setNote(event.target.value) }
                                    required
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </Layout>
    )
}