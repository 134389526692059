import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import { 
    DashboardOutlined,
    ShoppingCartOutlined,
    PlusCircleOutlined,
    DollarOutlined,
    CreditCardOutlined,
    TeamOutlined,
    ShopOutlined,
    TagOutlined,
    ApartmentOutlined,
    RetweetOutlined,
    SettingOutlined,
    IdcardOutlined,
    AuditOutlined,
    UserOutlined,
    InboxOutlined,
    BarcodeOutlined,
    ToolOutlined,
    FormOutlined,
    ReloadOutlined,
    FileTextOutlined
} from '@ant-design/icons';

import { listPermissions } from '../../services/auth';

import './style.css';

const { SubMenu } = Menu;

function Menubar() {
    const [permissions, setPermissions] = useState({});

    useEffect(() => {
        async function loadPermissions(){
            setPermissions(listPermissions());
        }

        loadPermissions();
    }, []);

    return (
        <Menu className="menubar" mode="horizontal">
            <Menu.Item key="dashboard">
                <Link to="/monitoramento" rel="noopener noreferrer">
                    <DashboardOutlined />
                    Monitoramento
                </Link>
            </Menu.Item>

            { (permissions.sales_edit || permissions.supplies_edit) && 
                <SubMenu
                    title={
                        <span className="submenu-title-wrapper">
                            <ShoppingCartOutlined />
                            Movimentações
                        </span>
                    }
                >
                    { permissions.sales_edit && 
                        <Menu.Item key="sales:1">
                            <Link to="/vendas/novo">
                                <PlusCircleOutlined />
                                Nova Venda
                            </Link>
                        </Menu.Item>
                    }
                    { permissions.sales_edit && 
                        <Menu.Item key="sales:2">
                            <Link to="/vendas" rel="noopener noreferrer">
                                <ShoppingCartOutlined />
                                Vendas
                            </Link>
                        </Menu.Item>
                    }
                    { permissions.supplies_edit && 
                        <Menu.Item key="purchases:1">
                            <Link to="/compras/novo" rel="noopener noreferrer">
                                <PlusCircleOutlined />
                                Nova Compra
                            </Link>
                        </Menu.Item>
                    }
                    { permissions.supplies_edit && 
                        <Menu.Item key="purchases:2">
                            <Link to="/compras" rel="noopener noreferrer">
                                <ShoppingCartOutlined />
                                Compras
                            </Link>
                        </Menu.Item>
                    }
                </SubMenu>
            }

            { (
                permissions.financial_accounts_edit ||
                permissions.transactions_view ||
                permissions.bills_edit
            ) && 
                <SubMenu
                    title={
                        <span className="submenu-title-wrapper">
                            <DollarOutlined />
                            Financeiro
                        </span>
                    }
                >
                    { permissions.financial_accounts_edit && 
                        <Menu.Item key="bills:1">
                            <Link to="/contas-financeiras" rel="noopener noreferrer">
                                <CreditCardOutlined />
                                Contas
                            </Link>
                        </Menu.Item>
                    }
                    { permissions.bills_edit && 
                        <Menu.Item key="bills:2">
                            <Link to="/contas-pagar" rel="noopener noreferrer">
                                <DollarOutlined />
                                Contas a Pagar
                            </Link>
                        </Menu.Item>
                    }
                    { permissions.bills_edit && 
                        <Menu.Item key="bills:3">
                            <Link to="/contas-receber" rel="noopener noreferrer">
                                <DollarOutlined />
                                Contas a Receber
                            </Link>
                        </Menu.Item>
                    }
                    { permissions.bills_edit && 
                        <Menu.Item key="bills:4">
                            <Link to="/recorrencias" rel="noopener noreferrer">
                                <ReloadOutlined />
                                Recorrências
                            </Link>
                        </Menu.Item>
                    }
                </SubMenu>
            }

            { (
                permissions.prices_lists_edit ||
                permissions.stock_edit ||
                permissions.products_edit ||
                permissions.services_edit
            ) && 
                <SubMenu
                    title={
                        <span className="submenu-title-wrapper">
                            <InboxOutlined />
                            Produtos e Serviços
                        </span>
                    }
                >
                    { permissions.services_edit && 
                        <Menu.Item key="services">
                            <Link to="/servicos" rel="noopener noreferrer">
                                <ToolOutlined />
                                Serviços
                            </Link>
                        </Menu.Item>
                    }
                    { permissions.products_edit && 
                        <Menu.Item key="products">
                            <Link to="/produtos" rel="noopener noreferrer">
                                <BarcodeOutlined />
                                Produtos
                            </Link>
                        </Menu.Item>
                    }
                    { permissions.stock_edit && 
                        <Menu.Item key="stock">
                            <Link to="/estoque" rel="noopener noreferrer">
                                <InboxOutlined />
                                Estoque
                            </Link>
                        </Menu.Item>
                    }
                    { permissions.prices_lists_edit && 
                        <Menu.Item key="price_list">
                            <Link to="/listas-de-precos" rel="noopener noreferrer">
                                <ShoppingCartOutlined />
                                Listas de Preços
                            </Link>
                        </Menu.Item>
                    }
                </SubMenu>
            }

            { (
                permissions.transactions_view &&
                permissions.reports_edit
            ) && 
                <SubMenu
                    title={
                        <span className="submenu-title-wrapper">
                            <FileTextOutlined />
                            Relatórios
                        </span>
                    }
                >
                    { permissions.transactions_view && 
                        <Menu.Item key="reports:1">
                            <Link to="/extrato" rel="noopener noreferrer">
                                <FileTextOutlined />
                                Extrato de Movimentações
                            </Link>
                        </Menu.Item>
                    }
                    { permissions.reports_edit && 
                        <Menu.Item key="reports:2">
                            <Link to="/relatorio/dre" rel="noopener noreferrer">
                                <FileTextOutlined />
                                DRE Gerencial
                            </Link>
                        </Menu.Item>
                    }
                </SubMenu>
            }

            { (
                permissions.contacts_edit ||
                permissions.employees_edit
            ) && 
                <SubMenu
                    title={
                        <span className="submenu-title-wrapper">
                            <FormOutlined />
                            Cadastros
                        </span>
                    }
                >
                    { permissions.contacts_edit && 
                        <Menu.Item key="clients:1">
                            <Link to="/contatos" rel="noopener noreferrer">
                                <UserOutlined />
                                Todos os contatos
                            </Link>
                        </Menu.Item>
                    }
                    { permissions.contacts_edit && 
                        <Menu.Item key="clients:2">
                            <Link to="/contatos/clientes" rel="noopener noreferrer">
                                <UserOutlined />
                                Clientes
                            </Link>
                        </Menu.Item>
                    }
                    { permissions.contacts_edit && 
                        <Menu.Item key="providers">
                            <Link to="/contatos/fornecedores" rel="noopener noreferrer">
                                <AuditOutlined />
                                Fornecedores
                            </Link>
                        </Menu.Item>
                    }
                    { permissions.employees_edit && 
                        <Menu.Item key="contributors">
                            <Link to="/colaboradores" rel="noopener noreferrer">
                                <IdcardOutlined />
                                Colaboradores
                            </Link>
                        </Menu.Item>
                    }
                </SubMenu>
            }

            { (
                permissions.account_edit ||
                permissions.billing_edit ||
                permissions.products_categories_edit ||
                permissions.brands_edit ||
                permissions.financial_categories_edit ||
                permissions.cost_centers_edit
            ) && 
                <SubMenu
                    title={
                        <span className="submenu-title-wrapper">
                            <SettingOutlined />
                            Configurações
                        </span>
                    }
                >
                    { permissions.account_edit && 
                        <Menu.Item key="configuracoes">
                            <Link to="/configuracoes/empresa" rel="noopener noreferrer">
                                <ShopOutlined />
                                Dados da Empresa
                            </Link>
                        </Menu.Item>
                    }
                    { permissions.billing_edit && 
                        <Menu.Item key="assinatura">
                            <Link to="/configuracoes/assinatura" rel="noopener noreferrer">
                                <RetweetOutlined />
                                Meu Plano
                            </Link>
                        </Menu.Item>
                    }
                    { permissions.financial_categories_edit && 
                        <Menu.Item key="bills:2">
                            <Link to="/configuracoes/categorias-financeiras" rel="noopener noreferrer">
                                <ApartmentOutlined />
                                Categorias Financeiras
                            </Link>
                        </Menu.Item>
                    }
                    { permissions.cost_centers_edit && 
                        <Menu.Item key="bills:2">
                            <Link to="/configuracoes/centros-de-custo" rel="noopener noreferrer">
                                <ApartmentOutlined />
                                Centros de Custos
                            </Link>
                        </Menu.Item>
                    }
                    { permissions.products_categories_edit && 
                        <Menu.Item key="bills:2">
                            <Link to="/configuracoes/categorias-de-produtos" rel="noopener noreferrer">
                                <TagOutlined />
                                Categorias de Produtos
                            </Link>
                        </Menu.Item>
                    }
                    { permissions.brands_edit && 
                        <Menu.Item key="bills:2">
                            <Link to="/configuracoes/marcas-de-produtos" rel="noopener noreferrer">
                                <ShopOutlined />
                                Marcas de Produtos
                            </Link>
                        </Menu.Item>
                    }
                    { permissions.users_edit && 
                        <Menu.Item key="usuarios">
                            <Link to="/configuracoes/usuarios" rel="noopener noreferrer">
                                <TeamOutlined />
                                Usuários
                            </Link>
                        </Menu.Item>
                    }
                </SubMenu>
            }
      </Menu>
    );
}

export default Menubar;