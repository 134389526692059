import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import {
    Layout,
    Form,
    Breadcrumb,
    Input,
    Button,
    Row,
    Col,
    Select,
    DatePicker,
    Table,
    Modal,
    Tooltip,
    Card,
    Tag,
    Empty,
    Menu,
    Dropdown,
    message,
} from 'antd';
import { 
    CaretDownFilled,
    EditOutlined,
    PlusOutlined, 
    DeleteOutlined, 
    ShoppingCartOutlined, 
    FilePdfOutlined, 
    DollarOutlined 
} from '@ant-design/icons';

import locale from 'antd/es/date-picker/locale/pt_BR';
import {NumericFormat} from 'react-number-format';
import { parseISO, format } from 'date-fns';
import moment from 'moment';
import 'moment/locale/pt-br';

import Topbar from '../components/Topbar';
import Footbar from '../components/Footbar';
import Loading from '../components/Loading';
import BillEditModal from '../components/Modal/BillEdit';
import BillDetailsModal from '../components/Modal/BillDetails';
import BillPaymentsModal from '../components/Modal/BillPayments';
import BillFilesModal from '../components/Modal/BillFiles';

import 'antd/dist/antd.css';
import './styles/Main.css';

import api from '../services/api.js';

const { Search } = Input;
const { Content } = Layout;
const { Option, OptGroup } = Select;
const { confirm } = Modal;

export default function BillsToReceive() {
    const [loadingTable, setLoadingTable] = useState(true);
    const [loading, setLoading] = useState(false);
    const [loading_cost_centers, setLoadingCostCenters] = useState(false);
    const [loading_categories, setLoadingCategories] = useState(false);
    const [loading_clients, setLoadingClients] = useState(false);

    const [show_bill_modal, setShowBillModal] = useState(false);
    const [show_bill_details_modal, setShowBillDetailsModal] = useState(false);
    const [show_bill_payments_modal, setShowBillPaymentsModal] = useState(false);
    const [show_bill_files_modal, setShowBillFilesModal] = useState(false);
    const [bill, setBill] = useState({});
    
    const [data, setData] = useState([]);
    const [summary, setSummary] = useState([]);

    const [cost_centers, setCostCenters] = useState([]);
    const [categories, setCategories] = useState([]);
    const [clients, setClients] = useState([]);

    const [filter_cost_center, setFilterCostCenter] = useState('');
    const [filter_category, setFilterCategory] = useState('');
    const [filter_client, setFilterClient] = useState('');
    const [filter_issue_date_begin, setFilterIssueDateBegin] = useState('');
    const [filter_issue_date_end, setFilterIssueDateEnd] = useState('');
    const [filter_competence_date_begin, setFilterCompetenceDateBegin] = useState('');
    const [filter_competence_date_end, setFilterCompetenceDateEnd] = useState('');
    const [filter_due_date_begin, setFilterDueDateBegin] = useState(format(new Date(), "yyyy-MM-dd"));
    const [filter_due_date_end, setFilterDueDateEnd] = useState(format(new Date(), "yyyy-MM-dd"));
    const [filter_payday_begin, setFilterPaydayBegin] = useState('');
    const [filter_payday_end, setFilterPaydayEnd] = useState('');
    const [filter_document_number, setFilterDocumentNumber] = useState('');
    const [filter_origin, setFilterOrigin] = useState('');
    const [filter_status, setFilterStatus] = useState('');
    const [has_limit, setHasLimit] = useState(false);

    const [tableLength, setTableLength] = useState(10);
    const [tablePagination, setTablePagination] = useState({current: 1, pageSize: tableLength});
    const [tableFilters, setTableFilters] = useState({});
    const [tableSorter, setTableSorter] = useState({ field: 'issue_date', order: 'descend' });
    const [search, setSearch] = useState('');

    useEffect( () => {
        checkLimit();
    }, []);

    useEffect( () => {
        loadData();
    }, [tableLength]);

    async function checkLimit() {
        setLoading(true);
        let response = await api.get('/billing/limits/bills_to_receive');
        let hasLimit = response.data.data.has_limit;
        setHasLimit(hasLimit);
        setLoading(false);
    }

    async function reloadData(){
        loadData({current: 1, pageSize: tableLength});
    }

    async function loadData(
        pagination = tablePagination,
        filters = tableFilters,
        sorter = tableSorter
    ) {
        setLoadingTable(true);

        setTablePagination(pagination);
        setTableFilters(filters);

        if(!sorter.field){
            sorter = tableSorter
        }

        setTableSorter(sorter);

        let order = sorter.order;
        if(order === undefined) {
            order = 'asc';
        } else if(sorter.order === 'ascend') {
            order = 'asc';
        } else if(sorter.order === 'descend') {
            order = 'desc';
        }

        let response = await api.get('/bills/plots', {
            params: {
                type: 'to_receive',
                page: pagination.current,
                per_page: tableLength,
                order: order,
                order_by: sorter.field,
                search: search,
                issue_date_begin: filter_issue_date_begin,
                issue_date_end: filter_issue_date_end,
                due_date_begin: filter_due_date_begin,
                due_date_end: filter_due_date_end,
                competence_date_begin: filter_competence_date_begin,
                competence_date_end: filter_competence_date_end,
                payday_begin: filter_payday_begin,
                payday_end: filter_payday_end,
                document_number: filter_document_number,
                cost_center: filter_cost_center,
                contact: filter_client,
                category: filter_category,
                status: filter_status,
            },
        });

        setSummary(response.data.summary);
        let bills_list = response.data.data;

        setData(bills_list);
        setTablePagination({
            current: pagination.current,
            pageSize: tableLength,
            total: bills_list.total,
        });

        setLoadingTable(false);
    }

    async function showBill(bill = null) {
        setBill(bill);
        setShowBillModal(true);
    }

    async function showBillDetails(bill = null) {
        setBill(bill);
        setShowBillDetailsModal(true);
    }

    async function showBillPayments(bill = null) {
        setBill(bill);
        setShowBillPaymentsModal(true);
    }

    async function showBillFiles(bill = null) {
        setBill(bill);
        setShowBillFilesModal(true);
    }

    async function loadCostCenters(){
        setLoadingCostCenters(true);
        setCostCenters([]);
        let response = await api.get(`/cost_centers`);
        setCostCenters(response.data.data);
        setLoadingCostCenters(false);
    }

    async function loadCategories(){
        setLoadingCategories(true);
        setCategories([]);
        let response = await api.get(`/financial_categories?type=REVENUE&filter=parents`);
        setCategories(response.data.data);
        setLoadingCategories(false);
    }

    async function loadClients(){
        setLoadingClients(true);
        setClients([]);
        let response = await api.get(`/contacts?type=client`);
        setClients(response.data.data);
        setLoadingClients(false);
    }

    function deleteBill(bill_id) {
        confirm({
            title: 'Você deseja deletar a despesa e todas as suas parcelas?',
            content: 'Esta ação não poderá ser desfeita!',
            okText: 'Deletar',
            okType: 'danger',
            cancelText: 'Cancelar',
            async onOk() {
                let response = await api.delete(`/bills/${bill_id}`);

                if(response.data.success) {
                    message.success('Despesa deletada com sucesso');
                } else {
                    message.error('Ocorreu um erro, aguarde alguns instantes e tente novamente');
                }
                loadData();
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    function formatDate(date){
        if(date !== null && date !== '' && date !== '0000-00-00'){
            date = parseISO(date);
            return format(date, 'dd/MM/yyyy');
        }
        return '-';
    }

    function fixStatus(status){
        switch (status) {
            case 'A':
                return <span style={{fontWeight:'bold',color:'#ff4d4f'}}>Não recebido</span>;
            case 'P':
                return <span style={{fontWeight:'bold',color:'#2dce89'}}>Recebido</span>;
            case 'PP':
                return <span style={{fontWeight:'bold',color:'#ffc107'}}>Parcialmente Recebido</span>;
            default:
                return '-';
        }
    }

    const columns = [
        {
            title: 'DESCRIÇÃO',
            align: 'left',
            render: ( plot => (
                <>
                    { plot.bill.total_plots > 1 && 
                        <span style={{ fontWeight:'bold', color:'#0086ea', fontSize: '14px' }}>
                            { `[${plot.plot_number}/${plot.bill.total_plots}] ` }
                        </span>
                    }
                    <span style={{ fontWeight:'bold', fontSize: '14px' }}>
                        { plot.description ? plot.description : '-' }
                    </span>
                    <br />
                    <span style={{ color:'#9797ad', fontSize: '12px' }}>
                        { plot.contact && <>
                            de { plot.contact.name }
                            <br />
                        </>}
                    </span>
                    { plot.bill.document_number && 
                        <>
                            <span style={{ color:'#9797ad', fontSize: '12px' }}>
                                Documento: { plot.bill.document_number }
                            </span>
                        </>
                    }
                </>
            )),
        }, {
            title: 'CATEGORIA',
            render: ( plot => (
                plot.category.description ? plot.category.description : '-'
            )),
        }, {
            title: 'CENTRO DE CUSTOS',
            render: ( plot => (
                plot.cost_center && plot.cost_center.name ? plot.cost_center.name : '-'
            )),
        }, {
            title: 'VENCIMENTO',
            render: ( plot => (
                formatDate(plot.due_date)
            )),
        }, {
            title: 'VALOR (R$)',
            render: ( plot => (
                <>
                    <NumericFormat 
                        value={plot.value ? plot.value : 0}
                        displayType={'text'}
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={2} 
                        fixedDecimalScale={true}
                        style={{ fontWeight: 'bold', fontSize: '14px' }}
                    />
                    <br />
                    <span style={{ fontSize: '12px' }}>
                        {fixStatus(plot.status)}
                    </span>
                </>
            )),
            align: 'right',
        }, {
            title: '',
            width: '15%',
            render: (plot => (
                <>
                    <Dropdown.Button 
                        onClick={event => showBillDetails(plot)} 
                        type="secondary-default" 
                        icon={<CaretDownFilled />}
                        overlay={<Menu>
                                <Menu.Item
                                    onClick={e => showBill(plot)}
                                >
                                    <EditOutlined style={{marginRight:'5px'}} /> Editar
                                </Menu.Item>
                                <Menu.Item
                                    onClick={e => showBillPayments(plot)}
                                >
                                    <DollarOutlined style={{marginRight:'5px'}} /> Recebimentos
                                </Menu.Item>
                                <Menu.Item
                                    onClick={e => showBillFiles(plot)}
                                >
                                    <FilePdfOutlined style={{marginRight:'5px'}} /> Anexos
                                </Menu.Item>
                                { plot.bill.origin === 'L' &&
                                    <Menu.Item 
                                        onClick={e => deleteBill(plot.bill.id)}
                                        style={{color: '#f35b5b'}}
                                    >
                                        <DeleteOutlined style={{marginRight:'5px'}} /> Excluir
                                    </Menu.Item>
                                }
                                { plot.bill.origin === 'V' &&
                                    <Menu.Item>
                                        <Link to={`/vendas/${plot.bill.sale ? plot.bill.sale.id : ''}`} style={{color: '#e0a800'}}>
                                            <ShoppingCartOutlined style={{marginRight:'5px'}} /> Visualizar venda
                                        </Link>
                                    </Menu.Item>
                                }
                        </Menu>}>
                        Detalhes
                    </Dropdown.Button>
                </>
            )),
        },
    ];

    let loading_screen = '';
    if(loading){
        loading_screen = <Loading />
    }

    return (
        <Layout className="layout">
            { loading_screen }
            <Topbar />

            <Content className="fbm-main">
                <div id="main">
                    <Row style={{ marginLeft: '-10px', marginRight: '-10px' }}>
                        <Col span={24} style={{ padding: '0 10px 10px 10px' }}>
                            <Card loading={loading} className="fbm-card">
                                <Row style={{ paddingBottom: '15px', borderBottomWidth: '0.5px', borderBottomColor: '#e8e8e8', borderBottomStyle: 'solid' }}>
                                    <Col className="fbm-card-header" span={24}>
                                        <h1 className="big-title">
                                            Contas a receber
                                        </h1>
                                        <Button 
                                            type="success"
                                            onClick={() => showBill(null)}
                                            style={{marginLeft: '15px', borderRadius: '50%'}}
                                        >
                                            <PlusOutlined />
                                        </Button>
                                    </Col>
                                </Row>
                                <Form layout='vertical'>
                                    <Row>
                                        {/* Data de emissão */}
                                        <Col span={4}>
                                            <Form.Item
                                                className="form-item"
                                                label="Emissão (início):"
                                                style={{paddingLeft:0}}
                                            >
                                                <DatePicker
                                                    locale={locale}
                                                    showTime={false}
                                                    value={filter_issue_date_begin !== '' ? moment(filter_issue_date_begin) : ''}
                                                    format="DD/MM/YYYY"
                                                    onChange={ (date, dateString) => {
                                                        setFilterIssueDateBegin(date !== null ? format(date._d, "yyyy-MM-dd") : '');
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item
                                                className="form-item"
                                                label="Emissão (fim):"
                                            >
                                                <DatePicker 
                                                    locale={locale}
                                                    showTime={false}
                                                    value={filter_issue_date_end !== '' ? moment(filter_issue_date_end) : ''}
                                                    format="DD/MM/YYYY"
                                                    onChange={ (date, dateString) => {
                                                        setFilterIssueDateEnd(date !== null ? format(date._d, "yyyy-MM-dd") : '');
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        {/* Data de competência */}
                                        <Col span={4}>
                                            <Form.Item
                                                className="form-item"
                                                label="Competência (início):"
                                            >
                                                <DatePicker
                                                    locale={locale}
                                                    showTime={false}
                                                    value={filter_competence_date_begin !== '' ? moment(filter_competence_date_begin) : ''}
                                                    format="DD/MM/YYYY"
                                                    onChange={ (date, dateString) => {
                                                        setFilterCompetenceDateBegin(date !== null ? format(date._d, "yyyy-MM-dd") : '');
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item
                                                className="form-item"
                                                label="Competência (fim):"
                                            >
                                                <DatePicker 
                                                    locale={locale}
                                                    showTime={false}
                                                    value={filter_competence_date_end !== '' ? moment(filter_competence_date_end) : ''}
                                                    format="DD/MM/YYYY"
                                                    onChange={ (date, dateString) => {
                                                        setFilterCompetenceDateEnd(date !== null ? format(date._d, "yyyy-MM-dd") : '');
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        {/* Data de vencimento */}
                                        <Col span={4}>
                                            <Form.Item
                                                className="form-item"
                                                label="Vencimento (início):"
                                            >
                                                <DatePicker
                                                    locale={locale}
                                                    showTime={false}
                                                    value={filter_due_date_begin !== '' ? moment(filter_due_date_begin) : ''}
                                                    format="DD/MM/YYYY"
                                                    onChange={ (date, dateString) => {
                                                        setFilterDueDateBegin(date !== null ? format(date._d, "yyyy-MM-dd") : '');
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item
                                                className="form-item"
                                                label="Vencimento (fim):"
                                                style={{ paddingRight: '0' }}
                                            >
                                                <DatePicker 
                                                    locale={locale}
                                                    showTime={false}
                                                    value={filter_due_date_end !== '' ? moment(filter_due_date_end) : ''}
                                                    format="DD/MM/YYYY"
                                                    onChange={ (date, dateString) => {
                                                        setFilterDueDateEnd(date !== null ? format(date._d, "yyyy-MM-dd") : '');
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        {/* Data de emissão */}
                                        <Col span={4}>
                                            <Form.Item
                                                className="form-item"
                                                label="Pagamento (início):"
                                                style={{paddingLeft:0}}
                                            >
                                                <DatePicker
                                                    locale={locale}
                                                    showTime={false}
                                                    value={filter_payday_begin !== '' ? moment(filter_payday_begin) : ''}
                                                    format="DD/MM/YYYY"
                                                    onChange={ (date, dateString) => {
                                                        setFilterPaydayBegin(date !== null ? format(date._d, "yyyy-MM-dd") : '');
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item
                                                className="form-item"
                                                label="Pagamento (fim):"
                                            >
                                                <DatePicker 
                                                    locale={locale}
                                                    showTime={false}
                                                    value={filter_payday_end !== '' ? moment(filter_payday_end) : ''}
                                                    format="DD/MM/YYYY"
                                                    onChange={ (date, dateString) => {
                                                        setFilterPaydayEnd(date !== null ? format(date._d, "yyyy-MM-dd") : '');
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        {/* Documento */}
                                        <Col span={4}>
                                            <Form.Item
                                                className="form-item"
                                                label="Documento:"
                                            >
                                                <Input
                                                    id="filter_document_number" value={ filter_document_number }
                                                    onChange={ event => setFilterDocumentNumber(event.target.value) }
                                                    required
                                                />
                                            </Form.Item>
                                        </Col>
                                        {/* Centro de Custos */}
                                        <Col span={4}>
                                            <Form.Item
                                                className="form-item"
                                                label="Centro de Custos:"
                                            >
                                                <Select 
                                                    value={ filter_cost_center }
                                                    style={{ width: 120 }} 
                                                    onChange={ (value) => setFilterCostCenter(value) }
                                                    onFocus={ () => loadCostCenters() }
                                                    loading={ loading_cost_centers }
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    <Option value="">Todas</Option>
                                                    { cost_centers.map(item => (
                                                        <Option
                                                            key={item.id}
                                                            value={ item.id }
                                                        >
                                                            { item.name }
                                                        </Option>
                                                    )) }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        {/* Categoria */}
                                        <Col span={8}>
                                            <Form.Item
                                                className="form-item"
                                                label="Categoria:"
                                                style={{ paddingRight: '0' }}
                                            >
                                                <Select 
                                                    value={ filter_category }
                                                    style={{ width: 120 }} 
                                                    onChange={ (value) => setFilterCategory(value) }
                                                    onFocus={ () => loadCategories() }
                                                    loading={ loading_categories }
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => {
                                                        if(!Array.isArray(option.props.children) && option.props.children !== undefined){
                                                            return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    }}  
                                                >
                                                    <Option value="">Todas</Option>
                                                    { categories.map(category => (
                                                        <OptGroup
                                                            key={category.id}
                                                            label={ category.description }
                                                        >
                                                            { category.children.map(item => (
                                                                <Option
                                                                    key={item.id}
                                                                    value={ item.id }
                                                                >
                                                                    { item.description }
                                                                </Option>
                                                            )) }
                                                        </OptGroup>
                                                    )) }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        {/* Cliente */}
                                        <Col span={8}>
                                            <Form.Item
                                                className="form-item"
                                                label="Cliente:"
                                                style={{ paddingLeft: '0' }}
                                            >
                                                <Select 
                                                    value={ filter_client }
                                                    style={{ width: 120 }} 
                                                    onChange={ (value) => setFilterClient(value) }
                                                    onFocus={ () => loadClients() }
                                                    loading={ loading_clients }
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    <Option value="">Todas</Option>
                                                    { clients.map(item => (
                                                        <Option
                                                            key={item.id}
                                                            value={ item.id }
                                                        >
                                                            { item.name }
                                                        </Option>
                                                    )) }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        {/* Origem */}
                                        <Col span={4}>
                                            <Form.Item
                                                className="form-item"
                                                label="Origem:"
                                            >
                                                <Select 
                                                    value={ filter_origin }
                                                    style={{ width: 120 }} 
                                                    onChange={ (value) => setFilterOrigin(value) }
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    <Option value="">Todos</Option>
                                                    <Option key="CP" value="CP">Contas a receber</Option>
                                                    <Option key="C" value="C">Compras</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        {/* Situação */}
                                        <Col span={4}>
                                            <Form.Item
                                                className="form-item"
                                                label="Situação:"
                                            >
                                                <Select 
                                                    value={ filter_status }
                                                    style={{ width: 120 }} 
                                                    onChange={ (value) => setFilterStatus(value) }
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    <Option value="">Todos</Option>
                                                    <Option key="P" value="P">Recebido</Option>
                                                    <Option key="A" value="A">Não Recebido</Option>
                                                    <Option key="PP" value="PP">Parcialmente Recebido</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        {/* Filtrar */}
                                        <Col span={8}>
                                            <Form.Item
                                                className="form-item"
                                                style={{ marginTop: '25px', paddingRight: '0' }}
                                            >
                                                <Button 
                                                    key="submit"
                                                    type="primary"
                                                    loading={loadingTable}
                                                    onClick={ () => reloadData() }
                                                    style={{ width: '100%' }}
                                                >
                                                    Filtrar
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>

                                
                                <Row style={{ paddingTop: '15px',paddingBottom: 0, borderBottomWidth: '0.5px', borderBottomColor: '#c9d3dd', borderBottomStyle: 'dashed' }}>
                                    <Col span={4}>
                                        <>
                                            <p>
                                                Lançamentos: 
                                                <span style={{marginLeft:'5px',color:'#0086ea', fontWeight:'bold'}}>
                                                    {summary.plots ? summary.plots : 0}
                                                </span>
                                            </p>
                                        </>
                                    </Col>
                                    <Col span={4}>
                                        <>
                                            <p>
                                                Total: 
                                                <span style={{marginLeft:'5px',color:'#0086ea', fontWeight:'bold'}}>
                                                    R$
                                                </span>
                                                <span style={{marginLeft:'5px',color:'#0086ea', fontWeight:'bold'}}>
                                                    <NumericFormat 
                                                        value={summary.value ? summary.value : 0}
                                                        displayType={'text'}
                                                        thousandSeparator="."
                                                        decimalSeparator=","
                                                        decimalScale={2} 
                                                        fixedDecimalScale={true}
                                                    />
                                                </span>
                                            </p>
                                        </>
                                    </Col>
                                    <Col span={4}>
                                        <>
                                            <p>
                                                Recebido: 
                                                <span style={{marginLeft:'5px',color:'#0086ea', fontWeight:'bold'}}>
                                                    R$
                                                </span>
                                                <span style={{marginLeft:'5px',color:'#0086ea', fontWeight:'bold'}}>
                                                    <NumericFormat 
                                                        value={summary.paid ? summary.paid : 0}
                                                        displayType={'text'}
                                                        thousandSeparator="."
                                                        decimalSeparator=","
                                                        decimalScale={2} 
                                                        fixedDecimalScale={true}
                                                    />
                                                </span>
                                            </p>
                                        </>
                                    </Col>
                                    <Col span={4}>
                                        <>
                                            <p>
                                                Aberto: 
                                                <span style={{marginLeft:'5px',color:'#0086ea', fontWeight:'bold'}}>
                                                    R$
                                                </span>
                                                <span style={{marginLeft:'5px',color:'#0086ea', fontWeight:'bold'}}>
                                                    <NumericFormat 
                                                        value={summary.open ? summary.open : 0}
                                                        displayType={'text'}
                                                        thousandSeparator="."
                                                        decimalSeparator=","
                                                        decimalScale={2} 
                                                        fixedDecimalScale={true}
                                                    />
                                                </span>
                                            </p>
                                        </>
                                    </Col>
                                    <Col span={4}>
                                        <>
                                            <p>
                                                Desconto: 
                                                <span style={{marginLeft:'5px',color:'#0086ea', fontWeight:'bold'}}>
                                                    R$
                                                </span>
                                                <span style={{marginLeft:'5px',color:'#0086ea', fontWeight:'bold'}}>
                                                    <NumericFormat 
                                                        value={summary.discount ? summary.discount : 0}
                                                        displayType={'text'}
                                                        thousandSeparator="."
                                                        decimalSeparator=","
                                                        decimalScale={2} 
                                                        fixedDecimalScale={true}
                                                    />
                                                </span>
                                            </p>
                                        </>
                                    </Col>
                                    <Col span={4}>
                                        <>
                                            <p>
                                                Juros: 
                                                <span style={{marginLeft:'5px',color:'#0086ea', fontWeight:'bold'}}>
                                                    R$
                                                </span>
                                                <span style={{marginLeft:'5px',color:'#0086ea', fontWeight:'bold'}}>
                                                    <NumericFormat 
                                                        value={summary.interest ? summary.interest : 0}
                                                        displayType={'text'}
                                                        thousandSeparator="."
                                                        decimalSeparator=","
                                                        decimalScale={2} 
                                                        fixedDecimalScale={true}
                                                    />
                                                </span>
                                            </p>
                                        </>
                                    </Col>
                                </Row>
                                
                                <Row style={{ paddingTop:'20px',paddingBottom: '0' }}>
                                    <Col span={24}>
                                        <div style={{ overflow: 'auto', marginBottom:'15px' }}>
                                            <Form.Item
                                                className="form-item"
                                                style={{ 
                                                    paddingTop: 0, 
                                                    paddingRight: 0,
                                                    paddingLeft: 0,
                                                    paddingBottom: 0,
                                                    width: '100px',
                                                    float: 'left',
                                                }}
                                            >
                                                <Select 
                                                    value={tableLength} 
                                                    onChange={ async (value) => setTableLength(value) }
                                                >
                                                    <Option key="10" value="10">10</Option>
                                                    <Option key="25" value="25">25</Option>
                                                    <Option key="50" value="50">50</Option>
                                                    <Option key="100" value="100">100</Option>
                                                </Select>
                                            </Form.Item>
                                            <Form.Item
                                                className="form-item"
                                                style={{ 
                                                    paddingTop: 0,
                                                    paddingRight: 0,
                                                    paddingLeft: 0,
                                                    paddingBottom: 0,
                                                    width: '200px',
                                                    float: 'right',
                                                }}
                                            >
                                                <Search
                                                    id="search"
                                                    placeholder="Pesquisar"
                                                    value={search}
                                                    onChange={ event => setSearch(event.target.value) }
                                                    onSearch={ value => reloadData() }
                                                    loading={ loadingTable }
                                                />
                                            </Form.Item>
                                        </div>
                                    </Col>
                                    <Col span={24} >
                                        <Table
                                            bordered={true}
                                            columns={columns}
                                            rowKey={record => record.id}
                                            dataSource={data.data}
                                            pagination={tablePagination}
                                            loading={loadingTable}
                                            onChange={loadData}
                                            pageSize={tableLength}
                                            locale={{ emptyText: <Empty description={<b>Nenhum registro encontrado...</b>} /> }}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Content>

            <Footbar />

            <BillPaymentsModal type="to_pay" bill={bill} afterSave={loadData} visible={show_bill_payments_modal} setVisibility={setShowBillPaymentsModal} />

            <BillEditModal type="to_receive" bill={bill} afterSave={loadData} visible={show_bill_modal} setVisibility={setShowBillModal} />

            <BillDetailsModal type="to_receive" bill={bill} afterSave={loadData} visible={show_bill_details_modal} setVisibility={setShowBillDetailsModal} />
            
            <BillFilesModal type="to_receive" bill={bill} visible={show_bill_files_modal} setVisibility={setShowBillFilesModal} />
            
        </Layout>
    )
}