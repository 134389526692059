import React from 'react'
import { LoadingOutlined } from '@ant-design/icons';

import './style.css';

function Loading() {
    return (
        <div id="loading-screen">
            <div id="loading-logo">
                <LoadingOutlined />
            </div>
        </div>
    );
}

export default Loading;