import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import {
    Layout,
    Form,
    Breadcrumb,
    Input,
    Button,
    Row,
    Col,
    Select,
    DatePicker,
    message,
    Table,
    Modal,
    Tooltip,
    Card,
    Alert,
    Empty,
} from 'antd';
import {
    UploadOutlined,
    DownloadOutlined,
    EditOutlined,
    DeleteOutlined
} from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/pt_BR';
import {NumericFormat} from 'react-number-format';
import { parseISO, format } from 'date-fns';
import moment from 'moment';
import 'moment/locale/pt-br';
import CurrencyInput from 'react-currency-input';

import Topbar from '../components/Topbar';
import Footbar from '../components/Footbar';
import FinancialAccountModal from '../components/Modal/FinancialAccount/Details';
import WithdrawalModal from '../components/Modal/FinancialAccount/Withdrawal';
import IncrementModal from '../components/Modal/FinancialAccount/Increment';

import 'antd/dist/antd.css';
import './styles/Main.css';

import api from '../services/api.js';

const cleanFloatValue = require('../utils/cleanFloatValue');

const { Search } = Input;
const { Content } = Layout;
const { confirm } = Modal;
const { Option } = Select;

export default function FinancialAccounts() {
    const [loadingTable, setLoadingTable] = useState(true);
    const [loading_account, setLoadingAccount] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [saving, setSaving] = useState(false);
    const [show_financial_account_modal, setShowFinancialAccountModal] = useState(false);
    const [visible_modal_withdrawal, setVisibleModalWithdrawal] = useState(false);
    const [visible_modal_increment, setVisibleModalIncrement] = useState(false);

    const [financial_account, setFinancialAccount] = useState({});
    
    const [data, setData] = useState([]);
    const [id, setId] = useState(false);
    const [visible_modal, setVisibleModal] = useState(false);
    const [visible_modal_transfer, setVisibleModalTransfer] = useState(false);
    const [financial_accounts, setFinancialAccounts] = useState([]);
    const [destiny_account_id, setDestinyAccountId] = useState('');

    
    const [origin_transfer_account_id, setOriginTransferAccountId] = useState('');
    const [destiny_transfer_account_id, setDestinyTransferAccountId] = useState('');
    const [description_transfer, setDescriptionTransfer] = useState('');
    const [value_transfer, setValueTransfer] = useState('');
    const [date_transfer, setDateTransfer] = useState(format(new Date(), "yyyy-MM-dd"));

    const [filter_type, setFilterType] = useState('');
    const [filter_name, setFilterName] = useState('');
    const [filter_opening_date_begin, setFilterOpeningDateBegin] = useState('');
    const [filter_opening_date_end, setFilterOpeningDateEnd] = useState('');

    const [tableLength, setTableLength] = useState(10);
    const [tablePagination, setTablePagination] = useState({current: 1, pageSize: tableLength});
    const [tableFilters, setTableFilters] = useState({});
    const [tableSorter, setTableSorter] = useState({ field: 'description', order: 'ascend' });
    const [search, setSearch] = useState('');

    useEffect( () => {
        loadData();
    }, [tableLength]);

    async function reloadData(){
        loadData({current: 1, pageSize: tableLength});
    }

    async function loadData(
        pagination = tablePagination,
        filters = tableFilters,
        sorter = tableSorter
    ) {
        setLoadingTable(true);

        setTablePagination(pagination);
        setTableFilters(filters);
        if(!sorter.field){
            sorter = tableSorter
        }
        setTableSorter(sorter);

        let order = sorter.order;
        if(order === undefined) {
            order = 'asc';
        } else if(sorter.order === 'ascend') {
            order = 'asc';
        } else if(sorter.order === 'descend') {
            order = 'desc';
        }

        // FINANCIAL ACCOUNTS
        let uri = `/financial_accounts?page=${pagination.current}&per_page=${tableLength}&order=${order}&order_by=${sorter.field}&search=${search}`;
        uri += `&name=${filter_name}`;
        uri += `&type=${filter_type}`;
        uri += `&opening_date_begin=${filter_opening_date_begin}`;
        uri += `&opening_date_end=${filter_opening_date_end}`;
        
        let response = await api.get(uri);

        let accounts_list = response.data.data;

        setData(accounts_list);
        setTablePagination({
            current: pagination.current,
            pageSize: tableLength,
            total: accounts_list.total,
        });

        setLoadingTable(false);
    }

    async function showFinancialAccount(account = null) {
        setFinancialAccount(account);
        setShowFinancialAccountModal(true);
    }

    async function deleteAccount(account_id) {
        setLoadingAccount(true);
        setId(account_id);
        
        let response = await api.get('/financial_accounts');
        let financial_accounts = response.data.data;
        let financial_account_target = {};
        let count_financial_accounts = 0;

        for (var key in financial_accounts) {
            if(financial_accounts[key].id === account_id){
                financial_account_target = financial_accounts[key];
                delete financial_accounts[key];
            } else {
                count_financial_accounts++;
            }
        }

        if(count_financial_accounts > 0){
            setFinancialAccounts(financial_accounts);
            setVisibleModal(true);
        } else {
            confirm({
                title: 'Não é possível deletar a conta',
                content: 'Você não pode deletar sua única conta financeira!',
                okText: 'Ok',
                okType: 'primary',
                cancelText: 'Cancelar',
            });
        }

        setLoadingAccount(false);
    }

    async function withdrawal(account) {
        setLoadingAccount(true);
        
        if(account.balance > 0){
            setFinancialAccount(account);
            setVisibleModalWithdrawal(true);
        } else {
            confirm({
                title: 'Não é possível fazer retirada',
                content: 'Você não pode fazer retiradas de contas sem saldo!',
                okText: 'Ok',
                okType: 'primary',
                cancelText: 'Cancelar',
            });
        }

        setLoadingAccount(false);
    }

    async function increment(account) {
        setLoadingAccount(true);
        
        setFinancialAccount(account);
        setVisibleModalIncrement(true);

        setLoadingAccount(false);
    }

    async function transferMoneyDeleteAccount() {
        setDeleting(true);
        let response = await api.delete(`/financial_accounts/${id}?destiny_account_id=${destiny_account_id}`);
        
        if(response.data.success) {
            message.success('Conta financeira deletada com sucesso');
        } else {
            message.error('Ocorreu um erro, aguarde alguns instantes e tente novamente');
        }

        setVisibleModal(false);
        setDeleting(false);
        loadData();
    }

    async function newTransfer() {
        let response = await api.get('/financial_accounts');
        let financial_accounts = response.data.data;

        setFinancialAccounts(financial_accounts);

        setDestinyTransferAccountId('');
        setOriginTransferAccountId('');
        setValueTransfer('');
        setDescriptionTransfer('');
        setDateTransfer(format(new Date(), "yyyy-MM-dd"));

        setVisibleModalTransfer(true);
    }

    async function saveTransfer() {
        setSaving(true);

        const data = {
            financial_account_origin_id: origin_transfer_account_id,
            financial_account_destiny_id: destiny_transfer_account_id,
            date_transfer,
            value: cleanFloatValue(value_transfer),
            description: description_transfer,
        };

        try {
            let response = await api.post('/financial_transfers', data);

            if(response.data.success) {
                message.success('Transferência realizada com sucesso');
            } else {
                message.error('Ocorreu um erro, aguarde alguns instantes e tente novamente');
            }

            setVisibleModalTransfer(false);

            loadData();
            
            setSaving(false);
        } catch (error) {
            message.error('Ocorreu um erro, aguarde alguns instantes e tente novamente');
            setSaving(false);
        }
    }

    function fixFinancialAccount(financial_account){
        let account = '';
        if(financial_account){
            account = <span style={{ fontWeight:'bold' }}>{financial_account.name}</span>;
            if(financial_account.type !== 'CX'){
                account = <>
                    <span style={{ fontWeight:'bold' }}>{financial_account.name}</span>
                    - ag {financial_account.agency_number} cc {financial_account.account_number}
                </>
            }
        }

        return account;
    }

    function formatDate(date){
        if(date !== null && date !== '' && date !== '0000-00-00'){
            date = parseISO(date);
            return format(date, 'dd/MM/yyyy');
        } else {
            return '-';
        }
    }

    function fixType(type){
        switch (type) {
            case 'CX':
                return 'Caixinha';
            case 'CC':
                return 'Conta Corrente';
            case 'PP':
                return 'Poupança';
            case 'IV':
                return 'Investimento';
            case 'AP':
                return 'Aplicação';
            default:
                return '-';
        }
    }

    const columns = [
        {
            title: 'CONTA',
            render: ( account => (
                <>
                    {account.name}
                    <br/>
                    <span style={{ color:'#9797ad', fontSize: '13px' }}>
                        {fixType(account.type)}
                    </span>
                </>
            )),
            align: 'left',
            width: '25%',
        }, {
            title: 'Descrição',
            render: ( account => (
                <>
                    {account.description}
                    <span style={{ color:'#9797ad', fontSize: '13px' }}>
                        {account.agency_number && account.account_number ? `Agência: ${account.agency_number} - Conta: ${account.account_number}` : ''}
                    </span>
                </>
            )),
            align: 'left',
            width: '40%',
        }, {
            title: 'SALDO (R$)',
            align: 'right',
            render: ( account => (
                <NumericFormat 
                    value={account.balance ? account.balance : 0}
                    displayType={'text'}
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2} 
                    fixedDecimalScale={true}
                    style={{ fontWeight:'bold', color: '#5e72e4' }}
                />
            )),
            width: '10%',
        }, {
            title: 'AÇÕES',
            width: '20%',
            render: (account => (
                <>
                    <Tooltip title="Depósito" placement="left">
                        <Button
                            type="secondary-default"
                            icon={<UploadOutlined />}
                            onClick={event => increment(account)}
                            disabled={loading_account}
                            style={{
                                marginLeft: '5px',
                                paddingTop: '4px',
                                paddingBottom: '4px',
                                paddingLeft: '10px',
                                paddingRight: '10px',
                                width:'auto',
                            }}
                        ></Button>
                    </Tooltip>
                    <Tooltip title="Retirada" placement="top">
                        <Button
                            type="secondary-default"
                            icon={<DownloadOutlined />}
                            onClick={event => withdrawal(account)}
                            disabled={loading_account}
                            style={{
                                marginLeft: '5px',
                                paddingTop: '4px',
                                paddingBottom: '4px',
                                paddingLeft: '10px',
                                paddingRight: '10px',
                                width:'auto',
                            }}
                        ></Button>
                    </Tooltip>
                    <Tooltip title="Editar" placement="top">
                        <Button
                            type="secondary-default"
                            icon={<EditOutlined />}
                            onClick={event => showFinancialAccount(account)}
                            disabled={loading_account}
                            style={{
                                marginLeft: '5px',
                                paddingTop: '4px',
                                paddingBottom: '4px',
                                paddingLeft: '10px',
                                paddingRight: '10px',
                                width:'auto',
                            }}
                        ></Button>
                    </Tooltip>
                    <Tooltip title="Deletar" placement="right">
                        <Button
                            type="secondary-default"
                            icon={<DeleteOutlined />}
                            disabled={loading_account}
                            onClick={event => deleteAccount(account.id)}
                            style={{ 
                                marginLeft: '5px',
                                paddingTop: '4px',
                                paddingBottom: '4px',
                                paddingLeft: '10px',
                                paddingRight: '10px',
                                width:'auto',
                                color: '#ff4d4f !important',
                            }}
                        ></Button>
                    </Tooltip>
                </>
            )),
        },
    ];

    // let loading_screen = '';
    // if(loading){
    //     loading_screen = <Loading />
    // }

    return (
        <Layout className="layout">
            {/* { loading_screen } */}
            <Topbar />

            <Content className="fbm-main">
                <div id="main">
                    <Row>
                        <Col span={24} className="fbm-page-header">
                            <Breadcrumb className="breadcrumb" style={{ margin: '16px 0' }}>
                                <Breadcrumb.Item><Link to="/monitoramento">Monitoramento</Link></Breadcrumb.Item>
                                <Breadcrumb.Item>Contas Financeiras</Breadcrumb.Item>
                            </Breadcrumb>

                            <div className="actions">
                                <Button 
                                    type="primary"
                                    onClick={newTransfer}
                                >
                                    Nova Transferência
                                </Button>
                                {/* <Link to="/contas-financeiras/novo"> */}
                                    <Button 
                                        type="success"
                                        onClick={() => showFinancialAccount()}
                                    >
                                        Nova Conta
                                    </Button>
                                {/* </Link> */}
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ marginLeft: '-10px', marginRight: '-10px' }}>
                        <Col span={24} style={{ padding: '0 10px 10px 10px' }}>
                            <Card loading={loading} className="fbm-card">
                                <Form layout='vertical'>
                                    <Row style={{ paddingBottom: '15px', borderBottomWidth: '0.5px', borderBottomColor: '#c9d3dd', borderBottomStyle: 'dashed' }}>
                                        <Col span={4}>
                                            <Form.Item
                                                className="form-item"
                                                label="Tipo:"
                                                style={{ paddingLeft: '0' }}
                                            >
                                                <Select 
                                                    value={ filter_type }
                                                    style={{ width: 120 }} 
                                                    onChange={ (value) => setFilterType(value) }
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    <Option value="">Todos</Option>
                                                    <Option key="CX" value="CX">Caixinha</Option>
                                                    <Option key="CC" value="CC">Conta Corrente</Option>
                                                    <Option key="PP" value="PP">Poupança</Option>
                                                    <Option key="IV" value="IV">Investimento</Option>
                                                    <Option key="AP" value="AP">Aplicação</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                className="form-item"
                                                label="Nome da conta:"
                                            >
                                                <Input
                                                    id="name" value={ filter_name }
                                                    onChange={ event => setFilterName(event.target.value) }
                                                    required
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item
                                                className="form-item"
                                                label="Data de abertura (início):"
                                            >
                                                <DatePicker
                                                    locale={locale}
                                                    showTime={false}
                                                    value={filter_opening_date_begin !== '' ? moment(filter_opening_date_begin) : ''}
                                                    format="DD/MM/YYYY"
                                                    onChange={ (date, dateString) => {
                                                        setFilterOpeningDateBegin(date !== null ? format(date._d, "yyyy-MM-dd") : '');
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item
                                                className="form-item"
                                                label="Data de abertura (fim):"
                                            >
                                                <DatePicker 
                                                    locale={locale}
                                                    showTime={false}
                                                    value={filter_opening_date_end !== '' ? moment(filter_opening_date_end) : ''}
                                                    format="DD/MM/YYYY"
                                                    onChange={ (date, dateString) => {
                                                        setFilterOpeningDateEnd(date !== null ? format(date._d, "yyyy-MM-dd") : '');
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                className="form-item"
                                                style={{ marginTop: '25px', paddingRight: '0' }}
                                            >
                                                <Button 
                                                    key="submit"
                                                    type="primary"
                                                    loading={loadingTable}
                                                    onClick={ () => reloadData() }
                                                    style={{ width: '100%' }}
                                                >
                                                    Filtrar
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                                <Row style={{ paddingTop:'20px',paddingBottom: '0' }}>
                                    <Col span={24}>
                                        <div style={{ overflow: 'auto', marginBottom:'15px' }}>
                                            <Form.Item
                                                className="form-item"
                                                style={{ 
                                                    paddingTop: 0, 
                                                    paddingRight: 0,
                                                    paddingLeft: 0,
                                                    paddingBottom: 0,
                                                    width: '100px',
                                                    float: 'left',
                                                }}
                                            >
                                                <Select 
                                                    value={tableLength} 
                                                    onChange={ async (value) => setTableLength(value) }
                                                >
                                                    <Option key="10" value="10">10</Option>
                                                    <Option key="25" value="25">25</Option>
                                                    <Option key="50" value="50">50</Option>
                                                    <Option key="100" value="100">100</Option>
                                                </Select>
                                            </Form.Item>
                                            <Form.Item
                                                className="form-item"
                                                style={{ 
                                                    paddingTop: 0,
                                                    paddingRight: 0,
                                                    paddingLeft: 0,
                                                    paddingBottom: 0,
                                                    width: '200px',
                                                    float: 'right',
                                                }}
                                            >
                                                <Search
                                                    id="search"
                                                    placeholder="Pesquisar"
                                                    value={search}
                                                    onChange={ event => setSearch(event.target.value) }
                                                    onSearch={ value => reloadData() }
                                                    loading={ loadingTable }
                                                />
                                            </Form.Item>
                                        </div>
                                    </Col>
                                    <Col span={24} >
                                        <Table
                                            bordered={true}
                                            columns={columns}
                                            rowKey={record => record.id}
                                            dataSource={data.data}
                                            pagination={tablePagination}
                                            loading={loadingTable}
                                            onChange={loadData}
                                            pageSize={tableLength}
                                            locale={{ emptyText: <Empty description={<b>Nenhum registro encontrado...</b>} /> }}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Content>

            <Footbar />

            {/* START MODAL FINANCIAL ACCOUNT */}
            <FinancialAccountModal financial_account={financial_account} afterSave={loadData} visible={show_financial_account_modal} setVisibility={setShowFinancialAccountModal} />
            {/* END MODAL FINANCIAL ACCOUNT */}

            {/* START MODAL WITHDRAWAL */}
            <WithdrawalModal financial_account={financial_account} afterSave={loadData} visible={visible_modal_withdrawal} setVisibility={setVisibleModalWithdrawal} />
            {/* END MODAL WITHDRAWAL */}

            {/* START MODAL INCREMENT */}
            <IncrementModal financial_account={financial_account} afterSave={loadData} visible={visible_modal_increment} setVisibility={setVisibleModalIncrement} />
            {/* END MODAL INCREMENT */}


            {/* START MODAL DELETE ACCOUNT */}
            <Modal
                title="Selecione uma conta para transferir seu saldo"
                visible={visible_modal}
                centered
                footer={[
                    <Button 
                        key="back"
                        onClick={() => setVisibleModal(false)}
                    >
                        Cancelar
                    </Button>,
                    <Button 
                        key="submit"
                        type="danger"
                        loading={deleting}
                        onClick={ () => transferMoneyDeleteAccount() }
                        disabled = {( !destiny_account_id )}
                    >
                        Transferir saldo e encerrar conta
                    </Button>,
                ]}
                onCancel={() => setVisibleModal(false)}
            >
                <Row>
                    <Col span={24}>
                        <Alert
                            message="Atenção: Seu saldo remanescente será transferido para a conta selecionada, assim como futuros estornos e movimentações pendentes. O histórico da sua conta encerrada será mantido."
                            type="warning"
                            closable
                        />
                    </Col>
                </Row>
                <Row style={{ paddingTop: '15px' }}>
                    <Col span={24}>
                        <Form.Item className="form-item" style={{ paddingLeft: 0, paddingRight: 0 }} >
                            <Select 
                                value={ destiny_account_id } 
                                style={{ width: 120 }} 
                                onChange={ (value) => setDestinyAccountId(value) }
                            >
                                { financial_accounts.map(item => (
                                    <Option
                                        key={item.id}
                                        value={ item.id }
                                    >
                                        { fixFinancialAccount(item) }
                                    </Option>
                                )) }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Modal>
            {/* END MODAL DELETE ACCOUNT */}



            {/* START MODAL FINANCIAL TRANSFER */}
            <Modal
                title="Nova transferência entre contas"
                visible={visible_modal_transfer}
                centered
                footer={[
                    <Button key="back" onClick={() => setVisibleModalTransfer(false)} >
                        Cancelar
                    </Button>,
                    <Button 
                        key="submit"
                        type="success"
                        loading={saving}
                        onClick={ () => saveTransfer() }
                        disabled={
                            !origin_transfer_account_id ||
                            !destiny_transfer_account_id ||
                            !description_transfer ||
                            !value_transfer ||
                            !date_transfer
                        }
                    >
                        Salvar
                    </Button>,
                ]}
                onCancel={() => setVisibleModalTransfer(false)}
            >
                <Form 
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    layout="horizontal"
                >
                    <Row>
                        <Col span={24}>
                            <Form.Item 
                                label="Conta de Origem"
                                className="form-item"
                                style={{ paddingLeft: 0, paddingRight: 0 }}
                                required={true}
                            >
                                <Select 
                                    value={ origin_transfer_account_id } 
                                    style={{ width: 120 }} 
                                    onChange={ (value) => setOriginTransferAccountId(value) }
                                >
                                    { financial_accounts.map(item => ( item.id !== destiny_transfer_account_id &&
                                        <Option
                                            key={item.id}
                                            value={ item.id }
                                        >
                                            { fixFinancialAccount(item) }
                                        </Option>
                                    )) }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item 
                                label="Conta de Destino"
                                className="form-item"
                                style={{ paddingLeft: 0, paddingRight: 0 }}
                                required={true}
                            >
                                <Select 
                                    value={ destiny_transfer_account_id } 
                                    style={{ width: 120 }} 
                                    onChange={ (value) => setDestinyTransferAccountId(value) }
                                >
                                    { financial_accounts.map(item => ( item.id !== origin_transfer_account_id &&
                                        <Option
                                            key={item.id}
                                            value={ item.id }
                                        >
                                            { fixFinancialAccount(item) }
                                        </Option>
                                    )) }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item 
                                label="Descrição"
                                className="form-item"
                                style={{ paddingLeft: 0, paddingRight: 0 }}
                                required={true}
                            >
                                <Input
                                    id="name"
                                    value={ description_transfer }
                                    onChange={ event => setDescriptionTransfer(event.target.value) }
                                    required
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item 
                                label="Valor"
                                className="form-item"
                                style={{ paddingLeft: 0, paddingRight: 0 }}
                                required={true}
                            >
                                <CurrencyInput
                                    value={value_transfer}
                                    onChangeEvent={ event => setValueTransfer(event.target.value)}
                                    className="ant-input"
                                    decimalSeparator=","
                                    thousandSeparator=""
                                    prefix="R$ "
                                    style={{width:'60%'}}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item 
                                label="Data da transferência"
                                className="form-item"
                                style={{ paddingLeft: 0, paddingRight: 0 }}
                                required={true}
                            >
                                <DatePicker
                                    locale={locale}
                                    showTime={false}
                                    value={date_transfer !== '' ? moment(date_transfer) : ''}
                                    format="DD/MM/YYYY"
                                    onChange={ (date, dateString) => {
                                        setDateTransfer(date !== null ? format(date._d, "yyyy-MM-dd") : '');
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            {/* END MODAL FINANCIAL TRANSFER */}
        </Layout>
    )
}