import React, { useState, useEffect } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { Input, Row, Col, Button, Form, notification, Spin, Result, Select, Checkbox } from 'antd';
import InputMask from 'react-input-mask';

import { login, isAuthenticated } from '../services/auth';

import logo from '../assets/logo.png';
import cover_img from '../assets/image.3a4f9cdb.jpg';

import 'antd/dist/antd.css';
import './styles/Login.css';

import api from '../services/api.js';

const { Option } = Select;

export default function Signup() {
    const [loading, setLoading] = useState(false);

    const [accepted_terms, setAcceptedTerms] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [phone, setPhone] = useState('');
    const [formalized_company, setFormalizedCompany] = useState('');

    const [name_invalid, setNameInvalid] = useState(false);
    const [email_invalid, setEmailInvalid] = useState(false);
    const [password_invalid, setPasswordInvalid] = useState(false);
    const [phone_invalid, setPhoneInvalid] = useState(false);

    let navigate = useNavigate();

    async function handleSubmit(event) {
        setLoading(true);

        try {
            const response = await api.post('/auth/register', {
                name,
                email,
                password,
                phone,
                formalized_company,
            });

            login(response.data.data);

            let isLogged = setInterval(() => {
                if(isAuthenticated()) {
                    clearInterval(isLogged);

                    navigate('/monitoramento');
                }
            }, 1000);
            
        } catch (error) {
            setLoading(false);

            notification['error']({
                message: 'Ocorreu um erro',
                description: error.response.data.message,
            });
        }

    }

    function checkInput(field) {
        if(field.id === 'email') {
            if( email === '' || !validEmail(email)){
                setEmailInvalid(true)
                return false
            }
            setEmailInvalid(false)
        }

        if(field.id === 'name') {
            if( name === '' ){
                setNameInvalid(true)
                return false
            }
            setNameInvalid(false)
        }

        if(field.id === 'password') {
            if( password === '' ){
                setPasswordInvalid(true);
                return false;
            }
            setPasswordInvalid(false);
        }

        if(field.id === 'phone') {
            if( phone === '' || phone === '(__) _____-____' || !validPhone(phone)){
                setPhoneInvalid(true)
                return false
            }
            setPhoneInvalid(false)
        }
    }

    function validEmail (email) {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(email)
    }
    function validPhone (phone) {
        var re = /^\(\d{2}\) \d{5}\-\d{4}$/;
        return re.test(phone)
    }

    return (
        <div id="app">
            <Row>
                <Col className="layout_side" span={24} lg={{span: 8}}>
                    <div className="side_content">
                        <div className="logo">
                            <img src={logo} alt="" />
                        </div>
                        <Row style={{ paddingBottom: '25px' }}>
                            <Spin spinning={loading}>
                                <Col className="no-padding" style={{marginBottom:'30px'}}>
                                    <h2 className="section-subtitle">Fale um pouco sobre você   </h2>
                                    <p>Preencha os dados abaixo para realizar o seu cadastro:</p>
                                </Col>
                                <Form layout="vertical" onFinish={handleSubmit}>
                                    <Col 
                                        className="form-item"
                                        style={{ paddingLeft: 0, paddingRight: 0 }}
                                    >
                                        <Form.Item
                                            label="Nome:"
                                            required={true}
                                            hasFeedback
                                            validateStatus={`${name_invalid ? 'error' : ''}`}
                                            help={`${name_invalid ? 'Campo obrigatório' : ''}`}
                                        >
                                            <Input
                                                id="name"
                                                size="large"
                                                value={ name }
                                                onChange={ event => setName(event.target.value) }
                                                onKeyUp={ event => checkInput(event.target) }
                                                onBlur={ event => checkInput(event.target) }
                                                required
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col 
                                        className="form-item"
                                        style={{ paddingLeft: 0, paddingRight: 0 }}
                                    >
                                        <Form.Item
                                            label="E-mail:"
                                            required={true}
                                            hasFeedback
                                            validateStatus={`${email_invalid ? 'error' : ''}`}
                                            help={`${email_invalid ? 'Informe um e-mail válido' : ''}`}
                                        >
                                            <Input
                                                id="email"
                                                size="large"
                                                value={ email }
                                                onChange={ event => setEmail(event.target.value) }
                                                onKeyUp={ event => checkInput(event.target) }
                                                onBlur={ event => checkInput(event.target) }
                                                required
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col 
                                        className="form-item"
                                        style={{ paddingLeft: 0, paddingRight: 0 }}
                                    >
                                        <Form.Item
                                            label="Celular:"
                                            required={true}
                                            hasFeedback
                                            validateStatus={`${phone_invalid ? 'error' : ''}`}
                                            help={`${phone_invalid ? 'Campo obrigatório!' : ''}`}
                                            style={{ paddingLeft: 0, paddingRight: 0 }}
                                        >
                                            <InputMask 
                                                mask="(99) 99999-9999"
                                                alwaysShowMask={false}
                                                placeholder="(__) _____-____"
                                                id="phone"
                                                size="large"
                                                value={ phone }
                                                onChange={ event => setPhone(event.target.value) }
                                                onKeyUp={ event => checkInput(event.target) }
                                                onBlur={ event => checkInput(event.target) }
                                            >
                                                {(inputProps) => 
                                                    <Input 
                                                        {...inputProps} 
                                                        type="text"
                                                        required
                                                    />
                                                }
                                            </InputMask>
                                        </Form.Item>
                                    </Col>
                                    <Col 
                                        className="form-item"
                                        style={{ paddingLeft: 0, paddingRight: 0 }}
                                    >
                                        <Form.Item
                                            label="Crie sua senha de acesso:"
                                            required={true}
                                            hasFeedback
                                            validateStatus={`${password_invalid ? 'error' : ''}`}
                                            help={`${password_invalid ? 'Informe uma senha válida!' : ''}`}
                                        >
                                            <Input.Password
                                                id="password"
                                                size="large"
                                                value={ password }
                                                onChange={ event => setPassword(event.target.value) }
                                                onKeyUp={ event => checkInput(event.target) }
                                                onBlur={ event => checkInput(event.target) }
                                                required
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col 
                                        className="form-item"
                                        style={{ paddingLeft: 0, paddingRight: 0 }}
                                    >
                                        <Form.Item
                                            className="form-item"
                                            label="Você já tem empresa formalizada?"
                                            required={true}
                                            style={{ paddingLeft: 0, paddingRight: 0 }}
                                        >
                                            <Select 
                                                value={ formalized_company }
                                                onChange={ (value) => setFormalizedCompany(value) }
                                                size="large"
                                                placeholder="Selecione uma opção"
                                            >
                                                <Option value="Sim, de serviços" >
                                                    Sim, de serviços
                                                </Option>
                                                <Option value="Sim, de varejo" >
                                                    Sim, de varejo
                                                </Option>
                                                <Option value="Sim, de indústria" >
                                                    Sim, de indústria
                                                </Option>
                                                <Option value="Sim, sou MEI" >
                                                    Sim, sou MEI
                                                </Option>
                                                <Option value="Não" >
                                                    Não
                                                </Option>
                                                <Option value="Sou contador(a)" >
                                                    Sou contador(a)
                                                </Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col className="form-line">
                                        <Checkbox onChange={(e) => setAcceptedTerms(e.target.checked)}>
                                            Concordo com os <a href="https://semzebra.com.br/docs/termos_de_uso_sem_zebra.pdf" target="_blank">termos de uso</a> e <a href="https://semzebra.com.br/docs/politica_de_privacidade_sem_zebra.pdf" target="_blank">politicas de privacidade</a>.
                                        </Checkbox>
                                    </Col>
                                    <Col className="form-line">
                                        <Spin spinning={loading}>
                                            <Button 
                                                htmlType="submit"
                                                type="success"
                                                size="large"
                                                className="submit-button"
                                                disabled={(
                                                    name_invalid ||
                                                    email_invalid ||
                                                    password_invalid ||
                                                    !accepted_terms ||
                                                    !password ||
                                                    !name ||
                                                    !email ||
                                                    !formalized_company
                                                )}
                                            >
                                                Experimente Grátis
                                            </Button>
                                        </Spin>
                                    </Col>
                                    <Col className="form-line" style={{textAlign: 'center'}}>
                                        Já tem uma conta? <Link to="/entrar">Entrar!</Link>
                                    </Col>
                                </Form>
                            </Spin>
                        </Row>
                    </div>
                </Col>
                <Col className="layout_side cover_side" lg={{span: 16}}>
                    <div className="cover" style={{backgroundImage: 'url('+cover_img+')'}}></div>
                </Col>
            </Row>
        </div>
    )
}