import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import {
    Layout,
    Form,
    Breadcrumb,
    Input,
    Button,
    Row,
    Col,
    Select,
    Table,
    Card,
    Empty,
    Tag,
} from 'antd';
import {NumericFormat} from 'react-number-format';

import Topbar from '../components/Topbar';
import Footbar from '../components/Footbar';
// import Loading from '../components/Loading';

import 'antd/dist/antd.css';
import './styles/Main.css';

import api from '../services/api.js';

const { Content } = Layout;
const { Option } = Select;

export default function Stock() {
    const [loadingTable, setLoadingTable] = useState(true);
    const [loading, setLoading] = useState(false);
    const [loading_categories, setLoadingCategories] = useState(false);
    const [loading_brands, setLoadingBrands] = useState(false);
    
    const [data, setData] = useState([]);
    const [summary, setSummary] = useState([]);

    const [categories, setCategories] = useState([]);
    const [brands, setBrands] = useState([]);

    const [filter_brand, setFilterBrand] = useState('');
    const [filter_category, setFilterCategory] = useState('');

    const [tableLength, setTableLength] = useState(10);
    const [tablePagination, setTablePagination] = useState({current: 1, pageSize: tableLength});
    const [tableFilters, setTableFilters] = useState({});
    const [tableSorter, setTableSorter] = useState({ field: 'description', order: 'ascend' });
    const [search, setSearch] = useState('');

    useEffect( () => {
        loadData();
    }, []);

    async function loadBrands(){
        setLoadingBrands(true);
        setBrands([]);
        let response = await api.get(`/brands`);
        setBrands(response.data.data);
        setLoadingBrands(false);
    }

    async function loadCategories(){
        setLoadingCategories(true);
        setCategories([]);
        let response = await api.get(`/product_categories`);
        setCategories(response.data.data);
        setLoadingCategories(false);
    }

    async function reloadData(){
        loadData({current: 1, pageSize: tableLength});
    }

    async function loadData(
        pagination = tablePagination,
        filters = tableFilters,
        sorter = tableSorter,
        extra
    ) {
        setLoadingTable(true);

        if(!sorter.field){
            sorter = tableSorter
        }

        setTablePagination(pagination);
        setTableFilters(filters);
        setTableSorter(sorter);

        let order = sorter.order;
        if(order === undefined) {
            order = 'asc';
        } else if(sorter.order === 'ascend') {
            order = 'asc';
        } else if(sorter.order === 'descend') {
            order = 'desc';
        }

        // PRODUCTS
        let uri = `/products?order=${order}&order_by=${sorter.field}&search=${search}`;
            uri += `&brand=${filter_brand}`;
            uri += `&category=${filter_category}`;
        
        let response = await api.get(uri);

        setData(response.data.data);
        setSummary(response.data.summary);
        setLoadingTable(false);
    }

    const columns = [
        {
            title: 'CÓD.',
            dataIndex: 'code',
            align: 'left',
            width: '10%',
        }, {
            title: 'PRODUTO',
            render: ( product => {
                if(product.category_id) {
                    return (<>
                        <Tag>
                            {product.category.name}
                        </Tag>
                        {product.description}
                    </>)
                } else {
                    return product.description;
                }
            }),
            align: 'left',
            width: '20%',
        },{
            title: 'UN. MED.',
            dataIndex: 'unit_of_measurement',
            align: 'left',
            width: '5%',
        },{
            title: 'CUSTO MÉDIO',
            align: 'right',
            render: ( product => (
                <NumericFormat 
                    value={product.average_cost ? product.average_cost : 0}
                    displayType={'text'}
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2} 
                    fixedDecimalScale={true}
                />
            )),
            width: '10%',
        }, {
            title: 'VL. VENDA',
            align: 'right',
            key: 'sale_value',
            render: ( product => (
                <NumericFormat 
                    value={product.sale_value ? product.sale_value : 0}
                    displayType={'text'}
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2} 
                    fixedDecimalScale={true}
                />
            )),
            width: '10%',
        }, {
            title: 'ESTOQUE DISPONÍVEL',
            key: 'amount',
            render: ( product => (
                <NumericFormat 
                    value={product.amount ? product.amount : 0}
                    displayType={'text'}
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2} 
                    fixedDecimalScale={true}
                />
            )),
            align: 'right',
            width: '10%',
        }, {
            title: 'CUSTO TOTAL',
            key: 'amount',
            render: ( product => (
                <NumericFormat 
                    value={product.total_cost}
                    displayType={'text'}
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2} 
                    fixedDecimalScale={true}
                />
            )),
            align: 'right',
            width: '10%',
        }
    ];

    // let loading_screen = '';
    // if(loading){
    //     loading_screen = <Loading />
    // }

    return (
        <Layout className="layout">
            {/* { loading_screen } */}
            <Topbar />

            <Content className="fbm-main">
                <div id="main">
                    <Row style={{ marginLeft: '-10px', marginRight: '-10px' }}>
                        <Col span={24} style={{ padding: '0 10px 10px 10px' }}>
                            <Card loading={loading} className="fbm-card">
                                <Row style={{ paddingBottom: '15px', borderBottomWidth: '0.5px', borderBottomColor: '#e8e8e8', borderBottomStyle: 'solid' }}>
                                    <Col className="fbm-card-header" span={24}>
                                        <h1 className="big-title">
                                            Estoque
                                        </h1>
                                    </Col>
                                </Row>
                                <Form layout='vertical'>
                                    <Row style={{ paddingBottom: '15px' }}>
                                        <Col span={7}>
                                            <Form.Item
                                                className="form-item"
                                                label="Categoria:"
                                                style={{ paddingLeft: '0' }}
                                            >
                                                <Select 
                                                    value={ filter_category }
                                                    style={{ width: 120 }} 
                                                    onChange={ (value) => setFilterCategory(value) }
                                                    onFocus={ () => loadCategories() }
                                                    loading={ loading_categories }
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    <Option value="">Todas</Option>
                                                    { categories.map(item => (
                                                        <Option
                                                            key={item.id}
                                                            value={ item.id }
                                                        >
                                                            { item.name }
                                                        </Option>
                                                    )) }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={5}>
                                            <Form.Item
                                                className="form-item"
                                                label="Marca:"
                                            >
                                                <Select 
                                                    value={ filter_brand }
                                                    style={{ width: 120 }} 
                                                    onChange={ (value) => setFilterBrand(value) }
                                                    onFocus={ () => loadBrands() }
                                                    loading={ loading_brands }
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    <Option value="">Todas</Option>
                                                    { brands.map(item => (
                                                        <Option
                                                            key={item.id}
                                                            value={ item.id }
                                                        >
                                                            { item.name }
                                                        </Option>
                                                    )) }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                className="form-item"
                                                label="Pesquisa:"
                                            >
                                                <Input
                                                    id="search"
                                                    value={search}
                                                    onChange={ event => setSearch(event.target.value) }
                                                    loading={ loadingTable }
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item
                                                className="form-item"
                                                style={{ marginTop: '25px', paddingRight: '0' }}
                                            >
                                                <Button 
                                                    key="submit"
                                                    type="primary"
                                                    loading={loadingTable}
                                                    onClick={ () => reloadData() }
                                                    style={{ width: '100%' }}
                                                >
                                                    Filtrar
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                                <Row style={{ paddingTop: '15px',paddingBottom: 0, borderBottomWidth: '0.5px', borderBottomColor: '#c9d3dd', borderBottomStyle: 'dashed' }}>
                                    <Col span={8}>
                                        <>
                                            <p>
                                                Quantidade total em estoque: 
                                                <span style={{marginLeft:'5px',color:'#0086ea', fontWeight:'bold'}}>
                                                    <NumericFormat 
                                                        value={summary.total_amount ? summary.total_amount : 0}
                                                        displayType={'text'}
                                                        thousandSeparator="."
                                                        decimalSeparator=","
                                                        decimalScale={2} 
                                                        fixedDecimalScale={true}
                                                    />
                                                </span>
                                            </p>
                                        </>
                                    </Col>
                                    <Col span={8}>
                                        <>
                                            <p>
                                                Custo total em estoque: 
                                                <span style={{marginLeft:'5px',color:'#0086ea', fontWeight:'bold'}}>
                                                    R$
                                                </span>
                                                <span style={{marginLeft:'5px',color:'#0086ea', fontWeight:'bold'}}>
                                                    <NumericFormat 
                                                        value={summary.total_cost ? summary.total_cost : 0}
                                                        displayType={'text'}
                                                        thousandSeparator="."
                                                        decimalSeparator=","
                                                        decimalScale={2} 
                                                        fixedDecimalScale={true}
                                                    />
                                                </span>
                                            </p>
                                        </>
                                    </Col>
                                </Row>
                                <Row style={{ paddingTop:'20px',paddingBottom: '0' }}>
                                    <Col span={24} >
                                        <Table
                                            bordered={true}
                                            columns={columns}
                                            rowKey={record => record.id}
                                            dataSource={data}
                                            // pagination={tablePagination}
                                            pagination={{ position: 'none' }}
                                            loading={loadingTable}
                                            onChange={loadData}
                                            // pageSize={tableLength}
                                            locale={{ emptyText: <Empty description={<b>Nenhum registro encontrado...</b>} /> }}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Content>

            <Footbar />
        </Layout>
    )
}