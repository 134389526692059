import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import { 
    Layout,
    Form,
    Breadcrumb,
    Input,
    Button,
    DatePicker,
    Row,
    Col,
    Select,
    Table,
    Tooltip,
    Empty,
} from 'antd';
import { 
    UploadOutlined,
    RetweetOutlined,
    StopOutlined,
    DownloadOutlined,
    SwapOutlined,
    ArrowRightOutlined,
    ExportOutlined
} from '@ant-design/icons';

import locale from 'antd/es/date-picker/locale/pt_BR';
import moment from 'moment';
import 'moment/locale/pt-br';
import { parseISO, format } from 'date-fns';
import {NumericFormat} from 'react-number-format';

import Topbar from '../../components/Topbar';
import Footbar from '../../components/Footbar';
import BillDetailsModal from '../../components/Modal/BillDetails';

import 'antd/dist/antd.css';
import '../styles/Main.css';

import api from '../../services/api.js';

const { Search } = Input;
const { Content } = Layout;
const { Option } = Select;

export default function Transactions() {
    const [loadingTable, setLoadingTable] = useState(true);
    const [loading_clients, setLoadingClients] = useState(false);
    const [loading_cost_centers, setLoadingCostCenters] = useState(false);
    const [loading_providers, setLoadingProviders] = useState(false);
    const [loading_financial_accounts, setLoadingFinancialAccounts] = useState(false);
    
    const [show_bill_details_modal, setShowBillDetailsModal] = useState(false);
    const [data, setData] = useState([]);
    const [bill, setBill] = useState({});
    const [bill_type, setBillType] = useState('to_pay');

    const [tableLength, setTableLength] = useState(10);
    const [tablePagination, setTablePagination] = useState({current: 1, pageSize: tableLength});
    const [tableFilters, setTableFilters] = useState({});
    const [tableSorter, setTableSorter] = useState({ field: 'created_at', order: 'descend' });
    const [search, setSearch] = useState('');
    
    const [filter_period_begin, setFilterPeriodBegin] = useState(format(new Date(), "yyyy-MM-dd"));
    const [filter_period_end, setFilterPeriodEnd] = useState(format(new Date(), "yyyy-MM-dd"));
    const [filter_cost_center, setFilterCostCenter] = useState('');
    const [filter_provider, setFilterProvider] = useState('');
    const [filter_client, setFilterClient] = useState('');
    const [filter_financial_account, setFilterFinancialAccount] = useState('');
    const [filter_type, setFilterType] = useState('');

    const [cost_centers, setCostCenters] = useState([]);
    const [providers, setProviders] = useState([]);
    const [clients, setClients] = useState([]);
    const [financial_accounts, setFinancialAccounts] = useState([]);

    useEffect(() => {
        loadData();
    }, [tableLength]);

    async function loadCostCenters(){
        setLoadingCostCenters(true);
        setCostCenters([]);
        let response = await api.get(`/cost_centers`);
        setCostCenters(response.data.data);
        setLoadingCostCenters(false);
    }

    async function loadProviders(){
        setLoadingProviders(true);
        setProviders([]);
        let response = await api.get(`/contacts?type=provider`);
        setProviders(response.data.data);
        setLoadingProviders(false);
    }

    async function loadClients(){
        setLoadingClients(true);
        setClients([]);
        let response = await api.get(`/contacts?type=client`);
        setClients(response.data.data);
        setLoadingClients(false);
    }

    async function loadFinancialAccounts(){
        setLoadingFinancialAccounts(true);
        setFinancialAccounts([]);
        let response = await api.get(`/financial_accounts`);
        setFinancialAccounts(response.data.data);
        setLoadingFinancialAccounts(false);
    }

    async function reloadData(){
        loadData({current: 1, pageSize: tableLength});
    }

    async function loadData(
        pagination = tablePagination,
        filters = tableFilters,
        sorter = tableSorter
    ) {
        setLoadingTable(true);

        setTablePagination(pagination);
        setTableFilters(filters);
        if(!sorter.field){
            sorter = tableSorter
        }
        setTableSorter(sorter);

        let order = sorter.order;
        if(order === undefined) {
            order = 'asc';
        } else if(sorter.order === 'ascend') {
            order = 'asc';
        } else if(sorter.order === 'descend') {
            order = 'desc';
        }

        // TRANSACTIONS
        let uri = `/transactions?page=${pagination.current}&per_page=${tableLength}&order=${order}&order_by=${sorter.field}&search=${search}`;
        uri += `&period_begin=${filter_period_begin}&period_end=${filter_period_end}`;
        uri += `&cost_center=${filter_cost_center}`;
        uri += `&contact=${filter_provider}`;
        uri += `&contact=${filter_client}`;
        uri += `&financial_account=${filter_financial_account}`;
        uri += `&type=${filter_type}`;

        let response = await api.get(uri);

        let transactions_list = response.data.data;

        setData(transactions_list);
        setTablePagination({
            current: pagination.current,
            pageSize: tableLength,
            total: transactions_list.total,
        });

        setLoadingTable(false);
    }

    // function fixName(name){
    //     name = name.split(' ');
    //     name = name[0];
    //     return name;
    // }

    // function fixFinancialAccount(transaction, financial_account){
    //     let account = '';
    //     if(financial_account){
    //         account = <span style={{ fontWeight:'bold' }}>{financial_account.name}</span>;
    //         if(financial_account.type !== 'CX'){
    //             account = <>
    //                 <span style={{ fontWeight:'bold' }}>{financial_account.name}</span>
    //                 - ag {financial_account.agency_number} cc {financial_account.account_number}
    //             </>
    //         }
    //     } else if(transaction.contact) {
    //         account = <><span style={{ fontWeight:'bold' }}>{transaction.contact.name}</span></>;
    //     }

    //     return account;
    // }

    function fixType(type){
        let processed_type = '';
        switch (type) {
            case 'TR':
                processed_type = <>
                    <span style={{ fontWeight:'bold', color:'#ffc107', fontSize: '1em' }}>
                        Transferência
                        <br/>
                        <SwapOutlined />
                    </span>
                </>;
                return processed_type;
            case 'CP':
                processed_type = <>
                    <span style={{ fontWeight:'bold', color:'#f5365c', fontSize: '1em' }}>
                        Pagamento
                        <br/>
                        <ArrowRightOutlined />
                    </span>
                </>;
                return processed_type;
            case 'CR':
                processed_type = <>
                    <span style={{ fontWeight:'bold', color:'#2dce89', fontSize: '1em' }}>
                        Recebimento
                        <br/>
                        <ArrowRightOutlined />
                    </span>
                </>;
                return processed_type;
            case 'ET':
                processed_type = <>
                    <span style={{ fontWeight:'bold', color:'#5e72e4', fontSize: '1em' }}>
                        Estorno de Transferência
                        <br/>
                        <StopOutlined />
                    </span>
                </>;
                return processed_type;
            case 'EP':
                processed_type = <>
                    <span style={{ fontWeight:'bold', color:'#5e72e4', fontSize: '1em' }}>
                        Estorno de Pagamento
                        <br/>
                        <StopOutlined />
                    </span>
                </>;
                return processed_type;
            case 'ER':
                processed_type = <>
                    <span style={{ fontWeight:'bold', color:'#5e72e4', fontSize: '1em' }}>
                        Estorno de Recebimento
                        <br/>
                        <StopOutlined />
                    </span>
                </>;
                return processed_type;
            case 'ASI':
                processed_type = <>
                    <span style={{ fontWeight:'bold', color:'#5e72e4', fontSize: '1em' }}>
                        Saldo Inicial
                        <br/>
                        <RetweetOutlined />
                    </span>
                </>;
                return processed_type;
            case 'RE':
                processed_type = <>
                    <span style={{ fontWeight:'bold', color:'#f5365c', fontSize: '1em' }}>
                        Retirada
                        <br/>
                        <DownloadOutlined />
                    </span>
                </>;
                return processed_type;
            case 'IN':
                processed_type = <>
                    <span style={{ fontWeight:'bold', color:'#2dce89', fontSize: '1em' }}>
                        Depósito
                        <br/>
                        <UploadOutlined />
                    </span>
                </>;
                return processed_type;
            default:
                return '-';
        }
    }

    function formatDateTime(date){
        if(date !== null && date !== '' && date !== '0000-00-00'){
            date = parseISO(date);
            return format(date, "dd/MM/yyyy 'às' HH:mm");
        } else {
            return '-';
        }
    }

    function firstName(name){
        name = name.split(' ');
        name = name[0];
        return name;
    }

    function registeredBy(name){
        return <Tooltip title={ name }>
            { `${firstName(name)}` }
        </Tooltip>
    }

    const columns = [
        {
            title: 'Informações',
            align: 'left',
            render: (transaction => (
                <>
                    <span style={{ fontWeight:'bold', fontSize: '14px' }}>{transaction.report}</span>
                    <br />
                    <span style={{color:'#777'}}>Realizado por {registeredBy(transaction.user.name)} em {formatDateTime(transaction.created_at ? transaction.created_at : '')}</span>
                    <br />
                    { transaction.plot && 
                        <Link onClick={e => showPlotDetails(transaction.plot)}>
                            <ExportOutlined /> Mais detalhes
                        </Link>
                    }
                </>
            )),
            width: '30%',
        }, {
            title: 'ORIGEM',
            render: ( transaction => (
                <>
                    { transaction.financial_account_origin && 
                        <span style={{ fontWeight:'bold' }}>{transaction.financial_account_origin.name}</span>
                    }
                    { !transaction.financial_account_origin && transaction.contact &&
                        <span style={{ fontWeight:'bold' }}>{transaction.contact.name}</span>
                    }
                </>
            )),
            width: '15%',
        },{
            title: 'TIPO',
            render: (transaction => (
                fixType(transaction.type)
            )),
            width: '15%',
        },{
            title: 'DESTINO',
            render: ( transaction => (
                <>
                    { transaction.financial_account_destiny && 
                        <span style={{ fontWeight:'bold' }}>{transaction.financial_account_destiny.name}</span>
                    }
                    { !transaction.financial_account_destiny && transaction.contact &&
                        <span style={{ fontWeight:'bold' }}>{transaction.contact.name}</span>
                    }
                </>
            )),
            width: '15%',
        },{
            title: 'VALOR (R$)',
            align: 'right',
            render: (transaction => (
                <NumericFormat 
                    value={transaction.value}
                    displayType={'text'}
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2} 
                    fixedDecimalScale={true}
                    style={{ fontWeight:'bold' }}
                />
            )),
            width: '10%',
        },{
            title: 'CENTRO DE CUSTOS',
            render: (transaction => (
                transaction.cost_center !== null ? transaction.cost_center.name : ''
            )),
            width: '15%',
        },
    ];

    async function showPlotDetails(plot){
        setBillType(plot.bill.to_pay ? 'to_pay' : 'to_receive');
        setBill(plot);
        setShowBillDetailsModal(true);
    }

    // let loading_screen = '';
    // if(loading){
    //     loading_screen = <Loading />
    // }

    return (
        <Layout className="layout">
            {/* { loading_screen } */}
            <Topbar />

            <Content className="fbm-main">
                <div id="main">
                    <Row style={{ marginLeft: '-10px', marginRight: '-10px' }}>
                        <Col span={24} style={{ padding: '0 10px 10px 10px' }}>
                            <div className="fbm-card">
                                <Form layout='vertical'>
                                    <Row style={{ paddingBottom: '15px', borderBottomWidth: '0.5px', borderBottomColor: '#e8e8e8', borderBottomStyle: 'solid' }}>
                                        <Col className="fbm-card-header" span={24}>
                                            <h1 className="big-title">
                                                Extrato de Movimentações
                                            </h1>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={4}>
                                            <Form.Item
                                                className="form-item"
                                                label="Período (início):"
                                                style={{ paddingLeft: '0' }}
                                            >
                                                <DatePicker
                                                    locale={locale}
                                                    showTime={false}
                                                    value={filter_period_begin !== '' ? moment(filter_period_begin) : ''}
                                                    format="DD/MM/YYYY"
                                                    onChange={ (date, dateString) => {
                                                        setFilterPeriodBegin(date !== null ? format(date._d, "yyyy-MM-dd") : '');
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item
                                                className="form-item"
                                                label="Período (fim):"
                                            >
                                                <DatePicker
                                                    locale={locale}
                                                    showTime={false}
                                                    value={filter_period_end !== '' ? moment(filter_period_end) : ''}
                                                    format="DD/MM/YYYY"
                                                    onChange={ (date, dateString) => {
                                                        setFilterPeriodEnd(date !== null ? format(date._d, "yyyy-MM-dd") : '');
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                className="form-item"
                                                label="Centro de custos:"
                                            >
                                                <Select 
                                                    value={ filter_cost_center }
                                                    style={{ width: 120 }} 
                                                    onChange={ (value) => setFilterCostCenter(value) }
                                                    onFocus={ () => loadCostCenters() }
                                                    loading={ loading_cost_centers }
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    <Option value="" >Todos</Option>
                                                    { cost_centers.map(item => (
                                                        <Option 
                                                            key={item.id}
                                                            value={ item.id }
                                                        >
                                                            { item.name }
                                                        </Option>
                                                    )) }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={7}>
                                            <Form.Item
                                                className="form-item"
                                                label="Tipo:"
                                                style={{ paddingRight: '0' }}
                                            >
                                                <Select 
                                                    value={ filter_type }
                                                    style={{ width: 120 }} 
                                                    onChange={ (value) => setFilterType(value) }
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    <Option value="" >Todos</Option>
                                                    <Option value="TR" >Transferência</Option>
                                                    <Option value="CP" >Pagamentos</Option>
                                                    <Option value="CR" >Recebimentos</Option>
                                                    <Option value="ASI" >Saldo Inicial</Option>
                                                    <Option value="ET" >Estorno - Transferência</Option>
                                                    <Option value="EP" >Estorno - Pagamentos</Option>
                                                    <Option value="ER" >Estorno - Recebimentos</Option>
                                                    <Option value="RE" >Retirada</Option>
                                                    <Option value="IN" >Depósito</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row style={{ paddingBottom: '20px', borderBottomWidth: '0.5px', borderBottomColor: '#c9d3dd', borderBottomStyle: 'dashed' }}>
                                        <Col span={6}>
                                            <Form.Item
                                                className="form-item"
                                                label="Conta:"
                                                style={{ paddingLeft: '0' }}
                                            >
                                                <Select 
                                                    value={ filter_financial_account }
                                                    style={{ width: 120 }} 
                                                    onChange={ (value) => setFilterFinancialAccount(value) }
                                                    onFocus={ () => loadFinancialAccounts() }
                                                    loading={ loading_financial_accounts }
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    <Option value="" >Todos</Option>
                                                    { financial_accounts.map(item => (
                                                        <Option 
                                                            key={item.id}
                                                            value={ item.id }
                                                        >
                                                            { item.name }
                                                            {/* { fixFinancialAccount(null, item) } */}
                                                        </Option>
                                                    )) }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                className="form-item"
                                                label="Fornecedor:"
                                            >
                                                <Select 
                                                    value={ filter_provider }
                                                    style={{ width: 120 }} 
                                                    onChange={ (value) => setFilterProvider(value) }
                                                    onFocus={ () => loadProviders() }
                                                    loading={ loading_providers }
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    <Option value="" >Todos</Option>
                                                    { providers.map(item => (
                                                        <Option 
                                                            key={item.id}
                                                            value={ item.id }
                                                        >
                                                            { item.name !== '' ? item.name : item.fantasy_name }
                                                        </Option>
                                                    )) }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                className="form-item"
                                                label="Cliente:"
                                            >
                                                <Select 
                                                    value={ filter_client }
                                                    style={{ width: 120 }} 
                                                    onChange={ (value) => setFilterClient(value) }
                                                    onFocus={ () => loadClients() }
                                                    loading={ loading_clients }
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    <Option value="" >Todos</Option>
                                                    { clients.map(item => (
                                                        <Option 
                                                            key={item.id}
                                                            value={ item.id }
                                                        >
                                                            { item.name !== '' ? item.name : item.fantasy_name }
                                                        </Option>
                                                    )) }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                className="form-item"
                                                style={{ marginTop: '25px', paddingRight: '0' }}
                                            >
                                                <Button 
                                                    key="submit"
                                                    type="primary"
                                                    loading={loadingTable}
                                                    onClick={ () => reloadData() }
                                                    style={{ width: '100%' }}
                                                >
                                                    Filtrar
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>





                                <Row style={{ paddingTop:'20px',paddingBottom: '0' }}>
                                    <Col span={24}>
                                        <div style={{ overflow: 'auto', marginBottom:'15px' }}>
                                            <Form.Item
                                                className="form-item"
                                                style={{ 
                                                    paddingTop: 0, 
                                                    paddingRight: 0,
                                                    paddingLeft: 0,
                                                    paddingBottom: 0,
                                                    width: '100px',
                                                    float: 'left',
                                                }}
                                            >
                                                <Select 
                                                    value={tableLength} 
                                                    onChange={ async (value) => setTableLength(value) }
                                                >
                                                    <Option key="10" value="10">10</Option>
                                                    <Option key="25" value="25">25</Option>
                                                    <Option key="50" value="50">50</Option>
                                                    <Option key="100" value="100">100</Option>
                                                </Select>
                                            </Form.Item>
                                            <Form.Item
                                                className="form-item"
                                                style={{ 
                                                    paddingTop: 0,
                                                    paddingRight: 0,
                                                    paddingLeft: 0,
                                                    paddingBottom: 0,
                                                    width: '200px',
                                                    float: 'right',
                                                }}
                                            >
                                                <Search
                                                    id="search"
                                                    placeholder="Pesquisar"
                                                    value={search}
                                                    onChange={ event => setSearch(event.target.value) }
                                                    onSearch={ value => reloadData() }
                                                    loading={ loadingTable }
                                                />
                                            </Form.Item>
                                        </div>
                                    </Col>
                                    <Col span={24} >
                                        <Table
                                            bordered={true}
                                            columns={columns}
                                            rowKey={record => record.id}
                                            dataSource={data.data}
                                            pagination={tablePagination}
                                            loading={loadingTable}
                                            onChange={loadData}
                                            pageSize={tableLength}
                                            locale={{ emptyText: <Empty description={<b>Nenhum registro encontrado...</b>} /> }}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Content>

            <BillDetailsModal type={bill_type} bill={bill} afterSave={loadData} visible={show_bill_details_modal} setVisibility={setShowBillDetailsModal} />

            <Footbar />
        </Layout>
    )
}