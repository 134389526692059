import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Layout, Tooltip } from 'antd';

import { listPermissions } from '../../services/auth';

import './style.css';

const { Header } = Layout;

function SettingsSidebar(props) {
    const [permissions, setPermissions] = useState({});

    useEffect(() => {
        async function loadPermissions(){
            setPermissions(listPermissions());
        }

        loadPermissions();
    }, []);

    return (
        <>
            <div className="settings_sidebar">
                <ul>
                    { permissions.account_edit && 
                        <li>
                            <a href="/configuracoes/empresa" className={props.actived == 'account' ? 'actived' : ''}>
                                Dados da empresa
                            </a>
                        </li>
                    }
                    { permissions.billing_edit && 
                        <li>
                            <a href="/configuracoes/assinatura" className={props.actived == 'billing' ? 'actived' : ''}>
                                Meu Plano
                            </a>
                        </li>
                    }
                    { permissions.financial_categories_edit && 
                        <li>
                            <a href="/configuracoes/categorias-financeiras" className={props.actived == 'financial_categories' ? 'actived' : ''}>
                                Categorias Financeiras
                            </a>
                        </li>
                    }
                    { permissions.cost_centers_edit && 
                        <li>
                            <a href="/configuracoes/centros-de-custo" className={props.actived == 'cost_centers' ? 'actived' : ''}>
                                Centros de custo
                            </a>
                        </li>
                    }
                    { permissions.products_categories_edit && 
                        <li>
                            <a href="/configuracoes/categorias-de-produtos" className={props.actived == 'products_categories' ? 'actived' : ''}>
                                Categorias de Produtos
                            </a>
                        </li>
                    }
                    { permissions.brands_edit && 
                        <li>
                            <a href="/configuracoes/marcas-de-produtos" className={props.actived == 'products_brands' ? 'actived' : ''}>
                                Marcas de Produtos
                            </a>
                        </li>
                    }
                    { permissions.users_edit && 
                        <li>
                            <a href="/configuracoes/usuarios" className={props.actived == 'users' ? 'actived' : ''}>
                                Usuários
                            </a>
                        </li>
                    }
                </ul>
            </div>
        </>
    );
}

export default SettingsSidebar;