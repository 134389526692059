module.exports = function validateValue( value )
{
    if(typeof value === 'string'){
        value = value.replace(' ', '');
        if(value.indexOf('R$') > -1){
            value = value.replace('R$', '');
        }    
        if(value.indexOf('%') > -1){
            value = value.replace('%', '');
        }
        if(value.includes('.') && value.includes(',')){
            value = value.replace('.', '');
        }
        if(value.includes(',')){
            value = value.replace(',', '.');
        }
    }

    if(value === ''){
        value = 0;
    }

    return parseFloat(value);
}