import React, { useState, useEffect } from 'react';
import {Link, useParams, useNavigate} from 'react-router-dom';
import { 
    Layout,
    Form,
    Input,
    Button,
    DatePicker,
    Row,
    Col,
    Select,
    message,
    Table,
    Modal,
    Tooltip,
    Empty,
    Tabs,
} from 'antd';
import { 
    PlusOutlined,
    EditOutlined,
    DeleteOutlined
} from '@ant-design/icons';

import locale from 'antd/es/date-picker/locale/pt_BR';
import moment from 'moment';
import 'moment/locale/pt-br';
import { parseISO, format } from 'date-fns';

import Topbar from '../components/Topbar';
import Footbar from '../components/Footbar';
import ContactModal from '../components/Modal/Contact';

import 'antd/dist/antd.css';
import './styles/Main.css';

import api from '../services/api.js';

const { Search } = Input;
const { Content } = Layout;
const { confirm } = Modal;
const { Option } = Select;
const { TabPane } = Tabs;

export default function Contacts() {
    const [loadingTable, setLoadingTable] = useState(true);
    const [loading_contact, setLoadingContact] = useState(false);
    const [show_contact_modal, setShowContactModal] = useState(false);

    const [data, setData] = useState([]);

    const [tableLength, setTableLength] = useState(10);
    const [tablePagination, setTablePagination] = useState({current: 1, pageSize: tableLength});
    const [tableFilters, setTableFilters] = useState({});
    const [tableSorter, setTableSorter] = useState({ field: 'name', order: 'ascend' });
    const [search, setSearch] = useState('');
    const [type, setType] = useState('all');
    
    const [filter_type_person, setFilterTypePerson] = useState('');
    const [filter_date_birth_begin, setFilterDateBirthBegin] = useState('');
    const [filter_date_birth_end, setFilterDateBirthEnd] = useState('');

    const [contact, setContact] = useState({});
    const [id, setId] = useState('');

    let params   = useParams();
    let navigate = useNavigate();

    useEffect(() => {
        let params_type = "";
        if(params.type){
            switch (params.type) {
                case "clientes":
                    params_type = "client";
                    break;
                case "fornecedores":
                    params_type = "provider";
                    break;
                default:
                    params_type = "";
                    break;
            }
            setType(params_type);
        }
        loadData(params_type);
    }, [tableLength]);

    async function reloadData(){
        loadData(type, {current: 1, pageSize: tableLength});
    }

    async function loadData(
        tab = type,
        pagination = tablePagination,
        filters = tableFilters,
        sorter = tableSorter
    ) {
        setLoadingTable(true);

        setTablePagination(pagination);
        setTableFilters(filters);
        if(!sorter.field){
            sorter = tableSorter
        }
        setTableSorter(sorter);

        let order = sorter.order;
        if(order === undefined) {
            order = 'asc';
        } else if(sorter.order === 'ascend') {
            order = 'asc';
        } else if(sorter.order === 'descend') {
            order = 'desc';
        }

        if(tab){
            tab = `type=${tab}&`;
        }

        let uri = `/contacts?${tab}page=${pagination.current}&per_page=${tableLength}&order=${order}&order_by=${sorter.field}&search=${search}`;
        uri += `&type_person=${filter_type_person}`;
        uri += `&birth_begin=${filter_date_birth_begin}&birth_end=${filter_date_birth_end}`;

        let response = await api.get(uri);

        let contacts_list = response.data.data;

        setData(contacts_list);
        setTablePagination({
            current: pagination.current,
            pageSize: tableLength,
            total: contacts_list.total,
        });

        setLoadingTable(false);
    }

    async function showContact(contact = null) {
        setContact(contact);
        setShowContactModal(true);
    }

    function deleteContact(contact_id) {
        confirm({
            title: 'Você tem certeza?',
            content: 'Esta ação não poderá ser desfeita!',
            okText: 'Deletar',
            okType: 'danger',
            cancelText: 'Cancelar',
            async onOk() {
                let response = await api.delete(`/contacts/${contact_id}`);

                if(response.data.success) {
                    message.success('Contato deletado com sucesso');
                } else {
                    message.error('Ocorreu um erro, aguarde alguns instantes e tente novamente');
                }

                await loadData();
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    function fixGender(gender){
        switch (gender) {
            case 'M':
                return 'Masculino';
            case 'F':
                return 'Feminino';
            default:
                return '-';
        }
    }

    function formatDate(date){
        if(date !== null && date !== '' && date !== '0000-00-00'){
            date = parseISO(date);
            return format(date, 'dd/MM/yyyy');
        } else {
            return '-';
        }
    }

    function changeTab(key) {
        setType(key);
        loadData(key);
    }

    const columns = [
        {
            title: 'NOME ',
            dataIndex: 'name',
            width: '15%',
        }, {
            title: 'EMAIL',
            dataIndex: 'email',
            width: '15%',
        }, {
            title: 'TELEFONE',
            dataIndex: 'phone',
            width: '15%',
        }, {
            title: 'CPF / CNPJ',
            render: contact => ((
                <>
                    { contact.cpf !== '' ? contact.cpf : contact.cnpj }
                </>
            )),
            width: '10%',
        }, {
            title: 'SEXO',
            render: (contact => (
                <>
                    { fixGender(contact.gender) }
                </>
            )),
            width: '10%',
        }, {
            title: 'CIDADE/UF',
            render: (contact => (
                <>
                    {(!contact.city || !contact.state) ? '-' : `${contact.city}/${contact.state}` }
                </>
            )),
            width: '10%',
        },{
            title: 'AÇÕES',
            width: '20%',
            render: (contact => (
                <>
                    <Tooltip title="Editar" placement="left">
                        <Button 
                            type="secondary-default" 
                            icon={<EditOutlined />}
                            onClick={event => showContact(contact)}
                            disabled={loading_contact}
                            style={{
                                marginLeft: '5px',
                                paddingTop: '4px',
                                paddingBottom: '4px',
                                paddingLeft: '10px',
                                paddingRight: '10px',
                                width:'auto',
                            }}
                        ></Button>
                    </Tooltip>
                    <Tooltip title="Deletar" placement="right">
                        <Button 
                            type="secondary-default" 
                            icon={<DeleteOutlined/>}
                            onClick={event => deleteContact(contact.id)}
                            disabled={loading_contact}
                            style={{ 
                                marginLeft: '5px',
                                paddingTop: '4px',
                                paddingBottom: '4px',
                                paddingLeft: '10px',
                                paddingRight: '10px',
                                width:'auto',
                                color: '#ff4d4f !important',
                            }}
                        ></Button>
                    </Tooltip>
                </>
            )),
        },
    ];

    // let loading_screen = '';
    // if(loading){
    //     loading_screen = <Loading />
    // }

    return (
        <Layout className="layout">
            {/* { loading_screen } */}
            <Topbar />

            <Content className="fbm-main">
                <div id="main">
                    <Row style={{ marginLeft: '-10px', marginRight: '-10px' }}>
                        <Col span={24} style={{ padding: '0 10px 10px 10px' }}>
                            <div className="fbm-card">
                                <Row style={{ paddingBottom: '15px', borderBottomWidth: '0.5px', borderBottomColor: '#e8e8e8', borderBottomStyle: 'solid' }}>
                                    <Col className="fbm-card-header" span={24}>
                                        <h1 className="big-title">
                                            Contatos
                                        </h1>
                                        <Button 
                                            type="success"
                                            onClick={showContact}
                                            style={{marginLeft: '15px', borderRadius: '50%'}}
                                        >
                                            <PlusOutlined />
                                        </Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <Tabs activeKey={type} onChange={changeTab}>
                                            <TabPane tab="Geral" key="all"></TabPane>
                                            <TabPane tab="Clientes" key="client"></TabPane>
                                            <TabPane tab="Fornecedores" key="provider"></TabPane>
                                            <TabPane tab="Outros" key="other"></TabPane>
                                        </Tabs>
                                    </Col>
                                </Row>
                                <Form layout='vertical'>
                                    <Row style={{ paddingBottom: '20px', borderBottomWidth: '0.5px', borderBottomColor: '#c9d3dd', borderBottomStyle: 'dashed' }}>
                                        <Col span={4}>
                                            <Form.Item
                                                className="form-item"
                                                label="Tipo:"
                                                style={{ paddingLeft: '0' }}
                                            >
                                                <Select 
                                                    value={ filter_type_person }
                                                    style={{ width: 120 }} 
                                                    onChange={ (value) => setFilterTypePerson(value) }
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    <Option value="" >
                                                        Todos
                                                    </Option>
                                                    <Option value="PF" >
                                                        Pessoa Física
                                                    </Option>
                                                    <Option value="PJ" >
                                                        Pessoa Jurídica
                                                    </Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item
                                                className="form-item"
                                                label="Abert./Nasc. (início):"
                                            >
                                                <DatePicker
                                                    locale={locale}
                                                    showTime={false}
                                                    value={filter_date_birth_begin !== '' ? moment(filter_date_birth_begin) : ''}
                                                    format="DD/MM/YYYY"
                                                    onChange={ (date, dateString) => {
                                                        setFilterDateBirthBegin(date !== null ? format(date._d, "yyyy-MM-dd") : '');
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item
                                                className="form-item"
                                                label="Abert./Nasc. (fim):"
                                            >
                                                <DatePicker 
                                                    locale={locale}
                                                    showTime={false}
                                                    value={filter_date_birth_end !== '' ? moment(filter_date_birth_end) : ''}
                                                    format="DD/MM/YYYY"
                                                    onChange={ (date, dateString) => {
                                                        setFilterDateBirthEnd(date !== null ? format(date._d, "yyyy-MM-dd") : '');
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item
                                                className="form-item"
                                                style={{ marginTop: '25px', paddingRight: '0' }}
                                            >
                                                <Button 
                                                    key="submit"
                                                    type="primary"
                                                    loading={loadingTable}
                                                    onClick={ () => reloadData() }
                                                    style={{ width: '100%' }}
                                                >
                                                    Filtrar
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>





                                <Row style={{ paddingTop:'20px',paddingBottom: '0' }}>
                                    <Col span={24}>
                                        <div style={{ overflow: 'auto', marginBottom:'15px' }}>
                                            <Form.Item
                                                className="form-item"
                                                style={{ 
                                                    paddingTop: 0, 
                                                    paddingRight: 0,
                                                    paddingLeft: 0,
                                                    paddingBottom: 0,
                                                    width: '100px',
                                                    float: 'left',
                                                }}
                                            >
                                                <Select 
                                                    value={tableLength} 
                                                    onChange={ async (value) => setTableLength(value) }
                                                >
                                                    <Option key="10" value="10">10</Option>
                                                    <Option key="25" value="25">25</Option>
                                                    <Option key="50" value="50">50</Option>
                                                    <Option key="100" value="100">100</Option>
                                                </Select>
                                            </Form.Item>
                                            <Form.Item
                                                className="form-item"
                                                style={{ 
                                                    paddingTop: 0,
                                                    paddingRight: 0,
                                                    paddingLeft: 0,
                                                    paddingBottom: 0,
                                                    width: '200px',
                                                    float: 'right',
                                                }}
                                            >
                                                <Search
                                                    id="search"
                                                    placeholder="Pesquisar"
                                                    value={search}
                                                    onChange={ event => setSearch(event.target.value) }
                                                    onSearch={ value => reloadData() }
                                                    loading={ loadingTable }
                                                />
                                            </Form.Item>
                                        </div>
                                    </Col>
                                    <Col span={24} >
                                        <Table
                                            bordered={true}
                                            columns={columns}
                                            rowKey={record => record.id}
                                            dataSource={data.data}
                                            pagination={tablePagination}
                                            loading={loadingTable || loading_contact}
                                            onChange={loadData}
                                            pageSize={tableLength}
                                            locale={{ emptyText: <Empty description={<b>Nenhum registro encontrado...</b>} /> }}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Content>

            <Footbar />



            <ContactModal type="contact" contact={contact} afterSave={loadData} visible={show_contact_modal} setVisibility={setShowContactModal} />
    
        </Layout>
    )
}