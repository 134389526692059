import React, { useState, useEffect } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { 
    Layout,
    Form,
    Breadcrumb,
    Input,
    Button,
    Row,
    Col,
    Select,
    message,
    Card,
    Modal,
    Radio,
    Checkbox,
    DatePicker,
    Tooltip,
} from 'antd';
import { 
    PlusOutlined,
    DeleteOutlined
} from '@ant-design/icons';

import CurrencyInput from 'react-currency-input';
import {NumericFormat} from 'react-number-format';
import locale from 'antd/es/date-picker/locale/pt_BR';
import moment from 'moment';
import 'moment/locale/pt-br';
import { parseISO, format } from 'date-fns';

import Topbar from '../components/Topbar';
import Footbar from '../components/Footbar';
// import Loading from '../components/Loading';

import 'antd/dist/antd.css';
import './styles/Main.css';

import api from '../services/api.js';

const cleanFloatValue = require('../utils/cleanFloatValue');

const { Content } = Layout;
const { Option, OptGroup } = Select;

export default function ProductsNew() {
    const [saving, setSaving] = useState(false);
    const [loading_categories, setLoadingCategories] = useState(false);
    const [loading_brands, setLoadingBrands] = useState(false);
    const [loading_products, setLoadingProducts] = useState(false);
    
    const [commission_invalid, setCommissionInvalid] = useState(false);
    
    const [products_list, setProductsList] = useState([]);
    const [categories, setCategories] = useState([]);
    const [brands, setBrands] = useState([]);

    const [description, setDescription] = useState('');
    const [code, setCode] = useState('');
    const [barcode, setBarcode] = useState('');
    const [category_id, setCategoryId] = useState('');
    const [brand_id, setBrandId] = useState('');
    const [sale_value, setSaleValue] = useState('');
    const [unit_cost, setUnitCost] = useState(0);
    const [amount, setAmount] = useState(0);
    const [min_amount, setMinAmount] = useState(0);
    const [max_amount, setMaxAmount] = useState(0);
    const [unit_of_measurement, setUnitOfMeasurement] = useState('');
    const [ncm_code, setNcmCode] = useState('');
    const [cest_code, setCestCode] = useState('');
    const [net_weight, setNetWeight] = useState('');
    const [gross_weight, setGrossWeight] = useState('');
    const [commission, setCommission] = useState('');
    const [compound, setCompound] = useState(false);
    const [has_stock, setHasStock] = useState(true);

    const [composition_is_valid, setCompositionIsValid] = useState(false);
    const blank_composition = { "id": null, "product_id": "", "amount": 0 };
    const [compositions, setCompositions] = useState([{...blank_composition}]);

    let navigate = useNavigate();

    useEffect(() => {
        checkLimit();
    }, []);

    async function checkLimit() {
        let response = await api.get('/billing/limits/products');
        let hasLimit = response.data.data.has_limit;
        
        if(!hasLimit){
            navigate('/produtos');
        }
    }

    async function loadBrands(){
        setLoadingBrands(true);
        setBrands([]);
        let response = await api.get(`/brands`);
        setBrands(response.data.data);
        setLoadingBrands(false);
    }

    async function loadCategories(){
        setLoadingCategories(true);
        setCategories([]);
        let response = await api.get(`/product_categories`);
        setCategories(response.data.data);
        setLoadingCategories(false);
    }

    async function saveProduct() {
        setSaving(true);

        const data = {
            description,
            code,
            barcode,
            category_id,
            brand_id,
            sale_value: cleanFloatValue(sale_value),
            unit_cost: cleanFloatValue(unit_cost),
            amount: cleanFloatValue(amount),
            max_amount: cleanFloatValue(max_amount),
            min_amount: cleanFloatValue(min_amount),
            unit_of_measurement,
            ncm_code,
            cest_code,
            net_weight: cleanFloatValue(net_weight),
            gross_weight: cleanFloatValue(gross_weight),
            commission: cleanFloatValue(commission),
            has_stock,
            compound,
            compositions: fixCompositionsValues()
        };

        try {
            let response = await api.post('/products', data);

            if(response.data.success) {
                message.success('Produto salvo com sucesso');
            } else {
                message.error('Ocorreu um erro, aguarde alguns instantes e tente novamente');
            }

            setSaving(false);

            navigate('/produtos');
        } catch (error) {
            message.error('Ocorreu um erro, aguarde alguns instantes e tente novamente');
            setSaving(false);
        }
    }

    function checkInput(field) {
        if (field.id === 'commission') {
            let clean_commission = cleanFloatValue(commission);
            
            if(clean_commission >= 0 && clean_commission <= 100){
                setCommissionInvalid(false)
                return true
            }
            setCommissionInvalid(true)
        }
    }

    // START PRODUCTS
    async function loadProducts(){
        setLoadingProducts(true);
        setProductsList([]);
        let response = await api.get(`/products_services?type=P`);
        setProductsList(response.data.data);
        setLoadingProducts(false);
    }
    // END PRODUCTS

    // START COMPOSITIONS
    function addComposition(){
        let new_composition = blank_composition;
        let updatedCompositions = [...compositions, {...new_composition}];
        setCompositions(updatedCompositions);
        validateCompositions(updatedCompositions);
    }
    function validateCompositions(updatedCompositions) {
        let valid = true;
        updatedCompositions.forEach(composition => {
            if(!composition.product_id || !composition.amount){
                valid = false;
            }
        });
        setCompositionIsValid(valid); 
    }
    function deleteComposition(idx){
        if(compositions.length > 1){
            let updatedCompositions = [...compositions.filter((composition, key) => key != idx)];
            setCompositions(updatedCompositions);
            validateCompositions(updatedCompositions);
        }
    }
    function handleCompositionChange(idx, name, value){
        const updatedCompositions = [...compositions];
        updatedCompositions[idx][name] = value;
        setCompositions(updatedCompositions);
        validateCompositions(updatedCompositions);
        console.log(updatedCompositions);
    }
    function fixCompositionsValues()
    {
        const updatedCompositions = [...compositions];
        updatedCompositions.forEach(composition => {
            composition.amount = cleanFloatValue(composition.amount);
        });
        return updatedCompositions;
    }
    //END COMPOSITIONS

    // let loading_screen = '';
    // if(loading){
    //     loading_screen = <Loading />
    // }

    return (
        <Layout className="layout">
            {/* { loading_screen } */}
            <Topbar />

            <Content className="fbm-main">
                <div id="main">
                    <Row>
                        <Col span={24} className="fbm-page-header">
                            <Breadcrumb className="breadcrumb" style={{ margin: '16px 0' }}>
                                <Breadcrumb.Item><Link to="/monitoramento">Monitoramento</Link></Breadcrumb.Item>
                                <Breadcrumb.Item><Link to="/produtos">Produtos</Link></Breadcrumb.Item>
                                <Breadcrumb.Item>Novo Produto</Breadcrumb.Item>
                            </Breadcrumb>

                            <div className="actions">
                                <Link to="/produtos">
                                    <Button type="secondary-default">
                                        Voltar
                                    </Button>
                                </Link>
                                <Button 
                                    type="success"
                                    onClick={saveProduct}
                                    loading={saving}
                                    disabled={(
                                        !description
                                    )}
                                >
                                    Salvar
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ marginLeft: '-10px', marginRight: '-10px' }}>
                        <Col span={24} style={{ padding: '0 10px 10px 10px' }}>
                            <Card className="fbm-card fbm-page">
                                <Row style={{ paddingBottom: '5px', borderBottomWidth: '0.5px', borderBottomColor: '#c9d3dd', borderBottomStyle: 'dashed' }}>
                                    <h3>Informações do produto</h3>
                                </Row>
                                <Form layout='vertical'>
                                    <Row style={{ paddingTop:'10px' }}>
                                        <Col span={10}>
                                            <Form.Item
                                                className="form-item"
                                                label="Nome do Produto:"
                                                style={{ paddingLeft: 0 }}
                                                required={true}
                                            >
                                                <Input
                                                    id="description"
                                                    value={ description }
                                                    onChange={ event => setDescription(event.target.value) }
                                                    required
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={4}>
                                            <Form.Item
                                                className="form-item"
                                                label="Código do Produto:"
                                                style={{ paddingLeft: 0 }}
                                            >
                                                <Input
                                                    id="code"
                                                    value={ code }
                                                    onChange={ event => setCode(event.target.value) }
                                                    required
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                className="form-item"
                                                label="Código de barras (EAN):"
                                            >
                                                <Input
                                                    id="barcode"
                                                    value={ barcode }
                                                    onChange={ event => setBarcode(event.target.value) }
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                className="form-item"
                                                label="Categoria do Produto:"
                                            >
                                                <Select 
                                                    value={category_id} 
                                                    onChange={ async (value) => setCategoryId(value) }
                                                    onFocus={ () => loadCategories() }
                                                    loading={ loading_categories }
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    <Option value="" disabled>Selecione</Option>
                                                    { categories.map(item => (
                                                        <Option
                                                            key={item.id}
                                                            value={ item.id }
                                                        >
                                                            { item.name }
                                                        </Option>
                                                    )) }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                className="form-item"
                                                label="Marca:"
                                                style={{ paddingRight: 0 }}
                                            >
                                                <Select 
                                                    value={brand_id} 
                                                    onChange={ async (value) => setBrandId(value) }
                                                    onFocus={ () => loadBrands() }
                                                    loading={ loading_brands }
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    <Option value="">Selecione</Option>
                                                    { brands.map(item => (
                                                        <Option
                                                            key={item.id}
                                                            value={ item.id }
                                                        >
                                                            { item.name }
                                                        </Option>
                                                    )) }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={6}>
                                            <Form.Item
                                                className="form-item"
                                                label="Valor de venda:"
                                                style={{ paddingLeft:0 }}
                                            >
                                                <CurrencyInput
                                                    value={sale_value}
                                                    onChangeEvent={ event => setSaleValue(event.target.value)}
                                                    className="ant-input"
                                                    decimalSeparator=","
                                                    thousandSeparator=""
                                                    prefix="R$ "
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                className="form-item"
                                                label="Valor de custo:"
                                            >
                                                <CurrencyInput
                                                    value={unit_cost}
                                                    onChangeEvent={ event => setUnitCost(event.target.value)}
                                                    className="ant-input"
                                                    decimalSeparator=","
                                                    thousandSeparator=""
                                                    prefix="R$ "
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                className="form-item"
                                                label="Comissão:"
                                                hasFeedback
                                                validateStatus={`${commission_invalid ? 'error' : ''}`}
                                                help={`${commission_invalid ? 'Valor máximo de 100%' : ''}`}
                                            >
                                                <CurrencyInput
                                                    id="commission"
                                                    value={commission}
                                                    onChangeEvent={ event => setCommission(event.target.value) }
                                                    onKeyUp={ event => checkInput(event.target) }
                                                    onBlur={ event => checkInput(event.target) }
                                                    className="ant-input"
                                                    decimalSeparator=","
                                                    thousandSeparator=""
                                                    suffix="%"
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    
                                    { (!compound || has_stock) && <Row>
                                        <Col span={6}>
                                            <Form.Item
                                                className="form-item"
                                                label="Disponível em estoque:"
                                                style={{ paddingLeft: 0 }}
                                            >
                                                <CurrencyInput
                                                    value={amount}
                                                    onChangeEvent={ event => setAmount(event.target.value)}
                                                    className="ant-input"
                                                    decimalSeparator=","
                                                    thousandSeparator=""
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                className="form-item"
                                                label="Mínimo em estoque:"
                                            >
                                                <CurrencyInput
                                                    value={min_amount}
                                                    onChangeEvent={ event => setMinAmount(event.target.value)}
                                                    className="ant-input"
                                                    decimalSeparator=","
                                                    thousandSeparator=""
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                className="form-item"
                                                label="Máximo em estoque:"
                                                style={{ paddingRight: 0 }}
                                            >
                                                <CurrencyInput
                                                    value={max_amount}
                                                    onChangeEvent={ event => setMaxAmount(event.target.value)}
                                                    className="ant-input"
                                                    decimalSeparator=","
                                                    thousandSeparator=""
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row> }





                                    <Row style={{ paddingTop:'25px'}}>
                                        <Col span={24}>
                                            <Radio.Group 
                                                defaultValue={compound}
                                                buttonStyle="solid"
                                                onChange={event => { 
                                                    setCompound(event.target.value)
                                                    if(!event.target.value){
                                                        setHasStock(true);
                                                    }
                                                }}
                                            >
                                                <Radio.Button value={false}>Produto Simples</Radio.Button>
                                                <Radio.Button value={true}>Produto Composto</Radio.Button>
                                            </Radio.Group>
                                        </Col>
                                    </Row>











                                    { compound && <>
                                        <Row style={{ paddingTop:'25px'}}>
                                            <Col span={24}>
                                                <Checkbox checked={has_stock} onChange={(e) => setHasStock(e.target.checked)}>Possui estoque</Checkbox>
                                            </Col>
                                        </Row>
                                        <Row style={{ paddingTop:'25px', paddingBottom: '10px', borderBottomWidth: '0.5px', borderBottomColor: '#c9d3dd', borderBottomStyle: 'dashed' }}>
                                            <h3>Composição</h3>
                                        </Row>
                                        <Row style={{ paddingTop:'15px'}}>
                                            { compositions.map((item, idx) => (
                                                <Row>
                                                    <Col span={8}>
                                                        <Form.Item
                                                            className="form-item"
                                                            label="Produto:"
                                                            required={true}
                                                            style={{ paddingLeft: '0' }}
                                                        >
                                                            <Select
                                                                value={ item.product_id }
                                                                onChange={ (value) => handleCompositionChange(idx, "product_id", value) }
                                                                id={`product_id-${idx}`}
                                                                onFocus={ () => loadProducts() }
                                                                loading={ loading_products }
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => {
                                                                    if(!Array.isArray(option.props.children) && option.props.children !== undefined){
                                                                        return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                    }
                                                                }}  
                                                            >
                                                                <Option value="" disabled={true}>Selecione</Option>
                                                                { products_list.map(product => {
                                                                    if(product.type === 'P'){
                                                                        return <Option
                                                                            key={product.id}
                                                                            value={ product.id }
                                                                        >
                                                                            { product.description }
                                                                        </Option>
                                                                    }
                                                                }) }
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={4}>
                                                        <Form.Item
                                                            className="form-item"
                                                            label="Quantidade:"
                                                            required={true}
                                                        >
                                                            <CurrencyInput
                                                                value={item.amount}
                                                                onChangeEvent={ event => handleCompositionChange(idx, "amount", event.target.value) }
                                                                className="ant-input"
                                                                decimalSeparator=","
                                                                thousandSeparator=""
                                                                name="amount"
                                                                data-idx={idx}
                                                                id={`amount-${idx}`}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={1}>
                                                        <Form.Item
                                                            className="form-item"
                                                            label=""
                                                            style={{paddingTop:'39px', paddingLeft:'5px'}}
                                                        >
                                                            <Tooltip title="Deletar" placement="right">
                                                                <Button 
                                                                    type="danger"
                                                                    icon={<DeleteOutlined />}
                                                                    onClick={event => deleteComposition(idx)}
                                                                    style={{
                                                                        marginLeft: '5px',
                                                                        paddingTop: '4px',
                                                                        paddingBottom: '4px',
                                                                        paddingLeft: '10px',
                                                                        paddingRight: '10px',
                                                                        width:'auto',
                                                                        color: '#ff4d4f !important',
                                                                    }}
                                                                ></Button>
                                                            </Tooltip>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            ))}

                                            <Row>
                                                <Col span={24} style={{paddingRight:0, paddingTop:'20px'}}>
                                                    <Form.Item >
                                                        <Button 
                                                            type="secondary-default" 
                                                            onClick={addComposition}
                                                            style={{ width: '100%' }}
                                                        >
                                                            <PlusOutlined /> Adicionar produto
                                                        </Button>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Row>
                                    </> }














                                    <Row style={{ paddingTop:'25px', paddingBottom: '10px', borderBottomWidth: '0.5px', borderBottomColor: '#c9d3dd', borderBottomStyle: 'dashed' }}>
                                        <h3>Informações usadas na Nota Fiscal (opcional)</h3>
                                    </Row>
                                    <Row style={{ paddingTop:'15px'}}>
                                        <Col span={4}>
                                            <Form.Item
                                                className="form-item"
                                                label="Un. Medida:"
                                                style={{ paddingLeft: 0 }}
                                            >
                                                <Input
                                                    id="unit_of_measurement"
                                                    value={ unit_of_measurement }
                                                    onChange={ event => setUnitOfMeasurement(event.target.value) }
                                                    required
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                className="form-item"
                                                label="NCM:"
                                            >
                                                <Input
                                                    id="ncm_code"
                                                    value={ ncm_code }
                                                    onChange={ event => setNcmCode(event.target.value) }
                                                    required
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                className="form-item"
                                                label="CEST:"
                                            >
                                                <Input
                                                    id="cest_code"
                                                    value={ cest_code }
                                                    onChange={ event => setCestCode(event.target.value) }
                                                    required
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={6}>
                                            <Form.Item
                                                className="form-item"
                                                label="Peso Liquído:"
                                                style={{ paddingLeft: 0 }}
                                            >
                                                <CurrencyInput
                                                    value={net_weight}
                                                    onChangeEvent={ event => setNetWeight(event.target.value)}
                                                    className="ant-input"
                                                    decimalSeparator=","
                                                    thousandSeparator=""
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                className="form-item"
                                                label="Peso Bruto:"
                                            >
                                                <CurrencyInput
                                                    value={gross_weight}
                                                    onChangeEvent={ event => setGrossWeight(event.target.value)}
                                                    className="ant-input"
                                                    decimalSeparator=","
                                                    thousandSeparator=""
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Content>

            <Footbar />
        </Layout>
    )
}