import React, { useState, useEffect } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {
    Layout,
    Form,
    Steps,
    Input,
    Button,
    Row,
    Col,
    Select,
    message,
    Modal,
    Card,
    Radio,
} from 'antd';
import InputMask from 'react-input-mask';
import {NumericFormat} from 'react-number-format';
import { parseISO, format } from 'date-fns';

import Cards from 'react-credit-cards';

import Topbar from '../components/Topbar';
import Footbar from '../components/Footbar';
import Loading from '../components/Loading';
import SettingsSidebar from '../components/SettingsSidebar';

import 'react-credit-cards/es/styles-compiled.css';

import 'antd/dist/antd.css';
import './styles/Main.css';

import api from '../services/api.js';
import external_api from '../services/external_api.js';

const { Step } = Steps;
const { Search } = Input;
const { Content } = Layout;
const { confirm } = Modal;
const { Option } = Select;

export default function ChangeBillingPlan() {
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [visibleModalBillingData, setVisibleModalBillingData] = useState(false);
    const [visibleModalPaymentForm, setVisibleModalPaymentForm] = useState(false);
    const [visibleModalBillingPlan, setVisibleModalBillingPlan] = useState(false);
    const [loading_zip_code, setLoadingZipCode] = useState(false);
    const [loading_coupon, setLoadingCoupon] = useState(false);
    
    const [focus, setFocus] = useState('');
    const [prev_card, setPrevCard] = useState({});
    
    const [summary, setSummary] = useState({});
    const [billing_plans_list, setBillingPlansList] = useState([]);
    const [selected_billing_plan, setSelectedBillingPlan] = useState({});

    const [coupon, setCoupon] = useState('');
    const [billing_status, setBillingStatus] = useState('active');
    const [email, setEmail] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [address, setAddress] = useState('');
    const [district, setDistrict] = useState('');
    const [zip_code, setZipCode] = useState('');
    const [complement, setComplement] = useState('');
    const [state, setState] = useState('');
    const [number, setNumber] = useState(null);
    const [card_name, setCardName] = useState('');
    const [card_number, setCardNumber] = useState('');
    const [exp_month, setExpMonth] = useState(format(new Date(), 'MM'));
    const [exp_year, setExpYear] = useState(format(new Date(), 'yyyy'));
    const [cpf_cnpj, setCpfCnpj] = useState('');
    const [period_start, setPeriodStart] = useState('');
    const [period_end, setPeriodEnd] = useState('');
    const [billing_plan, setBillingPlan] = useState({});
    const [name_social_reason, setNameSocialReason] = useState('');
    const [cvc, setCvc] = useState('');
    const [client_stripe_id, setClientStripeId] = useState('');
    const [payment_stripe_id, setPaymentStripeId] = useState('');
    const [subscription_stripe_id, setSubscriptionStripeId] = useState('');
    const [cancelled_at, setCancelledAt] = useState(null);
    const [next_billing_value, setNextBillingValue] = useState(0);
    const [date_today, setDateToday] = useState(new Date());
    
    const [amount_off, setAmountOff] = useState(0);
    const [percent_off, setPercentOff] = useState(0);
    const [current_step, setCurrentStep] = useState(0);
    const [type_plan, setTypePlan] = useState("annual");
    const [selected_plan_id, setSelectedPlanId] = useState("");

    let navigate = useNavigate();

    let next_billing = new Date();

    if(type_plan == 'monthly'){
        next_billing = new Date().setMonth(date_today.getMonth()+1);
    } else if(type_plan == 'quarterly'){
        next_billing = new Date().setMonth(date_today.getMonth()+3);
    } else if(type_plan == 'annual'){
        next_billing = new Date().setMonth(date_today.getMonth()+12);
    }

    const steps = [
        {
          title: 'Selecionar plano',
          content: <>
            <Row style={{textAlign:'center', paddingBottom:'15px'}}>
                <Radio.Group 
                    defaultValue={type_plan} 
                    onChange={(e) => {
                        setTypePlan(e.target.value);
                        setSelectedPlanId("");
                    }} 
                    buttonStyle="solid"
                >
                    <Radio.Button value="monthly">Mensal</Radio.Button>
                    <Radio.Button value="quarterly">Trimestral</Radio.Button>
                    <Radio.Button value="annual">Anual</Radio.Button>
                </Radio.Group>
            </Row>
            <Row>
                { billing_plans_list.map(billing_plan => (
                    billing_plan.type == type_plan && <Col span="8">
                        <Card className="fbm-card" loading={loading}>
                            <Row>
                                <Col span={24} style={{ paddingRight: '10px' }}>
                                    <Radio 
                                        value={billing_plan.id} 
                                        className="page-title" 
                                        style={{fontSize:'1.3rem'}}
                                        onChange={(e) => {
                                            setSelectedBillingPlan(billing_plan);
                                            setSelectedPlanId(e.target.value);
                                        }}
                                        checked={billing_plan.id == selected_plan_id}
                                    >
                                        {billing_plan.title}
                                    </Radio>
                                </Col>
                                <Col span={24}>
                                    { (amount_off > 0 || percent_off > 0) && <>
                                        <p className="overdue-today large-value" style={{
                                            padding: 0,
                                            color:'#919191',
                                            textDecoration:'line-through',
                                            fontSize:'1.4rem'
                                        }}>
                                            R$ <NumericFormat 
                                                value={billing_plan.value ? billing_plan.value : 0}
                                                displayType={'text'}
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                decimalScale={2} 
                                                fixedDecimalScale={true}
                                            />
                                        </p>
                                        <p className="overdue-today large-value" style={{paddingTop:'10px',fontWeight:'bold',color:'#5e72e4'}}>
                                            <span style={{
                                                color: '#3d474d',
                                                fontSize: '1.4rem',
                                                fontWeight: 'bold'
                                            }}>R$</span> <NumericFormat 
                                                value={billing_plan.value ? (amount_off > 0 ? billing_plan.value-((amount_off / 100).toFixed(2)) : billing_plan.value-((billing_plan.value*percent_off)/100)) : 0}
                                                displayType={'text'}
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                decimalScale={2} 
                                                fixedDecimalScale={true}
                                            />
                                        </p>
                                    </> }

                                    { (!amount_off && !percent_off) && <>
                                        <p className="overdue-today large-value" style={{fontWeight:'bold',color:'#5e72e4'}}>
                                            <span style={{
                                                color: '#3d474d',
                                                fontSize: '1.4rem',
                                                fontWeight: 'bold'
                                            }}>R$</span> <NumericFormat 
                                                value={billing_plan.value ? billing_plan.value : 0}
                                                displayType={'text'}
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                decimalScale={2} 
                                                fixedDecimalScale={true}
                                            />
                                        </p>
                                    </> }
                                </Col>
                                <Col span={24} >
                                    <p>
                                        Limite de Usuários: <strong>{billing_plan.users_limit}</strong>
                                    </p>
                                    <p>
                                        Limite de Produtos: <strong>{billing_plan.products_limit > 0 ? billing_plan.products_limit : 'ilimitado'}</strong>
                                    </p>
                                    <p>
                                        Limite de Contas a pagar: <strong>{billing_plan.bills_to_pay_limit > 0 ? billing_plan.bills_to_pay_limit : 'ilimitado'}</strong>
                                    </p>
                                    <p>
                                        Limite de Contas a receber: <strong>{billing_plan.bills_to_receive_limit > 0 ? billing_plan.bills_to_receive_limit : 'ilimitado'}</strong>
                                    </p>
                                    <p>
                                        Limite de Arquivos: <strong>{billing_plan.files_limit > 0 ? billing_plan.files_limit : 'ilimitado'}</strong>
                                    </p>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                ))}
            </Row>
            <Row style={{marginTop:'15px'}}>
                <Col span={6}>
                    <Form.Item
                        className="form-item"
                        label="Possui um cupom de desconto?"
                    >
                        <Search
                            placeholder="Digite aqui seu cupom"
                            id="coupon"
                            value={ coupon }
                            onChange={ (e) => {
                                setAmountOff(0);
                                setPercentOff(0);
                                setCoupon(e.target.value)
                            }}
                            onSearch={ value => searchCoupon() }
                            loading={ loading_coupon }
                            disabled={saving}
                        />
                    </Form.Item>
                </Col>
            </Row>
          </>
        },
        {
           title: 'Selecionar pagamento',
           content: <>
                <Row>
                    <Col span={12}>
                        <Cards
                            cvc={cvc}
                            expiry={exp_month+'/'+exp_year}
                            focused={focus}
                            name={card_name}
                            number={card_number}
                        />
                    </Col>
                    <Col span={12} style={{marginBottom: '15px'}}>
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    className="form-item"
                                    label="Número do cartão:"
                                    required={true}
                                    hasFeedback
                                    validateStatus={`${card_number === '' ? 'error' : ''}`}
                                >
                                    <InputMask 
                                        mask="9999 9999 9999 9999"
                                        alwaysShowMask={false}
                                        placeholder="____ ____ ____ ____"
                                        id="card_number"
                                        name="card_number"
                                        value={ card_number }
                                        disabled={saving}
                                        onChange={ event => setCardNumber(event.target.value) }
                                        // onKeyUp={ event => checkInput(event.target) }
                                        // onBlur={ event => checkInput(event.target) }
                                        onFocus={event => setFocus(event.target.name)}
                                    >
                                        {(inputProps) => 
                                            <Input 
                                                {...inputProps} 
                                                type="text"
                                                required
                                            />
                                        }
                                    </InputMask>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    className="form-item"
                                    label="Nome impresso no cartão:"
                                    required={true}
                                    hasFeedback
                                    validateStatus={`${card_name === '' ? 'error' : ''}`}
                                >
                                    <Input
                                        id="card_name"
                                        name="card_name"
                                        value={ card_name }
                                        disabled={saving}
                                        onChange={ event => setCardName(event.target.value) }
                                        onFocus={event => setFocus(event.target.name)}
                                        required
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    className="form-item"
                                    label="Mês de validade:"
                                    required={true}
                                >
                                    <Select 
                                        value={ exp_month }
                                        disabled={saving}
                                        style={{ width: 120 }} 
                                        onChange={ (value) => setExpMonth(value) }
                                        onFocus={event => setFocus('expiry')}
                                    >
                                        <Option value="01" >Janeiro</Option>
                                        <Option value="02" >Fevereiro</Option>
                                        <Option value="03" >Março</Option>
                                        <Option value="04" >Abril</Option>
                                        <Option value="05" >Maio</Option>
                                        <Option value="06" >Junho</Option>
                                        <Option value="07" >Julho</Option>
                                        <Option value="08" >Agosto</Option>
                                        <Option value="09" >Setembro</Option>
                                        <Option value="10" >Outubro</Option>
                                        <Option value="11" >Novembro</Option>
                                        <Option value="12" >Dezembro</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    className="form-item"
                                    label="Ano de Validade:"
                                    required={true}
                                >
                                    <Select 
                                        value={ exp_year }
                                        disabled={saving}
                                        style={{ width: 120 }} 
                                        onChange={ (value) => setExpYear(value) }
                                        onFocus={event => setFocus('expiry')}
                                    >
                                        <Option value="2021" >2021</Option>
                                        <Option value="2022" >2022</Option>
                                        <Option value="2023" >2023</Option>
                                        <Option value="2024" >2024</Option>
                                        <Option value="2025" >2025</Option>
                                        <Option value="2026" >2026</Option>
                                        <Option value="2027" >2027</Option>
                                        <Option value="2028" >2028</Option>
                                        <Option value="2029" >2029</Option>
                                        <Option value="2030" >2030</Option>
                                        <Option value="2031" >2031</Option>
                                        <Option value="2032" >2032</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    className="form-item"
                                    label="Cód. Segurança:"
                                    required={true}
                                    hasFeedback
                                    validateStatus={`${cvc === '' ? 'error' : ''}`}
                                >
                                    <Input
                                        id="cvc"
                                        name="cvc"
                                        value={ cvc }
                                        disabled={saving}
                                        onChange={ event => setCvc(event.target.value) }
                                        onFocus={event => setFocus(event.target.name)}
                                        maxLength={4}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        },
        {
            title: 'Dados de cobrança',
            content: <>
                <Form layout='vertical'>
                    <Row>
                        <Col span={7}>
                            <Form.Item
                                className="form-item"
                                label="E-mail:"
                                required={true}
                                hasFeedback
                                validateStatus={`${email === '' ? 'error' : ''}`}
                            >
                                <Input
                                    id="name_social_reason"
                                    value={ email }
                                    disabled={saving}
                                    onChange={ event => setEmail(event.target.value) }
                                    required
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className="form-item"
                                label="CPF / CNPJ:"
                                required={true}
                                hasFeedback
                                validateStatus={`${cpf_cnpj === '' ? 'error' : ''}`}
                            >
                                <Input
                                    id="cpf_cnpj"
                                    value={ cpf_cnpj }
                                    disabled={saving}
                                    onChange={ event => setCpfCnpj(event.target.value) }
                                    required
                                />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                className="form-item"
                                label="Nome / Razão social:"
                                required={true}
                                hasFeedback
                                validateStatus={`${name_social_reason === '' ? 'error' : ''}`}
                            >
                                <Input
                                    id="name_social_reason"
                                    value={ name_social_reason }
                                    disabled={saving}
                                    onChange={ event => setNameSocialReason(event.target.value) }
                                    required
                                />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                className="form-item"
                                label="CEP:"
                                required={true}
                            >
                                <InputMask 
                                    mask="99999-999"
                                    alwaysShowMask={false}
                                    placeholder="_____-___"
                                    id="zip_code"
                                    value={ zip_code }
                                    onChange={ event => setZipCode(event.target.value) }
                                    disabled={saving}
                                >
                                    {(inputProps) => 
                                        <Search
                                            {...inputProps}
                                            onSearch={ value => searchZipCode() }
                                            loading={ loading_zip_code }
                                        />
                                    }
                                </InputMask>
                            </Form.Item>
                        </Col>
                        <Col span={7}>
                            <Form.Item
                                className="form-item"
                                label="Endereço:"
                                required={true}
                                hasFeedback
                                validateStatus={`${address === '' ? 'error' : ''}`}
                            >
                                <Input
                                    id="address"
                                    value={ address }
                                    disabled={saving}
                                    onChange={ event => setAddress(event.target.value) }
                                    required
                                />
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item
                                className="form-item"
                                label="Número:"
                                required={true}
                                hasFeedback
                                validateStatus={`${number === '' ? 'error' : ''}`}
                            >
                                <Input
                                    id="number"
                                    value={ number }
                                    disabled={saving}
                                    onChange={ event => setNumber(event.target.value) }
                                    required
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className="form-item"
                                label="Bairro:"
                                required={true}
                                hasFeedback
                                validateStatus={`${district === '' ? 'error' : ''}`}
                            >
                                <Input
                                    id="district"
                                    value={ district }
                                    disabled={saving}
                                    onChange={ event => setDistrict(event.target.value) }
                                    required
                                />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                className="form-item"
                                label="Cidade:"
                                required={true}
                                hasFeedback
                                validateStatus={`${city === '' ? 'error' : ''}`}
                            >
                                <Input
                                    id="city"
                                    value={ city }
                                    disabled={saving}
                                    onChange={ event => setCity(event.target.value) }
                                    required
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                className="form-item"
                                label="Complemento:"
                            >
                                <Input
                                    id="complement"
                                    value={ complement }
                                    disabled={saving}
                                    onChange={ event => setComplement(event.target.value) }
                                    required
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </>
        },
        {
            title: 'Concluir',
            content: <>
                <Row>
                    <Col splan={24}>
                        <h2 className='page-title' style={{fontSize:'2rem',marginBottom:'15px'}}>Confirmar alteração</h2>
                        <h5 className='page-title'>{selected_billing_plan.title}</h5>
                        <p>Seu plano <b>será alterado imediatamente</b> e o valor do novo plano será cobrado no cartão selecionado.</p>
                        <br/>
                        <p>O valor referente a alteração será cobrada na forma de pagamento escolhida;</p>
                        <p>A alteração para o plano escolhido será imediata;</p>
                        <p>A assinatura será <b>renovada automaticamente em {format(next_billing, "dd/MM/yyyy")}.</b></p>
                    </Col>
                </Row>  
            </>,
        }
    ];


















    useEffect(() => {
        loadData();
    }, []);

    async function loadData() {
        let response = await api.get('/billing');
        let summary = response.data.summary;
        let customer = response.data.data.customer;
        let billing = response.data.data.billing;

        let next_billing_value_formatted = insertDecimal(customer.next_billing_value);

        setNextBillingValue(next_billing_value_formatted);
        
        setSummary(summary);

        setEmail(billing.email);
        setCity(billing.city);
        setCountry(billing.country);
        setAddress(billing.address);
        setDistrict(billing.district);
        setZipCode(billing.zip_code);
        setComplement(billing.complement);
        setState(billing.state);
        setNumber(billing.number);
        setCardName(billing.card_name);
        setCardNumber(billing.card_number ? billing.card_number : '');

        if(billing.billing_plan){
            setSelectedPlanId(billing.billing_plan.id);
            setTypePlan(billing.billing_plan.type);
        }

        if(billing.exp_month){
            setExpMonth(("0" + billing.exp_month).slice(-2));
        }

        if(billing.exp_year){
            setExpYear(billing.exp_year);
        }

        setCpfCnpj(billing.cpf_cnpj);
        setPeriodStart(billing.period_start);
        setPeriodEnd(billing.period_end);
        setBillingPlan(billing.billing_plan);
        setNameSocialReason(billing.name_social_reason);
        setClientStripeId(billing.client_stripe_id);
        setPaymentStripeId(billing.payment_stripe_id);
        setSubscriptionStripeId(billing.subscription_stripe_id);
        setCancelledAt(billing.cancelled_at);
        setBillingStatus(billing.status);
        setPrevCard({
            'card_number': billing.card_number ? billing.card_number : '',
            'exp_month': ("0" + billing.exp_month).slice(-2),
            'exp_year': ("0" + billing.exp_year).slice(-2),
            'card_name': billing.card_name,
        });

        setSelectedBillingPlan(billing.billing_plan);

        response = await api.get('/billing_plans');
        let billing_plans = response.data.data;
        setBillingPlansList(billing_plans); 

        setLoading(false);
    }

    function insertDecimal(num) {
        let total = (num / 100).toFixed(2);
        return total*1;
    }

    function formatDate(date){
        if(date !== null && date !== '' && date !== '0000-00-00'){
            date = parseISO(date);
            return format(date, 'dd/MM/yyyy');
        } else {
            return '-';
        }
    }

    async function searchZipCode() {
        setLoadingZipCode(true);

        try {
            let response = await external_api.get(`https://brasilapi.com.br/api/cep/v1/${zip_code.replace('-', '')}`);

            setLoadingZipCode(false);

            response = response.data;

            setNumber('');
            setComplement('');
            setAddress(response.street);
            setDistrict(response.neighborhood);
            setCity(response.city);
            setState(response.state);
            setCountry('BR');
        } catch (error) {
            setLoadingZipCode(false);
        }
    }

    async function searchCoupon() {
        setLoadingCoupon(true);
        
        try {
            let response = await api.get(`/billing/validate-coupon/${coupon}`);

            if(response.data.success) {
                let data = response.data.data;

                setAmountOff(data.amount_off);
                setPercentOff(data.percent_off);

                message.success('Cupom de desconto aplicado com sucesso!');
            } else {
                setCoupon('');
                setAmountOff(0);
                setPercentOff(0);
                message.error('Cupom de desconto não encontrado!');
            }
            
            setLoadingCoupon(false);
        } catch (error) {
            setCoupon('');
            setAmountOff(0);
            setPercentOff(0);
            message.error('Cupom de desconto não encontrado!');
            setLoadingCoupon(false);
        }
    }

    async function saveBillingPaymentMethod() {
        let response = [];
        const data = { 
            card_number,
            card_name,
            cvc,
            exp_month,
            exp_year 
        };
        response = await api.put('/billing/payment_methods', data);
        
        if(response.data.success) {
            return true;
        } else {
            return false;
        }
    }

    async function saveBillingData() {
        let response = [];
        
        const data = {
            email,
            cpf_cnpj,
            name_social_reason,
            city,
            country,
            address,
            number, 
            district,
            complement,
            zip_code,
            state
        };
        response = await api.put('/billing', data);

        if(response.data.success) {
            return true;
        } else {
            return false;
        }
    }

    async function changeBillingPlan() {

        setSaving(true);

        try {
            let response = [];

            let store_payment_method = await saveBillingPaymentMethod();
                
            if(!store_payment_method){
                message.error('Ocorreu um erro, verifique suas informações de pagamento e tente novamente');
            } else {
                
                let store_billing_data = await saveBillingData();

                if(!store_billing_data){
                    message.error('Ocorreu um erro, aguarde alguns instantes e tente novamente');
                } else {
                    const data = {
                        billing_plan_id: selected_plan_id,
                        coupon: coupon
                    };
                    response = await api.post('/billing', data);
                    
                    if(response.data.success) {
                        message.success('Plano alterado com sucesso');
                    } else {
                        message.error('Ocorreu um erro, aguarde alguns instantes e tente novamente');
                    }
                }
            }

            setSaving(false);

            navigate("/configuracoes/assinatura");
        } catch (error) {
            message.error('Ocorreu um erro, aguarde alguns instantes e tente novamente');
            setSaving(false);
        }
    }
    
    let loading_screen = '';
    if(loading){
        loading_screen = <Loading />
    }

    return (
        <Layout className="layout">
            { loading_screen }
            <Topbar />

            <Content className="fbm-main">
                <div id="main">
                    <Row>
                        <Col span={24}>
                            <Row>
                                <Col span={24} style={{ padding: '0 10px 10px 0' }}>
                                    <Card className="fbm-card" loading={loading}>
                                        <div>
                                            <Steps current={current_step}>
                                            {steps.map(item => (
                                                <Step key={item.title} title={item.title} />
                                            ))}
                                            </Steps>
                                            <div 
                                                style={{
                                                    padding: '50px 0'
                                                }}
                                                className="steps-content"
                                            >
                                                {steps[current_step].content}
                                            </div>
                                            <div className="steps-action">
                                                {current_step < steps.length - 1 && (
                                                    <Button 
                                                        type="success" 
                                                        style={{
                                                            float:'right'
                                                        }}
                                                        onClick={() => setCurrentStep(current_step+1)}
                                                        disabled={
                                                            current_step == 0 && ((billing_plan.id == selected_plan_id && !cancelled_at) || !type_plan || !selected_plan_id)
                                                            || current_step == 1 && (!card_number || !card_name || !cvc || !exp_month || !exp_year)
                                                            || current_step == 2 && (!email || !cpf_cnpj || !name_social_reason || !zip_code || !address || !number || !district || !city)
                                                        }
                                                    >
                                                        Próximo Passo
                                                    </Button>
                                                )}
                                                {current_step === steps.length - 1 && (
                                                    <Button 
                                                        type="success" 
                                                        style={{
                                                            float:'right'
                                                        }}
                                                        disabled={false}
                                                        onClick={ () => changeBillingPlan() }
                                                        loading={saving}
                                                    >
                                                        Concluir
                                                    </Button>
                                                )}
                                            {current_step > 0 && (
                                                <Button 
                                                    onClick={() => setCurrentStep(current_step-1)}
                                                >
                                                    Passo Anterior
                                                </Button>
                                            )}
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Content>

            <Footbar />
        </Layout>
    )
}