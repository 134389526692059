import React, { useState, useEffect } from 'react';
import {Link, useParams, useNavigate} from 'react-router-dom';
import { 
    Layout,
    Form,
    Breadcrumb,
    Input,
    Button,
    Row,
    Col,
    Select,
    message,
    Card,
    Switch,
    Modal,
} from 'antd';
import { 
    CheckOutlined,
    CloseOutlined,
} from '@ant-design/icons';
import CurrencyInput from 'react-currency-input';

import Topbar from '../components/Topbar';
import Footbar from '../components/Footbar';
import Loading from '../components/Loading';

import 'antd/dist/antd.css';
import './styles/Main.css';

import api from '../services/api.js';
import external_api from '../services/external_api.js';

import services_svg from '../assets/services.svg';

const cleanFloatValue = require('../utils/cleanFloatValue');

const { Content } = Layout;
const { Option } = Select;

export default function ServicesDetails() {
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [cnae_list, setCnaeList] = useState([]);

    const [id, setId] = useState('');
    const [description, setDescription] = useState('');
    const [code, setCode] = useState('');
    const [sale_value, setSaleValue] = useState(0);
    const [cost_value, setCostValue] = useState(0);
    const [status, setStatus] = useState('A');
    const [type, setType] = useState('P');
    const [cnae_ibge_id, setCnaeIbgeId] = useState('');
    const [municipal_service_code, setMunicipalServiceCode] = useState('');
    const [complementary_law_code_116, setComplementaryLawCode] = useState('');

    let params   = useParams();
    let navigate = useNavigate();

    useEffect(() => {
        async function loadService() {
            let response = await external_api.get('https://servicodados.ibge.gov.br/api/v2/cnae/classes');
            setCnaeList(response.data);

            if(params.id){
                setLoading(true);

                response = await api.get(`/services/${params.id}`);

                let service = response.data.data;

                setId(service.id);
                setDescription(service.description);
                setCode(service.code);
                setSaleValue(service.sale_value);
                setCostValue(service.average_cost);
                setStatus(service.status);
                setType(service.subtype);
                setCnaeIbgeId(service.cnae_ibge_id);
                setMunicipalServiceCode(service.municipal_service_code);
                setComplementaryLawCode(service.complementary_law_code_116);

                setLoading(false);
            }
        }
        
        checkLimit();
        loadService();
    }, []);

    async function checkLimit() {
        let response = await api.get('/billing/limits/services');
        let hasLimit = response.data.data.has_limit;
        
        if(!hasLimit){
            navigate('/servicos');
        }
    }

    async function saveService() {
        setSaving(true);

        const data = {
            cnae_ibge_id,
            municipal_service_code,
            complementary_law_code_116,
            description,
            code,
            sale_value: cleanFloatValue(sale_value),
            cost_value: cleanFloatValue(cost_value),
            type,
            status,
        };

        try {
            let response = [];

            if(id)
                response = await api.put(`/services/${id}`, data);
            else
                response = await api.post('/services', data);

            if(response.data.success) {
                message.success('Serviço salvo com sucesso');
            } else {
                message.error('Ocorreu um erro, aguarde alguns instantes e tente novamente');
            }

            setSaving(false);

            navigate('/servicos');
        } catch (error) {
            message.error('Ocorreu um erro, aguarde alguns instantes e tente novamente');
            setSaving(false);
        }
    }

    let loading_screen = '';
    if(loading){
        loading_screen = <Loading />
    }

    return (
        <Layout className="layout">
            { loading_screen }
            <Topbar />

            <Content className="fbm-main">
                <div id="main">
                    <Row>
                        <Col span={24} className="fbm-page-header">
                            <Breadcrumb className="breadcrumb" style={{ margin: '16px 0' }}>
                                <Breadcrumb.Item><Link to="/monitoramento">Monitoramento</Link></Breadcrumb.Item>
                                <Breadcrumb.Item><Link to="/servicos">Serviços</Link></Breadcrumb.Item>
                                <Breadcrumb.Item>Serviço</Breadcrumb.Item>
                            </Breadcrumb>

                            <div className="actions">
                                <Link to="/servicos">
                                    <Button type="secondary-default">
                                        Cancelar
                                    </Button>
                                </Link>
                                <Button 
                                    type="success"
                                    onClick={saveService}
                                    loading={saving}
                                    disabled={(
                                        !description ||
                                        !code ||
                                        !type
                                    )}
                                >
                                    Salvar
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <Form layout='vertical'>
                        <Row style={{ marginLeft: '-10px', marginRight: '-10px' }}>
                            <Col span={16} style={{ padding: '0 10px 10px 10px' }}>
                                <Card className="fbm-card" loading={loading}>
                                    <Row style={{ paddingBottom: '5px', borderBottomWidth: '0.5px', borderBottomColor: '#c9d3dd', borderBottomStyle: 'dashed' }}>
                                        <h3>Dados do serviço</h3>
                                    </Row>
                                    <Row style={{ paddingTop:'10px' }}>
                                        <Col span={12}>
                                            <Form.Item
                                                className="form-item"
                                                label="Nome do serviço:"
                                                style={{ paddingLeft: 0 }}
                                                required={true}
                                            >
                                                <Input
                                                    id="description"
                                                    value={ description }
                                                    onChange={ event => setDescription(event.target.value) }
                                                    required
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                className="form-item"
                                                label="Código interno:"
                                                required={true}
                                            >
                                                <Input
                                                    id="code"
                                                    value={ code }
                                                    onChange={ event => setCode(event.target.value) }
                                                    required
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                className="form-item"
                                                label="Tipo:"
                                                required={true}
                                                style={{ paddingRight: 0 }}
                                            >
                                                <Select 
                                                    value={type} 
                                                    onChange={ async (value) => setType(value) }
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    <Option key="P" value="P">Prestado</Option>
                                                    <Option key="T" value="T">Tomado</Option>
                                                    <Option key="PT" value="PT">Prestado e Tomado</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={6}>
                                            <Form.Item
                                                className="form-item"
                                                label="Valor de custo:"
                                                style={{ paddingLeft:0 }}
                                            >
                                                <CurrencyInput
                                                    value={cost_value}
                                                    onChangeEvent={ event => setCostValue(event.target.value)}
                                                    className="ant-input"
                                                    decimalSeparator=","
                                                    thousandSeparator=""
                                                    prefix="R$ "
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                className="form-item"
                                                label="Valor de venda:"
                                            >
                                                <CurrencyInput
                                                    value={sale_value}
                                                    onChangeEvent={ event => setSaleValue(event.target.value)}
                                                    className="ant-input"
                                                    decimalSeparator=","
                                                    thousandSeparator=""
                                                    prefix="R$ "
                                                />                                            
                                            </Form.Item>
                                        </Col>
                                        <Col span={2}>
                                            <Form.Item
                                                className="form-item"
                                                label="Status:"
                                                hasFeedback
                                            >
                                                <Switch 
                                                    checkedChildren={<CheckOutlined />}
                                                    unCheckedChildren={<CloseOutlined />}
                                                    checked={( status === 'A' )}
                                                    onChange={(value) => {
                                                        if(value){
                                                            setStatus('A')
                                                        } else {
                                                            setStatus('I')
                                                        }
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row style={{ marginTop: '30px', paddingBottom: '5px', borderBottomWidth: '0.5px', borderBottomColor: '#c9d3dd', borderBottomStyle: 'dashed' }}>
                                        <h3>Dados fiscais</h3>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <Form.Item
                                                className="form-item"
                                                label="CNAE do serviço:"
                                                style={{ paddingRight: 0, paddingLeft: 0 }}
                                            >
                                                <Select 
                                                    value={ cnae_ibge_id } 
                                                    style={{ width: 120 }} 
                                                    onChange={ (value) => setCnaeIbgeId(value) }
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }    
                                                >
                                                    { cnae_list.map(cnae => (
                                                        <Option 
                                                            key={cnae.id}
                                                            value={ cnae.id }
                                                        >
                                                            { cnae.descricao }
                                                        </Option>
                                                    )) }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}>
                                            <Form.Item
                                                className="form-item"
                                                label="Código de serviço municipal:"
                                                style={{ paddingLeft: 0 }}
                                            >
                                                <Input
                                                    id="municipal_service_code"
                                                    value={ municipal_service_code }
                                                    onChange={ event => setMunicipalServiceCode(event.target.value) }
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                className="form-item"
                                                label="Código de Lei complementar 116:"
                                                style={{ paddingRight: 0 }}
                                            >
                                                <Input
                                                    id="complementary_law_code_116"
                                                    value={ complementary_law_code_116 }
                                                    onChange={ event => setComplementaryLawCode(event.target.value) }
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col span={8} style={{ padding: '0 10px 10px 10px' }}>
                                <div className="fbm-card">
                                    <div style={{ textAlign:'center' }}>
                                        <img draggable="false" src={services_svg} width="300" height="200" alt="Usuários" />
                                        <h5 className="page-title" style={{ marginTop:'25px' }}>
                                            Serviços
                                        </h5>
                                    </div>
                                    <p>
                                        O cadastro dos serviços é importante, pois a partir dele você poderá realizar as vendas e orçamentos e também a emissão de Nota Fiscal de Serviço.
                                    </p>
                                </div>
                            </Col> 
                        </Row>
                    </Form>
                </div>
            </Content>

            <Footbar />
        </Layout>
    )
}