import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import {
    Layout,
    Form,
    Breadcrumb,
    Input,
    Button,
    Row,
    Col,
    Select,
    message,
    Table,
    Modal,
    Tooltip,
    Empty,
} from 'antd';
import { 
    PlusOutlined,
    EditOutlined,
    DeleteOutlined
} from '@ant-design/icons';

import {NumericFormat} from 'react-number-format';

import Topbar from '../components/Topbar';
import Footbar from '../components/Footbar';
// import Loading from '../components/Loading';

import 'antd/dist/antd.css';
import './styles/Main.css';

import api from '../services/api.js';

const { Search } = Input;
const { Content } = Layout;
const { confirm } = Modal;
const { Option } = Select;

export default function Services() {
    const [loadingTable, setLoadingTable] = useState(true);
    
    const [data, setData] = useState([]);
    const [has_limit, setHasLimit] = useState(false);

    const [tableLength, setTableLength] = useState(10);
    const [tablePagination, setTablePagination] = useState({current: 1, pageSize: tableLength});
    const [tableFilters, setTableFilters] = useState({});
    const [tableSorter, setTableSorter] = useState({ field: 'description', order: 'ascend' });
    const [search, setSearch] = useState('');

    useEffect(() => {
        checkLimit();
    }, []);

    useEffect(() => {
        loadData();
    }, [tableLength]);
    
    async function checkLimit() {
        setLoadingTable(true);
        let response = await api.get('/billing/limits/services');
        let hasLimit = response.data.data.has_limit;
        setHasLimit(hasLimit);
        setLoadingTable(false);
    }

    async function reloadData(){
        loadData({current: 1, pageSize: tableLength});
    }

    async function loadData(
        pagination = tablePagination,
        filters = tableFilters,
        sorter = tableSorter
    ) {
        setLoadingTable(true);

        setTablePagination(pagination);
        setTableFilters(filters);
        if(!sorter.field){
            sorter = tableSorter
        }
        setTableSorter(sorter);

        let order = sorter.order;
        if(order === undefined) {
            order = 'asc';
        } else if(sorter.order === 'ascend') {
            order = 'asc';
        } else if(sorter.order === 'descend') {
            order = 'desc';
        }

        // services
        let uri = `/services?page=${pagination.current}&per_page=${tableLength}&order=${order}&order_by=${sorter.field}&search=${search}`;
        
        let response = await api.get(uri);

        let services_list = response.data.data;

        setData(services_list);
        setTablePagination({
            current: pagination.current,
            pageSize: tableLength,
            total: services_list.total,
        });

        setLoadingTable(false);
    }

    function deleteService(service_id) {
        confirm({
            title: 'Você tem certeza?',
            content: 'Esta ação não poderá ser desfeita!',
            okText: 'Deletar',
            okType: 'danger',
            cancelText: 'Cancelar',
            async onOk() {
                let response = await api.delete(`/services/${service_id}`);

                if(response.data.success) {
                    message.success('Serviço deletado com sucesso');
                } else {
                    message.error('Ocorreu um erro, aguarde alguns instantes e tente novamente');
                }

                await loadData();
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    function fixType(type){
        switch (type) {
            case 'P':
                return 'Prestado';
            case 'T':
                return 'Tomado';
            case 'PT':
                return 'Prestado e Tomado';
            default:
                return '-';
        }
    }

    const columns = [
        {
            title: 'NOME DO SERVIÇOS',
            dataIndex: 'description',
            width: '10%',
        }, {
            title: 'CÓDIGO',
            dataIndex: 'code',
            width: '15%',
        },{
            title: 'TIPO',
            render: (service => (
                fixType(service.subtype)
            )),
            width: '10%',
        },{
            title: 'VALOR CUSTO (R$)',
            render: ( service => (
                <NumericFormat 
                    value={service.average_cost}
                    displayType={'text'}
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2} 
                    fixedDecimalScale={true}
                    style={{ fontWeight:'bold', color:'#0086ea' }}
                />
            )),
            width: '15%',
        },{
            title: 'VALOR VENDA (R$)',
            render: (service => (
                <NumericFormat 
                    value={service.sale_value}
                    displayType={'text'}
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2} 
                    fixedDecimalScale={true}
                    style={{ fontWeight:'bold', color:'#0086ea' }}
                />
            )),
            width: '10%',
        }, {
            title: 'AÇÕES',
            width: '10%',
            render: (service => (
                <>
                    <Tooltip title="Editar" placement="left">
                        <Link to={`/servicos/${service.id}`}>
                            <Button 
                                type="secondary-default" 
                                icon={<EditOutlined />}
                                style={{
                                    paddingTop: '4px',
                                    paddingBottom: '4px',
                                    paddingLeft: '10px',
                                    paddingRight: '10px',
                                    width:'auto',
                                }}
                            ></Button>
                        </Link>
                    </Tooltip>
                    <Tooltip title="Deletar" placement="right">
                        <Button 
                            type="secondary-default" 
                            icon={<DeleteOutlined />}
                            onClick={event => deleteService(service.id)}
                            style={{ 
                                marginLeft: '5px',
                                paddingTop: '4px',
                                paddingBottom: '4px',
                                paddingLeft: '10px',
                                paddingRight: '10px',
                                width:'auto',
                                color: '#ff4d4f !important',
                            }}
                        ></Button>
                    </Tooltip>
                </>
            )),
        },
    ];

    // let loading_screen = '';
    // if(loading){
    //     loading_screen = <Loading />
    // }

    return (
        <Layout className="layout">
            {/* { loading_screen } */}
            <Topbar />

            <Content className="fbm-main">
                <div id="main">
                    <Row style={{ marginLeft: '-10px', marginRight: '-10px' }}>
                        <Col span={24} style={{ padding: '0 10px 10px 10px' }}>
                            <div className="fbm-card">
                                <Row style={{ paddingBottom: '15px', borderBottomWidth: '0.5px', borderBottomColor: '#e8e8e8', borderBottomStyle: 'solid' }}>
                                    <Col className="fbm-card-header" span={24}>
                                        <h1 className="big-title">
                                            Serviços
                                        </h1>
                                        <Link to="/servicos/novo">
                                            <Button 
                                                type="success"
                                                style={{marginLeft: '15px', borderRadius: '50%'}}
                                            >
                                                <PlusOutlined />
                                            </Button>
                                        </Link>
                                    </Col>
                                </Row>
                                <Row style={{ paddingTop:'20px',paddingBottom: '0' }}>
                                    <Col span={24}>
                                        <div style={{ overflow: 'auto', marginBottom:'15px' }}>
                                            <Form.Item
                                                className="form-item"
                                                style={{ 
                                                    paddingTop: 0, 
                                                    paddingRight: 0,
                                                    paddingLeft: 0,
                                                    paddingBottom: 0,
                                                    width: '100px',
                                                    float: 'left',
                                                }}
                                            >
                                                <Select 
                                                    value={tableLength} 
                                                    onChange={ async (value) => setTableLength(value) }
                                                >
                                                    <Option key="10" value="10">10</Option>
                                                    <Option key="25" value="25">25</Option>
                                                    <Option key="50" value="50">50</Option>
                                                    <Option key="100" value="100">100</Option>
                                                </Select>
                                            </Form.Item>
                                            <Form.Item
                                                className="form-item"
                                                style={{ 
                                                    paddingTop: 0,
                                                    paddingRight: 0,
                                                    paddingLeft: 0,
                                                    paddingBottom: 0,
                                                    width: '200px',
                                                    float: 'right',
                                                }}
                                            >
                                                <Search
                                                    id="search"
                                                    placeholder="Pesquisar"
                                                    value={search}
                                                    onChange={ event => setSearch(event.target.value) }
                                                    onSearch={ value => reloadData() }
                                                    loading={ loadingTable }
                                                />
                                            </Form.Item>
                                        </div>
                                    </Col>
                                    <Col span={24} >
                                        <Table
                                            bordered={true}
                                            columns={columns}
                                            rowKey={record => record.id}
                                            dataSource={data.data}
                                            pagination={tablePagination}
                                            loading={loadingTable}
                                            onChange={loadData}
                                            pageSize={tableLength}
                                            locale={{ emptyText: <Empty description={<b>Nenhum registro encontrado...</b>} /> }}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Content>

            <Footbar />
        </Layout>
    )
}