import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import { 
    Layout,
    Row,
    Col,
    Spin,
    Card,
    Button,
    Result,
} from 'antd';
import { 
    EyeInvisibleTwoTone,
    EyeInvisibleFilled,
    EyeFilled,
    EyeTwoTone,
    RightCircleOutlined,
    CheckCircleFilled,
    MinusCircleOutlined,
    PlusCircleOutlined,
    ShoppingCartOutlined
} from '@ant-design/icons';

// import {
//     Chart as ChartJS, 
//     CategoryScale,
//     LinearScale,
//     PointElement,
//     LineElement,
//     BarElement,
// } from 'chart.js'; 
import { Chart as ChartJS } from 'chart.js/auto';
import { Bar } from 'react-chartjs-2';

import BillEditModal from '../components/Modal/BillEdit';
import Topbar from '../components/Topbar';
import Footbar from '../components/Footbar';

import { format, addDays } from 'date-fns';

import 'antd/dist/antd.css';
import './styles/Main.css';
import './styles/Dashboard.css';

import api from '../services/api.js';

import climate_day from '../assets/icon_day.svg';
import climate_night from '../assets/icon_night.svg';
import setup_wizard_svg from '../assets/setup_wizard.svg';
import Increment from '../components/Modal/FinancialAccount/Increment';

// ChartJS.register(
//     CategoryScale,
//     LinearScale,
//     PointElement,
//     LineElement,
//     BarElement
// );

export default function Dashboard() {
    const [user, setUser] = useState([]);
    const [loading_company_data, setLoadingCompanyData] = useState(true);
    const [loading_cash_flow, setLoadingCashFlow] = useState(true);
    const [loading_sales_flow, setLoadingSalesFlow] = useState(true);
    const [hide_values, setHideValues] = useState(localStorage.getItem('@semzebra/hide_values') == 'false' ? false : true);

    const [type, setType] = useState('to_pay');
    const [show_bill_modal, setShowBillModal] = useState(false);
    const [progress, setProgress] = useState(100);
    // const [company_name, setCompanyName] = useState('');
    // const [type_person, setTypePerson] = useState('');
    // const [cpf, setCpf] = useState('');
    // const [cnpj, setCnpj] = useState('');
    // const [services, setServices] = useState('');
    // const [products, setProducts] = useState('');
    // const [contributors, setContributors] = useState('');
    // const [providers, setProviders] = useState('');
    // const [clients, setClients] = useState('');
    
    const [financial_accounts, setFinancialAccounts] = useState([]);
    const [total_balance, setTotalBalance] = useState(0);
    
    const [sales_chart_data, setSalesChartData] = useState({
        datasets: [],
    });
    const [chart_data, setChartData] = useState({
        datasets: [],
    });

    const [to_pay, setToPay] = useState({
        overdue_today: 0.00,
        overdue_month: 0.00,
        total_overdue: 0.00,
    });
    const [to_receive, setToReceive] = useState({
        overdue_today: 0.00,
        overdue_month: 0.00,
        total_overdue: 0.00,
    });

    useEffect(() => {
        // LOAD USER 
        let user_logged = localStorage.getItem('@semzebra/user');
        user_logged = JSON.parse(user_logged);
        user_logged.name = user_logged.name.split(/(\s).+\s/).join("");
        setUser(user_logged);

        loadCompanyData();
        loadCashFlow();
        loadSalesFlow();
    }, []);


    async function loadCompanyData() {
        const response = await api.get('/dashboard');

        const { company, progress, financial_accounts, to_pay, to_receive } = response.data.data;

        setProgress(progress);
        // setCompanyName(company.company);
        // setCnpj(company.cnpj ? company.cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5") : '');
        // setCpf(company.cpf ? company.cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$5") : '');
        // setTypePerson(company.type_person);
        // setServices(company.services);
        // setProducts(company.products);
        // setContributors(company.employees);
        // setProviders(company.providers);
        // setClients(company.clients);

        setFinancialAccounts(financial_accounts);

        let sum_total_balance = 0;
        financial_accounts.forEach(account => {
            switch (account.type) {
                case 'CX':
                    account.type = 'Caixinha';
                    break;
                case 'CC':
                    account.type = 'Conta Corrente';
                    break;
                case 'PP':
                    account.type = 'Poupança/Investimento';
                    break;
                default:
                    account.type = 'Outros';
                    break;
            }
            sum_total_balance += account.balance;
        });

        setTotalBalance(sum_total_balance);
        setToPay(to_pay);
        setToReceive(to_receive);

        setLoadingCompanyData(false);
    }

    async function loadCashFlow() {
        const period_end = format(new Date(), "yyyy-MM-dd");
        const period_begin = format(addDays(new Date(), -14), "yyyy-MM-dd");

        const response = await api.get('/dashboard/daily_cash_flow', {
            params: {
                period_begin,
                period_end,
            }
        });

        let { labels, balance, payments, receipts, increments, withdrawals } = response.data.data;

        setChartData({
            labels,
            datasets: [
                {
                    type: 'line',
                    label: 'Saldo',
                    data: balance,
                    fill: false,
                    backgroundColor: '#395b7b',
                    borderColor: '#395b7b',
                }, {
                    label: 'Recebimentos',
                    data: receipts,
                    fill: false,
                    backgroundColor: '#2dce89',
                    borderColor: '#2dce89',
                }, {
                    label: 'Pagamentos',
                    data: payments,
                    fill: false,
                    backgroundColor: '#f5365c',
                    borderColor: '#f5365c',
                }, {
                    label: 'Depósitos',
                    data: increments,
                    fill: false,
                    backgroundColor: '#dbd63a',
                    borderColor: '#dbd63a',
                }, {
                    label: 'Retiradas',
                    data: withdrawals,
                    fill: false,
                    backgroundColor: '#f98e41',
                    borderColor: '#f98e41',
                }
            ],
        });

        setLoadingCashFlow(false);
    }

    async function loadSalesFlow() {
        const response = await api.get('/dashboard/sales_flow');

        let { labels, sales } = response.data.data;

        setSalesChartData({
            labels,
            datasets: [{
                label: 'Faturamento',
                data: sales,
                fill: false,
                backgroundColor: '#2dce89',
                borderColor: '#2dce89',
            }],
        });

        setLoadingSalesFlow(false);
    }

    function reloadDashboard() {
        loadCompanyData();
        loadCashFlow();
        loadSalesFlow();
    }

    function changeHideValues(value) {
        console.log(value);
        localStorage.setItem('@semzebra/hide_values', value);
        setHideValues(value);
    }

    function dayPeriodSalutation() {
        const d = new Date();
        const hour = d.getHours();
        if(hour < 5) {
            return "Boa noite";
        } else if(hour < 8) {
            return "Bom dia";
        } else if(hour < 12) {
            return "Bom dia!";
        } else if(hour < 18) {
            return "Boa tarde";
        } else {
            return "Boa noite";
        }
    }

    function dayPeriodClimate() {
        const d = new Date();
        const hour = d.getHours();
        if(hour < 5) {
            return climate_night;
        } else if(hour < 8) {
            return climate_day;
        } else if(hour < 12) {
            return climate_day;
        } else if(hour < 18) {
            return climate_day;
        } else {
            return climate_night;
        }
    }

    let progress_card = '';
    if(progress < 100 || financial_accounts.length === 0){
        progress_card = <Card className="fbm-card" loading={loading_company_data}>
            <Row>
                <Col span={24} style={{ paddingLeft: '25px', paddingRight: '10px' }}>
                    <Row>
                        <Col span={16} style={{paddingRight: '15px'}}>
                            <h3 style={{fontWeight:'bold'}}>Seus primeiros passos...</h3>
                            <p style={{color: '#9797ad', fontSize:'15px'}}>Nós acreditamos que este é o melhor caminho para uma experiência de sucesso no Sem Zebra.</p>

                            <Link to="/configuracoes/empresa" className="setup_wizard_item" style={{ fontSize: '16px', marginTop: '20px' }}>
                                <p 
                                    style={{marginBottom: '10px'}}
                                >
                                    {progress < 100 && <RightCircleOutlined 
                                        style={{marginRight: '10px', color:'#9797ad'}}
                                    />}

                                    {progress >= 100 && <CheckCircleFilled 
                                        style={{marginRight: '10px',color:'#2dce89'}}
                                    />}
                                    Preencha os dados do seu negócio
                                </p>
                            </Link>

                            <Link to="/contas-financeiras" className="setup_wizard_item" style={{ fontSize: '16px', marginTop: '20px' }}>
                                <p 
                                    style={{marginBottom: '10px'}}
                                >
                                    {financial_accounts.length === 0 && <RightCircleOutlined 
                                        style={{marginRight: '10px', color:'#9797ad'}} 
                                    />}

                                    {financial_accounts.length !== 0 && <CheckCircleFilled 
                                        style={{marginRight: '10px',color:'#2dce89'}} 
                                    />}
                                    Configure suas contas bancárias
                                </p>
                            </Link>
                        </Col>
                        <Col span={8}>
                            <img src={setup_wizard_svg} alt="Setuo Wizard" width="100%" />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Card>
    }

    let financial_accounts_card = '';
    if(financial_accounts.length > 0) {
        financial_accounts_card = <>
            {financial_accounts.map(account => (
                <div key={account.id} className="financial-account-item">
                    <div style={{float: 'left'}}>
                        <p className="name">{ account.name }</p>
                        <p className="type">{ account.type }</p>
                    </div>
                    <div style={{float: 'right'}}>
                        <p className="balance">R$ { !hide_values ? account.balance.toLocaleString('pt-BR', {minimumFractionDigits: 2}) : '-' }</p>
                    </div>
                </div>
            ))}
        </>
    } else {
        financial_accounts_card = <>
            <div className="financial-account-total" style={{paddingTop:0}}>
                <p className="overdue-today-text">Cadastre todas as suas contas para iniciar o controle financeiro e saber onde está seu dinheiro.</p>
            
                <div style={{ paddingTop:'20px' }}>
                    <Link to="/contas-financeiras">
                        <Button type="primary" style={{ width:'100%' }}>
                            Adicionar conta
                        </Button>
                    </Link>
                </div>
            </div>
        </>
    }

    return (
        <Layout className="layout">
            <Topbar />

            <Row className="fbm-main">
                <Col span={7} style={{ paddingRight: '30px' }}>
                    <Card className="fbm-card" loading={loading_company_data}>
                        <p style={{margin:0}}>
                            {dayPeriodSalutation()},
                            <br/>
                            <span style={{fontWeight:'bold',fontSize:'1.1em'}}>{ user.name }!</span>
                            <img src={dayPeriodClimate()} style={{width:'20px','marginLeft':'10px','marginTop':'-5px'}} />
                        </p>
                    </Card>

                    <Card className="fbm-card" loading={loading_company_data}>
                        <Row>
                            <Col span={24}>
                                <p style={{fontWeight:'bold',fontSize:'1rem'}}>Acesso rápido</p>
                            </Col>
                        </Row>
                        <Row className='two-columns' style={{gap:'10px'}}>
                            <div 
                                className='inside-card text-center' 
                                onClick={() => {
                                    setType('to_pay');
                                    setShowBillModal(true);
                                }}  
                                style={{padding:'15px',cursor:'pointer'}}
                            >
                                <MinusCircleOutlined style={{fontSize:'2rem',color:'#f5365c',marginBottom:'10px'}} />
                                <br/>
                                Despesa
                            </div>
                            <div 
                                className='inside-card text-center' 
                                onClick={() => {
                                    setType('to_receive');
                                    setShowBillModal(true);
                                }}  
                                style={{padding:'15px',cursor:'pointer'}}
                            >
                                <PlusCircleOutlined style={{fontSize:'2rem',color:'#2dce89',marginBottom:'10px'}} />
                                <br/>
                                Receita
                            </div>
                        </Row>
                        {/* <Row className='two-columns' style={{gap:'10px',paddingTop:'15px'}}>
                            <Link to='/compras/novo'>
                                <div 
                                    className='inside-card text-center' 
                                    style={{padding:'15px',cursor:'pointer'}}
                                >
                                    <ShoppingCartOutlined style={{fontSize:'2rem',color:'#f5365c',marginBottom:'10px'}} />
                                    <br/>
                                    Compra
                                </div>
                            </Link>
                            <Link to='/vendas/novo'>
                                <div 
                                    className='inside-card text-center' 
                                    style={{padding:'15px',cursor:'pointer'}}
                                >
                                    <ShoppingCartOutlined style={{fontSize:'2rem',color:'#2dce89',marginBottom:'10px'}} />
                                    <br/>
                                    Venda
                                </div>
                            </Link>
                        </Row> */}
                    </Card>

                    <Card className="fbm-card" loading={loading_company_data}>
                        <div className="fbm-card-header">
                            <p style={{fontWeight:'bold',fontSize:'1rem',margin: 0}}>Saldo Geral</p>
                            {hide_values && <EyeFilled
                                onClick={ (e) => {changeHideValues(!hide_values)} }
                                style={{ position:'absolute',top:'15px',right:'15px',fontSize:'18px' }}
                            />}
                            {!hide_values && <EyeInvisibleFilled
                                onClick={ (e) => {changeHideValues(!hide_values)} }
                                style={{ position:'absolute',top:'15px',right:'15px',fontSize:'18px' }}
                            />}
                        </div>
                        <p style={{paddingTop: '15px', margin:0}}>
                            <Link to="/contas-financeiras" className="large-value" style={{fontWeight: 'bold'}}>
                                R$ { !hide_values ? total_balance.toLocaleString('pt-BR', {minimumFractionDigits: 2}) : '-' }
                            </Link>
                        </p>
                    </Card>

                    <Card className="fbm-card" loading={loading_company_data}>
                        <Row>
                            <Col span={24}>
                                <p style={{fontWeight:'bold',fontSize:'1rem'}}>Minhas contas</p>
                            </Col>
                        </Row>
                        { financial_accounts_card }
                    </Card>
                    <Card className="fbm-card" loading={loading_company_data}>
                        <Row>
                            <Col span={24}>
                                <p style={{fontWeight:'bold',fontSize:'1rem'}}>Suporte</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <p>Fale com a gente (SAC) para elogios, sugestões e reclamações.</p>
                                <p>
                                    <strong>Email: </strong> 
                                    <a href="mailto:meajuda@semzebra.com.br">meajuda@semzebra.com.br</a>
                                </p>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={17} id="main">
                    {progress_card}

                    <div className="two-columns">
                        <Card className="fbm-card" loading={loading_company_data}>
                            <div className="fbm-card-header">
                                <p className="title">A receber hoje</p>
                            </div>
                            {!hide_values && <>
                                <p className="overdue-today">
                                    <>
                                    { to_receive.overdue_today > 0 &&
                                        <Link to="/contas-receber" className="large-value" style={{color:'#2dce89',fontWeight:'bold'}}>
                                            R$ {
                                                to_receive.overdue_today.toLocaleString('pt-BR', {
                                                    minimumFractionDigits: 2
                                                })
                                            }
                                        </Link>
                                    }
                                    { to_receive.overdue_today === 0 &&
                                        <p className="overdue-today-text">Suas contas a receber de hoje <br /> estão em dia.</p>
                                    }
                                    </>
                                </p>
                                <Row style={{justifyContent: 'space-between'}}>
                                    <p className="month-remaining">Restante no mês:</p>
                                    <Link to="/contas-receber">
                                        <p className="month-remaining-value">
                                            R$ { to_receive.overdue_month.toLocaleString('pt-BR', {minimumFractionDigits: 2}) }
                                        </p>
                                    </Link>
                                </Row>
                                <Row style={{paddingTop:'5px',justifyContent: 'space-between'}}>
                                    <p className="month-remaining">Em atraso:</p>
                                    <Link to="/contas-receber">
                                        <p className="month-due-value">
                                            R$ { !hide_values ? to_receive.total_overdue.toLocaleString('pt-BR', {minimumFractionDigits: 2}) : '-' }
                                        </p>
                                    </Link>
                                </Row>
                            </>}
                            {hide_values && <Result
                                icon={<EyeInvisibleTwoTone
                                    onClick={() => changeHideValues(false)}
                                />}
                                style={{padding:'25px'}}
                            />}
                        </Card>
                        <Card className="fbm-card" loading={loading_company_data}>
                            <div className="fbm-card-header">
                                <p className="title">A pagar hoje</p>
                            </div>
                            {!hide_values && <>
                                <p className="overdue-today">
                                    <>
                                    { to_pay.overdue_today > 0 &&
                                        <Link to="/contas-pagar" className="large-value" style={{color:'#ff4d4f',fontWeight:'bold'}}>
                                            R$ {
                                                !hide_values ? to_pay.overdue_today.toLocaleString('pt-BR', {
                                                    minimumFractionDigits: 2
                                                }) : '-'
                                            }
                                        </Link>
                                    }
                                    { to_pay.overdue_today === 0 &&
                                        <p className="overdue-today-text">Suas contas a pagar de hoje <br /> estão em dia.</p>
                                    }
                                    </>
                                </p>
                                <Row style={{justifyContent: 'space-between'}}>
                                    <p className="month-remaining">Restante no mês:</p>
                                    <Link to="/contas-pagar">
                                        <p className="month-remaining-value">
                                            R$ { !hide_values ? to_pay.overdue_month.toLocaleString('pt-BR', {minimumFractionDigits: 2}) : '-' }
                                        </p>
                                    </Link>
                                </Row>
                                <Row style={{paddingTop:'5px',justifyContent: 'space-between'}}>
                                    <p className="month-remaining">Em atraso:</p>
                                    <Link to="/contas-pagar">
                                        <p className="month-due-value">
                                            R$ { !hide_values ? to_pay.total_overdue.toLocaleString('pt-BR', {minimumFractionDigits: 2}) : '-' }
                                        </p>
                                    </Link>
                                </Row>
                            </>}
                            {hide_values && <Result
                                icon={<EyeInvisibleTwoTone
                                    onClick={() => changeHideValues(false)}
                                />}
                                style={{padding:'25px'}}
                            />}
                        </Card>
                    </div>

                    <Card className="fbm-card" loading={loading_company_data}>
                        <div className="fbm-card-header" style={{ paddingBottom: 15 }}>
                            <p className="title">Fluxo de Caixa diário</p>
                            <div className="action">
                                <Link to="/extrato">
                                    <Button type="secondary-default">
                                        Extrato Financeiro
                                    </Button>
                                </Link>
                            </div>
                        </div>
                        <Spin
                            tip="CARREGANDO..."
                            spinning={loading_cash_flow}
                            delay={500}
                        >
                            {!hide_values && <Bar 
                                data={ chart_data }
                                height={135}
                                options={{
                                    plugins: {
                                        tooltip: {
                                            mode: 'index',
                                            intersect: false,
                                            callbacks: {
                                                label: function(context) {
                                                    const label = context.dataset.label || '';
                                                    
                                                    const value = Number(context.dataset.data[context.dataIndex]).toLocaleString('pt-BR', { 
                                                        minimumFractionDigits: 2, 
                                                    });

                                                    return label+': R$ ' + value
                                                }
                                            }
                                        },
                                    },
                                    responsive: true,
                                    scales: {
                                        x: {
                                            // stacked: true,
                                        },
                                        y: {
                                            // stacked: true,
                                            ticks: {
                                                callback: function(value, index, ticks) {
                                                    return 'R$ '+Number(value).toLocaleString('pt-BR', { 
                                                        minimumFractionDigits: 2, 
                                                    });
                                                }
                                            },
                                        }
                                    }
                                }}
                            />}
                            {hide_values && <Result
                                icon={<EyeInvisibleTwoTone
                                    onClick={() => changeHideValues(false)}
                                />}
                            />}
                        </Spin>
                    </Card>

                    <Card className="fbm-card" loading={loading_company_data}>
                        <div className="fbm-card-header" style={{ paddingBottom: 15 }}>
                            <p className="title">Gráfico de vendas</p>
                            <div className="action">
                                <Link to="/vendas">
                                    <Button type="secondary-default">
                                        Vendas
                                    </Button>
                                </Link>
                            </div>
                        </div>
                        <Spin
                            tip="CARREGANDO..."
                            spinning={loading_sales_flow}
                            delay={500}
                        >
                            {!hide_values && <Bar 
                                data={ sales_chart_data }
                                height={135}
                                options={{
                                    tooltips: {
                                        mode: 'index',
                                        intersect: false,
                                        callbacks: {
                                            label: function(tooltipItem, data) {
                                                const label = data.datasets[tooltipItem.datasetIndex].label;
                                                const value = Number(tooltipItem.value).toLocaleString('pt-BR', { 
                                                    minimumFractionDigits: 2, 
                                                });
                                                return label+': R$ ' + value;
                                            }
                                        }
                                    },
                                    responsive: true,
                                    scales: {
                                        x: {
                                            stacked: true,
                                        },
                                        y: {
                                            stacked: true,
                                            ticks: {
                                                callback: function(label, index, labels) {
                                                    return 'R$ '+Number(label).toLocaleString('pt-BR', { 
                                                        minimumFractionDigits: 2, 
                                                    });
                                                }
                                            },
                                        }
                                    }
                                }}
                            />}
                            {hide_values && <Result
                                icon={<EyeInvisibleTwoTone
                                    onClick={() => changeHideValues(false)}
                                />}
                            />}
                        </Spin>
                    </Card>
                </Col>
            </Row>
            
            <Footbar />

            <BillEditModal type={type} bill={null} afterSave={reloadDashboard} visible={show_bill_modal} setVisibility={setShowBillModal} />
        </Layout>
    )
}