import React, { useEffect, useState } from 'react';
import { 
    Modal,
    Button,
    Row,
    Col,
    Form,
    Input,
    InputNumber,
    message,
    Layout,
    Radio,
    DatePicker,
    Select,
    Spin,
    Checkbox,
} from 'antd';
import CurrencyInput from 'react-currency-input';
import locale from 'antd/es/date-picker/locale/pt_BR';
import InputMask from 'react-input-mask';
import moment from 'moment';
import 'moment/locale/pt-br';
import { parseISO, format } from 'date-fns';

import api from '../../../../services/api';

const cleanFloatValue = require('../../../../utils/cleanFloatValue');

const { Search, TextArea } = Input;
const { Content } = Layout;
const { Option, OptGroup } = Select;

function Subscription(props) {
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loading_cost_centers, setLoadingCostCenters] = useState(false);
    const [loading_categories, setLoadingCategories] = useState(false);
    const [loading_contacts, setLoadingContacts] = useState(false);
    const [showMoreInfo, setShowMoreInfo] = useState(false);
    const [showContact, setShowContact] = useState(false);
    const [showCostCenter, setShowCostCenter] = useState(false);

    const [categories, setCategories] = useState([]);
    const [cost_centers, setCostCenters] = useState([]);
    const [contacts, setContacts] = useState([]);

    const [id, setId] = useState('');
    const [type, setType] = useState('to_pay');
    const [days_to_pay, setDaysToPay] = useState('');
    const [cost_center, setCostCenter] = useState('');
    const [category, setCategory] = useState('');
    const [contact, setContact] = useState('');
    const [interval, setInterval] = useState('monthly');
    const [last_due_date, setLastDueDate] = useState('');
    const [description, setDescription] = useState('');
    const [value, setValue] = useState('');
    const [status, setStatus] = useState('A');
    const [note, setNote] = useState('');

    useEffect(() => {
        emptySubscription();
        if(props.subscription && props.visible){
            setLoading(true);
            showSubscription(props.subscription);
        }
    }, [props.visible]);
    
    async function loadCostCenters(){
        setLoadingCostCenters(true);
        setCostCenters([]);
        let response = await api.get(`/cost_centers`);
        setCostCenters(response.data.data);
        setLoadingCostCenters(false);
    }

    async function loadCategories(cat_type = type){
        setLoadingCategories(true);
        setCategories([]);
        let categories_type = cat_type == 'to_pay' ? 'EXPENSE' : 'REVENUE';
        let response = await api.get(`/financial_categories?type=${categories_type}&filter=parents`);

        setCategories(response.data.data);
        setLoadingCategories(false);
    }

    async function loadContacts(){
        setLoadingContacts(true);
        setContacts([]);
        let contact_type = type == 'to_pay' ? 'provider' : 'client';
        let response = await api.get(`/contacts?type=${contact_type}`);
        setContacts(response.data.data);
        setLoadingContacts(false);
    }

    function emptySubscription() {
        setId('');
        setType('to_pay');
        setDaysToPay('');
        setCostCenter('');
        setCategory('');
        setContact('');
        setInterval('');
        setLastDueDate('');
        setDescription('');
        setValue('');
        setStatus('');
        setNote('');
        setShowMoreInfo(false);
        setShowCostCenter(false);
        setShowContact(false);
    }

    async function saveAccount() {
        setSaving(true);

        const data = {
            days_to_pay,
            cost_center,
            category,
            contact,
            interval,
            last_due_date,
            description,
            value: cleanFloatValue(value),
            status,
            note,
            type
        };

        try {
            let response = [];

            if(id)
                response = await api.put(`/subscriptions/${id}`, data);
            else
                response = await api.post('/subscriptions', data);

            if(response.data.success) {
                message.success('Assinatura salva com sucesso');
            } else {
                message.error('Ocorreu um erro, aguarde alguns instantes e tente novamente');
            }

            setSaving(false);
            props.afterSave();
            props.setVisibility(false);
        } catch (error) {
            message.error('Ocorreu um erro, aguarde alguns instantes e tente novamente');
            setSaving(false);
        }
    }

    async function showSubscription(subscription) {
        setId(subscription.id);
        setType(subscription.type);
        setDaysToPay(subscription.days_to_pay);
        setCostCenter(subscription.cost_center_id);
        setCategory(subscription.category_id);
        setContact(subscription.contact_id);
        setInterval(subscription.interval);
        setLastDueDate(subscription.last_due_date);
        setDescription(subscription.description);
        setValue(subscription.value);
        setStatus(subscription.status);
        setNote(subscription.note);

        if(subscription.note){
            setShowMoreInfo(true);
        }

        if(subscription.cost_center_id){
            await loadCostCenters();
            setShowCostCenter(true);
        }

        if(subscription.contact_id){
            await loadContacts();
            setShowContact(true);
        }

        if(subscription.category_id){
            await loadCategories(subscription.type);
        }

        setLoading(false);
    }

    return (
        <Modal
            title={`Assinatura`}
            visible={ props.visible }
            footer={[
                <Button
                    type="success"
                    onClick={ () => saveAccount() }
                    loading={ saving }
                    disabled={ !description || !category || !value || !interval }
                >
                    Salvar
                </Button>,
            ]}
            onCancel={() => props.setVisibility(false)}
        >
            <Spin spinning={loading}>
                <Form layout='vertical'>
                    <Row style={{paddingTop:'10px'}}>
                        <Col span={12}>
                            <Form.Item
                                className="form-item"
                                label="Tipo:"
                                required={true}
                                style={{ paddingLeft: 0}}
                            >
                                <Select 
                                    value={type} 
                                    onChange={ async (value) => setType(value) }
                                >
                                    <Option key="to_pay" value="to_pay">A pagar</Option>
                                    <Option key="to_receive" value="to_receive">A receber</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                className="form-item"
                                label="Última Cobrança:"
                                required={true}
                                style={{ paddingRight:0 }}
                            >
                                <DatePicker
                                    locale={locale}
                                    showTime={false}
                                    value={last_due_date !== '' ? moment(last_due_date) : ''}
                                    format="DD/MM/YYYY"
                                    onChange={ (date, dateString) => {
                                        setLastDueDate(date !== null ? format(date._d, "yyyy-MM-dd") : '');
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{paddingTop:'10px'}}>
                        <Col span={16}>
                            <Form.Item
                                className="form-item"
                                label="Descrição:"
                                required={true}
                                style={{ paddingLeft: 0, paddingRight: 0 }}
                            >
                                <Input
                                    id="description"
                                    value={ description }
                                    onChange={ event => setDescription(event.target.value) }
                                    required
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                className="form-item"
                                label="Dias para pagar:"
                                required={true}
                                style={{ paddingLeft: '15px', paddingRight: 0 }}
                            >
                                <InputNumber
                                    id="days_to_pay"
                                    value={ days_to_pay }
                                    onChange={ val => {
                                        if(val != null && val != undefined){
                                            if(val.length > 0){
                                                val = val.toString();
                                                val = val.replace('.', '');
                                                setDaysToPay(val);
                                            } else {
                                                setDaysToPay(val);
                                            }
                                        }
                                    }}
                                    required
                                    min={0}
                                    precision={0}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{paddingTop:'10px'}}>
                        <Col span={24}>
                            <Form.Item
                                className="form-item"
                                label="Categoria:"
                                required={true}
                                style={{ paddingLeft: 0, paddingRight: 0 }}
                            >
                                <Select 
                                    value={category} 
                                    onChange={ async (value) => setCategory(value) }
                                    onFocus={ () => loadCategories() }
                                    loading={ loading_categories }
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => {
                                        if(!Array.isArray(option.props.children) && option.props.children !== undefined){
                                            return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    }}  
                                >
                                    <Option value="" disabled>Selecione</Option>
                                    { categories.map(category => (
                                        <OptGroup
                                            key={category.id}
                                            label={ category.description }
                                        >
                                            { category.children.map(item => (
                                                <Option
                                                    key={item.id}
                                                    value={ item.id }
                                                >
                                                    { item.description }
                                                </Option>
                                            )) }
                                        </OptGroup>
                                    )) }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    { showContact && <>
                        <Row style={{paddingTop: '10px'}}>
                            <Col span={24}>
                                <Form.Item
                                    className="form-item"
                                    label={type == 'to_pay' ? 'Fornecedor' : 'Cliente'}
                                    style={{ paddingLeft: 0, paddingRight:0 }}
                                >
                                    <Select 
                                        value={contact} 
                                        onChange={ async (value) => setContact(value) }
                                        onFocus={ () => loadContacts() }
                                        loading={ loading_contacts }
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        <Option value="" disabled>Selecione</Option>
                                        { contacts.map(item => (
                                            <Option
                                                key={item.id}
                                                value={ item.id }
                                            >
                                                { item.name }
                                            </Option>
                                        )) }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </>}
                    { showCostCenter && <>
                        <Row style={{ paddingTop: '10px' }}>
                            <Col span={24}>
                                <Form.Item
                                    className="form-item"
                                    label="Centro de custo:"
                                    style={{ paddingLeft: 0, paddingRight:0 }}
                                >
                                    <Select 
                                        value={cost_center} 
                                        onChange={ async (value) => setCostCenter(value) }
                                        onFocus={ () => loadCostCenters() }
                                        loading={ loading_cost_centers }
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        <Option value="" disabled>Selecione</Option>
                                        { cost_centers.map(item => (
                                            <Option
                                                key={item.id}
                                                value={ item.id }
                                            >
                                                { item.name }
                                            </Option>
                                        )) }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </> }
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                className="form-item"
                                label="Valor:"
                                style={{ paddingLeft:0 }}
                                required={true}
                            >
                                <CurrencyInput
                                    value={value}
                                    onChangeEvent={ event => setValue(event.target.value)}
                                    className="ant-input"
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    prefix="R$ "
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                className="form-item"
                                label="Intervalo:"
                                required={true}
                                style={{ paddingRight:0 }}
                            >
                                <Select 
                                    value={interval} 
                                    onChange={ async (value) => setInterval(value) }
                                >
                                    <Option key="biweekly" value="biweekly">Quinzenal</Option>
                                    <Option key="monthly" value="monthly">Mensal</Option>
                                    <Option key="bimonthly" value="bimonthly">Bimestral</Option>
                                    <Option key="quarterly" value="quarterly">Trimestral</Option>
                                    <Option key="semiannual" value="semiannual">Semestral</Option>
                                    <Option key="yearly" value="yearly">Anual</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    { showMoreInfo && <>
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    className="form-item"
                                    label="Observação:"
                                    style={{ paddingLeft: 0, paddingRight: 0 }}
                                >
                                    <TextArea 
                                        rows={3}
                                        id="note"
                                        value={ note }
                                        onChange={ event => setNote(event.target.value) }
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </>}
                    <Row>
                        <Col span={24} style={{paddingTop:'10px'}}>
                            <p>
                                <a
                                    onClick={() => setShowContact(!showContact)}
                                    style={ showContact ? {color: "#9797ad"} : {} }
                                >
                                    + {type == 'to_pay' ? 'Fornecedor' : 'Cliente'}
                                </a>
                                <a 
                                    style={ showCostCenter ? {color: "#9797ad", marginLeft:'15px'} : {marginLeft:'15px'} }
                                    onClick={() => setShowCostCenter(!showCostCenter)}
                                >
                                    + Centro de custo
                                </a>
                                <a 
                                    style={ showMoreInfo ? {color: "#9797ad", marginLeft:'15px'} : {marginLeft:'15px'} }
                                    onClick={() => setShowMoreInfo(!showMoreInfo)}
                                >
                                    + Observação
                                </a>
                            </p>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </Modal>
    );
}

export default Subscription;