import api from './api';

export const getToken = () => localStorage.getItem('@semzebra/token');

export const login = (data) => {
    const { token_type, token, user } = data;
    
    const expires = (new Date().getTime()) + (60000 * 480);

    let role_permission = user.role.role_permission;
    let permissions = [];
    role_permission.forEach(rp => {
        if(rp.permission){
            permissions.push(rp.permission);
        }
    });

    delete user.role.role_permission;

    const new_user = { ...user, permissions: permissions }

    localStorage.setItem('@semzebra/token', token_type+' '+token);
    localStorage.setItem('@semzebra/user', JSON.stringify(new_user));
    localStorage.setItem('@semzebra/expires', expires);
};

export const logout = () => {
    const token = localStorage.getItem('@semzebra/token');
        
    api.post('/auth/logout', null, {
        headers: {
            'Authorization': token
        }
    });

    localStorage.removeItem('@semzebra/token');
    localStorage.removeItem('@semzebra/user');
    localStorage.removeItem('@semzebra/expires');
};

export const isAuthenticated = () => {
    const token = localStorage.getItem('@semzebra/token');
    const user = localStorage.getItem('@semzebra/user');
    const expires = localStorage.getItem('@semzebra/expires');

    if(!token || token === '' || !user || user === '' || !expires || expires === ''){
        return false;
    }

    const now = new Date().getTime();
    if(now >= expires) {
        localStorage.removeItem('@semzebra/token');
        localStorage.removeItem('@semzebra/user');
        localStorage.removeItem('@semzebra/expires');
        
        return false;
    }

    
    const new_expires = (new Date().getTime()) + (60000 * 480);
    localStorage.setItem('@semzebra/expires', new_expires);

    return true;
}

export const checkPermission = (permissionKey) => {
    const user = localStorage.getItem('@semzebra/user');
    
    if(permissionKey === 'monitoramento' || !permissionKey){
        return true;
    }

    let allow_access = false;

    const user_obj = JSON.parse(user);
    const user_permissions = user_obj.permissions;

    user_permissions.forEach(permission => {
        if(permission.key === permissionKey){
            allow_access = true;
        }
    });

    return allow_access;
}

export const listPermissions = () => {
    const user = localStorage.getItem('@semzebra/user');
    const user_obj = JSON.parse(user);
    const user_permissions = user_obj.permissions;
    let permissions = {};

    user_permissions.forEach(permission => {
        permissions[permission.key] = true;
    });

    return permissions;
}