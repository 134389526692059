import React, { useState, useEffect } from 'react';
import {Link,useNavigate} from 'react-router-dom';
import { 
    Layout,
    Form,
    Breadcrumb,
    Input,
    Button,
    Row,
    Col,
    Select,
    message,
    Card,
    Modal,
    Table,
    DatePicker,
    Tooltip,
    Empty,
} from 'antd';
import { 
    PlusOutlined,
    DeleteOutlined
} from '@ant-design/icons';

import CurrencyInput from 'react-currency-input';
import {NumericFormat} from 'react-number-format';
import locale from 'antd/es/date-picker/locale/pt_BR';
import moment from 'moment';
import 'moment/locale/pt-br';
import { parseISO, format } from 'date-fns';

import Topbar from '../components/Topbar';
import Footbar from '../components/Footbar';
import Loading from '../components/Loading';
import ContactModal from '../components/Modal/Contact';

import 'antd/dist/antd.css';
import './styles/Main.css';

import api from '../services/api.js';
import PriceLists from './PriceLists';

const cleanFloatValue = require('../utils/cleanFloatValue');

const { TextArea } = Input;
const { Content } = Layout;
const { Option, OptGroup } = Select;
const { confirm } = Modal;

export default function SaleAdd() {
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [loading_cost_centers, setLoadingCostCenters] = useState(false);
    const [loading_categories, setLoadingCategories] = useState(false);
    const [loading_clients, setLoadingClients] = useState(false);
    const [loading_sellers, setLoadingSellers] = useState(false);
    const [loading_products, setLoadingProducts] = useState(false);
    const [loading_price_lists, setLoadingPriceLists] = useState(false);

    const [show_contact_modal, setShowContactModal] = useState(false);
    const [contact, setContact] = useState({});

    const [products_list, setProductsList] = useState([]);
    const [clients, setClients] = useState([]);
    const [cost_centers, setCostCenters] = useState([]);
    const [categories, setCategories] = useState([]);
    const [sellers, setSellers] = useState([]);
    const [price_lists, setPriceLists] = useState([]);

    const [total_amount, setTotalAmount] = useState('');
    const [total, setTotal] = useState('');
    const [net_value, setNetValue] = useState('');
    const [gross_total, setGrossTotal] = useState('');
    const [discount_total, setDiscountTotal] = useState('');
    const [discount_sale, setDiscountSale] = useState('');
    const [discount_type, setDiscountType] = useState('money');

    const [date_sale, setDateSale] = useState(format(new Date(), "yyyy-MM-dd"));
    const [seller, setSeller] = useState('');
    const [client, setClient] = useState('');
    const [cost_center, setCostCenter] = useState('');
    const [sale_number, setSaleNumber] = useState('');
    // const [description, setDescription] = useState('');
    const [category, setCategory] = useState('');
    const [note, setNote] = useState('');

    const [products_is_valid, setProductsIsValid] = useState(false);
    const [plots_is_valid, setPlotsIsValid] = useState(false);
    
    let navigate = useNavigate();

    const blank_product = {
        "id": "",
        "amount": 1,
        "sale_value": 0,
        "total_value": 0,
        "gross_total": 0,
        "discount_value": 0,
        "price_list": "",
    };
    const [products, setProducts] = useState([{...blank_product}]);

    const blank_plot = { "value": 0, "due_date": format(new Date(), "yyyy-MM-dd"), "note": "" };
    const [plots, setPlots] = useState([{...blank_plot}]);

    useEffect(() => {
        checkLimit();
    }, []);
    
    useEffect(() => {
        calcTotal();
    }, [discount_sale, discount_type]);

    async function checkLimit() {
        setLoading(true);
        let response = await api.get('/billing/limits/bills_to_receive');
        let hasLimit = response.data.data.has_limit;
        
        setLoading(false);
        
        if(!hasLimit){
            navigate('/vendas');
        }
    }

    async function loadSellers(){
        setLoadingSellers(true);
        setSellers([]);
        let response = await api.get(`/employees`);
        setSellers(response.data.data);
        setLoadingSellers(false);
    }

    async function loadPriceLists(){
        setLoadingPriceLists(true);
        setPriceLists([]);
        let response = await api.get(`/price_lists`);
        setPriceLists(response.data.data);
        setLoadingPriceLists(false);
    }

    async function loadProducts(){
        setLoadingProducts(true);
        setProductsList([]);
        let response = await api.get(`/products_services?type=P&show_on=sale`);
        setProductsList(response.data.data);
        setLoadingProducts(false);
    }

    async function loadCostCenters(){
        setLoadingCostCenters(true);
        setCostCenters([]);
        let response = await api.get(`/cost_centers`);
        setCostCenters(response.data.data);
        setLoadingCostCenters(false);
    }

    async function loadCategories(){
        setLoadingCategories(true);
        setCategories([]);
        let response = await api.get(`/financial_categories?type=REVENUE&filter=parents`);
        setCategories(response.data.data);
        setLoadingCategories(false);
    }

    async function loadClients(){
        setLoadingClients(true);
        setClients([]);
        let response = await api.get(`/contacts?type=client`);
        setClients(response.data.data);
        setLoadingClients(false);
    }

    // PLOTS
    function handlePlotChange(idx, name, value){
        const updatedPlots = [...plots];
        updatedPlots[idx][name] = value;
        setPlots(updatedPlots);
        validatePlots(updatedPlots);
    }
    function deletePlot(idx){
        if(plots.length > 1){
            let updatedPlots = [...plots.filter((plot, key) => key != idx)];
            let date = updatedPlots[0].due_date;
            updatedPlots.forEach(plot => {
                plot.due_date = date;
                date = moment(date).add(1, 'M');
            });
            setPlots(updatedPlots);
            calcPlots(updatedPlots);
            validatePlots(updatedPlots);
        }
    }
    function addPlot(){
        let new_plot = blank_plot;
        new_plot.due_date = moment(plots[plots.length-1].due_date).add(1, 'M').format('YYYY-MM-DD');
        let updatedPlots = [...plots, {...new_plot}];
        setPlots(updatedPlots);
        calcPlots(updatedPlots);
        validatePlots(updatedPlots);
    }
    function calcPlots(plots, new_net_value = net_value){
        let updatedPlots = plots;

        let plot_value = new_net_value/updatedPlots.length;
        let diff_value = new_net_value - (plot_value.toFixed(2)*updatedPlots.length);

        updatedPlots.forEach(plot => {
            plot.value = plot_value;
        });
        updatedPlots[updatedPlots.length-1].value = diff_value + updatedPlots[updatedPlots.length-1].value;

        setPlots(updatedPlots);
    }
    // PRODUCTS
    function calcTotal(){
        const updatedProducts = [...products];
        let sum_gross_total = 0;
        let sum_discount_total = 0;
        let sum_amount = 0;
        for (let index = 0; index < updatedProducts.length; index++) {
            const element = updatedProducts[index];

            let clean_sale_value = cleanFloatValue(element.sale_value);

            let clean_discount_value = cleanFloatValue(element.discount_value);

            let clean_amount = cleanFloatValue(element.amount);

            updatedProducts[index]['gross_total'] = clean_sale_value * element.amount;
            updatedProducts[index]['total_value'] = (clean_sale_value * element.amount)-clean_discount_value;

            sum_gross_total += element.gross_total;
            sum_discount_total += clean_discount_value * 1;
            sum_amount += parseFloat(clean_amount);
        }
        setProducts(updatedProducts);
        setTotalAmount(sum_amount);

        let clean_discount_sale = cleanFloatValue(discount_sale);

        let tmp_total = sum_gross_total - sum_discount_total;
        let tmp_discount_sale = clean_discount_sale;
        
        if(discount_type === 'percent'){
            tmp_discount_sale = (tmp_total * tmp_discount_sale)/100;
        }
        
        let clean_net_value = tmp_total - tmp_discount_sale;

        setGrossTotal(sum_gross_total);
        setTotal(tmp_total);
        setNetValue(clean_net_value);
        setDiscountTotal(parseFloat(sum_discount_total) + parseFloat(tmp_discount_sale));

        calcPlots(plots, clean_net_value);
    }

    async function handleProductChange(idx, name, value){
        const updatedProducts = [...products];
        updatedProducts[idx][name] = value;
        let product_id = updatedProducts[idx]['id'];
        let price_list_id = updatedProducts[idx]['price_list'];
        if(name === 'price_list' || name === 'id'){
            if(updatedProducts[idx]['price_list'] === ''){
                let tmp_product = products_list.filter(product => product.id == updatedProducts[idx]['id']);
                updatedProducts[idx]['sale_value'] = tmp_product[0].sale_value;
            } else {
                let response = await api.get(`/price_lists/${price_list_id}/products/${product_id}`);
                updatedProducts[idx]['sale_value'] = response.data.data.sale_value;
            }
        }
        setProducts(updatedProducts);
        calcTotal();
        validateProducts(updatedProducts);
        validatePlots(plots);
    }
    function deleteProduct(idx){
        if(products.length > 1){
            const updatedProducts = [...products.filter((product, key) => key != idx)];
            setProducts(updatedProducts);
            validateProducts(updatedProducts);
            validatePlots(plots);
        }
    }
    function addProduct(){
        setProducts([...products, {...blank_product}]);
        setProductsIsValid(false);
    }

    async function saveSale(){
        setSaving(true);


        let clean_discount_total = cleanFloatValue(discount_total);

        let clean_discount_sale = cleanFloatValue(discount_sale);

        if(discount_type === 'percent'){
            clean_discount_sale = (total * clean_discount_sale)/100;
        }

        let clean_plots = [];
        let tmp_plots = plots;
        tmp_plots.map(plot => {
            plot.value = cleanFloatValue(plot.value);
            clean_plots.push(plot);
        });

        let clean_products = [];
        let tmp_products = products;
        tmp_products.map(product => {
            product.amount = cleanFloatValue(product.amount);
            product.sale_value = cleanFloatValue(product.sale_value);
            product.discount_value = cleanFloatValue(product.discount_value);

            clean_products.push(product);
        });

        const data = {
            seller,
            cost_center: cost_center ? cost_center : null,
            category,
            contact: client,
            total_amount: cleanFloatValue(total_amount),
            total_value: cleanFloatValue(total),
            net_value: cleanFloatValue(net_value),
            gross_total: cleanFloatValue(gross_total),
            discount_total: clean_discount_total ? clean_discount_total : 0,
            discount_sale: clean_discount_sale,
            sale_number,
            date_sale,
            // description,
            note,
            products: clean_products,
            plots: clean_plots,
        };

        try {
            let response = await api.post('/sales', data);

            if(response.data.success) {
                message.success('Venda salva com sucesso');
            } else {
                message.error('Ocorreu um erro, aguarde alguns instantes e tente novamente');
            }

            setSaving(false);

            navigate('/vendas');
        } catch (error) {
            message.error('Ocorreu um erro, aguarde alguns instantes e tente novamente');
            setSaving(false);
        }
    }

    function validateProducts(updatedProducts) {
        let valid = true;
        updatedProducts.forEach(product => {
            if(!product.id){
                valid = false;
            }
        });
        setProductsIsValid(valid); 
    }

    function validatePlots(updatedPlots) {
        let valid = true;
        updatedPlots.forEach(plots => {
            if(!plots.value || !plots.due_date){
                valid = false;
            }
        });
        setPlotsIsValid(valid); 
    }

    async function showContact(contact = null) {
        setContact(contact);
        setShowContactModal(true);
    }

    let loading_screen = '';
    if(loading){
        loading_screen = <Loading />
    }

    const columns = [
        {
            title: 'Lista de Preços',
            className: 'no-padding',
            render: ( (text, product, index) => (
                <Select 
                    value={ product.price_list }
                    className='no-border'
                    onChange={ (value) => handleProductChange(index, "price_list", value) }
                    id={`price_list-${index}`}
                    onFocus={ () => loadPriceLists() }
                    loading={ loading_price_lists }
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    <Option value="">Preço Padrão</Option>
                    { price_lists.map(item => (
                        <Option
                            key={item.id}
                            value={ item.id }
                        >
                            { item.name }
                        </Option>
                    )) }
                </Select>
            )),
            width: '20%',
        },
        {
            title: 'Produto / Serviço',
            className: 'no-padding',
            render: ( (text, product, index) => (
                <Select
                    value={ product.id }
                    className='no-border'
                    onChange={ (value) => handleProductChange(index, "id", value) }
                    id={`id-${index}`}
                    onFocus={ () => loadProducts() }
                    loading={ loading_products }
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                        if(!Array.isArray(option.props.children) && option.props.children !== undefined){
                            return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    }}  
                >
                    <Option value="" disabled={true}>Selecione</Option>
                    <OptGroup label="Produtos">
                        { products_list.map(item => {
                            if(item.type === 'P'){
                                return <Option
                                    key={item.id}
                                    value={ item.id }
                                >
                                    { item.description }
                                </Option>
                            }
                        }) }
                    </OptGroup>
                    <OptGroup label="Serviços">
                        { products_list.map(item => {
                            if(item.type === 'S'){
                                return <Option
                                    key={item.id}
                                    value={ item.id }
                                >
                                    { item.description }
                                </Option>
                            }
                        }) }
                    </OptGroup>
                </Select>
            )),
            width: '20%',
        },
        {
            title: 'Preço',
            className: 'no-padding',
            render: ( (text, product, index) => (
                <CurrencyInput
                    style={{border:0,textAlign:'right'}}
                    value={product.sale_value}
                    onChangeEvent={ event => handleProductChange(index, "sale_value", event.target.value) }
                    className="ant-input"
                    decimalSeparator=","
                    thousandSeparator=""
                    name="sale_value"
                    data-idx={index}
                    id={`sale_value-${index}`}
                    prefix="R$ "
                />
            )),
            width: '15%',
        },
        {
            title: 'Qtde',
            className: 'no-padding',
            render: ( (text, product, index) => (
                <Input
                    style={{border:0,textAlign:'right'}}
                    type="number"
                    min="1"
                    value={ product.amount }
                    name="amount"
                    data-idx={index}
                    id={`amount-${index}`}
                    onChange={ event => handleProductChange(index, "amount", event.target.value) }
                    required
                />
            )),
            width: '8%',
        },
        {
            title: 'Desconto',
            className: 'no-padding',
            render: ( (text, product, index) => (
                <CurrencyInput
                    style={{border:0,textAlign:'right'}}
                    value={product.discount_value}
                    onChangeEvent={ event => handleProductChange(index, "discount_value", event.target.value) }
                    className="ant-input"
                    decimalSeparator=","
                    thousandSeparator=""
                    name="discount_value"
                    data-idx={index}
                    id={`discount_value-${index}`}
                    prefix="R$ "
                />
            )),
            width: '15%',
        },
        {
            title: 'Subtotal item',
            className: 'no-padding',
            render: ( (text, product, index) => (
                <CurrencyInput
                    style={{border:0,textAlign:'right',backgroundColor:'#fff'}}
                    value={product.total_value}
                    onChangeEvent={ event => handleProductChange(index, "total", event.target.value) }
                    className="ant-input"
                    decimalSeparator=","
                    thousandSeparator=""
                    name="total_value"
                    data-idx={index}
                    id={`total_value-${index}`}
                    disabled={true}
                    prefix="R$ "
                />
            )),
            width: '15%',
        },
        {
            title: '',
            render: ( (text, product, index) => (
                <Tooltip title="Deletar" placement="right">
                    <Button 
                        type="secondary-default"
                        icon={<DeleteOutlined />}
                        onClick={event => deleteProduct(index)}
                        style={{
                            marginLeft: '5px',
                            paddingTop: '4px',
                            paddingBottom: '4px',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            width:'auto',
                            color: '#ff4d4f !important',
                        }}
                    ></Button>
                </Tooltip>
            )),
            width: '7%',
        },
    ];

    return (
        <Layout className="layout">
            { loading_screen }
            <Topbar />

            <Content className="fbm-main">
                <div id="main">
                    <Row>
                        <Col span={24} className="fbm-page-header">
                            <Breadcrumb className="breadcrumb" style={{ margin: '16px 0' }}>
                                <Breadcrumb.Item><Link to="/monitoramento">Monitoramento</Link></Breadcrumb.Item>
                                <Breadcrumb.Item><Link to="/vendas">Vendas</Link></Breadcrumb.Item>
                                <Breadcrumb.Item>Nova Venda</Breadcrumb.Item>
                            </Breadcrumb>

                            <div className="actions">
                                <Link to="/vendas">
                                    <Button type="secondary-default">
                                        Cancelar
                                    </Button>
                                </Link>
                                <Button 
                                    type="success"
                                    onClick={saveSale}
                                    loading={saving}
                                    disabled={
                                        !date_sale ||
                                        !seller ||
                                        !client ||
                                        !cost_center ||
                                        !sale_number ||
                                        !category ||
                                        !products_is_valid ||
                                        !plots_is_valid
                                    }
                                >
                                    Salvar Venda
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ marginLeft: '-10px', marginRight: '-10px' }}>
                        <Col span={24} style={{ padding: '0 10px 10px 10px' }}>
                            <Card className="fbm-card fbm-page" loading={loading}>
                                <Row style={{ justifyContent:'space-between',paddingBottom: '5px', borderBottomWidth: '0.5px', borderBottomColor: '#c9d3dd', borderBottomStyle: 'dashed' }}>
                                    <h1 style={{float:'left', fontSize:'1.5rem',marginTop:'10px'}}>
                                        VENDA
                                        { sale_number  && <span 
                                            className="badge badge-secondary"
                                            style={{
                                                paddingTop:'5px',
                                                paddingRight:'7px',
                                                paddingLeft:'7px',
                                                fontWeight:400,
                                                backgroundColor:'#eef1f6',
                                                color:'#848b90',
                                                marginLeft:'15px',
                                                borderRadius:'3px',
                                                fontSize:'20px',
                                            }}
                                        >
                                            {sale_number}
                                        </span> }
                                    </h1>
                                    <h1 style={{float:'right', fontWeight:'bold'}}>
                                        <span style={{color:'#777', fontSize:'18px'}}>R$ </span>
                                        <span style={{color:'#395b7b', fontSize:'2rem'}}>
                                            <NumericFormat 
                                                value={net_value ? net_value : 0}
                                                displayType={'text'}
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                decimalScale={2} 
                                                fixedDecimalScale={true}
                                            />
                                        </span>
                                    </h1>
                                </Row>
                                <Form layout='vertical'>
                                    <Row style={{marginTop:'25px'}}>
                                        <Col span={4}>
                                            <Form.Item
                                                className="form-item"
                                                label="Data de venda:"
                                                required={true}
                                                style={{ paddingLeft: '0' }}
                                            >
                                                <DatePicker
                                                    locale={locale}
                                                    showTime={false}
                                                    value={date_sale !== '' ? moment(date_sale) : ''}
                                                    format="DD/MM/YYYY"
                                                    onChange={ (date, dateString) => {
                                                        setDateSale(date !== null ? format(date._d, "yyyy-MM-dd") : '');
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                className="form-item"
                                                label="Vendedor:"
                                                required={true}
                                            >
                                                <Select 
                                                    value={ seller }
                                                    style={{ width: 120 }} 
                                                    onChange={ (value) => setSeller(value) }
                                                    onFocus={ () => loadSellers() }
                                                    loading={ loading_sellers }
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    <Option value="" disabled={true}>Selecione</Option>
                                                    { sellers.map(item => (
                                                        <Option
                                                            key={item.id}
                                                            value={ item.id }
                                                        >
                                                            { item.name }
                                                        </Option>
                                                    )) }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                className="form-item"
                                                label={
                                                    <>
                                                        <span>Cliente
                                                            <Link 
                                                                onClick={event => showContact(contact)}
                                                            >
                                                                <span> (novo)</span>
                                                            </Link>
                                                        </span>
                                                    </>
                                                }
                                                required={true}
                                            >
                                                <Select 
                                                    value={ client }
                                                    style={{ width: 120 }} 
                                                    onChange={ (value) => setClient(value) }
                                                    onFocus={ () => loadClients() }
                                                    loading={ loading_clients }
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    <Option value="" disabled={true}>Selecione</Option>
                                                    { clients.map(item => (
                                                        <Option
                                                            key={item.id}
                                                            value={ item.id }
                                                        >
                                                            { item.name }
                                                        </Option>
                                                    )) }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item
                                                className="form-item"
                                                label="Centro de custos:"
                                                required={true}
                                            >
                                                <Select 
                                                    value={ cost_center }
                                                    style={{ width: 120 }} 
                                                    onChange={ (value) => setCostCenter(value) }
                                                    onFocus={ () => loadCostCenters() }
                                                    loading={ loading_cost_centers }
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    <Option value="" disabled={true}>Selecione</Option>
                                                    { cost_centers.map(item => (
                                                        <Option
                                                            key={item.id}
                                                            value={ item.id }
                                                        >
                                                            { item.name }
                                                        </Option>
                                                    )) }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item
                                                className="form-item"
                                                label="Código de referência:"
                                                required={true}
                                                style={{ paddingRight: '0' }}
                                            >
                                                <Input
                                                    id="name" value={ sale_number }
                                                    onChange={ event => setSaleNumber(event.target.value) }
                                                    required
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                                <Row style={{marginTop:'25px'}}>
                                    <Table
                                        bordered
                                        dataSource={products}
                                        columns={columns}
                                        pagination={false}
                                        locale={{ emptyText: <Empty description={<b>Nenhum registro encontrado...</b>} /> }}
                                    />
                                </Row>
                                <Row style={{ paddingTop: '15px', paddingBottom: 0, borderBottomWidth: '0.5px', borderBottomColor: '#c9d3dd', borderBottomStyle: 'dashed' }}>
                                    <Col span={16}>
                                        <Form.Item >
                                            <Button 
                                                type="secondary-default" 
                                                onClick={addProduct}
                                            >
                                                <PlusOutlined /> Adicionar Produto
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Row style={{paddingTop:'15px'}}>
                                            <Col span={10} style={{textAlign:'right', fontSize:'1.2em', color:'#395b7b'}}>
                                                Subtotal:
                                            </Col>
                                            <Col span={14} style={{textAlign:'right', fontSize:'1.2em', color:'#395b7b', fontWeight:'bold'}}>
                                                <NumericFormat 
                                                    value={total ? total : 0}
                                                    displayType={'text'}
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    decimalScale={2} 
                                                    fixedDecimalScale={true}
                                                />
                                            </Col>
                                        </Row>
                                        <Row style={{ paddingTop: '15px', paddingBottom: '15px', borderBottomWidth: '0.5px', borderBottomColor: '#c9d3dd', borderBottomStyle: 'dashed' }}>
                                            <Col span={10} style={{textAlign:'right', fontSize:'1.2em', color:'#395b7b'}}>
                                                Desconto:
                                            </Col>
                                            <Col span={14} style={{textAlign:'right', fontSize:'1.2em', color:'#395b7b', fontWeight:'bold'}}>
                                                <Row>
                                                    <Col span={12} style={{paddingLeft:'15px', paddingRight:'10px'}}>
                                                        <Select 
                                                            value={ discount_type }
                                                            onChange={ (value) => setDiscountType(value) }
                                                            style={{width:'65px'}}
                                                        >
                                                            <Option value="money">R$</Option>
                                                            <Option value="percent">%</Option>
                                                        </Select>
                                                    </Col>
                                                    <Col span={12}>
                                                        <CurrencyInput
                                                            value={discount_sale}
                                                            onChangeEvent={ event => setDiscountSale(event.target.value) }
                                                            className="ant-input"
                                                            decimalSeparator=","
                                                            thousandSeparator=""
                                                            name="value"
                                                            style={{height:'32px!important',textAlign:'right'}}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row style={{paddingTop:'15px',paddingBottom:'15px'}}>
                                            <Col span={10} style={{textAlign:'right', fontSize:'1.2em', color:'#395b7b', fontWeight:'bold'}}>
                                                Total:
                                            </Col>
                                            <Col span={14} style={{textAlign:'right', fontSize:'1.2em', color:'#395b7b', fontWeight:'bold'}}>
                                                <NumericFormat 
                                                    value={ net_value ? net_value : 0 }
                                                    displayType={'text'}
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    decimalScale={2} 
                                                    fixedDecimalScale={true}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Form layout='vertical'>
                                    <Row style={{paddingTop:'15px'}}>
                                        {/* <Col span={8}>
                                            <Form.Item
                                                className="form-item"
                                                label="Descrição desta conta:"
                                                style={{ paddingLeft: '0' }}
                                            >
                                                <Input
                                                    id="name" value={ description }
                                                    onChange={ event => setDescription(event.target.value) }
                                                    required
                                                />
                                            </Form.Item>
                                        </Col> */}
                                        <Col span={8}>
                                            <Form.Item
                                                className="form-item"
                                                label="Categoria:"
                                                required={true}
                                                style={{ paddingLeft: 0 }}
                                            >
                                                <Select 
                                                    value={ category }
                                                    style={{ width: 120 }} 
                                                    onChange={ (value) => setCategory(value) }
                                                    onFocus={ () => loadCategories() }
                                                    loading={ loading_categories }
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => {
                                                        if(!Array.isArray(option.props.children) && option.props.children !== undefined){
                                                            return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    }}  
                                                >
                                                    <Option value="" disabled>Selecione</Option>
                                                    { categories.map(category => (
                                                        <OptGroup
                                                            key={category.id}
                                                            label={ category.description }
                                                        >
                                                            { category.children.map(item => (
                                                                <Option
                                                                    key={item.id}
                                                                    value={ item.id }
                                                                >
                                                                    { item.description }
                                                                </Option>
                                                            )) }
                                                        </OptGroup>
                                                    )) }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={16}>
                                            <Form.Item
                                                className="form-item"
                                                label="Observações:"
                                                style={{ paddingRight: 0 }}
                                            >
                                                <Input
                                                    id="note" value={ note }
                                                    onChange={ event => setNote(event.target.value) }
                                                    required
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    { plots.map((item, idx) => (
                                        <Row>
                                            <Col span={2}>
                                                <Form.Item
                                                    className="form-item"
                                                    label="Parcela:"
                                                    style={{paddingLeft:0}}
                                                >
                                                    <Input
                                                        disabled={true}
                                                        id="amount"
                                                        value={ `${idx+1}/${plots.length}` }
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={4}>
                                                <Form.Item
                                                    className="form-item"
                                                    label="Valor:"
                                                    required={true}
                                                >
                                                    <CurrencyInput
                                                        value={item.value}
                                                        onChangeEvent={ event => handlePlotChange(idx, "value", event.target.value) }
                                                        className="ant-input"
                                                        decimalSeparator=","
                                                        thousandSeparator=""
                                                        name="value"
                                                        data-idx={idx}
                                                        id={`value-${idx}`}
                                                        prefix="R$ "
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={4}>
                                                <Form.Item
                                                    className="form-item"
                                                    label="Data de vencimento:"
                                                    required={true}
                                                >
                                                    <DatePicker
                                                        locale={locale}
                                                        showTime={false}
                                                        value={item.due_date !== '' ? moment(item.due_date) : ''}
                                                        format="DD/MM/YYYY"
                                                        id={`due_date-${idx}`}
                                                        onChange={ (date, dateString) => {
                                                            handlePlotChange(idx, "due_date", date !== null ? format(date._d, "yyyy-MM-dd") : '')
                                                        }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={13}>
                                                <Form.Item
                                                    className="form-item"
                                                    label="Observação desta parcela:"
                                                >
                                                    <Input
                                                        value={ item.note }
                                                        name="note"
                                                        data-idx={idx}
                                                        id={`note-${idx}`}
                                                        onChange={ event => handlePlotChange(idx, "note", event.target.value) }
                                                        required
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={1}>
                                                <Form.Item
                                                    className="form-item"
                                                    label=""
                                                    style={{paddingTop:'39px', paddingLeft:'5px'}}
                                                >
                                                    <Tooltip title="Deletar" placement="right">
                                                        <Button 
                                                            type="secondary-default"
                                                            icon={<DeleteOutlined />}
                                                            onClick={event => deletePlot(idx)}
                                                            style={{
                                                                marginLeft: '5px',
                                                                paddingTop: '4px',
                                                                paddingBottom: '4px',
                                                                paddingLeft: '10px',
                                                                paddingRight: '10px',
                                                                width:'auto',
                                                                color: '#ff4d4f !important',
                                                            }}
                                                        ></Button>
                                                    </Tooltip>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    ))}
                                </Form>

                                <Row>
                                    <Col span={24} style={{paddingRight:0, paddingTop:'20px'}}>
                                        <Form.Item >
                                            <Button 
                                                type="secondary-default" 
                                                onClick={addPlot}
                                                style={{ width: '100%' }}
                                            >
                                                <PlusOutlined /> Nova Parcela
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </div>
    
                <ContactModal type="contact" contact={contact} afterSave={loadClients} visible={show_contact_modal} setVisibility={setShowContactModal} />
    
            </Content>

            <Footbar />
        </Layout>
    )
}