import React from 'react'
import { Col, Button, Tooltip, Row } from 'antd';

import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';

function CategoriesList(props) {

    let list = '';
    list = <>
        <Row style={{ paddingTop:'10px', paddingBottom:'10px',borderBottom:'1px solid #c9d3dd' }}>
            <Col span={16}>
                <h3 style={{fontSize:'16px'}}>
                    {props.category.description}
                </h3>
            </Col>
            <Col span={8} style={{textAlign:'right'}}>
                <Tooltip title="Criar categoria" placement="top">
                    <Button 
                        type="secondary-default" 
                        icon={<PlusOutlined />}
                        onClick={event => props.new(props.type, props.category.id)}
                        // disabled={loadingCategory}
                        style={{
                            paddingTop: '4px',
                            paddingBottom: '4px',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            width:'auto',
                        }}
                    ></Button>
                </Tooltip>
                <Tooltip title="Editar" placement="top">
                    <Button 
                        type="secondary-default"
                        icon={<EditOutlined />}
                        onClick={event => props.show(props.type, props.category.id)}
                        // disabled={loadingCategory}
                        style={{
                            marginLeft: '10px',
                            paddingTop: '4px',
                            paddingBottom: '4px',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            width:'auto',
                        }}
                    ></Button>
                </Tooltip>
                <Tooltip title="Deletar" placement="top">
                    <Button 
                        type="secondary-default"
                        icon={<DeleteOutlined />}
                        onClick={event => props.delete(props.category.id)}
                        // disabled={loadingCategory}
                        style={{ 
                            marginLeft: '10px',
                            paddingTop: '4px',
                            paddingBottom: '4px',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            width:'auto',
                            color: '#ff4d4f !important',
                        }}
                    ></Button>
                </Tooltip>
            </Col>
        </Row>
        { props.category.children && props.category.children.map ( category => (
            <Row style={{ paddingTop:'10px', paddingBottom:'10px',borderBottom:'1px solid #c9d3dd' }}>
                <Col span={16} style={{paddingLeft:'30px'}}>
                    <span style={{fontSize:'14px'}}>
                        {category.description}
                    </span>
                    <br/>
                    <span style={{fontSize:'12px'}}>
                        DRE: <strong>{category.dre_category ? category.dre_category.description : 'Não mostrar no DRE Gerencial'}</strong>
                    </span>
                </Col>
                <Col span={8} style={{textAlign:'right'}}>
                    <Tooltip title="Editar" placement="top">
                        <Button
                            type="secondary-default"
                            icon={<EditOutlined />}
                            onClick={event => props.show(props.type, category.id)}
                            // disabled={loadingCategory}
                            style={{
                                paddingTop: '4px',
                                paddingBottom: '4px',
                                paddingLeft: '10px',
                                paddingRight: '10px',
                                width:'auto',
                            }}
                        ></Button>
                    </Tooltip>
                    <Tooltip title="Deletar" placement="top">
                        <Button
                            type="secondary-default"
                            icon={<DeleteOutlined />}
                            onClick={event => props.delete(category.id)}
                            // disabled={loadingCategory}
                            style={{ 
                                marginLeft: '10px',
                                paddingTop: '4px',
                                paddingBottom: '4px',
                                paddingLeft: '10px',
                                paddingRight: '10px',
                                width:'auto',
                                color: '#ff4d4f !important',
                            }}
                        ></Button>
                    </Tooltip>
                </Col>
            </Row>
        )) }
    </>

    return (
        list
    )
}

export default CategoriesList;