import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import { 
    Layout,
    Form,
    Breadcrumb,
    Input,
    Button,
    Row,
    Col,
    Select,
    message,
    Table,
    Modal,
    Tooltip,
    Card,
    Empty,
} from 'antd';

import { EyeOutlined, ArrowLeftOutlined, PlusOutlined, EditOutlined, DeleteOutlined} from '@ant-design/icons';

import Topbar from '../components/Topbar';
import Footbar from '../components/Footbar';
// import Loading from '../components/Loading';

import 'antd/dist/antd.css';
import './styles/Main.css';
import './styles/Settings.css';

import api from '../services/api.js';

import price_list_svg from '../assets/price_list.svg';

const { Search } = Input;
const { Content } = Layout;
const { confirm } = Modal;
const { Option } = Select;

export default function PriceLists() {
    // const [loading, setLoading] = useState(false);
    const [loadingTable, setLoadingTable] = useState(true);
    const [loadingPriceList, setLoadingPriceList] = useState(false);
    const [saving, setSaving] = useState(false);
    const [visibleModal, setVisibleModal] = useState(false);

    const [data, setData] = useState([]);
    const [tableLength, setTableLength] = useState(10);
    const [tablePagination, setTablePagination] = useState({current: 1, pageSize: tableLength});
    const [tableFilters, setTableFilters] = useState({});
    const [tableSorter, setTableSorter] = useState({ field: 'name', order: 'ascend' });
    const [search, setSearch] = useState('');
    
    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    useEffect(() => {
        loadData();
    }, [tableLength]);

    async function reloadData(){
        loadData({current: 1, pageSize: tableLength});
    }

    async function loadData(
        pagination = tablePagination,
        filters = tableFilters,
        sorter = tableSorter
    ) {
        setLoadingTable(true);

        setTablePagination(pagination);
        setTableFilters(filters);
        if(!sorter.field){
            sorter = tableSorter
        }
        setTableSorter(sorter);

        let order = sorter.order;
        if(order === undefined) {
            order = 'asc';
        } else if(sorter.order === 'ascend') {
            order = 'asc';
        } else if(sorter.order === 'descend') {
            order = 'desc';
        }

        let response = await api.get(`/price_lists?page=${pagination.current}&per_page=${tableLength}&order=${order}&order_by=${sorter.field}&search=${search}`);

        let price_lists_list = response.data.data;

        setData(price_lists_list);
        setTablePagination({
            current: pagination.current,
            pageSize: tableLength,
            total: price_lists_list.total,
        });

        setLoadingTable(false);
    }

    function newPriceList() {
        setId('');
        setName('');
        setDescription('');

        setVisibleModal(true);
    }

    async function showPriceList(price_list_id) {
        setLoadingPriceList(true);

        let response = await api.get(`/price_lists/${price_list_id}`);

        let price_list = response.data.data;

        setId(price_list.id);
        setName(price_list.name);
        setDescription(price_list.description);

        setVisibleModal(true);
        setLoadingPriceList(false);
    }

    async function savePriceList() {
        setSaving(true);

        const data = {
            name,
            description,
        };

        try {
            let response = [];

            if(id)
                response = await api.put(`/price_lists/${id}`, data);
            else
                response = await api.post('/price_lists', data);

            if(response.data.success) {
                message.success('Lista de preços salva com sucesso');
            } else {
                message.error('Ocorreu um erro, aguarde alguns instantes e tente novamente');
            }

            await loadData();
            setSaving(false);
            setVisibleModal(false);
        } catch (error) {
            message.error('Ocorreu um erro, aguarde alguns instantes e tente novamente');
            setSaving(false);
        }
    }

    function deletePriceList(price_list_id) {
        confirm({
            title: 'Você tem certeza?',
            content: 'Esta ação não poderá ser desfeita!',
            okText: 'Deletar',
            okType: 'danger',
            cancelText: 'Cancelar',
            async onOk() {
                let response = await api.delete(`/price_lists/${price_list_id}`);

                if(response.data.success) {
                    message.success('Lista de preços deletada com sucesso');
                } else {
                    message.error('Ocorreu um erro, aguarde alguns instantes e tente novamente');
                }

                await loadData();
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }
    const columns = [
        {
            title: 'NOME',
            dataIndex: 'name',
            sorter: true,
            width: '30%',
        }, {
            title: 'DESCRIÇÃO',
            dataIndex: 'description',
            width: '45%',
        }, {
            title: 'AÇÕES',
            width: '25%',
            render: (price_list => (
                <>
                    <Tooltip title="Produtos e Serviços" placement="left">
                        <Link to={`/listas-de-precos/${price_list.id}`}>
                            <Button 
                                type="secondary-default" 
                                icon={<EyeOutlined />}
                                disabled={loadingPriceList}
                                style={{
                                    paddingTop: '4px',
                                    paddingBottom: '4px',
                                    paddingLeft: '10px',
                                    paddingRight: '10px',
                                    width:'auto',
                                }}
                            ></Button>
                        </Link>
                    </Tooltip>
                    <Tooltip title="Editar" placement="top">
                        <Button 
                            type="secondary-default" 
                            icon={<EditOutlined />}
                            onClick={event => showPriceList(price_list.id)}
                            disabled={loadingPriceList}
                            style={{
                                marginLeft: '5px',
                                paddingTop: '4px',
                                paddingBottom: '4px',
                                paddingLeft: '10px',
                                paddingRight: '10px',
                                width:'auto',
                            }}
                        ></Button>
                    </Tooltip>
                    <Tooltip title="Deletar" placement="right">
                        <Button 
                            type="secondary-default"
                            icon={<DeleteOutlined />}
                            onClick={event => deletePriceList(price_list.id)}
                            disabled={loadingPriceList}
                            style={{ 
                                marginLeft: '5px',
                                paddingTop: '4px',
                                paddingBottom: '4px',
                                paddingLeft: '10px',
                                paddingRight: '10px',
                                width:'auto',
                                color: '#ff4d4f !important',
                            }}
                        ></Button>
                    </Tooltip>
                </>
            )),
        },
    ];

    // let loading_screen = '';
    // if(loading){
    //     loading_screen = <Loading />
    // }

    return (
        <Layout className="layout">
            {/* { loading_screen } */}
            <Topbar />

            <Content className="fbm-main">
                <div id="main">
                    <Row>
                        <Col span={24} className="fbm-page-header">
                            <Breadcrumb className="breadcrumb" style={{ margin: '16px 0' }}>
                                <Breadcrumb.Item><Link to="/monitoramento">Monitoramento</Link></Breadcrumb.Item>
                                <Breadcrumb.Item><Link to="/configuracoes">Configurações</Link></Breadcrumb.Item>
                                <Breadcrumb.Item>Listas de Preços</Breadcrumb.Item>
                            </Breadcrumb>

                            <div className="actions">
                                <Link to="/configuracoes">
                                    <Button type="primary" icon={<ArrowLeftOutlined />}>
                                        Voltar
                                    </Button>
                                </Link>
                                <Button 
                                    type="success" 
                                    icon={<PlusOutlined />}
                                    onClick={newPriceList}
                                >
                                    Novo
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ marginLeft: '-10px', marginRight: '-10px' }}>
                        <Col span={16} style={{ padding: '0 10px 10px 10px' }}>
                            <Card className="fbm-card">
                                <Row style={{ paddingBottom: '15px' }}>
                                    <Col span={24}>
                                        <div style={{ overflow: 'auto' }}>
                                            <Form.Item
                                                className="form-item"
                                                style={{ 
                                                    paddingTop: 0, 
                                                    paddingRight: 0,
                                                    paddingLeft: 0,
                                                    paddingBottom: 0,
                                                    width: '100px',
                                                    float: 'left',
                                                }}
                                            >
                                                <Select 
                                                    value={tableLength} 
                                                    onChange={ async (value) => setTableLength(value) }
                                                >
                                                    <Option key="10" value="10">10</Option>
                                                    <Option key="25" value="25">25</Option>
                                                    <Option key="50" value="50">50</Option>
                                                    <Option key="100" value="100">100</Option>
                                                </Select>
                                            </Form.Item>
                                            <Form.Item
                                                className="form-item"
                                                style={{ 
                                                    paddingTop: 0,
                                                    paddingRight: 0,
                                                    paddingLeft: 0,
                                                    paddingBottom: 0,
                                                    width: '200px',
                                                    float: 'right',
                                                }}
                                            >
                                                <Search
                                                    id="search"
                                                    placeholder="Pesquisar"
                                                    value={search}
                                                    onChange={ event => setSearch(event.target.value) }
                                                    onSearch={ value => reloadData() }
                                                    loading={ loadingTable }
                                                />
                                            </Form.Item>
                                        </div>
                                    </Col>
                                </Row>    
                                <Row style={{ paddingBottom: '0' }}>
                                    <Col span={24}>
                                        <Table
                                            bordered={true}
                                            columns={columns}
                                            rowKey={record => record.id}
                                            dataSource={data.data}
                                            pagination={tablePagination}
                                            loading={loadingTable}
                                            onChange={loadData}
                                            pageSize={tableLength}
                                            locale={{ emptyText: <Empty description={<b>Nenhum registro encontrado...</b>} /> }}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col span={8} style={{ padding: '0 10px 10px 10px' }}>
                            <Card className="fbm-card">
                                <div style={{ textAlign:'center' }}>
                                    <img draggable="false" src={price_list_svg} width="300" height="200" alt="Usuários" />
                                    <h5 className="page-title" style={{ marginTop:'25px' }}>
                                        Listas de Preços
                                    </h5>
                                </div>
                                <p>
                                    A lista de preços é utilizada para vender os produtos ou serviços com valores diferenciados, sem alterar o preço padrão dos mesmos.
                                </p>
                                <p>
                                    Por exemplo: o produto Caixa está cadastrado com o valor de R$ 5,00 na lista padrão, porém, a empresa precisa trabalhar com uma tabela de preços diferente para Atacado. Para isto, basta criar a Lista de Preços com o nome 'Atacado', e informar o valor desejado para o produto, neste exemplo R$ 4,30.
                                </p>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Content>

            <Footbar />


            <Modal
                title="Lista de Preços"
                visible={visibleModal}
                centered
                footer={[
                    <Button 
                        key="back"
                        onClick={() => setVisibleModal(false)}
                    >
                        Cancelar
                    </Button>,
                    <Button 
                        key="submit"
                        type="primary"
                        loading={saving}
                        onClick={ () => savePriceList() }
                    >
                        Salvar
                    </Button>,
                ]}
                onCancel={() => setVisibleModal(false)}
            >
                <Form layout='vertical'>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                className="form-item"
                                label="Nome:"
                                required={true}
                                hasFeedback
                            >
                                <Input
                                    id="name"
                                    value={ name }
                                    onChange={ event => setName(event.target.value) }
                                    required
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                className="form-item"
                                label="Descrição:"
                            >
                                <Input
                                    id="description"
                                    value={ description }
                                    onChange={ event => setDescription(event.target.value) }
                                    required
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </Layout>
    )
}