import React, { useEffect, useState } from 'react';
import { 
    Modal,
    Button,
    Row,
    Col,
    Form,
    Input,
    message,
    Layout,
    Radio,
    DatePicker,
    Select,
    Spin,
    Checkbox,
} from 'antd';
import locale from 'antd/es/date-picker/locale/pt_BR';
import InputMask from 'react-input-mask';
import moment from 'moment';
import 'moment/locale/pt-br';
import { parseISO, format } from 'date-fns';

import api from '../../../services/api';

const validateValue = require('../../../utils/validateValue');

const { Search, TextArea } = Input;
const { Content } = Layout;
const { Option } = Select;

function Contact(props) {
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loading_zip_code, setLoadingZipCode] = useState(false);
    const [showFiscalInfos, setShowFiscalInfos] = useState(false);
    const [showAddress, setShowAddress] = useState(false);
    const [showMoreInfo, setShowMoreInfo] = useState(false);

    
    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [social_name, setSocialName] = useState('');
    const [fantasy_name, setFantasyName] = useState('');
    const [gender, setGender] = useState('');
    const [email, setEmail] = useState('');
    const [date_birth, setDateBirth] = useState('');
    const [opening_date, setOpeningDate] = useState('');
    const [cpf, setCpf] = useState('');
    const [cnpj, setCnpj] = useState('');
    const [type_person, setTypePerson] = useState('PF');
    const [zip_code, setZipCode] = useState('');
    const [address, setAddress] = useState('');
    const [district, setDistrict] = useState('');
    const [number, setNumber] = useState('');
    const [complement, setComplement] = useState('');
    const [state, setState] = useState('');
    const [state_registration, setStateRegistration] = useState('');
    const [municipal_registration, setMunicipalRegistration] = useState('');
    const [foreign_identifier, setForeignIdentifier] = useState('');
    const [state_indicator, setStateIndicator] = useState('');
    const [city, setCity] = useState('');
    const [phone, setPhone] = useState('');
    const [phone_2, setPhone2] = useState('');
    const [phone_3, setPhone3] = useState('');
    const [status, setStatus] = useState('');
    const [note, setNote] = useState('');
    const [client, setClient] = useState(false);
    const [provider, setProvider] = useState(false);

    const [cpf_invalid, setCpfInvalid] = useState(false);
    const [cnpj_invalid, setCnpjInvalid] = useState(false);
    const [email_invalid, setEmailInvalid] = useState(false);

    useEffect(() => {
        emptyContact();
        if(props.contact && props.visible){
            setLoading(true);
            showContact(props.contact);
        }
    }, [props.visible]);
    
    async function searchZipCode() {
        setLoadingZipCode(true);

        let response = await api.get(`https://brasilapi.com.br/api/cep/v1/${zip_code.replace('-', '')}`);

        setLoadingZipCode(false);

        response = response.data;

        setAddress(response.logradouro);
        setDistrict(response.bairro);
        setCity(response.cidade);
        setState(response.estado);
    }

    function changeTypePerson(type){
        setTypePerson(type);

        setDateBirth('');
        setGender('');
        setCpf('');
        setCnpj('');
        setSocialName('');
        setFantasyName('');
        setOpeningDate('');
    }

    function checkInput(field) {
        if (field.id === 'cpf') {
            if( cpf === '' || !validateValue('cpf', cpf) ){
                setCpfInvalid(true);
                return false;
            }
            setCpfInvalid(false);
        }

        if (field.id === 'cnpj') {
            if( cnpj === '' || !validateValue('cnpj', cnpj) ){
                setCnpjInvalid(true);
                return false;
            }
            setCnpjInvalid(false);
        }

        if(field.id === 'email') {
            if( email === '' || !validateValue('email', email)){
                setEmailInvalid(true);
                return false;
            }
            setEmailInvalid(false);
        }
    }

    function emptyContact() {
        setShowFiscalInfos(false);
        setShowAddress(false);
        setShowMoreInfo(false);
        setId('');
        setName('');
        setSocialName('');
        setFantasyName('');
        setGender('');
        setEmail('');
        setPhone3('');
        setZipCode('');
        setDistrict('');
        setComplement('');
        setNumber('');
        setState('');
        setStateRegistration('');
        setMunicipalRegistration('');
        setForeignIdentifier('');
        setStateIndicator('');
        setCity('');
        setDateBirth('');
        setOpeningDate('');
        setNote('');
        setStatus('A');
        setCpf('');
        setCnpj('');
        setTypePerson('PF');
        setAddress('');
        setPhone('');
        setPhone2('');
        setClient(false);
        setProvider(false);
    }

    async function saveContact() {
        setSaving(true);

        const data = {
            type_person,
            name,
            date_birth,
            gender,
            cpf,
            cnpj,
            social_name,
            fantasy_name,
            opening_date,
            state_registration,
            zip_code,
            address,
            district,
            number,
            complement,
            state,
            city,
            phone,
            phone_2,
            phone_3,
            state_indicator,
            municipal_registration,
            email,
            note,
            status,
            client,
            provider,
            other: !client && !provider,
        };

        try {
            let response = [];

            if(id)
                response = await api.put(`/contacts/${id}`, data);
            else
                response = await api.post('/contacts', data);

            if(response.data.success) {
                message.success('Contato salvo com sucesso');
            } else {
                message.error('Ocorreu um erro, aguarde alguns instantes e tente novamente');
            }

            setSaving(false);
            props.afterSave();
            props.setVisibility(false);
        } catch (error) {
            message.error('Ocorreu um erro, aguarde alguns instantes e tente novamente');
            setSaving(false);
        }
    }

    async function showContact(contact) {
        setId(contact.id);
        setName(contact.name);
        setSocialName(contact.social_name);
        setFantasyName(contact.fantasy_name);
        setGender(contact.gender);
        setEmail(contact.email);
        setPhone3(contact.phone_3);
        setZipCode(contact.zip_code);
        setDistrict(contact.district);
        setComplement(contact.complement);
        setNumber(contact.number);
        setState(contact.state);
        setStateRegistration(contact.state_registration);
        setMunicipalRegistration(contact.municipal_registration);
        setForeignIdentifier(contact.foreign_identifier);
        setStateIndicator(contact.state_indicator);
        setCity(contact.city);
        setDateBirth(contact.date_birth);
        setOpeningDate(contact.opening_date);
        setNote(contact.note);
        setStatus(contact.status);
        setCpf(contact.cpf);
        setCnpj(contact.cnpj);
        setTypePerson(contact.type_person);
        setAddress(contact.address);
        setPhone(contact.phone);
        setPhone2(contact.phone_2);
        setClient(contact.client);
        setProvider(contact.provider);

        if(contact.cpf || contact.cnpj){
            setShowFiscalInfos(true);
        }
        if(contact.zip_code){
            setShowAddress(true);
        }
        if(contact.note){
            setShowMoreInfo(true);
        }

        setLoading(false);
    }

    return (
        <Modal
            title={`Informações do Contato`}
            visible={ props.visible }
            footer={[
                <Button
                    type="success"
                    onClick={ () => saveContact() }
                    loading={ saving }
                    disabled={ !name }
                >
                    Salvar
                </Button>,
            ]}
            onCancel={() => props.setVisibility(false)}
        >
            <Spin spinning={loading}>
                <Form layout='vertical'>
                    <Row>
                        <Col span={24}>
                            <Checkbox checked={client} onChange={(e) => setClient(e.target.checked)}>Cliente</Checkbox>
                            <Checkbox checked={provider} onChange={(e) => setProvider(e.target.checked)}>Fornecedor</Checkbox>
                        </Col>
                    </Row>
                    <Row style={{paddingTop:'10px'}}>
                        <Col span={24}>
                            <Form.Item
                                className="form-item"
                                label="Nome:"
                                required={true}
                                style={{ paddingLeft: 0, paddingRight: 0 }}
                            >
                                <Input
                                    id="name"
                                    value={ name }
                                    onChange={ event => setName(event.target.value) }
                                    onKeyUp={ event => checkInput(event.target) }
                                    onBlur={ event => checkInput(event.target) }
                                    required
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                className="form-item"
                                label="Email:"
                                style={{ paddingLeft: 0, paddingRight: 0 }}
                            >
                                <Input
                                    id="email"
                                    value={ email }
                                    onChange={ event => setEmail(event.target.value) }
                                    onKeyUp={ event => checkInput(event.target) }
                                    onBlur={ event => checkInput(event.target) }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                className="form-item"
                                label="Telefone:"
                                style={{ paddingLeft: 0 }}
                            >
                                <InputMask 
                                    mask="(99) 99999-9999"
                                    alwaysShowMask={false}
                                    placeholder="(__) _____-____"
                                    id="phone"
                                    value={ phone }
                                    onChange={ event => setPhone(event.target.value) }
                                >
                                    {(inputProps) => 
                                        <Input 
                                            {...inputProps} 
                                            type="text"
                                        />
                                    }
                                </InputMask>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                className="form-item"
                                label="Telefone 2:"
                                style={{ paddingRight: 0 }}
                            >
                                <InputMask 
                                    mask="(99) 99999-9999"
                                    alwaysShowMask={false}
                                    placeholder="(__) _____-____"
                                    id="phone_2"
                                    value={ phone_2 }
                                    onChange={ event => setPhone2(event.target.value) }
                                >
                                    {(inputProps) => 
                                        <Input 
                                            {...inputProps} 
                                            type="text"
                                        />
                                    }
                                </InputMask>
                            </Form.Item>
                        </Col>
                    </Row>


                    { showFiscalInfos && <>
                        <Row style={{paddingTop: '15px'}}>
                            <Col span={24}>
                                <Form.Item className="form-item" style={{paddingRight: 0, paddingLeft: 0}} >
                                    <Radio.Group 
                                        defaultValue={type_person}
                                        buttonStyle="solid"
                                        onChange={event => changeTypePerson(event.target.value)}
                                    >
                                        <Radio.Button value="PJ">Pessoa Jurídica</Radio.Button>
                                        <Radio.Button value="PF">Pessoa Física</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                        { type_person === "PF" && <>
                            <Row>
                                <Col span={12}>
                                    <Form.Item
                                        className="form-item"
                                        label="CPF:"
                                        hasFeedback
                                        validateStatus={`${cpf_invalid ? 'error' : ''}`}
                                        style={{paddingLeft: 0}}
                                    >
                                        <InputMask 
                                            mask="999.999.999-99"
                                            alwaysShowMask={false}
                                            placeholder="___.___.___-__"
                                            id="cpf"
                                            value={ cpf }
                                            onChange={ event => setCpf(event.target.value) }
                                            onKeyUp={ event => checkInput(event.target) }
                                            onBlur={ event => checkInput(event.target) }
                                        >
                                            {(inputProps) => 
                                                <Input 
                                                    {...inputProps} 
                                                    type="text"
                                                    required
                                                />
                                            }
                                        </InputMask>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        className="form-item"
                                        label="Data de nascimento:"
                                        style={{paddingRight: 0}}
                                    >
                                        <DatePicker
                                            locale={locale}
                                            showTime={false}
                                            value={date_birth !== '' ? moment(date_birth) : ''}
                                            format="DD/MM/YYYY"
                                            onChange={ (date, dateString) => {
                                                setDateBirth(date !== null ? format(date._d, "yyyy-MM-dd") : '');
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        className="form-item"
                                        label="Sexo:"
                                        style={{paddingLeft: 0}}
                                    >
                                        <Select 
                                            value={ gender } 
                                            style={{ width: 120 }}
                                            onChange={ (value) => setGender(value) }
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            <Option value="M" >
                                                Masculino
                                            </Option>
                                            <Option value="F" >
                                                Feminino
                                            </Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>}

                        { type_person === "PJ" && <>
                            <Row>
                                <Col span={12}>
                                    <Form.Item
                                        className="form-item"
                                        label="CNPJ:"
                                        hasFeedback
                                        validateStatus={`${cnpj_invalid ? 'error' : ''}`}
                                        style={{paddingLeft: 0}}
                                    >
                                        <InputMask 
                                            mask="99.999.999/9999-99"
                                            alwaysShowMask={false}
                                            placeholder="__.___.___/____-__"
                                            id="cnpj"
                                            value={ cnpj }
                                            onChange={ event => setCnpj(event.target.value) }
                                            onKeyUp={ event => checkInput(event.target) }
                                            onBlur={ event => checkInput(event.target) }
                                        >
                                            {(inputProps) => 
                                                <Input 
                                                    {...inputProps} 
                                                    type="text"
                                                />
                                            }
                                        </InputMask>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        className="form-item"
                                        label="Indicador de inscrição estadual:"
                                        style={{paddingRight: 0}}
                                    >
                                        <Select 
                                            value={ state_indicator } 
                                            style={{ width: 120 }}
                                            onChange={ (value) => setStateIndicator(value) }
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            <Option value="C" >
                                                Contribuinte
                                            </Option>
                                            <Option value="CI" >
                                                Contribuinte Isento
                                            </Option>
                                            <Option value="NC" >
                                                Não contribuinte
                                            </Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        className="form-item"
                                        label="Razão social:"
                                        style={{paddingRight: 0, paddingLeft: 0}}
                                    >
                                        <Input
                                            id="social_name" value={ social_name }
                                            onChange={ event => setSocialName(event.target.value) }
                                            required
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        className="form-item"
                                        label="Data de abertura:"
                                        style={{paddingRight: 0, paddingLeft: 0}}
                                    >
                                        <DatePicker
                                            locale={locale}
                                            showTime={false}
                                            value={opening_date !== '' ? moment(opening_date) : ''}
                                            format="DD/MM/YYYY"
                                            onChange={ (date, dateString) => {
                                                setOpeningDate(date !== null ? format(date._d, "yyyy-MM-dd") : '');
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        className="form-item"
                                        label="Inscrição estadual:"
                                        style={{paddingRight: 0}}
                                    >
                                        <InputMask 
                                            mask="99.999.999/9999-99"
                                            alwaysShowMask={false}
                                            placeholder="__.___.___/____-__"
                                            id="state_registration"
                                            value={ state_registration }
                                            onChange={ event => setStateRegistration(event.target.value) }
                                            onKeyUp={ event => checkInput(event.target) }
                                            onBlur={ event => checkInput(event.target) }
                                        >
                                            {(inputProps) => 
                                                <Input 
                                                    {...inputProps} 
                                                    type="text"
                                                />
                                            }
                                        </InputMask>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        className="form-item"
                                        label="Inscrição municipal:"
                                        style={{paddingLeft: 0}}
                                    >
                                        <Input
                                            id="municipal_registration" value={ municipal_registration }
                                            onChange={ event => setMunicipalRegistration(event.target.value) }
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        className="form-item"
                                        label="Identificador Estrangeiro:"
                                        style={{paddingRight: 0}}
                                    >
                                        <Input
                                            id="foreign_identifier" value={ foreign_identifier }
                                            onChange={ event => setForeignIdentifier(event.target.value) }
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>}
                    </>}

                    { showAddress && <>
                        <Row style={{ paddingTop: '10px' }}>
                            <Col span={8}>
                                <Form.Item
                                    className="form-item"
                                    label="CEP:"
                                    style={{ paddingLeft: 0 }}
                                >
                                    <InputMask 
                                        mask="99999-999"
                                        alwaysShowMask={false}
                                        placeholder="_____-___"
                                        id="zip_code"
                                        value={ zip_code }
                                        onChange={ event => setZipCode(event.target.value) }
                                    >
                                        {(inputProps) => 
                                            <Search
                                                {...inputProps}
                                                onSearch={ value => searchZipCode() }
                                                loading={ loading_zip_code }
                                            />
                                        }
                                    </InputMask>
                                </Form.Item>
                            </Col>
                            <Col span={16}>
                                <Form.Item
                                    className="form-item"
                                    label="Bairro:"
                                    style={{ paddingRight: 0 }}
                                >
                                    <Input
                                        id="district" value={ district }
                                        onChange={ event => setDistrict(event.target.value) }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={18}>
                                <Form.Item
                                    className="form-item"
                                    label="Endereço:"
                                    style={{ paddingLeft: 0 }}
                                >
                                    <Input
                                        id="address" value={ address }
                                        onChange={ event => setAddress(event.target.value) }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    className="form-item"
                                    label="Número:"
                                    style={{ paddingRight: 0 }}
                                >
                                    <Input
                                        id="number" value={ number }
                                        onChange={ event => setNumber(event.target.value) }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={18}>
                                <Form.Item
                                    className="form-item"
                                    label="Cidade:"
                                    style={{ paddingLeft: 0}}
                                >
                                    <Input
                                        id="city" value={ city }
                                        onChange={ event => setCity(event.target.value) }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    className="form-item"
                                    label="UF:"
                                    style={{ paddingRight: 0 }}
                                >
                                    <Input
                                        id="state"
                                        value={ state }
                                        style={{textTransform: 'uppercase'}}
                                        maxLength={2}
                                        onChange={ event => setState(event.target.value) }
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </> }

                    { showMoreInfo && <>
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    className="form-item"
                                    label="Informações adicionais:"
                                    style={{ paddingLeft: 0, paddingRight: 0 }}
                                >
                                    <TextArea 
                                        rows={3}
                                        id="note"
                                        value={ note }
                                        onChange={ event => setNote(event.target.value) }
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </>}
                    <Row>
                        <Col span={24} style={{paddingTop:'15px'}}>
                            <p>
                                <a
                                    onClick={() => setShowFiscalInfos(!showFiscalInfos)}
                                    style={ showFiscalInfos ? {color: "#9797ad"} : {} }
                                >
                                    + Informações fiscais
                                </a>
                                <a 
                                    style={ showAddress ? {color: "#9797ad", marginLeft:'15px'} : {marginLeft:'15px'} }
                                    onClick={() => setShowAddress(!showAddress)}
                                >
                                    + Endereço
                                </a>
                                <a 
                                    style={ showMoreInfo ? {color: "#9797ad", marginLeft:'15px'} : {marginLeft:'15px'} }
                                    onClick={() => setShowMoreInfo(!showMoreInfo)}
                                >
                                    + Info
                                </a>
                            </p>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </Modal>
    );
}

export default Contact;