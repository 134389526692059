import React, { useState, useEffect } from 'react';
import {Link, useParams, useNavigate} from 'react-router-dom';
import { 
    Layout,
    Breadcrumb,
    Button,
    Row,
    Col,
    message,
    Card,
    Modal,
    Descriptions,
    Table,
    Empty,
    Tooltip,
} from 'antd';
import { 
    CloseOutlined,
    MinusOutlined,
    CheckOutlined,
    FileTextOutlined
} from '@ant-design/icons';

import {NumericFormat} from 'react-number-format';
import { parseISO, format } from 'date-fns';

import Topbar from '../components/Topbar';
import Footbar from '../components/Footbar';
import Loading from '../components/Loading';
import BillPaymentsModal from '../components/Modal/BillPayments';

import 'antd/dist/antd.css';
import './styles/Main.css';

import api from '../services/api.js';

const { Content } = Layout;
const { confirm } = Modal;

export default function SupplyDetails() {
    const [loading, setLoading] = useState(true);
    const [deleting, setDeleting] = useState(false);
    
    const [plotsToPay, setPlotsToPay] = useState([]);
    const [summary, setSummary] = useState({});
    
    const [supply_id, setSupplyId] = useState('');
    const [provider_name, setProviderName] = useState('');
    const [provider_email, setProviderEmail] = useState('');
    const [provider_cpf_cnpj, setProviderCpfCnpj] = useState('');
    const [provider_phone, setProviderPhone] = useState('');
    const [date_supply, setDateSupply] = useState('');
    const [cost_center, setCostCenter] = useState('');
    const [user, setUser] = useState('');
    const [discount_total, setDiscountTotal] = useState(0);
    const [net_value, setNetValue] = useState(0);
    const [gross_total, setGrossTotal] = useState(0);
    const [amount, setAmount] = useState(0);
    const [supply_number, setSupplyNumber] = useState('');
    const [total, setTotal] = useState(0);
    const [supports, setSupports] = useState([]);
    // const [account_description, setAccountDescription] = useState('');
    // const [account_note, setAccountNote] = useState('');
    const [discount_supply, setDiscountSupply] = useState('');
    const [show_bill_payments_modal, setShowBillPaymentsModal] = useState(false);
    const [bill, setBill] = useState({});

    let params   = useParams();
    let navigate = useNavigate();

    useEffect(() => {
        loadSupply();
    }, []);

    async function loadSupply() {
        let response = await api.get(`/supplies/${params.id}?with_supports=true`);
        let supply = response.data.data;

        setPlotsToPay(supply.bill.plots);
        setSummary(response.data.summary);

        setSupplyId(supply.id);
        setProviderName(supply.contact.name);
        setProviderEmail(supply.contact.email);
        setProviderCpfCnpj(supply.contact.cnpj ? supply.contact.cnpj : supply.contact.cpf);
        setProviderPhone(supply.contact.phone);
        setDateSupply(supply.date_supply);
        setCostCenter(supply.cost_center.name);
        setUser(supply.user.name);
        setDiscountTotal(supply.discount_total);
        setGrossTotal(supply.gross_total);
        setNetValue(supply.net_value);
        setAmount(supply.amount);
        setTotal(supply.total);
        setSupplyNumber(supply.supply_number);
        setSupports(supply.supports);
        // setAccountDescription(supply.bill.description);
        // setAccountNote(supply.bill.note);
        setDiscountSupply(supply.discount_supply);

        setLoading(false);
    }

    async function showBillPayments(bill = null) {
        setBill(bill);
        setShowBillPaymentsModal(true);
    }

    function deleteSupply() {
        setDeleting(true);
        confirm({
            title: 'Você tem certeza?',
            content: 'Esta ação não poderá ser desfeita!',
            okText: 'Deletar',
            okType: 'danger',
            cancelText: 'Cancelar',
            async onOk() {
                let response = await api.delete(`/supplies/${supply_id}`);

                if(response.data.success) {
                    message.success('Compra deletada com sucesso');
                } else {
                    message.error('Ocorreu um erro, aguarde alguns instantes e tente novamente');
                }

                navigate('/compras');
            },
            onCancel() {
                console.log('Cancel');
            },
        });
        setDeleting(false);
    }

    const columns = [
        {
            title: 'CÓDIGO',
            dataIndex: ['product','code'],
            key: 'code',
            align: 'left',
        }, {
            title: 'CATEGORIA',
            dataIndex: ['product','category','name'],
            key: 'category',
        }, {
            title: 'PRODUTO/SERVIÇO',
            dataIndex: ['product','description'],
            key: 'description',
            align: 'left',
        }, {
            title: 'QUANTIDADE',
            dataIndex: 'amount',
            key: 'supply_amount',
        }, {
            title: 'CUSTO UN.',
            render: ( support => (
                <NumericFormat 
                    value={support.unit_cost}
                    displayType={'text'}
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2} 
                    fixedDecimalScale={true}
                />
            )),
            align: 'right',
            key: 'unit_cost',
        }, {
            title: 'CUSTO TOTAL',
            render: ( support => {
                let total_cost = support.amount*support.unit_cost;
                
                return (
                    <NumericFormat 
                        value={total_cost}
                        displayType={'text'}
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={2} 
                        fixedDecimalScale={true}
                    />
                )
            }),
            align: 'right',
            key: 'total_cost',
        }, {
            title: 'DESCONTOS',
            render: ( support => (
                <NumericFormat 
                    value={support.discount_value ? support.discount_value : 0}
                    displayType={'text'}
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2} 
                    fixedDecimalScale={true}
                />
            )),
            align: 'right',
            key: 'discount_value',
        }, {
            title: 'TOTAL LIQUIDO',
            render: ( support => (
                <NumericFormat 
                    value={support.total ? support.total : 0}
                    displayType={'text'}
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2} 
                    fixedDecimalScale={true}
                />
            )),
            align: 'right',
            key: 'total',
        },
    ];


    function fixName(name){
        name = name.split(' ');
        name = name[0];
        return name;
    }
    function formatDate(date){
        if(date !== null && date !== '' && date !== '0000-00-00'){
            date = parseISO(date);
            return format(date, 'dd/MM/yyyy');
        }
        return '-';
    }
    function formatTime(date){
        date = parseISO(date);
        return format(date, 'HH:mm');
    }

    function fixPaymentsCount(plot){
        const payments = plot.payments;
        let icon = '';
        let cont = 0;

        if(plot.status === 'A'){
            icon = <CloseOutlined style={{color:'#ff4d4f', marginRight:0}} />;
        } else if(plot.status === 'PP'){
            icon = <MinusOutlined style={{color:'#e0a800', marginRight:0}} />;
        } else if(plot.status === 'P'){
            icon = <CheckOutlined style={{color:'#2dce89', marginRight:0}} />;
        }

        payments.forEach(payment => {
            if(payment.status === 'A'){
                cont++;
            }
        });

        return <Tooltip title="Pagamentos" placement="top">
            <Button 
                onClick={event => showBillPayments(plot)} 
                type="secondary-default" 
                icon={<FileTextOutlined />}
                style={{
                    paddingTop: '4px',
                    paddingBottom: '4px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    width:'auto',
                    marginLeft:'5px'
                }}
            >
                {cont} {icon}
            </Button>
        </Tooltip>;
    }

    const bill_plots_columns = [
        {
            title: 'IDENTIFICAÇÃO',
            align: 'center',
            render: ( plot => (
                plot.document_number ? plot.document_number : '-'
            )),
        }, {
            title: 'CENTRO DE CUSTOS',
            render: ( plot => (
                plot.cost_center && plot.cost_center.name ? plot.cost_center.name : '-'
            )),
        }, {
            title: 'EMISSÃO',
            render: ( plot => (
                <>
                    <span style={{ fontWeight:'bold' }}>
                        { formatDate(plot.issue_date) }
                    </span>
                    <br />
                    <span style={{ fontWeight:'bold', color:'#0086ea' }}>
                        <Tooltip title={ plot.bill.user.name }>
                            { `[ ${fixName(plot.bill.user.name)} ]` }
                        </Tooltip>
                    </span>
                    <br />
                    <span style={{ fontWeight:'bold', color:'#0086ea'  }}>
                        { formatDate(plot.bill.created_at) }
                    </span>
                    <br />
                    <span style={{ fontWeight:'bold', color:'#0086ea'  }}>
                        { formatTime(plot.bill.created_at) }
                    </span>
                </>
            )),
        },
        {
            title: 'VENCIMENTO',
            render: ( plot => (
                formatDate(plot.due_date)
            )),
        }, {
            title: 'FORNECEDOR',
            render: ( plot => {
                if(plot.contact){
                    return plot.contact.name
                } 
                return '-';
            }),
        }, {
            title: 'CATEGORIA',
            render: ( plot => (
                plot.category.description ? plot.category.description : '-'
            )),
        }, {
            title: 'DESCRIÇÃO',
            align: 'left',
            render: ( plot => (
                <>
                    <span style={{ fontWeight:'bold', color:'#0086ea' }}>
                        { `[${plot.plot_number}/${plot.bill.total_plots}] ` }
                    </span>
                    { plot.description ? plot.description : '-' }
                </>
            )),
        }, {
            title: 'VALOR (R$)',
            render: ( plot => (
                <NumericFormat 
                    value={plot.value ? plot.value : 0}
                    displayType={'text'}
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2} 
                    fixedDecimalScale={true}
                />
            )),
            align: 'right',
        },
        {
            title: '',
            width: '13%',
            render: (plot => (
                <>
                    {fixPaymentsCount(plot)}
                </>
            )),
        },
    ];

    let loading_screen = '';
    if(loading){
        loading_screen = <Loading />
    }

    return (
        <Layout className="layout">
            { loading_screen }
            <Topbar />

            <Content className="fbm-main">
                <div id="main">
                    <Row>
                        <Col span={24} className="fbm-page-header">
                            <Breadcrumb className="breadcrumb" style={{ margin: '16px 0' }}>
                                <Breadcrumb.Item><Link to="/monitoramento">Monitoramento</Link></Breadcrumb.Item>
                                <Breadcrumb.Item><Link to="/compras">Compras</Link></Breadcrumb.Item>
                                <Breadcrumb.Item>Detalhes</Breadcrumb.Item>
                            </Breadcrumb>

                            <div className="actions">
                                <Link to="/compras">
                                    <Button type="secondary-default">
                                        Voltar
                                    </Button>
                                </Link>
                                <Button 
                                    type="danger"
                                    onClick={deleteSupply}
                                    loading={deleting}
                                >
                                    Deletar Compra
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ marginLeft: '-10px', marginRight: '-10px' }}>
                        <Col span={24} style={{ padding: '0 10px 10px 10px' }}>
                            <Card className="fbm-card" loading={loading}>
                                <Row style={{ justifyContent:'space-between',paddingBottom: '5px', borderBottomWidth: '0.5px', borderBottomColor: '#c9d3dd', borderBottomStyle: 'dashed' }}>
                                    <h1 style={{float:'left', fontSize:'1.5rem',marginTop:'10px'}}>
                                        COMPRA
                                        <span 
                                            className="badge badge-secondary"
                                            style={{
                                                paddingTop:'5px',
                                                paddingRight:'7px',
                                                paddingLeft:'7px',
                                                fontWeight:400,
                                                backgroundColor:'#eef1f6',
                                                color:'#848b90',
                                                marginLeft:'15px',
                                                borderRadius:'3px',
                                                fontSize:'20px',
                                            }}
                                        >
                                            {supply_number}
                                        </span>
                                    </h1>
                                    <h1 style={{float:'right', fontWeight:'bold'}}>
                                        <span style={{color:'#777', fontSize:'18px'}}>R$ </span>
                                        <span style={{color:'#395b7b', fontSize:'2rem'}}>
                                            <NumericFormat 
                                                value={net_value ? net_value : 0}
                                                displayType={'text'}
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                decimalScale={2} 
                                                fixedDecimalScale={true}
                                            />
                                        </span>
                                    </h1>
                                </Row>
                                <Row style={{paddingTop:'20px'}}>
                                    <Descriptions title="" layout="vertical" column={4}>
                                        <Descriptions.Item label="Fornecedor">
                                            <span style={{fontWeight:'700', fontSize:'1rem'}}>
                                                { provider_name }
                                            </span>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Email">
                                            <span style={{fontWeight:'700', fontSize:'1rem'}}>
                                                { provider_email }
                                            </span>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="CPF/CNPJ">
                                            <span style={{fontWeight:'700', fontSize:'1rem'}}>
                                                { provider_cpf_cnpj }
                                            </span>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Telefone">
                                            <span style={{fontWeight:'700', fontSize:'1rem'}}>
                                                { provider_phone }
                                            </span>
                                        </Descriptions.Item>
                                    </Descriptions>

                                    <Descriptions title="" layout="vertical" column={4} style={{paddingTop:'5px'}}>
                                        <Descriptions.Item label="Data da Venda">
                                            <span style={{fontWeight:'700', fontSize:'1rem'}}>
                                                { formatDate(date_supply) }
                                            </span>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Centro de Custo">
                                            <span style={{fontWeight:'700', fontSize:'1rem'}}>
                                                { cost_center }
                                            </span>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Realizado por">
                                            <span style={{fontWeight:'700', fontSize:'1rem'}}>
                                                { user }
                                            </span>
                                        </Descriptions.Item>
                                    </Descriptions>

                                    <Descriptions title="" layout="vertical" column={4} style={{paddingTop:'5px'}}>
                                        <Descriptions.Item label="Quantidade total">
                                            <span style={{fontWeight:'700', fontSize:'1rem'}}>
                                                { amount }
                                            </span>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Valor bruto (R$)">
                                            <span style={{fontWeight:'700', fontSize:'1rem'}}>
                                                <NumericFormat 
                                                    value={gross_total ? gross_total : 0}
                                                    displayType={'text'}
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    decimalScale={2} 
                                                    fixedDecimalScale={true}
                                                />
                                            </span>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Descontos (R$)">
                                            <span style={{fontWeight:'700', fontSize:'1rem'}}>
                                                <NumericFormat 
                                                    value={discount_total ? discount_total : 0}
                                                    displayType={'text'}
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    decimalScale={2} 
                                                    fixedDecimalScale={true}
                                                />
                                            </span>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Valor liquido (R$)">
                                            <span style={{fontWeight:'700', fontSize:'1rem'}}>
                                                <NumericFormat 
                                                    value={net_value ? net_value : 0}
                                                    displayType={'text'}
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    decimalScale={2} 
                                                    fixedDecimalScale={true}
                                                />
                                            </span>
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Row>
                                <Row style={{paddingTop:'15px'}}>
                                    <Col span={24}>
                                        <Table 
                                            dataSource={supports} 
                                            columns={columns}
                                            pagination={{position: 'none'}}
                                            locale={{ emptyText: <Empty description={<b>Nenhum registro encontrado...</b>} /> }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={16}></Col>
                                    <Col span={8}>
                                        <Row style={{paddingTop:'15px'}}>
                                            <Col span={12} style={{textAlign:'right', fontSize:'1.2em', color:'#395b7b'}}>
                                                Valor total:
                                            </Col>
                                            <Col span={12} style={{textAlign:'right', fontSize:'1.2em', color:'#395b7b', fontWeight:'bold'}}>
                                                <NumericFormat 
                                                    value={total ? total : 0}
                                                    displayType={'text'}
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    decimalScale={2} 
                                                    fixedDecimalScale={true}
                                                />
                                            </Col>
                                        </Row>
                                        <Row style={{ paddingTop: '15px', paddingBottom: '15px', borderBottomWidth: '0.5px', borderBottomColor: '#c9d3dd', borderBottomStyle: 'dashed' }}>
                                            <Col span={12} style={{textAlign:'right', fontSize:'1.2em', color:'#395b7b'}}>
                                                Desconto:
                                            </Col>
                                            <Col span={12} style={{textAlign:'right', fontSize:'1.2em', color:'#395b7b', fontWeight:'bold'}}>
                                                <NumericFormat 
                                                    value={discount_supply ? discount_supply : 0}
                                                    displayType={'text'}
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    decimalScale={2} 
                                                    fixedDecimalScale={true}
                                                />
                                            </Col>
                                        </Row>
                                        <Row style={{paddingTop:'15px',paddingBottom:'15px'}}>
                                            <Col span={12} style={{textAlign:'right', fontSize:'1.2em', color:'#395b7b', fontWeight:'bold'}}>
                                                Valor liquido:
                                            </Col>
                                            <Col span={12} style={{textAlign:'right', fontSize:'1.2em', color:'#395b7b', fontWeight:'bold'}}>
                                                <NumericFormat 
                                                    value={net_value ? net_value : 0}
                                                    displayType={'text'}
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    decimalScale={2} 
                                                    fixedDecimalScale={true}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>

                    <Row style={{ marginLeft: '-10px', marginRight: '-10px' }}>
                        <Col span={24} style={{ padding: '0 10px 10px 10px' }}>
                            <Card className="fbm-card fbm-page" loading={loading}>
                                <Row>
                                    <Col span={12}>
                                        <h1 style={{float:'left', fontSize:'1.5rem',marginTop:'10px'}}>
                                            PAGAMENTOS
                                        </h1>
                                    </Col>
                                    <Col span={12} >
                                        <Descriptions title="" column={2} layout="vertical">
                                            <Descriptions.Item label="pago">
                                                <h1 style={{float:'right', fontWeight:'bold'}}>
                                                    <span style={{color:'#777', fontSize:'18px'}}>R$ </span>
                                                    <span style={{color:'#395b7b', fontSize:'2rem'}}>
                                                        <NumericFormat 
                                                            value={summary.paid ? summary.paid : 0}
                                                            displayType={'text'}
                                                            thousandSeparator="."
                                                            decimalSeparator=","
                                                            decimalScale={2} 
                                                            fixedDecimalScale={true}
                                                        />
                                                    </span>
                                                </h1>
                                            </Descriptions.Item>
                                            <Descriptions.Item label="a pagar">
                                                <h1 style={{float:'right', fontWeight:'bold'}}>
                                                    <span style={{color:'#777', fontSize:'18px'}}>R$ </span>
                                                    <span style={{color:'#395b7b', fontSize:'2rem'}}>
                                                        <NumericFormat 
                                                            value={summary.open ? summary.open : 0}
                                                            displayType={'text'}
                                                            thousandSeparator="."
                                                            decimalSeparator=","
                                                            decimalScale={2} 
                                                            fixedDecimalScale={true}
                                                        />
                                                    </span>
                                                </h1>
                                            </Descriptions.Item>
                                        </Descriptions>
                                    </Col>
                                </Row>
                                <Row >
                                    <Col span={24} >
                                        <Table 
                                            rowKey={record => record.id}
                                            columns={bill_plots_columns}
                                            bordered={true}
                                            locale={{ emptyText: <Empty description={<b>Nenhum registro encontrado...</b>} /> }}
                                            dataSource={plotsToPay}
                                            pagination={false}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Content>

            <Footbar />

            <BillPaymentsModal type="to_pay" bill={bill} afterSave={loadSupply} visible={show_bill_payments_modal} setVisibility={setShowBillPaymentsModal} />

        </Layout>
    )
}