import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import {
    Layout,
    Form,
    Breadcrumb,
    Input,
    Button,
    Row,
    Col,
    Select,
    DatePicker,
    message,
    Table,
    Modal,
    Tooltip,
    Card,
    Alert,
    Empty,
} from 'antd';
import { EyeOutlined, DeleteOutlined} from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/pt_BR';
import {NumericFormat} from 'react-number-format';
import { parseISO, format } from 'date-fns';
import moment from 'moment';
import 'moment/locale/pt-br';

import Topbar from '../components/Topbar';
import Footbar from '../components/Footbar';
// import Loading from '../components/Loading';

import 'antd/dist/antd.css';
import './styles/Main.css';

import api from '../services/api.js';

const { Search } = Input;
const { Content } = Layout;
const { confirm } = Modal;
const { Option } = Select;

export default function Supplies() {
    const [loadingTable, setLoadingTable] = useState(true);
    const [loading, setLoading] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [loading_cost_centers, setLoadingCostCenters] = useState(false);
    const [loading_providers, setLoadingProviders] = useState(false);
    
    const [cost_centers, setCostCenters] = useState([]);
    const [providers, setProviders] = useState([]);
    
    const [data, setData] = useState([]);
    const [has_limit, setHasLimit] = useState(false);

    const [filter_supply_number, setFilterSupplyNumber] = useState('');
    const [filter_cost_center, setFilterCostCenter] = useState('');
    const [filter_provider, setFilterProvider] = useState('');
    const [filter_supply_date_begin, setFilterSupplyDateBegin] = useState(format(new Date(), "yyyy-MM-01"));
    const [filter_supply_date_end, setFilterSupplyDateEnd] = useState(format(new Date(), "yyyy-MM-dd"));

    const [tableLength, setTableLength] = useState(10);
    const [tablePagination, setTablePagination] = useState({current: 1, pageSize: tableLength});
    const [tableFilters, setTableFilters] = useState({});
    const [tableSorter, setTableSorter] = useState({ field: 'date_supply', order: 'descend' });
    const [search, setSearch] = useState('');

    useEffect( () => {
        checkLimit();
    }, []);

    useEffect( () => {
        loadData();
    }, [tableLength]);

    async function checkLimit() {
        setLoading(true);
        let response = await api.get('/billing/limits/bills_to_pay');
        let hasLimit = response.data.data.has_limit;
        setHasLimit(hasLimit);
        setLoading(false);
    }

    async function loadCostCenters(){
        setLoadingCostCenters(true);
        setCostCenters([]);
        let response = await api.get(`/cost_centers`);
        setCostCenters(response.data.data);
        setLoadingCostCenters(false);
    }
    
    async function loadProviders(){
        setLoadingProviders(true);
        setProviders([]);
        let response = await api.get(`/contacts?type=provider`);
        setProviders(response.data.data);
        setLoadingProviders(false);
    }

    async function reloadData(){
        loadData({current: 1, pageSize: tableLength});
    }

    async function loadData(
        pagination = tablePagination,
        filters = tableFilters,
        sorter = tableSorter
    ) {
        setLoadingTable(true);

        setTablePagination(pagination);
        setTableFilters(filters);
        if(!sorter.field){
            sorter = tableSorter
        }
        setTableSorter(sorter);

        let order = sorter.order;
        if(order === undefined) {
            order = 'asc';
        } else if(sorter.order === 'ascend') {
            order = 'asc';
        } else if(sorter.order === 'descend') {
            order = 'desc';
        }

        // SUPPLIES
        let uri = `/supplies?page=${pagination.current}&per_page=${tableLength}&order=${order}&order_by=${sorter.field}&search=${search}`;
        uri += `&supply_number=${filter_supply_number}`;
        uri += `&cost_center=${filter_cost_center}`;
        uri += `&contact=${filter_provider}`;
        uri += `&supply_date_begin=${filter_supply_date_begin}`;
        uri += `&supply_date_end=${filter_supply_date_end}`;
        
        let response = await api.get(uri);

        let supplies_list = response.data.data;

        setData(supplies_list);
        setTablePagination({
            current: pagination.current,
            pageSize: tableLength,
            total: supplies_list.total,
        });

        setLoadingTable(false);
    }

    async function deleteSupply(supply_id) {
        confirm({
            title: 'Você tem certeza?',
            content: 'As contas a pagar geradas por essa compra também serão deletadas. Esta ação não poderá ser desfeita!',
            okText: 'Deletar',
            okType: 'danger',
            cancelText: 'Cancelar',
            async onOk() {
                let response = await api.delete(`/supplies/${supply_id}`);

                if(response.data.success) {
                    message.success('Compra deletado com sucesso');
                } else {
                    message.error('Ocorreu um erro, aguarde alguns instantes e tente novamente');
                }

                reloadData();
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    function fixName(name){
        name = name.split(' ');
        name = name[0];
        return name;
    }
    function formatDate(date){
        if(date !== null && date !== '' && date !== '0000-00-00'){
            date = parseISO(date);
            return format(date, 'dd/MM/yyyy');
        } else {
            return '-';
        }
    }
    function formatTime(date){
        date = parseISO(date);
        return format(date, 'HH:mm');
    }

    const columns = [
        {
            title: 'DATA',
            render: ( supply => (
                formatDate(supply.date_supply)
            )),
        },{
            title: 'IDENTIFICAÇÃO',
            dataIndex: 'supply_number',
        },{
            title: 'CENTRO DE CUSTOS',
            dataIndex: ['cost_center','name'],
        },{
            title: 'FORNECEDOR',
            render: ( supply => (
                supply.contact.name
            )),
        }, {
            title: 'QUANTIDADE',
            dataIndex: 'amount',
        }, {
            title: 'REALIZADO POR',
            render: ( supply => (
                <>
                    <span style={{ fontWeight:'bold', color:'#0086ea' }}>
                        <Tooltip title={ supply.user.name }>
                            { `[ ${fixName(supply.user.name)} ]` }
                        </Tooltip>
                    </span>
                    <br />
                    <span style={{ fontWeight:'bold', color:'#0086ea'  }}>
                        { formatDate(supply.created_at) }
                    </span>
                    <br />
                    <span style={{ fontWeight:'bold', color:'#0086ea'  }}>
                        { formatTime(supply.created_at) }
                    </span>
                </>
            )),
        }, {
            title: 'TOTAL BRUTO (R$)',
            align: 'right',
            render: ( supply => (
                <NumericFormat
                    value={supply.gross_total ? supply.gross_total : 0}
                    displayType={'text'}
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2} 
                    fixedDecimalScale={true}
                    style={{ fontWeight:'bold', color: '#5e72e4' }}
                />
            )),
        },{
            title: 'DESCONTOS (R$)',
            align: 'right',
            render: ( supply => (
                <NumericFormat
                    value={supply.discount_total ? supply.discount_total : 0}
                    displayType={'text'}
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2} 
                    fixedDecimalScale={true}
                    style={{ fontWeight:'bold', color: '#5e72e4' }}
                />
            )),
        },{
            title: 'TOTAL LIQUIDO (R$)',
            align: 'right',
            render: ( supply => (
                <NumericFormat
                    value={supply.net_value ? supply.net_value : 0}
                    displayType={'text'}
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2} 
                    fixedDecimalScale={true}
                    style={{ fontWeight:'bold', color: '#5e72e4' }}
                />
            )),
        },{
            title: 'AÇÕES',
            width: '13%',
            render: (supply => (
                <>
                    <Tooltip title="Detalhes" placement="left">
                        <Link to={`/compras/${supply.id}`}>
                            <Button 
                                type="secondary-default"
                                icon={<EyeOutlined />}
                                // disabled={loading_account}
                                style={{
                                    marginLeft: '5px',
                                    paddingTop: '4px',
                                    paddingBottom: '4px',
                                    paddingLeft: '10px',
                                    paddingRight: '10px',
                                    width:'auto',
                                }}
                            ></Button>
                        </Link>
                    </Tooltip>
                    <Tooltip title="Deletar" placement="right">
                        <Button 
                            type="secondary-default"
                            icon={<DeleteOutlined />}
                            onClick={event => deleteSupply(supply.id)}
                            style={{ 
                                marginLeft: '5px',
                                paddingTop: '4px',
                                paddingBottom: '4px',
                                paddingLeft: '10px',
                                paddingRight: '10px',
                                width:'auto',
                                color: '#ff4d4f !important',
                            }}
                        ></Button>
                    </Tooltip>
                </>
            )),
        },
    ];

    // let loading_screen = '';
    // if(loading){
    //     loading_screen = <Loading />
    // }

    return (
        <Layout className="layout">
            {/* { loading_screen } */}
            <Topbar />

            <Content className="fbm-main">
                <div id="main">
                    <Row>
                        <Col span={24} className="fbm-page-header">
                            <Breadcrumb className="breadcrumb" style={{ margin: '16px 0' }}>
                                <Breadcrumb.Item><Link to="/monitoramento">Monitoramento</Link></Breadcrumb.Item>
                                <Breadcrumb.Item>Compras</Breadcrumb.Item>
                            </Breadcrumb>

                            <div className="actions">
                                <Link to="/compras/novo">
                                    <Button type="success" disabled={!has_limit} >
                                        Nova Compra
                                    </Button>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ marginLeft: '-10px', marginRight: '-10px' }}>
                        <Col span={24} style={{ padding: '0 10px 10px 10px' }}>
                            <Card loading={loading} className="fbm-card">
                                <Form layout='vertical'>
                                    <Row style={{ paddingBottom: '15px', borderBottomWidth: '0.5px', borderBottomColor: '#c9d3dd', borderBottomStyle: 'dashed' }}>
                                        <Col span={2}>
                                            <Form.Item
                                                className="form-item"
                                                label="Identificação:"
                                                style={{ paddingLeft: '0' }}
                                            >
                                                <Input
                                                    id="name" value={ filter_supply_number }
                                                    onChange={ event => setFilterSupplyNumber(event.target.value) }
                                                    required
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={5}>
                                            <Form.Item
                                                className="form-item"
                                                label="Centro de custos:"
                                            >
                                                <Select 
                                                    value={ filter_cost_center }
                                                    style={{ width: 120 }} 
                                                    onChange={ (value) => setFilterCostCenter(value) }
                                                    onFocus={ () => loadCostCenters() }
                                                    loading={ loading_cost_centers }
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    <Option value="">Todos</Option>
                                                    { cost_centers.map(item => (
                                                        <Option
                                                            key={item.id}
                                                            value={ item.id }
                                                        >
                                                            { item.name }
                                                        </Option>
                                                    )) }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={5}>
                                            <Form.Item
                                                className="form-item"
                                                label="Fornecedor:"
                                            >
                                                <Select 
                                                    value={ filter_provider }
                                                    style={{ width: 120 }} 
                                                    onChange={ (value) => setFilterProvider(value) }
                                                    onFocus={ () => loadProviders() }
                                                    loading={ loading_providers }
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    <Option value="">Todos</Option>
                                                    { providers.map(item => (
                                                        <Option
                                                            key={item.id}
                                                            value={ item.id }
                                                        >
                                                            { item.name }
                                                        </Option>
                                                    )) }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item
                                                className="form-item"
                                                label="Data de compra (início):"
                                            >
                                                <DatePicker
                                                    locale={locale}
                                                    showTime={false}
                                                    value={filter_supply_date_begin !== '' ? moment(filter_supply_date_begin) : ''}
                                                    format="DD/MM/YYYY"
                                                    onChange={ (date, dateString) => {
                                                        setFilterSupplyDateBegin(date !== null ? format(date._d, "yyyy-MM-dd") : '');
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item
                                                className="form-item"
                                                label="Data de compra (fim):"
                                            >
                                                <DatePicker 
                                                    locale={locale}
                                                    showTime={false}
                                                    value={filter_supply_date_end !== '' ? moment(filter_supply_date_end) : ''}
                                                    format="DD/MM/YYYY"
                                                    onChange={ (date, dateString) => {
                                                        setFilterSupplyDateEnd(date !== null ? format(date._d, "yyyy-MM-dd") : '');
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item
                                                className="form-item"
                                                style={{ marginTop: '25px', paddingRight: '0' }}
                                            >
                                                <Button 
                                                    key="submit"
                                                    type="primary"
                                                    loading={loadingTable}
                                                    onClick={ () => reloadData() }
                                                    style={{ width: '100%' }}
                                                >
                                                    Filtrar
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                                <Row style={{ paddingTop:'20px',paddingBottom: '0' }}>
                                    <Col span={24}>
                                        <div style={{ overflow: 'auto', marginBottom:'15px' }}>
                                            <Form.Item
                                                className="form-item"
                                                style={{ 
                                                    paddingTop: 0, 
                                                    paddingRight: 0,
                                                    paddingLeft: 0,
                                                    paddingBottom: 0,
                                                    width: '100px',
                                                    float: 'left',
                                                }}
                                            >
                                                <Select 
                                                    value={tableLength} 
                                                    onChange={ async (value) => setTableLength(value) }
                                                >
                                                    <Option key="10" value="10">10</Option>
                                                    <Option key="25" value="25">25</Option>
                                                    <Option key="50" value="50">50</Option>
                                                    <Option key="100" value="100">100</Option>
                                                </Select>
                                            </Form.Item>
                                            <Form.Item
                                                className="form-item"
                                                style={{ 
                                                    paddingTop: 0,
                                                    paddingRight: 0,
                                                    paddingLeft: 0,
                                                    paddingBottom: 0,
                                                    width: '200px',
                                                    float: 'right',
                                                }}
                                            >
                                                <Search
                                                    id="search"
                                                    placeholder="Pesquisar"
                                                    value={search}
                                                    onChange={ event => setSearch(event.target.value) }
                                                    onSearch={ value => reloadData() }
                                                    loading={ loadingTable }
                                                />
                                            </Form.Item>
                                        </div>
                                    </Col>
                                    <Col span={24} >
                                        <Table
                                            bordered={true}
                                            columns={columns}
                                            rowKey={record => record.id}
                                            dataSource={data.data}
                                            pagination={tablePagination}
                                            loading={loadingTable}
                                            onChange={loadData}
                                            pageSize={tableLength}
                                            locale={{ emptyText: <Empty description={<b>Nenhum registro encontrado...</b>} /> }}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Content>

            <Footbar />
        </Layout>
    )
}