module.exports = function validateValue( type, value )
{
    let re = '';

    switch (type) {
        case 'email':
            re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            break;
        case 'cpf':
            re = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
            break;
        case 'cnpj':
            re = /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/;
            break;
        case 'state_registration':
            re = /^\d{3}\.\d{3}\.\d{3}\.\d{3}$/;
            break;
        case 'phone':
            re = /^\(\d{2}\) \d{5}-\d{4}$/;
            break;
        default:
            re = '';
            break;
    }

    return re.test(value);
}