import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import { 
    Layout,
    Form,
    Breadcrumb,
    Input,
    Button,
    DatePicker,
    Row,
    Col,
    Select,
    message,
    Table,
    Modal,
    Tooltip,
    Switch,
    Empty,
} from 'antd';
import { 
    CheckOutlined,
    CloseOutlined,
    PlusOutlined,
    EditOutlined,
    DeleteOutlined,
    PauseOutlined
} from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/pt_BR';
import InputMask from 'react-input-mask';
import moment from 'moment';
import 'moment/locale/pt-br';
import { parseISO, format } from 'date-fns';
import CurrencyInput from 'react-currency-input';

import Topbar from '../../components/Topbar';
import Footbar from '../../components/Footbar';
// import Loading from '../components/Loading';

import 'antd/dist/antd.css';
import '../styles/Main.css';

import api from '../../services/api.js';

const validateValue = require('../../utils/validateValue');
const cleanFloatValue = require('../../utils/cleanFloatValue');

const { Search } = Input;
const { Content } = Layout;
const { confirm } = Modal;
const { Option } = Select;

export default function DRE() {
    const [loadingTable, setLoadingTable] = useState(true);

    const [data, setData] = useState([]);
    const [period, setPeriod] = useState('monthly');
    const [years_list, setYearsList] = useState([]);
    const [year, setYear] = useState(format(new Date(), "yyyy"));
    const [end_year, setEndYear] = useState(format(new Date(), "yyyy"));
    const [columns, setColumns] = useState([]);

    const [tableLength, setTableLength] = useState(10);
    const [tablePagination, setTablePagination] = useState({current: 1, pageSize: tableLength});

    useEffect(() => {
        loadData();
    }, []);

    async function reloadData(){
        loadData({current: 1, pageSize: tableLength});
    }

    async function loadData() {
        setLoadingTable(true);

        let this_year = format(new Date(), "yyyy");
        let year_list = [];

        for (let index = 2022; index <= this_year; index++) {
            year_list.push(index);
        };

        setYearsList(year_list);

        let uri = `/reports/dre?year=${year}&period=${period}&end_year=${end_year}`;

        let response = await api.get(uri);

        let report = response.data.data;

        report = Object.values(report);

        let report_data = [];

        report.forEach(item => {
            report_data.push({
                'root'  : true,
                'type'  : '',
                'totalizer' : item.totalizer,
                'title' : item.title,
                'total': item.total,
                'values': item.values
            });

            if(item.data){
                let categories = item.data;
                categories.forEach(category => {
                    report_data.push({
                        'root'  : false,
                        'totalizer' : item.totalizer,
                        'type'  : category.type,
                        'title' : category.title,
                        'total': category.total,
                        'values': category.values
                    });
                });
            }
        });

        setData(report_data);
        
        let cols = [];

        cols.push({
            title: 'Categoria',
            render(item) {
                return {
                    props: {
                        style: { 
                            height: '50px',
                            background: "#f5f5f5",
                            fontWeight: item.root ? 'bold' : '',
                            borderLeft: item.root ? '3px solid #ccc' : (item.type == 'REVENUE' ? '3px solid #4eb94e' : '3px solid #f34444'),
                            paddingLeft: item.root ? '15px' : '25px',
                            color: '#395a7a',
                        }
                    },
                    children: <>
                        {item.totalizer && <span style={{
                            backgroundColor: '#fff',
                            padding: '0 6px',
                            borderRadius: '50%',
                            border: '1px solid #bbb',
                            position: 'absolute',
                            right: '-13px',
                            fontWeight: 'bolder'
                        }}>=</span>}
                        {item.title}
                    </>
                };
            },
            fixed: 'left',
            width: '300px',
            align: 'left',
        });

        var count = 0;
        let data = report_data[0];
        var max_count = data.values.length-1;
        data.values.forEach(value => {
            cols.push({
                title: value.label,
                render: (item => {
                    if(count > max_count){
                        count = 1;
                    } else {
                        count++;
                    }
                
                    if(item.values.length >= count){
                        return {
                            props: {
                                style: { 
                                    fontWeight: item.root ? 'bold' : '',
                                    color: '#395a7a',
                                }
                            },
                            children: <>{formatReal(item.values[count-1].value)}</>
                        };
                    } else {
                        count = 0;
                        return '';
                    }
                }),
                width: 100,
                align: 'right',
            });           
        });

        cols.push({
            title: 'Total',
            render: (item => {
                return {
                  props: {
                    style: { 
                        background: "#f5f5f5",
                        fontWeight: item.root ? 'bold' : '',
                        color: '#395a7a',
                    }
                  },
                  children: <>{formatReal(item.total)}</>
                };
            }),
            width: 100,
            align: 'right',
        });

        setColumns(cols);

        setLoadingTable(false);
    }

    function formatReal(num) {
        if(isNaN(num)){
            num = 0;
        }

        num = parseFloat(num);
        num = num.toFixed(2) // casas decimais
            .replace('.', ',')
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')

        return num;
    }

    return (
        <Layout className="layout">
            {/* { loading_screen } */}
            <Topbar />

            <Content className="fbm-main">
                <div id="main">
                    <Row style={{ marginLeft: '-10px', marginRight: '-10px' }}>
                        <Col span={24} style={{ padding: '0 10px 10px 10px' }}>
                            <div className="fbm-card">
                                <Row style={{ paddingBottom: '15px', borderBottomWidth: '0.5px', borderBottomColor: '#e8e8e8', borderBottomStyle: 'solid' }}>
                                    <Col className="fbm-card-header" span={24}>
                                        <h1 className="big-title">
                                            Demonstrativo de Resultado do Exercício Gerencial
                                        </h1>
                                    </Col>
                                </Row>
                                <Form layout='vertical'>
                                    <Row style={{ paddingBottom: '20px', borderBottomWidth: '0.5px', borderBottomColor: '#c9d3dd', borderBottomStyle: 'dashed' }}>
                                        <Col span={4}>
                                            <Form.Item
                                                className="form-item"
                                                label="Período:"
                                                style={{ paddingLeft: '0' }}
                                            >
                                                <Select 
                                                    value={ period }
                                                    style={{ width: 120 }} 
                                                    onChange={ (value) => setPeriod(value) }
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    <Option value="monthly" >
                                                        Mensal
                                                    </Option>
                                                    <Option value="bimonthly" >
                                                        Bimestral
                                                    </Option>
                                                    <Option value="quarterly" >
                                                        Trimestral
                                                    </Option>
                                                    <Option value="semiannual" >
                                                        Semestral
                                                    </Option>
                                                    <Option value="annual" >
                                                        Anual
                                                    </Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item
                                                className="form-item"
                                                label={period == 'annual' ? "Ano (início):" : "Ano:"}
                                                style={{ paddingLeft: '0' }}
                                            >
                                                <Select 
                                                    value={ year }
                                                    style={{ width: 120 }} 
                                                    onChange={ (value) => setYear(value) }
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    { years_list.map(item => (
                                                        <Option value={item} >
                                                            {item}
                                                        </Option>
                                                    )) }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        { period == 'annual' && <Col span={4}>
                                            <Form.Item
                                                className="form-item"
                                                label="Ano (fim):"
                                                style={{ paddingLeft: '0' }}
                                            >
                                                <Select 
                                                    value={ end_year }
                                                    style={{ width: 120 }} 
                                                    onChange={ (value) => setEndYear(value) }
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    { years_list.map(item => (
                                                        <Option value={item} >
                                                            {item}
                                                        </Option>
                                                    )) }
                                                    <Option value={2023} >
                                                        2023
                                                    </Option>
                                                    <Option value={2024} >
                                                        2024
                                                    </Option>
                                                </Select>
                                            </Form.Item>
                                        </Col> }
                                        <Col span={4}>
                                            <Form.Item
                                                className="form-item"
                                                style={{ marginTop: '25px', paddingRight: '0' }}
                                            >
                                                <Button 
                                                    key="submit"
                                                    type="primary"
                                                    loading={loadingTable}
                                                    onClick={ () => reloadData() }
                                                    style={{ width: '100%' }}
                                                >
                                                    Filtrar
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>

                                <Row style={{ paddingTop:'20px',paddingBottom: '0' }}>
                                    <Col span={24} >
                                        <Table
                                            bordered={true}
                                            columns={columns}
                                            rowKey={record => record.id}
                                            dataSource={data}
                                            pagination={false}
                                            loading={loadingTable}
                                            scroll={{ x: 1000 }}
                                            sticky={true}
                                            style={{ maxWidth: '1000px' }}
                                            locale={{ emptyText: <Empty description={<b>Nenhum registro encontrado...</b>} /> }}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Content>

            <Footbar />
        </Layout>
    )
}