import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import { 
    Layout,
    Form,
    Breadcrumb,
    Input,
    Button,
    DatePicker,
    Row,
    Col,
    Select,
    message,
    Card,
    Radio,
    Upload,
    Spin,
} from 'antd';
import { 
    UploadOutlined,
    FileSearchOutlined,
    AuditOutlined,
    HomeOutlined,
} from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/pt_BR';
import moment from 'moment';
import 'moment/locale/pt-br';
import InputMask from 'react-input-mask';
import { format } from 'date-fns';

import Topbar from '../components/Topbar';
import Footbar from '../components/Footbar';
import Loading from '../components/Loading';
import SettingsSidebar from '../components/SettingsSidebar';

import 'antd/dist/antd.css';
import './styles/Main.css';

import { getToken } from '../services/auth';
import api from '../services/api.js';
import external_api from '../services/external_api.js';

const validateValue = require('../utils/validateValue');

const { Search } = Input;
const { Content } = Layout;
const { Option } = Select;

export default function Settings() {
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loading_zip_code, setLoadingZipCode] = useState(false);
    const [loading_cnpj, setLoadingCnpj] = useState(false);
    const [cnae_list, setCnaeList] = useState([]);
    
    const [logo_url, setLogoUrl] = useState('');
    const [type_person, setTypePerson] = useState('');
    const [cpf, setCpf] = useState('');
    const [name, setName] = useState('');
    const [cnae_ibge, setCnaeIbge] = useState('');
    const [company, setCompany] = useState(' ');
    const [social_reason, setSocialReason] = useState(' ');
    const [cnpj, setCnpj] = useState('');
    const [opening_date, setOpeningDate] = useState('');
    const [state_registration, setStateRegistration] = useState('');
    const [municipal_registration, setMunicipalRegistration] = useState('');
    const [foreign_identifier, setForeignIdentifier] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [phone2, setPhone2] = useState('');
    const [phone3, setPhone3] = useState('');
    const [zip_code, setZipCode] = useState('');
    const [address, setAddress] = useState('');
    const [district, setDistrict] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [number, setNumber] = useState('');
    const [complement, setComplement] = useState('');
    const [support_id, setSupportId] = useState('');
    const [support_word, setSupportWord] = useState('');

    const [cnpj_invalid, setCnpjInvalid] = useState(false);
    const [cpf_invalid, setCpfInvalid] = useState(false);
    const [state_registration_invalid, setStateRegistrationInvalid] = useState(false);
    const [email_invalid, setEmailInvalid] = useState(false);

    useEffect(() => {
        async function loadSettings() {
            let response = await external_api.get('https://servicodados.ibge.gov.br/api/v2/cnae/classes');
            setCnaeList(response.data);

            response = await api.get('/accounts');

            const account = response.data.data;
            
            setLogoUrl(account.logo_url);
            setName(account.name);
            setTypePerson(account.type_person);
            setCpf(account.cpf);
            setCnaeIbge(account.cnae_ibge_id);
            setCompany(account.company);
            setSocialReason(account.social_reason);
            setCnpj(account.cnpj);
            setOpeningDate(account.opening_date);
            setStateRegistration(account.state_registration);
            setMunicipalRegistration(account.foreign_identifier);
            setForeignIdentifier(account.foreign_identifier);
            setEmail(account.email);
            setPhone(account.phone);
            setPhone2(account.phone2);
            setPhone3(account.phone3);
            setZipCode(account.zip_code);
            setAddress(account.address);
            setDistrict(account.district);
            setState(account.state);
            setCity(account.city);
            setNumber(account.number);
            setComplement(account.complement);
            setSupportId(account.support_id);
            setSupportWord(account.support_word);

            setLoading(false);
        }

        loadSettings();
    }, []);

    async function searchZipCode() {
        setLoadingZipCode(true);

        try {
            let response = await external_api.get(`https://brasilapi.com.br/api/cep/v1/${zip_code.replace('-', '')}`);

            setLoadingZipCode(false);

            response = response.data;

            setNumber('');
            setComplement('');
            setAddress(response.street);
            setDistrict(response.neighborhood);
            setCity(response.city);
            setState(response.state);
        } catch (error) {
            setLoadingZipCode(false);
        }
    }

    async function searchCnpj() {
        setLoadingCnpj(true);

        try {
            let clean_cnpj = cnpj.replace('.', '');
            clean_cnpj = clean_cnpj.replace('.', '');
            clean_cnpj = clean_cnpj.replace('/', '');
            clean_cnpj = clean_cnpj.replace('-', '');
            
            let response = await external_api.get(`https://brasilapi.com.br/api/cnpj/v1/${clean_cnpj}`);

            setLoadingCnpj(false);

            response = response.data;

            let cnpj_cnae_ibge = response.cnae_fiscal+"";
            setCnaeIbge(cnpj_cnae_ibge.slice(0, -2));
            setName(response.nome_fantasia);
            setSocialReason(response.razao_social);
            setOpeningDate(response.data_inicio_atividade);
            setZipCode(response.cep);

            setNumber(response.numero);
            setComplement('');
            setAddress(response.descricao_tipo_de_logradouro+' '+response.logradouro);
            setDistrict(response.bairro);
            setCity(response.municipio);
            setState(response.uf);
        } catch (error) {
            setLoadingCnpj(false);
        }
    }

    function checkInput(field) {
        if (field.id === 'cpf') {
            if( cpf === '' || !validateValue('cpf', cpf) ){
                setCpfInvalid(true);
                return false;
            }
            setCpfInvalid(false);
        }

        if (field.id === 'cnpj') {
            if( cnpj === '' || !validateValue('cnpj', cnpj) ){
                setCnpjInvalid(true);
                return false;
            }
            setCnpjInvalid(false);
        }

        if(field.id === 'email') {
            if( email === '' || !validateValue('email', email)){
                setEmailInvalid(true);
                return false;
            }
            setEmailInvalid(false);
        }

        if (field.id === 'state_registration') {
            if( state_registration === '' || !validateValue('state_registration', state_registration) ){
                setStateRegistrationInvalid(true);
                return false
            }
            setStateRegistrationInvalid(false);
        }
    }

    async function updateAccount() {
        setSaving(true);

        const data = {
            cpf,
            name,
            type_person,
            cnae_ibge_id: cnae_ibge,
            company,
            social_reason,
            cnpj,
            state_registration,
            municipal_registration,
            foreign_identifier,
            email,
            phone,
            phone2,
            phone3,
            opening_date: opening_date ? opening_date : null,
            zip_code,
            address,
            district,
            complement,
            number,
            state,
            city,
        };

        try {
            let response = await api.put('/accounts', JSON.stringify(data));

            if(response.data.success) {
                let updated_user = localStorage.getItem('@semzebra/user');
                updated_user = JSON.parse(updated_user);
                updated_user.company = company;
                localStorage.setItem('@semzebra/user', JSON.stringify(updated_user));

                message.success('Configurações salvas com sucesso');
            } else {
                message.error('Ocorreu um erro, aguarde alguns instantes e tente novamente');
            }

            setSaving(false);
        } catch (error) {
            message.error('Ocorreu um erro, aguarde alguns instantes e tente novamente');
            setSaving(false);
        }
    }

    function checkForm(){
        if(
            !zip_code || !address || !number || 
            !district || !city || !state || 
            !email || email_invalid || !phone
        ){ return true }

        if( type_person === 'PF' && name && cpf && !cpf_invalid ) {
            return false;
        }
        else if (
            type_person === 'PJ' && 
            cnpj && !cnpj_invalid && 
            cnae_ibge && social_reason && 
            company && opening_date
        ) {
            return false;
        }
        return true;
    }

    function changeTypePerson(type){
        setTypePerson(type);

        setName('');
        setCpf('');
        setCnpj('');
        setCnaeIbge('');
        setSocialReason('');
        setCompany('');
        setStateRegistration('');
        setMunicipalRegistration('');
        setForeignIdentifier('');
        setOpeningDate('');
    }

    function onChange(info) {
        setSaving(true);
        if (info.file.status !== 'uploading') {
            // console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
            setLogoUrl(info.file.response.data.logo_url);
            message.success(`Logo alterado com sucesso. (${info.file.name})`);
        } else if (info.file.status === 'error') {
            message.success(`Ocorreu um erro, aguarde alguns instantes e tente novamente`);
        }
        
        setSaving(false);
    }

    function beforeUpload(file) {
        const isAccepted = (/image\/\w+/i).test(file.type);

        if (!isAccepted) {
            setSaving(false);
            message.error('Formato inválido!');
            return false;
        }

        const isLt2M = file.size / 1024 / 1024 < 2;
        
        if (!isLt2M) {
            setSaving(false);
            message.error('O anexo pode ter até 2MB!');
            return false;
        }
        
        return isAccepted && isLt2M;
    }

    let loading_screen = '';
    if(loading){
        loading_screen = <Loading />
    }

    return (
        <Layout className="layout">
            { loading_screen }
            <Topbar />

            <Content className="fbm-main">
                <div id="main">
                    <Row>
                        <Col span={5} style={{ padding: '0 10px 10px 10px' }}>
                            <SettingsSidebar actived="account" />
                        </Col>
                        <Col span={19}>
                            <Form layout='vertical'>
                                <Card className="fbm-card" loading={loading}>
                                    <Row style={{ paddingBottom: '10px' }}>
                                        <Col span={4} style={{ textAlign: 'center', paddingLeft: '10px' }}>
                                            <Spin spinning={saving}>
                                                <img 
                                                    draggable="false" 
                                                    src={logo_url ? logo_url : '' } 
                                                    width="130" 
                                                    alt="Logo"
                                                    style={{marginBottom: '10px'}}
                                                />
                                            </Spin>
                                            <Upload 
                                                name="logo"
                                                method="POST"
                                                showUploadList={false}
                                                action={process.env.REACT_APP_API_URL+'/accounts/logo'}
                                                headers={{
                                                    authorization: getToken(),
                                                }}
                                                onChange={onChange}
                                                disabled={saving}
                                                accept="image/*"
                                                beforeUpload={beforeUpload}
                                            >
                                                <Button>
                                                    <UploadOutlined /> Alterar Logo
                                                </Button>
                                            </Upload>
                                        </Col>
                                        <Col span={15} style={{ paddingLeft: '30px', paddingRight: '10px' }}>
                                            <h5 className="page-title">Minha Empresa</h5>
                                            <p style={{fontSize:'0.9em'}}><span style={{ fontWeight: 'bold', color: '#5e72e4' }}>Preencha todas as informações da empresa</span>. Ao gerar documentos, como notas fiscais, é necessário que não haja campos vazios ou com informações erradas.</p>
                                            <p style={{fontSize:'0.9em'}}>Os dados inseridos são de grande importância, e influenciam diretamente em outras rotinas, como a integração entre os módulos do sistema e geração de relatórios e notas fiscais.</p>
                                        </Col>
                                        <Col span={5} style={{ paddingLeft: '15px', borderLeft: '1px solid #ccc' }}>
                                            <p>
                                                Seu ID: <br />
                                                <strong>{support_id}</strong>
                                            </p>
                                            <p>
                                                Sua palavra de segurança:<br />
                                                <strong>{support_word}</strong>
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row style={{marginTop:'30px'}}>
                                        <Col span={2} style={{ textAlign: 'center' }}>
                                            <FileSearchOutlined style={{ color:'#898989',fontSize:'3rem'}} />
                                        </Col>
                                        <Col span={22} style={{ borderLeft:'1px solid #ccc' }}>
                                            <Row>
                                                <Col span={12} style={{paddingLeft:'10px'}}>
                                                    <Radio.Group 
                                                        defaultValue={type_person}
                                                        buttonStyle="solid"
                                                        onChange={event => changeTypePerson(event.target.value)}
                                                    >
                                                        <Radio.Button value="PJ">Pessoa Jurídica</Radio.Button>
                                                        <Radio.Button value="PF">Pessoa Física</Radio.Button>
                                                    </Radio.Group>
                                                </Col>
                                            </Row>
                                            { type_person === 'PF' && <>
                                                <Row>
                                                    <Col span={14}>
                                                        <Form.Item
                                                            className="form-item"
                                                            label="Nome completo:"
                                                            required={true}
                                                            hasFeedback
                                                            validateStatus={`${name === '' ? 'error' : ''}`}
                                                        >
                                                            <Input
                                                                id="name"
                                                                value={ name }
                                                                onChange={ event => setName(event.target.value) }
                                                                required
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={7}>
                                                        <Form.Item
                                                            className="form-item"
                                                            label="CPF:"
                                                            required={true}
                                                            hasFeedback
                                                            validateStatus={`${cpf_invalid ? 'error' : ''}`}
                                                        >
                                                            <InputMask 
                                                                mask="999.999.999-99"
                                                                alwaysShowMask={false}
                                                                placeholder="___.___.___-__"
                                                                id="cpf"
                                                                value={ cpf }
                                                                onChange={ event => setCpf(event.target.value) }
                                                                onKeyUp={ event => checkInput(event.target) }
                                                                onBlur={ event => checkInput(event.target) }
                                                            >
                                                                {(inputProps) => 
                                                                    <Input 
                                                                        {...inputProps} 
                                                                        type="text"
                                                                        required
                                                                    />
                                                                }
                                                            </InputMask>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </> }
                                            { type_person === 'PJ' && <>
                                                <Row>
                                                    <Col span={7}>
                                                        <Form.Item
                                                            className="form-item"
                                                            label="CNPJ da sua empresa:"
                                                            required={true}
                                                            hasFeedback
                                                            validateStatus={`${cnpj_invalid ? 'error' : ''}`}
                                                        >
                                                            <InputMask 
                                                                mask="99.999.999/9999-99"
                                                                alwaysShowMask={false}
                                                                placeholder="__.___.___/____-__"
                                                                id="cnpj"
                                                                value={ cnpj }
                                                                onChange={ event => setCnpj(event.target.value) }
                                                                onKeyUp={ event => checkInput(event.target) }
                                                                onBlur={ event => checkInput(event.target) }
                                                            >
                                                                {(inputProps) => 
                                                                    <Search
                                                                        {...inputProps}
                                                                        onSearch={ value => searchCnpj() }
                                                                        loading={ loading_cnpj }
                                                                        required
                                                                    />
                                                                }
                                                            </InputMask>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={15}>
                                                        <Form.Item
                                                            className="form-item"
                                                            label="CNAE Principal:"
                                                            required={true}
                                                        >
                                                            <Select 
                                                                value={ cnae_ibge } 
                                                                style={{ width: 120 }} 
                                                                onChange={ (value) => setCnaeIbge(value) }
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) =>
                                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }    
                                                            >
                                                                { cnae_list.map(cnae => (
                                                                    <Option 
                                                                        key={cnae.id}
                                                                        value={ cnae.id }
                                                                    >
                                                                        { cnae.descricao }
                                                                    </Option>
                                                                )) }
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={10}>
                                                        <Form.Item
                                                            className="form-item"
                                                            label="Nome fantasia:"
                                                            required={true}
                                                            hasFeedback
                                                            validateStatus={`${company === '' ? 'error' : ''}`}
                                                        >
                                                            <Input
                                                                id="company"
                                                                value={ company }
                                                                onChange={ event => setCompany(event.target.value) }
                                                                required
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={14}>
                                                        <Form.Item
                                                            className="form-item"
                                                            label="Razão social:"
                                                            required={true}
                                                            hasFeedback
                                                            validateStatus={`${social_reason === '' ? 'error' : ''}`}
                                                        >
                                                            <Input
                                                                id="social_reason"
                                                                value={ social_reason }
                                                                onChange={ event => setSocialReason(event.target.value) }
                                                                required
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </> }
                                        </Col>
                                    </Row>

                                    { type_person === 'PJ' && <>
                                        <Row style={{marginTop:'30px'}}>
                                            <Col span={2} style={{ textAlign: 'center' }}>
                                                <AuditOutlined style={{ color:'#898989',fontSize:'3rem'}} />
                                            </Col>
                                            <Col span={22} style={{ borderLeft:'1px solid #ccc' }}>
                                                <Row>
                                                    <Col span={6}>
                                                        <Form.Item
                                                            className="form-item"
                                                            label="Inscrição estadual:"
                                                            hasFeedback
                                                            validateStatus={`${state_registration_invalid ? 'error' : ''}`}
                                                        >
                                                            <InputMask 
                                                                mask="999.999.999.999"
                                                                alwaysShowMask={false}
                                                                placeholder="___.___.___.___"
                                                                id="state_registration"
                                                                value={ state_registration }
                                                                onChange={ event => setStateRegistration(event.target.value) }
                                                                onKeyUp={ event => checkInput(event.target) }
                                                                onBlur={ event => checkInput(event.target) }
                                                            >
                                                                {(inputProps) => 
                                                                    <Input 
                                                                        {...inputProps} 
                                                                        type="text"
                                                                        required
                                                                    />
                                                                }
                                                            </InputMask>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={6}>
                                                        <Form.Item
                                                            className="form-item"
                                                            label="Inscrição municipal:"
                                                        >
                                                            <Input
                                                                id="municipal_registration"
                                                                value={ municipal_registration }
                                                                onChange={ event => setMunicipalRegistration(event.target.value) }
                                                                required
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={6}>
                                                        <Form.Item
                                                            className="form-item"
                                                            label="Identificador estrangeiro:"
                                                        >
                                                            <Input
                                                                id="foreign_identifier"
                                                                value={ foreign_identifier }
                                                                onChange={ event => setForeignIdentifier(event.target.value) }
                                                                required
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={6}>
                                                        <Form.Item
                                                            className="form-item"
                                                            label="Data de fundação:"
                                                            required={true}
                                                        >
                                                            <DatePicker
                                                                locale={locale}
                                                                showTime={false}
                                                                value={opening_date !== '' ? moment(opening_date) : ''}
                                                                format="DD/MM/YYYY"
                                                                onChange={ (date, dateString) => {
                                                                    setOpeningDate(date !== null ? format(date._d, "yyyy-MM-dd") : '');
                                                                }}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </> }
                                    <Row style={{marginTop:'30px'}}>
                                        <Col span={2} style={{ textAlign: 'center' }}>
                                            <HomeOutlined style={{ color:'#898989',fontSize:'3rem'}} />
                                        </Col>
                                        <Col span={22} style={{ borderLeft:'1px solid #ccc' }}>
                                            <Row>
                                                <Col span={6}>
                                                    <Form.Item
                                                        className="form-item"
                                                        label="CEP:"
                                                        required={true}
                                                    >
                                                        <InputMask 
                                                            mask="99999-999"
                                                            alwaysShowMask={false}
                                                            placeholder="_____-___"
                                                            id="zip_code"
                                                            value={ zip_code }
                                                            onChange={ event => setZipCode(event.target.value) }
                                                        >
                                                            {(inputProps) => 
                                                                <Search
                                                                    {...inputProps}
                                                                    onSearch={ value => searchZipCode() }
                                                                    loading={ loading_zip_code }
                                                                />
                                                            }
                                                        </InputMask>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={10}>
                                                    <Form.Item
                                                        className="form-item"
                                                        label="Endereço:"
                                                        required={true}
                                                    >
                                                        <Input
                                                            id="address"
                                                            value={ address }
                                                            onChange={ event => setAddress(event.target.value) }
                                                            required
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={3}>
                                                    <Form.Item
                                                        className="form-item"
                                                        label="Número:"
                                                        required={true}
                                                        style={{paddingRight:0}}
                                                    >
                                                        <Input
                                                            id="number"
                                                            value={ number }
                                                            onChange={ event => setNumber(event.target.value) }
                                                            required
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={9}>
                                                    <Form.Item
                                                        className="form-item"
                                                        label="Bairro:"
                                                        required={true}
                                                    >
                                                        <Input
                                                            id="district"
                                                            value={ district }
                                                            onChange={ event => setDistrict(event.target.value) }
                                                            required
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6}>
                                                    <Form.Item
                                                        className="form-item"
                                                        label="Complemento:"
                                                    >
                                                        <Input
                                                            id="complement"
                                                            value={ complement }
                                                            onChange={ event => setComplement(event.target.value) }
                                                            required
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6}>
                                                    <Form.Item
                                                        className="form-item"
                                                        label="Cidade:"
                                                        required={true}
                                                    >
                                                        <Input
                                                            id="city"
                                                            value={ city }
                                                            onChange={ event => setCity(event.target.value) }
                                                            required
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={3}>
                                                    <Form.Item
                                                        className="form-item"
                                                        label="UF:"
                                                        required={true}
                                                    >
                                                        <Input
                                                            id="state"
                                                            style={{textTransform: 'uppercase'}}
                                                            maxLength={2}
                                                            value={ state }
                                                            onChange={ event => setState(event.target.value) }
                                                            required
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={8}>
                                                    <Form.Item
                                                        className="form-item"
                                                        label="Email comercial:"
                                                        required={true}
                                                        hasFeedback
                                                        validateStatus={`${email_invalid ? 'error' : ''}`}
                                                    >
                                                        <Input
                                                            id="email"
                                                            value={ email }
                                                            onChange={ event => setEmail(event.target.value) }
                                                            onKeyUp={ event => checkInput(event.target) }
                                                            onBlur={ event => checkInput(event.target) }
                                                            required
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6}>
                                                    <Form.Item
                                                        className="form-item"
                                                        label="Celular:"
                                                        required={true}
                                                    >
                                                        <InputMask 
                                                            mask="(99) 99999-9999"
                                                            alwaysShowMask={false}
                                                            placeholder="(__) _____-____"
                                                            id="phone"
                                                            value={ phone }
                                                            onChange={ event => setPhone(event.target.value) }
                                                        >
                                                            {(inputProps) => 
                                                                <Input 
                                                                    {...inputProps} 
                                                                    type="text"
                                                                    required
                                                                />
                                                            }
                                                        </InputMask>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6}>
                                                    <Form.Item
                                                        className="form-item"
                                                        label="Telefone 2:"
                                                    >
                                                        <InputMask 
                                                            mask="(99) 99999-9999"
                                                            alwaysShowMask={false}
                                                            placeholder="(__) _____-____"
                                                            id="phone2"
                                                            value={ phone2 }
                                                            onChange={ event => setPhone2(event.target.value) }
                                                        >
                                                            {(inputProps) => 
                                                                <Input 
                                                                    {...inputProps} 
                                                                    type="text"
                                                                    required
                                                                />
                                                            }
                                                        </InputMask>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24} style={{ paddingTop:'45px', textAlign:'right' }}>
                                            <Button
                                                type="success"
                                                style={{ height:'35px' }}
                                                onClick={ () => updateAccount() }
                                                loading={ saving }
                                                disabled={ checkForm() }
                                            >
                                                Salvar
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </Content>
            <Footbar />
        </Layout>
    )
}