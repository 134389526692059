import React from 'react'
import { Layout, Menu, Dropdown } from 'antd';
import { BookOutlined, MailOutlined, QuestionOutlined } from '@ant-design/icons';

import './style.css';

import logo_gray from '../../assets/logo_gray.png';

const { Footer } = Layout;

const menu = (
    <Menu className="fbm_help_list">
      <Menu.Item key="0">
        <a href="https://ajuda.semzebra.com.br" target="_blank">
            <BookOutlined /> Central de ajuda
        </a>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="3">
          <a href="mailto:meajuda@semzebra.com.br">
            <MailOutlined /> Suporte
          </a>
      </Menu.Item>
    </Menu>
  );

function Footbar() {
    return (
        <>
            <Dropdown overlay={menu} trigger={['click']} placement="topRight">
                <a className="fbm_help_dropdown" onClick={e => e.preventDefault()} >
                    <QuestionOutlined />
                </a>
            </Dropdown>
            <Footer style={{ textAlign: 'center' }}>
                <img src={logo_gray} alt="" width="180"/>
            </Footer>
        </>
    );
}

export default Footbar;