import React, { useState, useEffect } from 'react';
import {Link, redirect, useSearchParams, useNavigate} from 'react-router-dom';
import { Input, Row, Col, Button, Form, notification, Spin  } from 'antd';

import { login, isAuthenticated } from '../services/auth';

import logo from '../assets/logo.png';
import cover_img from '../assets/image.3a4f9cdb.jpg';

import 'antd/dist/antd.css';
import './styles/Login.css';

import api from '../services/api.js';

export default function Signin() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [email_invalid, setEmailInvalid] = useState(false);
    const [password_invalid, setPasswordInvalid] = useState(false);

    const [loading, setLoading] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();

    let navigate = useNavigate();

    async function handleSubmit(event) {
        setLoading(true);
        
        try {
            const response = await api.post('/auth/authenticate', {
                email,
                password,
            });

            login(response.data.data);

            let isLogged = setInterval(() => {
                if(isAuthenticated()) {
                    clearInterval(isLogged);

                    let redirect_to = '/monitoramento';
                    
                    if(searchParams.get('continue')){
                        redirect_to = searchParams.get('continue');
                    }

                    navigate(redirect_to);
                }
            }, 1000);
        } catch (error) {
            setLoading(false);

            console.log(error);

            notification['error']({
                message: 'Ocorreu um erro',
                description: error.response.data.message,
            });
        }

    }

    function checkInput(field) {
        if(field.id === 'email') {
            if( email === '' || !validEmail(email)){
                setEmailInvalid(true)
                return false
            }
            setEmailInvalid(false)
        }

        if(field.id === 'password') {
            if( password === '' ){
                setPasswordInvalid(true)
                return false
            }
            setPasswordInvalid(false)
        }
    }

    function validEmail (email) {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(email)
    }

    return (
        <div id="app">
            <Row>
                <Col className="layout_side" span={24} lg={{span: 8}}>
                    <div className="side_content">
                        <div className="logo">
                            <img src={logo} alt="" />
                        </div>
                        <Form layout="vertical" onFinish={handleSubmit}>
                            <Row>
                                <Col className="no-padding" style={{marginBottom:'20px', marginTop: '15px'}}>
                                    <h2 className="section-subtitle">Informe seus dados abaixo</h2>
                                </Col>
                                <Col 
                                    span={24}
                                    className="form-item"
                                    style={{ paddingLeft: 0, paddingRight: 0 }}
                                >
                                    <Form.Item
                                        label="Email:"
                                        required={true}
                                        hasFeedback
                                        validateStatus={`${email_invalid ? 'error' : ''}`}
                                        help={`${email_invalid ? 'Informe um e-mail válido' : ''}`}
                                    >
                                        <Input
                                            id="email"
                                            size="large"
                                            value={ email }
                                            onChange={ event => setEmail(event.target.value) }
                                            onKeyUp={ event => checkInput(event.target) }
                                            onBlur={ event => checkInput(event.target) }
                                            required
                                        />
                                    </Form.Item>
                                </Col>
                                <Col 
                                    span={24}
                                    className="form-item"
                                    style={{ paddingLeft: 0, paddingRight: 0 }}
                                >
                                    <Form.Item
                                        label="Senha:"
                                        required={true}
                                        hasFeedback
                                        validateStatus={`${password_invalid ? 'error' : ''}`}
                                        help={`${password_invalid ? 'Campo obrigatório' : ''}`}
                                    >
                                        <Input.Password
                                            id="password"
                                            size="large"
                                            value={ password }
                                            onChange={ event => setPassword(event.target.value) }
                                            onKeyUp={ event => checkInput(event.target) }
                                            onBlur={ event => checkInput(event.target) }
                                            required
                                        />
                                    </Form.Item>
                                </Col>
                                <Col className="form-line" span={24}>
                                    <Link to="/recuperar-senha">Esqueci minha senha</Link>
                                </Col>
                                <Col className="form-line" span={24}>
                                    <Spin spinning={loading}>
                                        <Button 
                                            htmlType="submit"
                                            type="success"
                                            size="large"
                                            className="submit-button"
                                            disabled={(
                                                email_invalid ||
                                                password_invalid || 
                                                !password ||
                                                !email
                                            )}
                                        >
                                            Entrar
                                        </Button>
                                    </Spin>
                                </Col>
                                <Col className="form-line" style={{textAlign: 'center'}} span={24}>
                                    <Link to="/cadastre-se">Não tem uma conta? Experimente grátis!</Link>
                                </Col>
                            </Row>
                        </Form> 
                    </div>
                </Col>
                <Col className="layout_side cover_side" lg={{span: 16}}>
                    <div className="cover" style={{backgroundImage: 'url('+cover_img+')'}}></div>
                </Col>
            </Row>
        </div>
    )
}