import React from 'react';
import { 
    Layout,
    Result,
    Button,
} from 'antd';
import { 
    ArrowLeftOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';

import 'antd/dist/antd.css';

export default function NotFound() {
    return (
        <Layout className="layout">
            <Result
                status="404"
                title="404"
                subTitle="
                    Oops.. Essa página não existe.
                    Verifique se o endereço foi digitado corretamente. 
                "
                extra={
                    <Link to="/entrar">
                        <Button type="primary" icon={<ArrowLeftOutlined />}>
                            Voltar para o ínicio
                        </Button>
                    </Link>
                }
            />
        </Layout>
    )
}