import React, { useState, useEffect } from 'react';
import {
    Row,
    Col,
    Result,
} from 'antd';import { 
    LoadingOutlined
} from '@ant-design/icons';

import 'antd/dist/antd.css';
import './styles/Main.css';
import './styles/Login.css';

import logo from '../assets/logo.png';

import api from '../services/api.js';
import { useParams, useNavigate } from 'react-router-dom';

export default function ConfirmAccount() {
    const [loading, setLoading] = useState(true);
    
    let params = useParams();
    let navigate = useNavigate();

    const token = params.token;

    useEffect(() => {
        sendConfirmation();
    }, []);

    async function sendConfirmation() {
        const response = await api.post('/auth/confirm', {
            token,
        });

        if(response.data.success) {
            navigate('/monitoramento');
        }
    }

    return (
        <div id="app">
            <Row>
                <Col span={24}>
                    <div className="logo" style={{ textAlign:'center', marginTop:'50px' }}>
                        <img src={logo} alt="" />
                    </div>
                    <Result
                        icon={<LoadingOutlined style={{ fontSize: 70 }} spin />}
                        title="Aguarde..."
                        extra={[<>
                            <p style={{fontSize: '20px'}}>Estamos confirmando seu email. Você será redirecionado automaticamente.</p>,
                        </>]}
                    />
                </Col>
            </Row>
        </div>
    )
}