import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import {
    Layout,
    Form,
    Input,
    Button,
    Row,
    Col,
    Select,
    message,
    Modal,
    Card,
} from 'antd';
import InputMask from 'react-input-mask';
import { NumericFormat } from 'react-number-format';
import { parseISO, format } from 'date-fns';

import Cards from 'react-credit-cards';

import Topbar from '../components/Topbar';
import Footbar from '../components/Footbar';
import Loading from '../components/Loading';
import SettingsSidebar from '../components/SettingsSidebar';

import 'react-credit-cards/es/styles-compiled.css';

import 'antd/dist/antd.css';
import './styles/Main.css';

import api from '../services/api.js';
import external_api from '../services/external_api.js';

import calendar_svg from '../assets/calendar.svg';

const { Search } = Input;
const { Content } = Layout;
const { confirm } = Modal;
const { Option } = Select;

export default function Users() {
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [visibleModalBillingData, setVisibleModalBillingData] = useState(false);
    const [visibleModalPaymentForm, setVisibleModalPaymentForm] = useState(false);
    const [loading_zip_code, setLoadingZipCode] = useState(false);
    
    const [focus, setFocus] = useState('');
    const [prev_card, setPrevCard] = useState({});
    
    const [summary, setSummary] = useState({});
    const [billing_plans_list, setBillingPlansList] = useState([]);
    const [selected_billing_plan, setSelectedBillingPlan] = useState({});

    const [coupon, setCoupon] = useState('');
    const [billing_status, setBillingStatus] = useState('active');
    const [email, setEmail] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [address, setAddress] = useState('');
    const [district, setDistrict] = useState('');
    const [zip_code, setZipCode] = useState('');
    const [complement, setComplement] = useState('');
    const [state, setState] = useState('');
    const [number, setNumber] = useState(null);
    const [card_name, setCardName] = useState('');
    const [card_number, setCardNumber] = useState('');
    const [exp_month, setExpMonth] = useState(null);
    const [exp_year, setExpYear] = useState(null);
    const [cpf_cnpj, setCpfCnpj] = useState('');
    const [period_start, setPeriodStart] = useState('');
    const [period_end, setPeriodEnd] = useState('');
    const [billing_plan, setBillingPlan] = useState({});
    const [name_social_reason, setNameSocialReason] = useState('');
    const [cvc, setCvc] = useState('');
    const [client_stripe_id, setClientStripeId] = useState('');
    const [payment_stripe_id, setPaymentStripeId] = useState('');
    const [subscription_stripe_id, setSubscriptionStripeId] = useState('');
    const [cancelled_at, setCancelledAt] = useState(null);
    const [next_billing_value, setNextBillingValue] = useState(0);
    const [date_today, setDateToday] = useState(new Date());

    useEffect(() => {
        loadData();
    }, []);

    async function loadData() {
        let response = await api.get('/billing');
        let summary = response.data.summary;
        let customer = response.data.data.customer;
        let billing = response.data.data.billing;

        let next_billing_value_formatted = insertDecimal(customer.next_billing_value);

        setNextBillingValue(next_billing_value_formatted);
        
        setSummary(summary);

        setEmail(billing.email);
        setCity(billing.city);
        setCountry(billing.country);
        setAddress(billing.address);
        setDistrict(billing.district);
        setZipCode(billing.zip_code);
        setComplement(billing.complement);
        setState(billing.state);
        setNumber(billing.number);
        setCardName(billing.card_name);
        setCardNumber(billing.card_number ? billing.card_number : '');
        setExpMonth(("0" + billing.exp_month).slice(-2));
        setExpYear(billing.exp_year);
        setCpfCnpj(billing.cpf_cnpj);
        setPeriodStart(billing.period_start);
        setPeriodEnd(billing.period_end);
        setBillingPlan(billing.billing_plan);
        setNameSocialReason(billing.name_social_reason);
        setClientStripeId(billing.client_stripe_id);
        setPaymentStripeId(billing.payment_stripe_id);
        setSubscriptionStripeId(billing.subscription_stripe_id);
        setCancelledAt(billing.cancelled_at);
        setBillingStatus(billing.status);
        setPrevCard({
            'card_number': billing.card_number ? billing.card_number : '',
            'exp_month': ("0" + billing.exp_month).slice(-2),
            'exp_year': ("0" + billing.exp_year).slice(-2),
            'card_name': billing.card_name,
        });

        setSelectedBillingPlan(billing.billing_plan);

        response = await api.get('/billing_plans');
        let billing_plans = response.data.data;
        setBillingPlansList(billing_plans); 

        setLoading(false);
    }

    function insertDecimal(num) {
        let total = (num / 100).toFixed(2);
        return total*1;
    }

    function formatDate(date){
        if(date !== null && date !== '' && date !== '0000-00-00'){
            date = parseISO(date);
            return format(date, 'dd/MM/yyyy');
        } else {
            return '-';
        }
    }

    async function searchZipCode() {
        setLoadingZipCode(true);

        try {
            let response = await external_api.get(`https://brasilapi.com.br/api/cep/v1/${zip_code.replace('-', '')}`);

            setLoadingZipCode(false);

            response = response.data;

            setNumber('');
            setComplement('');
            setAddress(response.street);
            setDistrict(response.neighborhood);
            setCity(response.city);
            setState(response.state);
            setCountry('BR');
        } catch (error) {
            setLoadingZipCode(false);
        }
    }

    async function saveBillingPaymentMethod() {
        setSaving(true);

        try {
            let response = [];
            const data = { 
                card_number,
                card_name,
                cvc,
                exp_month,
                exp_year 
            };
            response = await api.put('/billing/payment_methods', data);
            
            if(response.data.success) {
                message.success('Método de pagamento salvo com sucesso');
            } else {
                message.error('Ocorreu um erro, aguarde alguns instantes e tente novamente');
            }

            await loadData();
            setSaving(false);
            setVisibleModalPaymentForm(false);
        } catch (error) {
            message.error('Ocorreu um erro, aguarde alguns instantes e tente novamente');
            setSaving(false);
        }
    }

    async function saveBillingData() {
        setSaving(true);

        try {
            let response = [];
            
            const data = {
                email,
                cpf_cnpj,
                name_social_reason,
                city,
                country,
                address,
                number, 
                district,
                complement,
                zip_code,
                state
            };
            response = await api.put('/billing', data);
            
            if(response.data.success) {
                message.success('Dados salvos com sucesso');
            } else {
                message.error('Ocorreu um erro, aguarde alguns instantes e tente novamente');
            }

            await loadData();
            setSaving(false);
            setVisibleModalBillingData(false);
        } catch (error) {
            message.error('Ocorreu um erro, aguarde alguns instantes e tente novamente');
            setSaving(false);
        }
    }

    function cancelPlan() {
        confirm({
            title: 'Você tem certeza?',
            content: 'Você receberá o estorno proporcional ao período não utilizado do plano e perderá o acesso a todas as funcionalidades imediatamente.',
            okText: 'Cancelar Plano',
            okType: 'danger',
            cancelText: 'Fechar',
            async onOk() {
                let response = await api.delete(`/billing`);

                if(response.data.success) {
                    message.success('Plano cancelado com sucesso.');
                } else {
                    message.error('Ocorreu um erro, aguarde alguns instantes e tente novamente');
                }

                await loadData();
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    let loading_screen = '';
    if(loading){
        loading_screen = <Loading />
    }

    return (
        <Layout className="layout">
            { loading_screen }
            <Topbar />

            <Content className="fbm-main">
                <div id="main">
                    <Row>
                        <Col span={5} style={{ padding: '0 10px 10px 10px' }}>
                            <SettingsSidebar actived="billing" />
                        </Col>
                        <Col span={19}>
                            <Row>
                                <Col span={24} style={{ padding: '0 10px 10px 0' }}>
                                    <Card className="fbm-card" loading={loading}>
                                        <Row>
                                            <Col span={4} style={{ textAlign: 'center' }}>
                                                <img draggable="false" src={calendar_svg} width="150" alt="Configurações" />
                                            </Col>
                                            <Col span={13} style={{ paddingLeft: '25px' }}>
                                                <Row>
                                                    <Col span={24} style={{ paddingLeft: '25px' }}>
                                                        <h5 className="page-title">
                                                            Plano { billing_plan.title } 
                                                        </h5>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={8} style={{ paddingLeft: '25px' }}>
                                                        <p>
                                                            Próxima renovação:<br/>
                                                            <strong>{ formatDate(period_end) }</strong>
                                                        </p>
                                                    </Col>
                                                    <Col span={16} style={{ paddingLeft: '25px' }}>
                                                        <p>
                                                            Status:<br/>
                                                            <strong>{ (cancelled_at) ? `Cancelado em ${formatDate(period_end)}` : (billing_status == 'active' || billing_status == 'paid' || billing_status == 'trialing') ? 'Ativo' : `Cancelado em ${formatDate(period_end)}` }</strong>
                                                        </p>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={8} style={{ paddingLeft: '25px' }}>
                                                        <p>
                                                            Próxima cobrança:<br/>
                                                            <strong>
                                                                R$ <NumericFormat 
                                                                    value={next_billing_value}
                                                                    displayType={'text'}
                                                                    thousandSeparator="."
                                                                    decimalSeparator=","
                                                                    decimalScale={2} 
                                                                    fixedDecimalScale={true}
                                                                />
                                                            </strong>
                                                        </p>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={24} style={{ paddingLeft: '25px' }}>
                                                        <Link to="/configuracoes/assinatura/alterar">
                                                            <Button 
                                                                type="primary"
                                                                style={{ padding: '6px 40px', marginTop: '15px' }}
                                                                disabled={loading} 
                                                            >
                                                                Alterar Plano
                                                            </Button>
                                                        </Link>
                                                        <Button 
                                                            type="danger"
                                                            style={{ padding: '6px 40px', marginTop: '15px', marginLeft: '15px' }}
                                                            onClick={event => cancelPlan()}
                                                            disabled={loading || cancelled_at || (billing_plan.type === 'trial')} 
                                                        >
                                                            Cancelar Plano
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Col>


                                            <Col span={7} style={{ padding: '0 0 10px 10px' }}>
                                                <Row>
                                                    <Col span={24} style={{ paddingRight: '10px' }}>
                                                        <h5 className="page-title">{`Recursos - ${format(date_today, "MM/yyyy")}`}</h5>
                                                    </Col>
                                                    <Col span={24} >
                                                        <p>
                                                            Usuários: <strong>{("00" + summary.users).slice(-2)}/{("00" + billing_plan.users_limit).slice(-2)}</strong>
                                                        </p>
                                                        <p>
                                                            Produtos: <strong>{("000" + summary.products).slice(-3)}/{billing_plan.products_limit > 0 ? ("0" + billing_plan.products_limit).slice(-3) : 'ilimitado'}</strong>
                                                        </p>
                                                        <p>
                                                            Serviços: <strong>{("000" + summary.services).slice(-3)}/{billing_plan.services_limit > 0 ? ("0" + billing_plan.services_limit).slice(-3) : 'ilimitado'}</strong>
                                                        </p>
                                                        <p>
                                                            Contas a pagar(mês): <strong>{("000" + summary.bills_to_pay).slice(-3)}/{billing_plan.bills_to_pay_limit > 0 ? ("0" + billing_plan.bills_to_pay_limit).slice(-3) : 'ilimitado'}</strong>
                                                        </p>
                                                        <p>
                                                            Contas a receber(mês): <strong>{("000" + summary.bills_to_receive).slice(-3)}/{billing_plan.bills_to_receive_limit > 0 ? ("0" + billing_plan.bills_to_receive_limit).slice(-3) : 'ilimitado'}</strong>
                                                        </p>
                                                        <p>
                                                            Arquivos: <strong>{("0000" + summary.files).slice(-4)}/{billing_plan.files_limit > 0 ? billing_plan.files_limit : 'ilimitado'}</strong>
                                                        </p>                                                            
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>




                            <Row>
                                <Col span={24} style={{ padding: '0 0 10px 0' }}>
                                    <Card className="fbm-card" loading={loading}>
                                        <Row>
                                            <Col span={10} style={{ textAlign: 'center', paddingTop:'10px' }}>
                                                <Cards
                                                    cvc=""
                                                    expiry={prev_card.exp_month+'/'+prev_card.exp_year}
                                                    focused=""
                                                    name={prev_card.card_name}
                                                    number={prev_card.card_number}
                                                />
                                            </Col>
                                            <Col span={14} style={{ paddingRight: '10px' }}>
                                                <Row>
                                                    <Col span={24} style={{ paddingRight: '10px' }}>
                                                        <h5 className="page-title">Cobrança</h5>
                                                    </Col>
                                                    <Col span={24} >
                                                        <p>
                                                            Responsável:<br/>
                                                            <strong>{ name_social_reason }</strong>
                                                        </p>
                                                        <p>
                                                            CPF/CNPJ:<br/>
                                                            <strong>{ cpf_cnpj }</strong>
                                                        </p>
                                                    </Col>
                                                    <Col span={24} style={{ paddingTop: '17px' }}>
                                                        <Button 
                                                            type="primary"
                                                            style={{ padding: '6px 40px' }}
                                                            onClick={event => {
                                                                setCvc("");
                                                                setCardName("");
                                                                setCardNumber("");
                                                                setExpMonth("01");
                                                                setExpYear("2021");
                                                                setVisibleModalPaymentForm(true);
                                                            }}
                                                            disabled={loading} 
                                                        >
                                                            Atualizar Pagamento
                                                        </Button>
                                                        <Button 
                                                            type="secondary-default"
                                                            style={{ padding: '6px 40px', marginLeft: '10px' }}
                                                            onClick={event => setVisibleModalBillingData(true)}
                                                            disabled={loading} 
                                                        >
                                                            Dados de Cobrança
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Content>

            <Footbar />

            {/* BILLING DATA MODAL */}
            <Modal
                title="Informações de cobrança"
                visible={visibleModalBillingData}
                centered
                footer={[
                    <Button 
                        key="back"
                        onClick={() => setVisibleModalBillingData(false)}
                    >
                        Cancelar
                    </Button>,
                    <Button 
                        key="submit"
                        type="primary"
                        loading={saving}
                        onClick={ () => saveBillingData() }
                    >
                        Salvar
                    </Button>,
                ]}
                // onOk={this.handleOk}
                onCancel={() => setVisibleModalBillingData(false)}
            >
                <Row>
                    <Col span={24}>
                        <p>Preenchemos os dados com base no cadastro da empresa. Por favor, revise, pois as informações serão utilizadas na emissão da nota fiscal.</p>
                    </Col>


                    <Col span={24}>
                        <Form.Item
                            className="form-item"
                            label="Email:"
                            required={true}
                            hasFeedback
                            validateStatus={`${email === '' ? 'error' : ''}`}
                        >
                            <Input
                                id="name_social_reason"
                                value={ email }
                                disabled={saving}
                                onChange={ event => setEmail(event.target.value) }
                                required
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            className="form-item"
                            label="CPF / CNPJ:"
                            required={true}
                            hasFeedback
                            validateStatus={`${cpf_cnpj === '' ? 'error' : ''}`}
                        >
                            <Input
                                id="cpf_cnpj"
                                value={ cpf_cnpj }
                                disabled={saving}
                                onChange={ event => setCpfCnpj(event.target.value) }
                                required
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            className="form-item"
                            label="Nome / Razão social:"
                            required={true}
                            hasFeedback
                            validateStatus={`${name_social_reason === '' ? 'error' : ''}`}
                        >
                            <Input
                                id="name_social_reason"
                                value={ name_social_reason }
                                disabled={saving}
                                onChange={ event => setNameSocialReason(event.target.value) }
                                required
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            className="form-item"
                            label="CEP:"
                            required={true}
                        >
                            <InputMask 
                                mask="99999-999"
                                alwaysShowMask={false}
                                placeholder="_____-___"
                                id="zip_code"
                                value={ zip_code }
                                onChange={ event => setZipCode(event.target.value) }
                                disabled={saving}
                            >
                                {(inputProps) => 
                                    <Search
                                        {...inputProps}
                                        onSearch={ value => searchZipCode() }
                                        loading={ loading_zip_code }
                                    />
                                }
                            </InputMask>
                        </Form.Item>
                    </Col>
                    <Col span={16}>
                        <Form.Item
                            className="form-item"
                            label="Endereço:"
                            required={true}
                            hasFeedback
                            validateStatus={`${address === '' ? 'error' : ''}`}
                        >
                            <Input
                                id="address"
                                value={ address }
                                disabled={saving}
                                onChange={ event => setAddress(event.target.value) }
                                required
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            className="form-item"
                            label="Número:"
                            required={true}
                            hasFeedback
                            validateStatus={`${number === '' ? 'error' : ''}`}
                        >
                            <Input
                                id="number"
                                value={ number }
                                disabled={saving}
                                onChange={ event => setNumber(event.target.value) }
                                required
                            />
                        </Form.Item>
                    </Col>
                    <Col span={16}>
                        <Form.Item
                            className="form-item"
                            label="Complemento:"
                        >
                            <Input
                                id="complement"
                                value={ complement }
                                disabled={saving}
                                onChange={ event => setComplement(event.target.value) }
                                required
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            className="form-item"
                            label="Bairro:"
                            required={true}
                            hasFeedback
                            validateStatus={`${district === '' ? 'error' : ''}`}
                        >
                            <Input
                                id="district"
                                value={ district }
                                disabled={saving}
                                onChange={ event => setDistrict(event.target.value) }
                                required
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            className="form-item"
                            label="Cidade:"
                            required={true}
                            hasFeedback
                            validateStatus={`${city === '' ? 'error' : ''}`}
                        >
                            <Input
                                id="city"
                                value={ city }
                                disabled={saving}
                                onChange={ event => setCity(event.target.value) }
                                required
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Modal>


            {/* CARD MODAL */}
            <Modal
                title="Forma de Pagamento"
                visible={visibleModalPaymentForm}
                centered
                footer={[
                    <Button 
                        key="back"
                        onClick={() => setVisibleModalPaymentForm(false)}
                    >
                        Cancelar
                    </Button>,
                    <Button 
                        key="submit"
                        type="primary"
                        loading={saving}
                        onClick={ () => saveBillingPaymentMethod() }
                    >
                        Salvar
                    </Button>,
                ]}
                // onOk={this.handleOk}
                onCancel={() => setVisibleModalPaymentForm(false)}
            >
                <Row>
                    <Col span={24} style={{marginBottom: '15px'}}>
                        <Cards
                            cvc={cvc}
                            expiry={exp_month+'/'+exp_year}
                            focused={focus}
                            name={card_name}
                            number={card_number}
                        />
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            className="form-item"
                            label="Número do cartão:"
                            required={true}
                            hasFeedback
                            validateStatus={`${card_number === '' ? 'error' : ''}`}
                        >
                            <InputMask 
                                mask="9999 9999 9999 9999"
                                alwaysShowMask={false}
                                placeholder="____ ____ ____ ____"
                                id="card_number"
                                name="card_number"
                                value={ card_number }
                                disabled={saving}
                                onChange={ event => setCardNumber(event.target.value) }
                                // onKeyUp={ event => checkInput(event.target) }
                                // onBlur={ event => checkInput(event.target) }
                                onFocus={event => setFocus(event.target.name)}
                            >
                                {(inputProps) => 
                                    <Input 
                                        {...inputProps} 
                                        type="text"
                                        required
                                    />
                                }
                            </InputMask>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            className="form-item"
                            label="Nome impresso no cartão:"
                            required={true}
                            hasFeedback
                            validateStatus={`${card_name === '' ? 'error' : ''}`}
                        >
                            <Input
                                id="card_name"
                                name="card_name"
                                value={ card_name }
                                disabled={saving}
                                onChange={ event => setCardName(event.target.value) }
                                onFocus={event => setFocus(event.target.name)}
                                required
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            className="form-item"
                            label="Mês de validade:"
                            required={true}
                        >
                            <Select 
                                value={ exp_month }
                                disabled={saving}
                                style={{ width: 120 }} 
                                onChange={ (value) => setExpMonth(value) }
                                onFocus={event => setFocus('expiry')}
                            >
                                <Option value="01" >Janeiro</Option>
                                <Option value="02" >Fevereiro</Option>
                                <Option value="03" >Março</Option>
                                <Option value="04" >Abril</Option>
                                <Option value="05" >Maio</Option>
                                <Option value="06" >Junho</Option>
                                <Option value="07" >Julho</Option>
                                <Option value="08" >Agosto</Option>
                                <Option value="09" >Setembro</Option>
                                <Option value="10" >Outubro</Option>
                                <Option value="11" >Novembro</Option>
                                <Option value="12" >Dezembro</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            className="form-item"
                            label="Ano de Validade:"
                            required={true}
                        >
                            <Select 
                                value={ exp_year }
                                disabled={saving}
                                style={{ width: 120 }} 
                                onChange={ (value) => setExpYear(value) }
                                onFocus={event => setFocus('expiry')}
                            >
                                <Option value="2021" >2021</Option>
                                <Option value="2022" >2022</Option>
                                <Option value="2023" >2023</Option>
                                <Option value="2024" >2024</Option>
                                <Option value="2025" >2025</Option>
                                <Option value="2026" >2026</Option>
                                <Option value="2027" >2027</Option>
                                <Option value="2028" >2028</Option>
                                <Option value="2029" >2029</Option>
                                <Option value="2030" >2030</Option>
                                <Option value="2031" >2031</Option>
                                <Option value="2032" >2032</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            className="form-item"
                            label="Cód. Segurança:"
                            required={true}
                            hasFeedback
                            validateStatus={`${cvc === '' ? 'error' : ''}`}
                        >
                            <Input
                                id="cvc"
                                name="cvc"
                                value={ cvc }
                                disabled={saving}
                                onChange={ event => setCvc(event.target.value) }
                                onFocus={event => setFocus(event.target.name)}
                                maxLength={4}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Modal>
        </Layout>
    )
}