import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { 
    Layout,
    Button,
    Tooltip,
    message,
} from 'antd';
import { MailOutlined, CalendarOutlined } from '@ant-design/icons';

import './style.css';

import api from '../../services/api';
import { listPermissions } from '../../services/auth';
import { isAuthenticated, logout } from '../../services/auth';

function Alertbar() {
    const [resending, setResending] = useState(false);
    const [permissions, setPermissions] = useState({});
    const [show_email_alert, setShowEmailAlert] = useState(false);
    const [show_billing_alert, setShowBillingAlert] = useState(false);

    let params   = useParams();
    let navigate = useNavigate();

    useEffect(() => {
        async function loadPermissions(){
            setPermissions(listPermissions());
        }

        loadPermissions();
        checkAlerts();
    }, []);

    async function handleLogout() {
        logout();

        let isLogged = setInterval(() => {
            if(!isAuthenticated()) {
                clearInterval(isLogged);

                navigate('/entrar');
            }
        }, 1000);
    }

    async function checkAlerts(){
        try {
            let response = await api.get('/accounts/alerts', null);

            if(response.data.success) {
                let alerts = response.data.data;

                setShowBillingAlert(alerts.plan_expired);
                setShowEmailAlert(alerts.unconfirmed_email);
            } else {

                console.log('Ocorreu um erro, aguarde alguns instantes e tente novamente');
            }
        } catch (error) {
            if(error.response.status == 401){
                handleLogout();
            }
            console.log(error.response.status+' - Ocorreu um erro, aguarde alguns instantes e tente novamente');
        }
    }

    async function resendEmail(){
        setResending(true);
        try {
            let response = await api.post('/auth/confirm/resend_email', null);

            if(response.data.success) {
                message.success('O link de confirmação foi enviado para o seu email!');
            } else {
                message.error('Ocorreu um erro, aguarde alguns instantes e tente novamente');
            }
            setResending(false);
        } catch (error) {
            message.error('Ocorreu um erro, aguarde alguns instantes e tente novamente');
            setResending(false);
        }
    }

    return (
        <>
            {/* EMAIL CONFIRMATION ALERT */}
            { !show_billing_alert && show_email_alert && <>
                <div className="alertbar alertbar-red">
                    <MailOutlined className="alertbar-icon" />
                    <h6>SEU EMAIL AINDA NÃO FOI CONFIRMADO!</h6>
                    <p>Sabemos que essa mensagem é feia e chata, mas precisamos que acesse seu e-mail e confirme o seu cadastro.</p>
                    { permissions.account_edit && 
                        <Button
                            onClick={() => resendEmail()} loading={ resending } className="alertbar-button"
                        >
                                Receber o e-mail novamente
                        </Button>
                    }
                </div>
            </>}

            {/* BILLING PLAN EXPIRED ALERT */}
            { show_billing_alert && <>
                <div className="alertbar alertbar-red">
                    <CalendarOutlined className="alertbar-icon" />
                    <h6>SUA ASSINATURA CHEGOU AO FIM!</h6>
                    <p>Seu plano chegou ao fim e não foi renovado, por isso seu uso foi restrito e você não terá acesso a todas as funcionalidades.</p>
                    { permissions.account_edit && 
                        <Link to="/configuracoes/assinatura">
                            <Button className="alertbar-button">
                                Atualizar plano
                            </Button>
                        </Link>
                    }
                </div>
            </>}
        </>
    );
}

export default Alertbar;