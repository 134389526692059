import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import { 
    Layout,
    Form,
    Breadcrumb,
    Input,
    Button,
    Row,
    Col,
    Select,
    message,
    Modal,
    Card,
    Checkbox,
    Tooltip,
} from 'antd';

import { 
    InfoCircleOutlined
} from '@ant-design/icons';

import Topbar from '../components/Topbar';
import Footbar from '../components/Footbar';
import Loading from '../components/Loading';
import CategoriesList from '../components/CategoriesList';
import SettingsSidebar from '../components/SettingsSidebar';

import 'antd/dist/antd.css';
import './styles/Main.css';

import api from '../services/api.js';

const { Content } = Layout;
const { confirm } = Modal;
const { Option } = Select;

export default function FinancialCategories() {
    const [loading, setLoading] = useState(true);
    const [loading_category, setLoadingCategory] = useState(false);
    const [saving, setSaving] = useState(false);
    const [visibleModal, setVisibleModal] = useState(false);

    const [allow_children, setAllowChildren] = useState(true);

    const [expense_categories, setExpenseCategories] = useState([]);
    const [revenue_categories, setRevenueCategories] = useState([]);

    const [dre_expense_associates, setDreExpenseCategories] = useState([]);
    const [dre_revenue_associates, setDreRevenueCategories] = useState([]);
    
    const [id, setId] = useState('');
    const [parent_id, setParentId] = useState('');
    const [description, setDescription] = useState('');
    const [type, setType] = useState('');
    const [dre_category, setDreCategory] = useState('');
    const [cmv_category, setCMVCategory] = useState('N');

    useEffect(() => {
        loadData();
    }, []);

    async function loadData(){
        let response = await api.get(`/financial_categories`);
        let categories = response.data.data;

        response = await api.get(`/dre_categories`);
        let dre_categories = response.data.data;

        setExpenseCategories(categories.filter(category => category.type === 'EXPENSE'));
        setRevenueCategories(categories.filter(category => category.type === 'REVENUE'));

        setDreExpenseCategories(dre_categories.filter(category => category.type === 'EXPENSE'));
        setDreRevenueCategories(dre_categories.filter(category => category.type === 'REVENUE'));
        
        setLoading(false);
    }

    function newCategory(type = '', parent_id = '') {
        setId('');
        setParentId(parent_id);
        setDescription('');
        setType(type);
        setDreCategory('');
        setCMVCategory('N');
        setAllowChildren(true);

        setVisibleModal(true);
    }

    async function showCategory(type, category_id) {
        setLoadingCategory(true);

        setType(type);

        let selected_category = '';
        let categories_list = [];

        if(type === 'EXPENSE') {
            categories_list = expense_categories;
        } else if (type === 'REVENUE') {
            categories_list = revenue_categories;
        }

        categories_list.forEach(category => {
            if (category.id === category_id) {
                selected_category = category;
                return true;
            }

            category.children.forEach(children => {
                if (children.id === category_id) {
                    selected_category = children;
                    return true;
                }
            });
        });

        if(selected_category.parent_id || !selected_category.children) {
            setAllowChildren(true);
        } else {
            if(selected_category.children[0]){
                setAllowChildren(false);
            } else {
                setAllowChildren(true);
            }
        }

        setId(selected_category.id);
        setParentId(selected_category.parent ? selected_category.parent.id : '');
        setDescription(selected_category.description);
        setDreCategory(selected_category.dre_category ? selected_category.dre_category.id : null);
        setCMVCategory(selected_category.cmv_category);

        setVisibleModal(true);
        setLoadingCategory(false);
    }

    async function saveCategory() {
        setSaving(true);

        const data = {
            description,
            type,
            cmv_category,
            dre_category_id: dre_category ? dre_category : null,
            parent_id: parent_id ? parent_id : null,
        };

        try {
            let response = [];

            if(id)
                response = await api.put(`/financial_categories/${id}`, data);
            else
                response = await api.post('/financial_categories', data);

            if(response.data.success) {
                message.success('Categoria salva com sucesso');
            } else {
                message.error('Ocorreu um erro, aguarde alguns instantes e tente novamente');
            }

            await loadData();
            setSaving(false);
            setVisibleModal(false);
        } catch (error) {
            message.error('Ocorreu um erro, aguarde alguns instantes e tente novamente');
            setSaving(false);
        }
    }

    function deleteCategory(category_id) {
        console.log(category_id);
        confirm({
            title: 'Você tem certeza?',
            content: 'Esta ação não poderá ser desfeita!',
            okText: 'Deletar',
            okType: 'danger',
            cancelText: 'Cancelar',
            async onOk() {
                let response = await api.delete(`/financial_categories/${category_id}`);

                if(response.data.success) {
                    message.success('Categoria deletada com sucesso');
                } else {
                    message.error('Ocorreu um erro, aguarde alguns instantes e tente novamente');
                }

                await loadData();
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    let loading_screen = '';
    if(loading){
        loading_screen = <Loading />
    }

    return (
        <Layout className="layout">
            { loading_screen }
            <Topbar />

            <Content className="fbm-main">
                <div id="main">
                    <Row style={{ marginLeft: '-10px', marginRight: '-10px' }}>
                        <Col span={5} style={{ padding: '0 10px 10px 10px' }}>
                            <SettingsSidebar actived="financial_categories" />
                        </Col>
                        <Col span={19} style={{ padding: '0 10px 10px 10px' }}>
                            <Card loading={loading} className="fbm-card fbm-page">

                                <Row style={{borderBottom:'1px solid #c9d3dd'}}>
                                    <Col span={16}>
                                        <h1 style={{fontSize:'26px', fontWeight:'600', color:'#395a7a'}}>
                                            Categorias de Receita
                                        </h1>
                                    </Col>
                                    <Col span={8} style={{textAlign:'right'}}>
                                        <Button 
                                            type="primary"
                                            size="large"
                                            onClick={() => newCategory('REVENUE')}
                                        >
                                            Nova categoria de Receita
                                        </Button>
                                    </Col>
                                </Row>

                                { revenue_categories.map ( category => (
                                    <CategoriesList 
                                        type="REVENUE"
                                        category={category}
                                        new={newCategory}
                                        show={showCategory}
                                        delete={deleteCategory}
                                    />
                                )) }
                                
                                <Row style={{ paddingBottom:'20px', marginTop: '25px',borderBottom:'1px solid #c9d3dd' }}>
                                    <Col span={16}>
                                        <h1 style={{fontSize:'26px', fontWeight:'600', color:'#395a7a'}}>
                                            Categorias de Despesa
                                        </h1>
                                    </Col>
                                    <Col span={8} style={{textAlign:'right'}}>
                                        <Button 
                                            type="primary"
                                            size="large"
                                            onClick={() => newCategory('EXPENSE')}
                                        >
                                            Nova categoria de Despesa
                                        </Button>
                                    </Col>
                                </Row>

                                { expense_categories.map ( category => (
                                    <CategoriesList 
                                        type="EXPENSE"
                                        category={category}
                                        new={newCategory}
                                        show={showCategory}
                                        delete={deleteCategory}
                                    />
                                )) }
                                
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Content>

            <Footbar />


            <Modal title={type === 'REVENUE' ? 'Categoria de Receitas' : 'Categoria de Despesas'} visible={visibleModal} centered
                footer={[
                    <Button key="back"
                        onClick={() => setVisibleModal(false)}
                    >
                        Cancelar
                    </Button>,
                    <Button key="submit"
                        type="success"
                        loading={saving}
                        onClick={ () => saveCategory() }
                    >
                        Salvar
                    </Button>,
                ]}
                onCancel={() => setVisibleModal(false)}
            >
                <Row>
                    <Col span={12}>
                        <Form.Item
                            className="form-item"
                            label="Descrição:"
                            required={true}
                            style={{ paddingLeft: 0 }}
                        >
                            <Input
                                id="description"
                                value={ description }
                                onChange={ event => setDescription(event.target.value) }
                                required
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            className="form-item"
                            label="Aparecer dentro de:"
                            style={{ paddingRight: 0 }}
                        >
                            <Select 
                                disabled={!allow_children}
                                value={ parent_id } 
                                style={{ width: 120 }} 
                                onChange={ (value) => setParentId(value) }
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                { type === 'REVENUE' && <Option value="" >Receitas</Option>}
                                { type === 'REVENUE' && revenue_categories.map(category => {
                                    if(category.id != id){
                                        return <Option
                                            key={category.id}
                                            value={ category.id }
                                        >
                                            { category.description }
                                        </Option>
                                    }
                                }) }
                                { type === 'EXPENSE' && <Option value="" >Despesas</Option>}
                                { type === 'EXPENSE' && expense_categories.map(category => {
                                    if(category.id != id){
                                        return <Option
                                            key={category.id}
                                            value={ category.id }
                                        >
                                            { category.description }
                                        </Option>
                                    }
                                }) }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                {(allow_children) && <Row>
                    <Col span={16}>
                        <Form.Item
                            className="form-item"
                            label="Associar com DRE: "
                            style={{ paddingLeft: 0 }}
                        >
                            <Select 
                                disabled={!allow_children}
                                value={ dre_category ? dre_category : '' } 
                                style={{ width: 120 }} 
                                onChange={ (value) => setDreCategory(value) }
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Option value="" >Não mostrar no DRE Gerencial</Option>
                                { type === 'REVENUE' && dre_revenue_associates.map(associate => {
                                    if(associate.id != id){
                                        return <Option
                                            key={associate.id}
                                            value={ associate.id }
                                        >
                                            { associate.description }
                                        </Option>
                                    }
                                }) }
                                { type === 'EXPENSE' && dre_expense_associates.map(associate => {
                                    if(associate.id != id){
                                        return <Option
                                            key={associate.id}
                                            value={ associate.id }
                                        >
                                            { associate.description }
                                        </Option>
                                    }
                                }) }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>}
                {/* {type === 'EXPENSE' && allow_children && <Row>
                    <Col style={{ marginTop: '15px' }}>
                        <Checkbox checked={cmv_category === "Y" ? true : false} onChange={value => setCMVCategory(value ? 'Y' : 'N')}>
                            Categoria referente a compra para estoque de matéria prima ou produtos para revenda <Tooltip title="Ao selecionar esta opção, os lançamentos não serão considerados diretamente no DRE pela categoria, e sim pelo custo do produto vendido quando gerar uma venda."><InfoCircleOutlined theme="filled" /></Tooltip>
                        </Checkbox>
                    </Col>
                </Row>} */}
            </Modal>
        </Layout>
    )
}