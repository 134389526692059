import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import { 
    Layout,
    Form,
    Breadcrumb,
    Input,
    Button,
    DatePicker,
    Row,
    Col,
    Select,
    message,
    Table,
    Modal,
    Tooltip,
    Switch,
    Empty,
} from 'antd';
import { 
    CheckOutlined,
    CloseOutlined,
    PlusOutlined,
    EditOutlined,
    DeleteOutlined
} from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/pt_BR';
import InputMask from 'react-input-mask';
import moment from 'moment';
import 'moment/locale/pt-br';
import { parseISO, format } from 'date-fns';
import CurrencyInput from 'react-currency-input';

import Topbar from '../components/Topbar';
import Footbar from '../components/Footbar';
// import Loading from '../components/Loading';

import 'antd/dist/antd.css';
import './styles/Main.css';

import api from '../services/api.js';

const validateValue = require('../utils/validateValue');
const cleanFloatValue = require('../utils/cleanFloatValue');

const { Search } = Input;
const { Content } = Layout;
const { confirm } = Modal;
const { Option } = Select;

export default function Employees() {
    const [loadingTable, setLoadingTable] = useState(true);
    const [loading_zip_code, setLoadingZipCode] = useState(false);
    const [loading_employee, setLoadingEmployee] = useState(false);
    const [saving, setSaving] = useState(false);
    const [visibleModal, setVisibleModal] = useState(false);

    const [cpf_invalid, setCpfInvalid] = useState(false);
    const [email_invalid, setEmailInvalid] = useState(false);

    const [data, setData] = useState([]);

    const [tableLength, setTableLength] = useState(10);
    const [tablePagination, setTablePagination] = useState({current: 1, pageSize: tableLength});
    const [tableFilters, setTableFilters] = useState({});
    const [tableSorter, setTableSorter] = useState({ field: 'name', order: 'ascend' });
    const [search, setSearch] = useState('');
    
    const [filter_sector, setFilterSector] = useState('');
    const [filter_date_birth_begin, setFilterDateBirthBegin] = useState('');
    const [filter_date_birth_end, setFilterDateBirthEnd] = useState('');
    const [filter_hiring_date_begin, setFilterHiringDateBegin] = useState('');
    const [filter_hiring_date_end, setFilterHiringDateEnd] = useState('');

    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [date_birth, setDateBirth] = useState('');
    const [gender, setGender] = useState('');
    const [cpf, setCpf] = useState('');
    const [zip_code, setZipCode] = useState('');
    const [address, setAddress] = useState('');
    const [district, setDistrict] = useState('');
    const [number, setNumber] = useState('');
    const [complement, setComplement] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [phone, setPhone] = useState('');
    const [phone_2, setPhone2] = useState('');
    const [emergency_contact, setEmergencyContact] = useState('');
    const [email, setEmail] = useState('');
    const [status, setStatus] = useState('');
    const [note, setNote] = useState('');
    const [hiring_date, setHiringDate] = useState('');
    const [salary, setSalary] = useState('');
    const [sector, setSector] = useState('');
    const [individual_commission, setIndividualCommission] = useState('');

    useEffect(() => {
        loadData();
    }, [tableLength]);

    async function reloadData(){
        loadData({current: 1, pageSize: tableLength});
    }

    async function loadData(
        pagination = tablePagination,
        filters = tableFilters,
        sorter = tableSorter
    ) {
        setLoadingTable(true);

        setTablePagination(pagination);
        setTableFilters(filters);
        if(!sorter.field){
            sorter = tableSorter
        }
        setTableSorter(sorter);

        let order = sorter.order;
        if(order === undefined) {
            order = 'asc';
        } else if(sorter.order === 'ascend') {
            order = 'asc';
        } else if(sorter.order === 'descend') {
            order = 'desc';
        }

        let uri = `/employees?page=${pagination.current}&per_page=${tableLength}&order=${order}&order_by=${sorter.field}&search=${search}`;
        uri += `&sector=${filter_sector}`;
        uri += `&birth_begin=${filter_date_birth_begin}&birth_end=${filter_date_birth_end}`;
        uri += `&hiring_begin=${filter_hiring_date_begin}&hiring_end=${filter_hiring_date_end}`;

        let response = await api.get(uri);

        let employees_list = response.data.data;

        setData(employees_list);
        setTablePagination({
            current: pagination.current,
            pageSize: tableLength,
            total: employees_list.total,
        });

        setLoadingTable(false);
    }

    async function searchZipCode() {
        setLoadingZipCode(true);

        let response = await api.get(`https://brasilapi.com.br/api/cep/v1/${zip_code.replace('-', '')}`);

        setLoadingZipCode(false);

        response = response.data;

        setAddress(response.logradouro);
        setDistrict(response.bairro);
        setCity(response.cidade);
        setState(response.estado);
    }

    function newEmployee() {
        setId('');
        setName('');
        setDateBirth('');
        setGender('');
        setCpf('');
        setZipCode('');
        setAddress('');
        setDistrict('');
        setNumber('');
        setComplement('');
        setState('');
        setCity('');
        setPhone('');
        setPhone2('');
        setEmergencyContact('');
        setEmail('');
        setStatus('A');
        setNote('');
        setHiringDate('');
        setSalary('');
        setSector('');
        setIndividualCommission('');

        setVisibleModal(true);
    }

    async function showEmployee(employee_id) {
        setLoadingEmployee(true);

        let response = await api.get(`/employees/${employee_id}`);

        let employee = response.data.data;

        setId(employee.id);
        setName(employee.name);
        setDateBirth(employee.date_birth);
        setGender(employee.gender);
        setCpf(employee.cpf);
        setZipCode(employee.zip_code);
        setAddress(employee.address);
        setDistrict(employee.district);
        setNumber(employee.number);
        setComplement(employee.complement);
        setState(employee.state);
        setCity(employee.city);
        setPhone(employee.phone);
        setPhone2(employee.phone_2);
        setEmergencyContact(employee.emergency_contact);
        setEmail(employee.email);
        setStatus(employee.status);
        setNote(employee.note);
        setHiringDate(employee.hiring_date);
        setSalary(employee.salary);
        setSector(employee.sector);
        setIndividualCommission(employee.individual_commission);

        setVisibleModal(true);
        setLoadingEmployee(false);
    }

    async function saveEmployee() {
        setSaving(true);

        const data = {
            name,
            date_birth,
            gender,
            cpf,
            zip_code,
            address,
            district,
            number,
            complement,
            state,
            city,
            phone,
            phone_2,
            emergency_contact,
            email,
            status,
            note,
            hiring_date,
            salary: cleanFloatValue(salary),
            sector,
            individual_commission: individual_commission,
        };

        try {
            let response = [];

            if(id)
                response = await api.put(`/employees/${id}`, data);
            else
                response = await api.post('/employees', data);

            if(response.data.success) {
                message.success('Colaborador salvo com sucesso');
            } else {
                message.error('Ocorreu um erro, aguarde alguns instantes e tente novamente');
            }

            await loadData();
            setSaving(false);
            setVisibleModal(false);
        } catch (error) {
            message.error('Ocorreu um erro, aguarde alguns instantes e tente novamente');
            setSaving(false);
        }
    }

    function deleteEmployee(employee_id) {
        confirm({
            title: 'Você tem certeza?',
            content: 'Esta ação não poderá ser desfeita!',
            okText: 'Deletar',
            okType: 'danger',
            cancelText: 'Cancelar',
            async onOk() {
                let response = await api.delete(`/employees/${employee_id}`);

                if(response.data.success) {
                    message.success('Colaborador deletado com sucesso');
                } else {
                    message.error('Ocorreu um erro, aguarde alguns instantes e tente novamente');
                }

                await loadData();
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    function checkInput(field) {
        if (field.id === 'cpf') {
            if( cpf === '' || !validateValue('cpf', cpf) ){
                setCpfInvalid(true);
                return false;
            }
            setCpfInvalid(false);
        }

        if(field.id === 'email') {
            if( email === '' || !validateValue('email', email)){
                setEmailInvalid(true);
                return false;
            }
            setEmailInvalid(false);
        }
    }

    function fixGender(gender){
        switch (gender) {
            case 'M':
                return 'Masculino';
            case 'F':
                return 'Feminino';
            default:
                return '-';
        }
    }
    function fixSector(sector){
        switch (sector) {
            case 'G':
                return 'Gerencia';
            case 'A':
                return 'Administração';
            case 'C':
                return 'Comercial';
            case 'V':
                return 'Vendas';
            default:
                return '-';
        }
    }
    function formatDate(date){
        if(date !== null && date !== '' && date !== '0000-00-00'){
            date = parseISO(date);
            return format(date, 'dd/MM/yyyy');
        } else {
            return '-';
        }
    }

    const columns = [
        {
            title: 'NOME',
            dataIndex: 'name',
            sorter: true,
            width: '15%',
        }, {
            title: 'CPF',
            dataIndex: 'cpf',
            width: '10%',
        }, {
            title: 'DATA DE NASCIMENTO',
            render: (employee => (
                <>
                    { formatDate(employee.date_birth) }
                </>
            )),
            width: '10%',
        }, {
            title: 'FUNCIONÁRIO DESDE',
            render: (employee => (
                <>
                    { formatDate(employee.hiring_date) }
                </>
            )),
            width: '10%',
        }, {
            title: 'SETOR',
            render: (employee => (
                <>
                    { fixSector(employee.sector) }
                </>
            )),
            width: '10%',
        }, {
            title: 'SEXO',
            render: (employee => (
                <>
                    { fixGender(employee.gender) }
                </>
            )),
            width: '10%',
        }, {
            title: 'EMAIL',
            dataIndex: 'email',
            width: '15%',
        }, {
            title: 'AÇÕES',
            width: '15%',
            render: (employee => (
                <>
                    <Tooltip title="Editar" placement="left">
                        <Button 
                            type="secondary-default" 
                            icon={<EditOutlined />}
                            onClick={event => showEmployee(employee.id)}
                            disabled={loading_employee}
                            style={{
                                paddingTop: '4px',
                                paddingBottom: '4px',
                                paddingLeft: '10px',
                                paddingRight: '10px',
                                width:'auto',
                            }}
                        ></Button>
                    </Tooltip>
                    <Tooltip title="Deletar" placement="right">
                        <Button 
                            type="secondary-default" 
                            icon={<DeleteOutlined />}
                            onClick={event => deleteEmployee(employee.id)}
                            disabled={loading_employee}
                            style={{ 
                                marginLeft: '5px',
                                paddingTop: '4px',
                                paddingBottom: '4px',
                                paddingLeft: '10px',
                                paddingRight: '10px',
                                width:'auto',
                                color: '#ff4d4f !important',
                            }}
                        ></Button>
                    </Tooltip>
                </>
            )),
        },
    ];

    // let loading_screen = '';
    // if(loading){
    //     loading_screen = <Loading />
    // }

    return (
        <Layout className="layout">
            {/* { loading_screen } */}
            <Topbar />

            <Content className="fbm-main">
                <div id="main">
                    <Row style={{ marginLeft: '-10px', marginRight: '-10px' }}>
                        <Col span={24} style={{ padding: '0 10px 10px 10px' }}>
                            <div className="fbm-card">
                                <Row style={{ paddingBottom: '15px', borderBottomWidth: '0.5px', borderBottomColor: '#e8e8e8', borderBottomStyle: 'solid' }}>
                                    <Col className="fbm-card-header" span={24}>
                                        <h1 className="big-title">
                                            Colaboradores
                                        </h1>
                                        <Button 
                                            type="success"
                                            onClick={newEmployee}
                                            style={{marginLeft: '15px', borderRadius: '50%'}}
                                        >
                                            <PlusOutlined />
                                        </Button>
                                    </Col>
                                </Row>
                                <Form layout='vertical'>
                                    <Row style={{ paddingBottom: '20px', borderBottomWidth: '0.5px', borderBottomColor: '#c9d3dd', borderBottomStyle: 'dashed' }}>
                                        <Col span={4}>
                                            <Form.Item
                                                className="form-item"
                                                label="Setor:"
                                                style={{ paddingLeft: '0' }}
                                            >
                                                <Select 
                                                    value={ filter_sector }
                                                    style={{ width: 120 }} 
                                                    onChange={ (value) => setFilterSector(value) }
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    <Option value="" >
                                                        Todos
                                                    </Option>
                                                    <Option value="G" >
                                                        Gerencia
                                                    </Option>
                                                    <Option value="A" >
                                                        Administração
                                                    </Option>
                                                    <Option value="C" >
                                                        Comercial
                                                    </Option>
                                                    <Option value="V" >
                                                        Vendas
                                                    </Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item
                                                className="form-item"
                                                label="Nascimento (início):"
                                            >
                                                <DatePicker
                                                    locale={locale}
                                                    showTime={false}
                                                    value={filter_date_birth_begin !== '' ? moment(filter_date_birth_begin) : ''}
                                                    format="DD/MM/YYYY"
                                                    onChange={ (date, dateString) => {
                                                        setFilterDateBirthBegin(date !== null ? format(date._d, "yyyy-MM-dd") : '');
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item
                                                className="form-item"
                                                label="Nascimento (fim):"
                                            >
                                                <DatePicker 
                                                    locale={locale}
                                                    showTime={false}
                                                    value={filter_date_birth_end !== '' ? moment(filter_date_birth_end) : ''}
                                                    format="DD/MM/YYYY"
                                                    onChange={ (date, dateString) => {
                                                        setFilterDateBirthEnd(date !== null ? format(date._d, "yyyy-MM-dd") : '');
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item
                                                className="form-item"
                                                label="Contratação (início):"
                                            >
                                                <DatePicker
                                                    locale={locale}
                                                    showTime={false}
                                                    value={filter_hiring_date_begin !== '' ? moment(filter_hiring_date_begin) : ''}
                                                    format="DD/MM/YYYY"
                                                    onChange={ (date, dateString) => {
                                                        setFilterHiringDateBegin(date !== null ? format(date._d, "yyyy-MM-dd") : '');
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item
                                                className="form-item"
                                                label="Contratação (fim):"
                                            >
                                                <DatePicker
                                                    locale={locale}
                                                    showTime={false}
                                                    value={filter_hiring_date_end !== '' ? moment(filter_hiring_date_end) : ''}
                                                    format="DD/MM/YYYY"
                                                    onChange={ (date, dateString) => {
                                                        setFilterHiringDateEnd(date !== null ? format(date._d, "yyyy-MM-dd") : '');
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item
                                                className="form-item"
                                                style={{ marginTop: '25px', paddingRight: '0' }}
                                            >
                                                <Button 
                                                    key="submit"
                                                    type="primary"
                                                    loading={loadingTable}
                                                    onClick={ () => reloadData() }
                                                    style={{ width: '100%' }}
                                                >
                                                    Filtrar
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>





                                <Row style={{ paddingTop:'20px',paddingBottom: '0' }}>
                                    <Col span={24}>
                                        <div style={{ overflow: 'auto', marginBottom:'15px' }}>
                                            <Form.Item
                                                className="form-item"
                                                style={{ 
                                                    paddingTop: 0, 
                                                    paddingRight: 0,
                                                    paddingLeft: 0,
                                                    paddingBottom: 0,
                                                    width: '100px',
                                                    float: 'left',
                                                }}
                                            >
                                                <Select 
                                                    value={tableLength} 
                                                    onChange={ async (value) => setTableLength(value) }
                                                >
                                                    <Option key="10" value="10">10</Option>
                                                    <Option key="25" value="25">25</Option>
                                                    <Option key="50" value="50">50</Option>
                                                    <Option key="100" value="100">100</Option>
                                                </Select>
                                            </Form.Item>
                                            <Form.Item
                                                className="form-item"
                                                style={{ 
                                                    paddingTop: 0,
                                                    paddingRight: 0,
                                                    paddingLeft: 0,
                                                    paddingBottom: 0,
                                                    width: '200px',
                                                    float: 'right',
                                                }}
                                            >
                                                <Search
                                                    id="search"
                                                    placeholder="Pesquisar"
                                                    value={search}
                                                    onChange={ event => setSearch(event.target.value) }
                                                    onSearch={ value => reloadData() }
                                                    loading={ loadingTable }
                                                />
                                            </Form.Item>
                                        </div>
                                    </Col>
                                    <Col span={24} >
                                        <Table
                                            bordered={true}
                                            columns={columns}
                                            rowKey={record => record.id}
                                            dataSource={data.data}
                                            pagination={tablePagination}
                                            loading={loadingTable}
                                            onChange={loadData}
                                            pageSize={tableLength}
                                            locale={{ emptyText: <Empty description={<b>Nenhum registro encontrado...</b>} /> }}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Content>

            <Footbar />





            <Modal
                title="Informações do funcionário"
                visible={visibleModal}
                width="90%"
                centered
                footer={[
                    <Button 
                        key="back"
                        onClick={() => setVisibleModal(false)}
                    >
                        Cancelar
                    </Button>,
                    <Button 
                        key="submit"
                        type="primary"
                        loading={saving}
                        onClick={ () => saveEmployee() }
                        disabled={(
                            !name ||
                            !cpf ||
                            !sector ||
                            !email ||
                            !status ||
                            cpf_invalid ||
                            email_invalid
                        )}
                    >
                        Salvar
                    </Button>,
                ]}
                onCancel={() => setVisibleModal(false)}
            >
                <Form layout='vertical'>
                    <Row>
                        <Col span={8}>
                            <Form.Item
                                className="form-item"
                                label="Nome completo:"
                                required={true}
                                hasFeedback
                            >
                                <Input
                                    id="name" value={ name }
                                    onChange={ event => setName(event.target.value) }
                                    required
                                />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                className="form-item"
                                label="Data de contratação:"
                                hasFeedback
                            >
                                <DatePicker
                                    locale={locale}
                                    showTime={false}
                                    value={hiring_date !== '' ? moment(hiring_date) : ''}
                                    format="DD/MM/YYYY"
                                    onChange={ (date, dateString) => {
                                        setHiringDate(date !== null ? format(date._d, "yyyy-MM-dd") : '');
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                className="form-item"
                                label="Data de nascimento:"
                                hasFeedback
                            >
                                <DatePicker
                                    locale={locale}
                                    showTime={false}
                                    value={date_birth !== '' ? moment(date_birth) : ''}
                                    format="DD/MM/YYYY"
                                    onChange={ (date, dateString) => {
                                        setDateBirth(date !== null ? format(date._d, "yyyy-MM-dd") : '');
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                className="form-item"
                                label="Sexo:"
                                hasFeedback
                            >
                                <Select 
                                    value={ gender } 
                                    style={{ width: 120 }}
                                    onChange={ (value) => setGender(value) }
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Option value="M" >
                                        Masculino
                                    </Option>
                                    <Option value="F" >
                                        Feminino
                                    </Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                className="form-item"
                                label="CPF:"
                                required={true}
                                hasFeedback
                                validateStatus={`${cpf_invalid ? 'error' : ''}`}
                                help={`${cpf_invalid ? 'Informe um CPF válido' : ''}`}
                            >
                                <InputMask 
                                    mask="999.999.999-99"
                                    alwaysShowMask={false}
                                    placeholder="___.___.___-__"
                                    id="cpf"
                                    value={ cpf }
                                    onChange={ event => setCpf(event.target.value) }
                                    onKeyUp={ event => checkInput(event.target) }
                                    onBlur={ event => checkInput(event.target) }
                                >
                                    {(inputProps) => 
                                        <Input 
                                            {...inputProps} 
                                            type="text"
                                            required
                                        />
                                    }
                                </InputMask>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ paddingBottom: '20px', borderBottomWidth: '0.5px', borderBottomColor: '#c9d3dd', borderBottomStyle: 'dashed' }}>
                        <Col span={4}>
                            <Form.Item
                                className="form-item"
                                label="Setor:"
                                required={true}
                                hasFeedback
                            >
                                <Select 
                                    value={ sector }
                                    style={{ width: 120 }} 
                                    onChange={ (value) => setSector(value) }
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Option value="G" >
                                        Gerencia
                                    </Option>
                                    <Option value="A" >
                                        Administração
                                    </Option>
                                    <Option value="C" >
                                        Comercial
                                    </Option>
                                    <Option value="V" >
                                        Vendas
                                    </Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                className="form-item"
                                label="Salário:"
                            >
                                <CurrencyInput
                                    value={salary}
                                    onChangeEvent={ event => setSalary(event.target.value)}
                                    className="ant-input"
                                    decimalSeparator=","
                                    thousandSeparator=""
                                    prefix="R$ "
                                />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                className="form-item"
                                label="Comissão por venda:"
                            >
                                <Input
                                    id="individual_commission"
                                    value={ individual_commission }
                                    addonAfter="%"
                                    onChange={ event => setIndividualCommission(event.target.value) }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                className="form-item"
                                label="Observação:"
                            >
                                <Input
                                    id="note"
                                    value={ note }
                                    onChange={ event => setNote(event.target.value) }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: '10px' }}>
                        <Col span={4}>
                            <Form.Item
                                className="form-item"
                                label="CEP:"
                            >
                                <InputMask 
                                    mask="99999-999"
                                    alwaysShowMask={false}
                                    placeholder="_____-___"
                                    id="zip_code"
                                    value={ zip_code }
                                    onChange={ event => setZipCode(event.target.value) }
                                >
                                    {(inputProps) => 
                                        <Search
                                            {...inputProps}
                                            onSearch={ value => searchZipCode() }
                                            loading={ loading_zip_code }
                                        />
                                    }
                                </InputMask>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className="form-item"
                                label="Endereço:"
                            >
                                <Input
                                    id="address" value={ address }
                                    onChange={ event => setAddress(event.target.value) }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className="form-item"
                                label="Bairro:"
                            >
                                <Input
                                    id="district" value={ district }
                                    onChange={ event => setDistrict(event.target.value) }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item
                                className="form-item"
                                label="UF:"
                            >
                                <Input
                                    id="state" value={ state }
                                    onChange={ event => setState(event.target.value) }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                className="form-item"
                                label="Cidade:"
                            >
                                <Input
                                    id="city" value={ city }
                                    onChange={ event => setCity(event.target.value) }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item
                                className="form-item"
                                label="Número:"
                            >
                                <Input
                                    id="number" value={ number }
                                    onChange={ event => setNumber(event.target.value) }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={4}>
                            <Form.Item
                                className="form-item"
                                label="Complemento:"
                            >
                                <Input
                                    id="complement" value={ complement }
                                    onChange={ event => setComplement(event.target.value) }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className="form-item"
                                label="Email:"
                                required={true}
                                hasFeedback
                                validateStatus={`${email_invalid ? 'error' : ''}`}
                                help={`${email_invalid ? 'Informe um email válido' : ''}`}
                            >
                                <Input
                                    id="email" value={ email }
                                    onChange={ event => setEmail(event.target.value) }
                                    onKeyUp={ event => checkInput(event.target) }
                                    onBlur={ event => checkInput(event.target) }
                                    required
                                />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                className="form-item"
                                label="Contato de emergência:"
                            >
                                <Input
                                    id="emergency_contact" value={ emergency_contact }
                                    onChange={ event => setEmergencyContact(event.target.value) }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                className="form-item"
                                label="Telefone:"
                            >
                                <InputMask 
                                    mask="(99) 99999-9999"
                                    alwaysShowMask={false}
                                    placeholder="(__) _____-____"
                                    id="phone"
                                    value={ phone }
                                    onChange={ event => setPhone(event.target.value) }
                                >
                                    {(inputProps) => 
                                        <Input 
                                            {...inputProps} 
                                            type="text"
                                        />
                                    }
                                </InputMask>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                className="form-item"
                                label="Telefone 2:"
                            >
                                <InputMask 
                                    mask="(99) 99999-9999"
                                    alwaysShowMask={false}
                                    placeholder="(__) _____-____"
                                    id="phone_2"
                                    value={ phone_2 }
                                    onChange={ event => setPhone2(event.target.value) }
                                >
                                    {(inputProps) => 
                                        <Input 
                                            {...inputProps} 
                                            type="text"
                                        />
                                    }
                                </InputMask>
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item
                                className="form-item"
                                label="Status:"
                                required={true}
                                hasFeedback
                            >
                                <Switch 
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    checked={( status === 'A' )}
                                    onChange={(value) => {
                                        if(value){
                                            setStatus('A')
                                        } else {
                                            setStatus('I')
                                        }
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </Layout>
    )
}