import React, { useEffect, useState } from 'react';
import { 
    Modal,
    Input,
    Layout,
    Select,
    Spin,
    Descriptions,
    Row,
    Col,
    Tooltip,
    Upload,
    Button,
    Table,
    Empty,
    message,
} from 'antd';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import {NumericFormat} from 'react-number-format';
import CurrencyInput from 'react-currency-input';
import locale from 'antd/es/date-picker/locale/pt_BR';
import InputMask from 'react-input-mask';
import moment from 'moment';
import 'moment/locale/pt-br';
import { parseISO, format, setDate, set } from 'date-fns';
import { getToken } from '../../../services/auth';

import api from '../../../services/api';

import './style.css';

const cleanFloatValue = require('../../../utils/cleanFloatValue');

const { Search, TextArea } = Input;
const { Content } = Layout;
const { Option, OptGroup } = Select;
const { confirm } = Modal;

function BillFiles(props) {
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loading_cost_centers, setLoadingCostCenters] = useState(false);
    const [loading_categories, setLoadingCategories] = useState(false);
    const [loading_contacts, setLoadingContacts] = useState(false);
    const [showCostCenter, setShowCostCenter] = useState(false);
    const [showContact, setShowContact] = useState(false);
    const [showMoreInfo, setShowMoreInfo] = useState(false);
    const [bill, setBill] = useState({});
    const [billTo, setBillTo] = useState({});
    const [data, setData] = useState([]);
    const token = getToken();

    const [tableLength, setTableLength] = useState(10);
    const [tablePagination, setTablePagination] = useState({current: 1, pageSize: tableLength});
    const [tableFilters, setTableFilters] = useState({});
    const [tableSorter, setTableSorter] = useState({ field: 'updated_at', order: 'descend' });
    const [search, setSearch] = useState('');

    useEffect(() => {
        if(props.visible){
            setLoading(true);
            showBill(props.bill);
            loadFiles(props.bill.id);
        }
    }, [props.visible]);

    async function loadFiles(
        bill_id = null,
        pagination = tablePagination,
        filters = tableFilters,
        sorter = tableSorter
    ) {
        setLoading(true);

        setTablePagination(pagination);
        setTableFilters(filters);
        if(!sorter.field){
            sorter = tableSorter
        }
        setTableSorter(sorter);

        let order = sorter.order;
        if(order === undefined) {
            order = 'asc';
        } else if(sorter.order === 'ascend') {
            order = 'asc';
        } else if(sorter.order === 'descend') {
            order = 'desc';
        }

        let uri = `/files?type=bill&bill_plot_id=${bill_id}&page=${pagination.current}&per_page=${tableLength}&order=${order}&order_by=${sorter.field}&search=${search}`;
        
        let response = await api.get(uri);

        let files_list = response.data.data;
        
        setData(files_list);

        setTablePagination({
            current: pagination.current,
            pageSize: tableLength,
            total: files_list.length,
        });

        setLoading(false);
    }
    
    function formatDate(date){
        if(date !== null && date !== '' && date !== '0000-00-00'){
            date = parseISO(date);
            return format(date, 'dd/MM/yyyy');
        } else {
            return '-';
        }
    }

    function formatDateTime(date){
        if(date !== null && date !== '' && date !== '0000-00-00'){
            date = parseISO(date);
            return format(date, "dd/MM/yyyy 'às' HH:mm");
        } else {
            return '-';
        }
    }

    function beforeUpload(file) {
        setLoading(true);
        const isAccepted = file.type === 'image/jpeg' || 
            file.type === 'image/png' ||
            file.type === 'application/pdf' ||
            file.type === 'application/msword' ||
            file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
            file.type === 'application/vnd.ms-excel' ||
            file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
            file.type === 'application/vnd.ms-powerpoint' ||
            file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
        
        if (!isAccepted) {
            message.error('Formato inválido!');
            setLoading(false);
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('O anexo pode ter até 2MB!');
            setLoading(false);
        }
        return isAccepted && isLt2M;
    }

    function firstName(name){
        name = name.split(' ');
        name = name[0];
        return name;
    }

    function registeredBy(){
        return <Tooltip title={ billTo.user ? billTo.user.name : '-' }>
            { `${firstName(billTo.user ? billTo.user.name : '-')}` }
        </Tooltip>
    }

    async function showBill(bill) {
        setBill(bill);
        if(bill.bill){
            setBillTo(bill.bill);
        }
        setLoading(false);
    }

    function fixName(name){
        name = name.split(' ');
        name = name[0];
        return name;
    }

    function deleteFile(file_id) {
        confirm({
            title: 'Você tem certeza?',
            content: 'Esta ação não poderá ser desfeita!',
            okText: 'Deletar',
            okType: 'danger',
            cancelText: 'Cancelar',
            async onOk() {
                let response = await api.delete(`/files/${file_id}`);

                if(response.data.success) {
                    message.success('Arquivo deletado com sucesso');
                } else {
                    message.error('Ocorreu um erro, aguarde alguns instantes e tente novamente');
                }

                loadFiles(props.bill.id);
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    const columns = [
        {
            title: 'Anexo',
            render: (file) => (
                <div>
                    <a href={`${file.file_url}`} target="_blank" style={{ fontWeight:'bold' }}>
                        { file.file_name }
                    </a>
                    <br />
                    <span>
                        { formatDateTime(file.updated_at) }
                    </span>
                    <br/>
                    <span style={{ color:'#1890ff' }}>
                        <Tooltip title={ file.user.name }>
                            { `[ ${fixName(file.user.name)} ]` }
                        </Tooltip>
                    </span>
                </div>
            ),
            align: 'left',
        }, {
            title: '',
            render: (file) => (
                <Tooltip title="Deletar" placement="right">
                    <Button 
                        type="secondary-default" 
                        icon={<DeleteOutlined />}
                        onClick={event => deleteFile(file.id)}
                        style={{ 
                            marginLeft: '5px',
                            paddingTop: '4px',
                            paddingBottom: '4px',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            width:'auto',
                            color: '#ff4d4f !important',
                        }}
                    ></Button>
                </Tooltip>
            ),
            align: 'left',
        }
    ];

    return (
        <Modal
            wrapClassName="fbm-modal"
            title={<div className="bill_modal_title" style={props.type == 'to_pay' ? {background: '#f35b5b'} : {background: '#25ba6e'}}>
                <h3 className="bill_description">{bill.description}</h3>
                <h1 className="bill_value">
                    <NumericFormat 
                        value={bill.value ? bill.value : 0}
                        displayType={'text'}
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={2}
                        fixedDecimalScale={true}
                    />
                </h1>
                <p className="bill_sub">Lançada por {registeredBy()} em {formatDateTime(bill.created_at ? bill.created_at : '')}</p>
            </div>}
            visible={ props.visible }
            footer=""
            onCancel={() => props.setVisibility(false)}
        >
            <Spin spinning={loading}>
                <Row>
                    <Col span={24}>
                        <Upload 
                            action={`${process.env.REACT_APP_API_URL}/files?bill_plot_id=${bill.id}&type=bill`}
                            listType="picture"
                            multiple={false}
                            showUploadList={false}
                            beforeUpload={beforeUpload}
                            accept={'image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation'}
                            headers={{
                                'Authorization': `${token}`
                            }}
                            onChange={(info) => {
                                if (info.file.status !== 'uploading') {
                                    loadFiles(bill.id);
                                    setLoading(false);
                                }
                            }}
                        >
                            <Button>
                                <UploadOutlined /> Anexar Arquivo
                            </Button>
                        </Upload>
                    </Col>
                </Row>
                <Row style={{ marginTop: '20px', paddingTop: '10px', borderTopWidth: '0.5px', borderTopColor: '#c9d3dd', borderTopStyle: 'solid' }}>
                    <Col span={24} >
                        <Table
                            bordered={true}
                            columns={columns}
                            rowKey={record => record.id}
                            dataSource={data.data}
                            pagination={tablePagination}
                            // loading={loading}
                            pageSize={tableLength}
                            locale={{ emptyText: <Empty description={<b>Nenhum registro encontrado...</b>} /> }}
                        />
                    </Col>
                </Row>
            </Spin>
        </Modal>
    );
}

export default BillFiles;