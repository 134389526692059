import React, { useEffect, useState } from 'react';
import { 
    Modal,
    Button,
    Row,
    Col,
    Form,
    Input,
    message,
    Layout,
    Radio,
    DatePicker,
    Select,
    Spin,
    Checkbox,
} from 'antd';
import CurrencyInput from 'react-currency-input';
import locale from 'antd/es/date-picker/locale/pt_BR';
import InputMask from 'react-input-mask';
import moment from 'moment';
import 'moment/locale/pt-br';
import { parseISO, format } from 'date-fns';

import api from '../../../../services/api';

const cleanFloatValue = require('../../../../utils/cleanFloatValue');

const { Search, TextArea } = Input;
const { Content } = Layout;
const { Option } = Select;

function FinancialAccount(props) {
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loading_zip_code, setLoadingZipCode] = useState(false);
    const [showBankInfos, setShowBankInfos] = useState(false);
    const [showMoreInfo, setShowMoreInfo] = useState(false);

    
    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [opening_date, setOpeningDate] = useState('');
    const [agency_number, setAgencyNumber] = useState('');
    const [account_number, setAccountNumber] = useState('');
    const [type, setType] = useState('CX');
    const [type_person, setTypePerson] = useState('PF');
    const [initial_balance, setInitialBalance] = useState(0);

    useEffect(() => {
        emptyAccount();
        if(props.financial_account && props.visible){
            setLoading(true);
            showAccount(props.financial_account);
        }
    }, [props.visible]);
    
    function setAccountType(type){
        setType(type);

        setOpeningDate('');
        setTypePerson('');
        setAgencyNumber('');
        setAccountNumber('');
    }

    function emptyAccount() {
        setId('');
        setName('');
        setDescription('');
        setOpeningDate('');
        setAgencyNumber('');
        setAccountNumber('');
        setType('CX');
        setTypePerson('PF');
        setInitialBalance(0);
        setShowBankInfos(false);
        setShowMoreInfo(false);
    }

    async function saveAccount() {
        setSaving(true);

        const data = {
            name,
            description,
            opening_date,
            agency_number,
            account_number,
            type,
            type_person: type_person !== '' ? type_person : null,
            initial_balance: cleanFloatValue(initial_balance),
            initial_balance_updated_at: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
        };

        try {
            let response = [];

            if(id)
                response = await api.put(`/financial_accounts/${id}`, data);
            else
                response = await api.post('/financial_accounts', data);

            if(response.data.success) {
                message.success('Conta financeira salva com sucesso');
            } else {
                message.error('Ocorreu um erro, aguarde alguns instantes e tente novamente');
            }

            setSaving(false);
            props.afterSave();
            props.setVisibility(false);
        } catch (error) {
            message.error('Ocorreu um erro, aguarde alguns instantes e tente novamente');
            setSaving(false);
        }
    }

    async function showAccount(account) {
        setId(account.id);
        setName(account.name);
        setDescription(account.description);
        setOpeningDate(account.opening_date);
        setAgencyNumber(account.agency_number);
        setAccountNumber(account.account_number);
        setType(account.type);
        setTypePerson(account.type_person);
        setInitialBalance(account.initial_balance);

        if(
            (account.opening_date != null 
                && account.opening_date != '0000-00-00' 
                && account.opening_date != ""
                && account.opening_date != undefined
            ) && 
            account.agency_number || 
            account.account_number
        ){
            setShowBankInfos(true);
        }
        if(account.description){
            setShowMoreInfo(true);
        }

        setLoading(false);
    }

    return (
        <Modal
            title={`Conta Financeira`}
            visible={ props.visible }
            footer={[
                <Button
                    type="success"
                    onClick={ () => saveAccount() }
                    loading={ saving }
                    disabled={ !name }
                >
                    Salvar
                </Button>,
            ]}
            onCancel={() => props.setVisibility(false)}
        >
            <Spin spinning={loading}>
                <Form layout='vertical'>
                    <Row style={{paddingTop:'10px'}}>
                        <Col span={24}>
                            <Form.Item
                                className="form-item"
                                label="Nome:"
                                required={true}
                                style={{ paddingLeft: 0, paddingRight: 0 }}
                            >
                                <Input
                                    id="name"
                                    value={ name }
                                    onChange={ event => setName(event.target.value) }
                                    required
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                className="form-item"
                                label="Saldo inicial:"
                                style={{ paddingLeft:0 }}
                                required={true}
                            >
                                <CurrencyInput
                                    value={initial_balance}
                                    onChangeEvent={ event => setInitialBalance(event.target.value)}
                                    className="ant-input"
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    prefix="R$ "
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                className="form-item"
                                label="Tipo de conta:"
                                required={true}
                                style={{ paddingRight:0 }}
                            >
                                <Select 
                                    value={type} 
                                    onChange={ async (value) => setAccountType(value) }
                                >
                                    <Option key="CC" value="CC">Conta Corrente</Option>
                                    <Option key="PP" value="PP">Poupança/Investimento</Option>
                                    <Option key="CX" value="CX">Caixinha</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    { showBankInfos && <>
                        <Row style={{paddingTop: '15px'}}>
                            <Col span={12}>
                                <Form.Item
                                    className="form-item"
                                    label="Data de abertura:"
                                    style={{ paddingLeft:0 }}
                                >
                                    <DatePicker
                                        locale={locale}
                                        showTime={false}
                                        value={opening_date !== '' ? moment(opening_date) : ''}
                                        format="DD/MM/YYYY"
                                        onChange={ (date, dateString) => {
                                            setOpeningDate(date !== null ? format(date._d, "yyyy-MM-dd") : '');
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    className="form-item"
                                    label="Tipo:"
                                    style={{ paddingRight:0 }}
                                >
                                    <Select 
                                        value={type_person} 
                                        onChange={ async (value) => setTypePerson(value) }
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        <Option key="PF" value="PF">Pessoa Física</Option>
                                        <Option key="PJ" value="PJ">Pessoa Jurídica</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    className="form-item"
                                    label="Agência:"
                                    style={{ paddingLeft: 0}}
                                >
                                    <Input
                                        id="agency_number"
                                        value={ agency_number }
                                        onChange={ event => setAgencyNumber(event.target.value) }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    className="form-item"
                                    label="Número da conta:"
                                    style={{ paddingRight:0 }}
                                >
                                    <Input
                                        id="account_number"
                                        value={ account_number }
                                        onChange={ event => setAccountNumber(event.target.value) }
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </>}

                    { showMoreInfo && <>
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    className="form-item"
                                    label="Descrição:"
                                    style={{ paddingLeft: 0, paddingRight: 0 }}
                                >
                                    <TextArea 
                                        rows={3}
                                        id="description"
                                        value={ description }
                                        onChange={ event => setDescription(event.target.value) }
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </>}
                    <Row>
                        <Col span={24} style={{paddingTop:'15px'}}>
                            <p>
                                {type !== "CX" && <>
                                    <a
                                        onClick={() => setShowBankInfos(!showBankInfos)}
                                        style={ showBankInfos ? {color: "#9797ad", marginRight:'15px'} : {marginRight:'15px'} }
                                    >
                                        + Informações bancárias
                                    </a>
                                </>}
                                <a 
                                    style={ showMoreInfo ? {color: "#9797ad"} : {} }
                                    onClick={() => setShowMoreInfo(!showMoreInfo)}
                                >
                                    + Info
                                </a>
                            </p>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </Modal>
    );
}

export default FinancialAccount;