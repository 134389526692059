import React, { useEffect, useState } from 'react';
import { 
    Modal,
    Button,
    Row,
    Col,
    Form,
    Input,
    message,
    Layout,
 } from 'antd';

import api from '../../services/api';

const validateValue = require('../../utils/validateValue');

const { Content } = Layout;

function MySettings(props) {
    const [saving, setSaving] = useState(false);
    
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [password_repeat, setPasswordRepeat] = useState('');

    const [name_invalid, setNameInvalid] = useState(false);
    const [email_invalid, setEmailInvalid] = useState(false);
    const [password_invalid, setPasswordInvalid] = useState(false);
    const [password_repeat_invalid, setPasswordRepeatInvalid] = useState(false);

    useEffect(() => {
        let user_logged = localStorage.getItem('@semzebra/user');

        user_logged = JSON.parse(user_logged);

        setName(user_logged.name);
        setEmail(user_logged.email);
    }, [props.visible]);
    
    function checkInput(field) {
        if(field.id === 'name') {
            if( name === ''){
                setNameInvalid(true);
                return false;
            }
            setNameInvalid(false);
        }

        if(field.id === 'email') {
            if( email === '' || !validateValue('email', email)){
                setEmailInvalid(true);
                return false;
            }
            setEmailInvalid(false);
        }

        if(field.id === 'password' || field.id === 'password_repeat') {
            if( password !== '' || password_repeat !== '' ){
                if( password_repeat === '' || password_repeat !== password ){
                    setPasswordInvalid(true);
                    setPasswordRepeatInvalid(true);
                    return false;
                }
            }
            setPasswordInvalid(false);
            setPasswordRepeatInvalid(false);
        }
    }

    async function updateProfile() {
        setSaving(true);

        const data = {
            name,
            email,
            password,
        };

        try {
            let response = await api.put('/profiles', JSON.stringify(data));

            if(response.data.success) {
                let updated_user = localStorage.getItem('@semzebra/user');

                updated_user = JSON.parse(updated_user);
                updated_user.name = name;
                updated_user.email = email;

                localStorage.setItem('@semzebra/user', JSON.stringify(updated_user));

                message.success('Conta atualizada com sucesso');

                setPassword('');
                setPasswordRepeat('');
                props.setVisibility(false);
                props.afterSave(false);
            } else {
                message.error('Ocorreu um erro, aguarde alguns instantes e tente novamente');
            }

            setSaving(false);
        } catch (error) {
            message.error('Ocorreu um erro, aguarde alguns instantes e tente novamente');
            setSaving(false);
        }
    }

    return (
        <Modal
            title="Meus dados"
            visible={ props.visible }
            centered
            footer={[
                <Button 
                    key="back" 
                    onClick={() => props.setVisibility(false)} 
                >
                    Cancelar
                </Button>,
                <Button
                    type="success"
                    onClick={ () => updateProfile() }
                    loading={ saving }
                    disabled={(
                        name_invalid ||
                        email_invalid ||
                        password_invalid ||
                        password_repeat_invalid
                    )}
                >
                    Salvar
                </Button>,
            ]}
            onCancel={() => props.setVisibility(false)}
        >
            <Form layout='vertical'>
                <Row>
                    <Col span={24}>
                        <p style={{fontSize:'0.9em'}}><span style={{ fontWeight: 'bold', color: '#5e72e4' }}>Mantenha seus dados de acesso atualizados</span>, assim você garante a integridade da sua conta e de todo o sistema.</p>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Form.Item
                            className="form-item"
                            label="Nome completo:"
                            required={true}
                            hasFeedback
                            validateStatus={`${name_invalid ? 'error' : ''}`}
                            help={`${name_invalid ? 'Campo obrigatório' : ''}`}
                            style={{ paddingLeft: 0 }}
                        >
                            <Input
                                id="name"
                                value={ name }
                                onChange={ event => setName(event.target.value) }
                                onKeyUp={ event => checkInput(event.target) }
                                onBlur={ event => checkInput(event.target) }
                                required
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            className="form-item"
                            label="Email:"
                            required={true}
                            hasFeedback
                            validateStatus={`${email_invalid ? 'error' : ''}`}
                            help={`${email_invalid ? 'Informe um Email válido' : ''}`}
                            style={{ paddingRight: 0 }}
                        >
                            <Input
                                id="email"
                                value={ email }
                                onChange={ event => setEmail(event.target.value) }
                                onKeyUp={ event => checkInput(event.target) }
                                onBlur={ event => checkInput(event.target) }
                                required
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row style={{marginTop:'10px'}}>
                    <Col span={12}>
                        <Form.Item
                            className="form-item"
                            label="Senha:"
                            hasFeedback
                            validateStatus={`${password_invalid ? 'error' : ''}`}
                            help={`${password_invalid ? 'As senhas não coincidem' : ''}`}
                            style={{ paddingLeft: 0 }}
                        >
                            <Input.Password
                                id="password"
                                value={ password }
                                onChange={ event => setPassword(event.target.value) }
                                onKeyUp={ event => checkInput(event.target) }
                                onBlur={ event => checkInput(event.target) }
                                required
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            className="form-item"
                            label="Repita a senha:"
                            hasFeedback
                            validateStatus={`${password_repeat_invalid ? 'error' : ''}`}
                            help={`${password_repeat_invalid ? 'As senhas não coincidem' : ''}`}
                            style={{ paddingRight: 0 }}
                        >
                            <Input.Password
                                id="password_repeat"
                                value={ password_repeat }
                                onChange={ event => setPasswordRepeat(event.target.value) }
                                onKeyUp={ event => checkInput(event.target) }
                                onBlur={ event => checkInput(event.target) }
                                required
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row style={{ paddingTop:'15px' }}>
                    <Col span={24}>
                        <p style={{fontSize:'0.9em'}}>Se não deseja alterar a senha basta deixar o campo "senha" e "repita a senha" em branco.</p>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}

export default MySettings;