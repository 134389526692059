import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import {
    Layout,
    Form,
    Breadcrumb,
    Input,
    Button,
    Row,
    Col,
    Select,
    DatePicker,
    message,
    Table,
    Modal,
    Tooltip,
    Card,
    Tag,
    Empty,
} from 'antd';
import {
    PlusOutlined,
    EditOutlined,
    DeleteOutlined
} from '@ant-design/icons';

import locale from 'antd/es/date-picker/locale/pt_BR';
import {NumericFormat} from 'react-number-format';
import { parseISO, format } from 'date-fns';
import moment from 'moment';

import Topbar from '../components/Topbar';
import Footbar from '../components/Footbar';
// import Loading from '../components/Loading';

import 'antd/dist/antd.css';
import './styles/Main.css';

import api from '../services/api.js';

const { Search } = Input;
const { Content } = Layout;
const { confirm } = Modal;
const { Option } = Select;

export default function Products() {
    const [loadingTable, setLoadingTable] = useState(true);
    const [loading, setLoading] = useState(false);
    const [loading_categories, setLoadingCategories] = useState(false);
    const [loading_brands, setLoadingBrands] = useState(false);
    
    const [data, setData] = useState([]);

    const [categories, setCategories] = useState([]);
    const [brands, setBrands] = useState([]);
    const [has_limit, setHasLimit] = useState(false);

    const [filter_brand, setFilterBrand] = useState('');
    const [filter_category, setFilterCategory] = useState('');
    const [filter_last_sale_begin, setFilterLastSaleBegin] = useState('');
    const [filter_last_sale_end, setFilterLastSaleEnd] = useState('');

    const [tableLength, setTableLength] = useState(10);
    const [tablePagination, setTablePagination] = useState({current: 1, pageSize: tableLength});
    const [tableFilters, setTableFilters] = useState({});
    const [tableSorter, setTableSorter] = useState({ field: 'description', order: 'ascend' });
    const [search, setSearch] = useState('');

    useEffect( () => {
        checkLimit();
    }, []);

    useEffect( () => {
        loadData();
    }, [tableLength]);

    async function checkLimit() {
        setLoading(true);
        let response = await api.get('/billing/limits/products');
        let hasLimit = response.data.data.has_limit;
        setHasLimit(hasLimit);
        setLoading(false);
    }

    async function loadBrands(){
        setLoadingBrands(true);
        setBrands([]);
        let response = await api.get(`/brands`);
        setBrands(response.data.data);
        setLoadingBrands(false);
    }

    async function loadCategories(){
        setLoadingCategories(true);
        setCategories([]);
        let response = await api.get(`/product_categories`);
        setCategories(response.data.data);
        setLoadingCategories(false);
    }

    async function reloadData(){
        loadData({current: 1, pageSize: tableLength});
    }

    async function loadData(
        pagination = tablePagination,
        filters = tableFilters,
        sorter = tableSorter,
        extra
    ) {
        setLoadingTable(true);

        if(!sorter.field){
            sorter = tableSorter
        }

        setTablePagination(pagination);
        setTableFilters(filters);
        setTableSorter(sorter);

        let order = sorter.order;
        if(order === undefined) {
            order = 'asc';
        } else if(sorter.order === 'ascend') {
            order = 'asc';
        } else if(sorter.order === 'descend') {
            order = 'desc';
        }

        // PRODUCTS
        let uri = `/products?page=${pagination.current}&per_page=${tableLength}&order=${order}&order_by=${sorter.field}&search=${search}`;
        uri += `&brand=${filter_brand}`;
        uri += `&category=${filter_category}`;
        uri += `&last_sale_begin=${filter_last_sale_begin}`;
        uri += `&last_sale_end=${filter_last_sale_end}`;
        
        let response = await api.get(uri);
        let products_list = response.data.data;

        setData(products_list);
        setTablePagination({
            current: pagination.current,
            pageSize: tableLength,
            total: products_list.total,
        });

        setLoadingTable(false);
    }

    function deleteProduct(product_id) {
        confirm({
            title: 'Você tem certeza?',
            content: 'Esta ação não poderá ser desfeita!',
            okText: 'Deletar',
            okType: 'danger',
            cancelText: 'Cancelar',
            async onOk() {
                let response = await api.delete(`/products/${product_id}`);

                if(response.data.success) {
                    message.success('Produto deletado com sucesso');
                } else {
                    message.error('Ocorreu um erro, aguarde alguns instantes e tente novamente');
                }

                await loadData();
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    const columns = [
        {
            title: 'CÓD. PRODUTO',
            dataIndex: 'code',
            align: 'left',
            width: '10%',
        }, {
            title: 'NOME DO PRODUTO',
            render: ( product => {
                if(product.category_id) {
                    return (<>
                        <Tag>
                            {product.category.name}
                        </Tag>
                        {product.description}
                    </>)
                } else {
                    return product.description;
                }
            }),
            align: 'left',
            width: '35%',
        }, {
            title: 'PREÇO DE VENDA (R$)',
            align: 'right',
            key: 'sale_value',
            render: ( product => (
                <NumericFormat 
                    value={product.sale_value ? product.sale_value : 0}
                    displayType={'text'}
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2} 
                    fixedDecimalScale={true}
                />
            )),
            width: '10%',
        },{
            title: 'ESTOQUE DISPONÍVEL',
            key: 'amount',
            render: ( product => (
                <>
                    <NumericFormat 
                        value={product.amount ? product.amount : 0}
                        displayType={'text'}
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={2} 
                        fixedDecimalScale={true}
                    /> <br/><sub>{product.unit_of_measurement}</sub>
                </>
            )),
            align: 'right',
            width: '10%',
        }, {
            title: 'AÇÕES',
            width: '12%',
            render: (product => (
                <>
                    <Tooltip title="Editar" placement="left">
                        <Link to={`/produtos/${product.id}`}>
                            <Button 
                                type="secondary-default" 
                                icon={<EditOutlined />}
                                style={{
                                    paddingTop: '4px',
                                    paddingBottom: '4px',
                                    paddingLeft: '10px',
                                    paddingRight: '10px',
                                    width:'auto',
                                }}
                            ></Button>
                        </Link>
                    </Tooltip>
                    <Tooltip title="Deletar" placement="right">
                        <Button 
                            type="secondary-default" 
                            icon={<DeleteOutlined />}
                            onClick={event => deleteProduct(product.id)}
                            style={{ 
                                marginLeft: '5px',
                                paddingTop: '4px',
                                paddingBottom: '4px',
                                paddingLeft: '10px',
                                paddingRight: '10px',
                                width:'auto',
                                color: '#ff4d4f !important',
                            }}
                        ></Button>
                    </Tooltip>
                </>
            )),
        },
    ];

    // let loading_screen = '';
    // if(loading){
    //     loading_screen = <Loading />
    // }

    return (
        <Layout className="layout">
            {/* { loading_screen } */}
            <Topbar />

            <Content className="fbm-main">
                <div id="main">
                    <Row style={{ marginLeft: '-10px', marginRight: '-10px' }}>
                        <Col span={24} style={{ padding: '0 10px 10px 10px' }}>
                            <Card loading={loading} className="fbm-card">
                                <Row style={{ paddingBottom: '15px', borderBottomWidth: '0.5px', borderBottomColor: '#e8e8e8', borderBottomStyle: 'solid' }}>
                                    <Col className="fbm-card-header" span={24}>
                                        <h1 className="big-title">
                                            Produtos
                                        </h1>
                                        <Link to="/produtos/novo">
                                            <Button 
                                                type="success"
                                                style={{marginLeft: '15px', borderRadius: '50%'}}
                                            >
                                                <PlusOutlined />
                                            </Button>
                                        </Link>
                                    </Col>
                                </Row>
                                <Form layout='vertical'>
                                    <Row style={{ paddingBottom: '15px' }}>
                                        <Col span={7}>
                                            <Form.Item
                                                className="form-item"
                                                label="Categoria:"
                                                style={{ paddingLeft: '0' }}
                                            >
                                                <Select 
                                                    value={ filter_category }
                                                    style={{ width: 120 }} 
                                                    onChange={ (value) => setFilterCategory(value) }
                                                    onFocus={ () => loadCategories() }
                                                    loading={ loading_categories }
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    <Option value="">Todas</Option>
                                                    { categories.map(item => (
                                                        <Option
                                                            key={item.id}
                                                            value={ item.id }
                                                        >
                                                            { item.name }
                                                        </Option>
                                                    )) }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={5}>
                                            <Form.Item
                                                className="form-item"
                                                label="Marca:"
                                            >
                                                <Select 
                                                    value={ filter_brand }
                                                    style={{ width: 120 }} 
                                                    onChange={ (value) => setFilterBrand(value) }
                                                    onFocus={ () => loadBrands() }
                                                    loading={ loading_brands }
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    <Option value="">Todas</Option>
                                                    { brands.map(item => (
                                                        <Option
                                                            key={item.id}
                                                            value={ item.id }
                                                        >
                                                            { item.name }
                                                        </Option>
                                                    )) }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item
                                                className="form-item"
                                                label="Ult. Venda (início):"
                                            >
                                                <DatePicker
                                                    locale={locale}
                                                    showTime={false}
                                                    value={filter_last_sale_begin !== '' ? moment(filter_last_sale_begin) : ''}
                                                    format="DD/MM/YYYY"
                                                    onChange={ (date, dateString) => {
                                                        setFilterLastSaleBegin(date !== null ? format(date._d, "yyyy-MM-dd") : '');
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item
                                                className="form-item"
                                                label="Ult. Venda (fim):"
                                            >
                                                <DatePicker 
                                                    locale={locale}
                                                    showTime={false}
                                                    value={filter_last_sale_end !== '' ? moment(filter_last_sale_end) : ''}
                                                    format="DD/MM/YYYY"
                                                    onChange={ (date, dateString) => {
                                                        setFilterLastSaleEnd(date !== null ? format(date._d, "yyyy-MM-dd") : '');
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item
                                                className="form-item"
                                                style={{ marginTop: '26px', paddingRight: '0' }}
                                            >
                                                <Button 
                                                    key="submit"
                                                    type="primary"
                                                    loading={loadingTable}
                                                    onClick={ () => reloadData() }
                                                    style={{ width: '100%' }}
                                                >
                                                    Filtrar
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                                <Row style={{ paddingTop:'20px',paddingBottom: '0' }}>
                                    <Col span={24}>
                                        <div style={{ overflow: 'auto', marginBottom:'15px' }}>
                                            <Form.Item
                                                className="form-item"
                                                style={{ 
                                                    paddingTop: 0, 
                                                    paddingRight: 0,
                                                    paddingLeft: 0,
                                                    paddingBottom: 0,
                                                    width: '100px',
                                                    float: 'left',
                                                }}
                                            >
                                                <Select 
                                                    value={tableLength} 
                                                    onChange={ async (value) => setTableLength(value) }
                                                >
                                                    <Option key="10" value="10">10</Option>
                                                    <Option key="25" value="25">25</Option>
                                                    <Option key="50" value="50">50</Option>
                                                    <Option key="100" value="100">100</Option>
                                                </Select>
                                            </Form.Item>
                                            <Form.Item
                                                className="form-item"
                                                style={{ 
                                                    paddingTop: 0,
                                                    paddingRight: 0,
                                                    paddingLeft: 0,
                                                    paddingBottom: 0,
                                                    width: '200px',
                                                    float: 'right',
                                                }}
                                            >
                                                <Search
                                                    id="search"
                                                    placeholder="Pesquisar"
                                                    value={search}
                                                    onChange={ event => setSearch(event.target.value) }
                                                    onSearch={ value => reloadData() }
                                                    loading={ loadingTable }
                                                />
                                            </Form.Item>
                                        </div>
                                    </Col>
                                    <Col span={24} >
                                        <Table
                                            bordered={true}
                                            columns={columns}
                                            rowKey={record => record.id}
                                            dataSource={data.data}
                                            pagination={tablePagination}
                                            loading={loadingTable}
                                            onChange={loadData}
                                            pageSize={tableLength}
                                            locale={{ emptyText: <Empty description={<b>Nenhum registro encontrado...</b>} /> }}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Content>

            <Footbar />
        </Layout>
    )
}