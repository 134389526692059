import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Layout, Tooltip } from 'antd';

import { listPermissions } from '../../services/auth';

import './style.css';

const { Header } = Layout;

function SecuritySidebar(props) {
    const [permissions, setPermissions] = useState({});

    useEffect(() => {
        async function loadPermissions(){
            setPermissions(listPermissions());
        }

        loadPermissions();
    }, []);

    return (
        <>
            <div className="settings_sidebar">
                <ul>
                    <li>
                        <a href="/configuracoes/historico-login" className={props.actived == 'authhistory' ? 'actived' : ''}>
                            Histórico de login
                        </a>
                    </li>
                </ul>
            </div>
        </>
    );
}

export default SecuritySidebar;