import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import {
    Layout,
    Button,
    Row,
    Col,
    message,
    Modal,
    Card,
} from 'antd';
import { 
    EditOutlined,
    UserDeleteOutlined
} from '@ant-design/icons';

import Topbar from '../components/Topbar';
import Footbar from '../components/Footbar';
import Loading from '../components/Loading';
import SettingsSidebar from '../components/SettingsSidebar';

import 'antd/dist/antd.css';
import './styles/Main.css';

import api from '../services/api.js';

import users_svg from '../assets/users.svg';

const { Content } = Layout;
const { confirm } = Modal;

export default function Users() {
    const [loading, setLoading] = useState(true);

    const [users, setUsers] = useState([]);
    const [has_limit, setHasLimit] = useState(false);

    const [logged_email, setLoggedEmail] = useState('');

    useEffect(() => {
        let user_logged = localStorage.getItem('@semzebra/user');
        user_logged = JSON.parse(user_logged);
        setLoggedEmail(user_logged.email);

        checkLimit();
        loadUsers();
    }, []);

    async function checkLimit() {
        setLoading(true);
        let response = await api.get('/billing/limits/users');
        let hasLimit = response.data.data.has_limit;
        setHasLimit(hasLimit);
        setLoading(false);
    }

    async function loadUsers() {
        let response = await api.get('/users');

        let users_list = response.data.data;

        users_list.forEach(user => {
            switch (user.status) {
                case 'A':
                    user.status = 'Ativo';
                    break;
                case 'I':
                    user.status = 'Inativo';
                    break;
                default:
                    user.status = '';
                    break;
            }
        });

        setUsers(users_list);

        setLoading(false);
    }

    function deleteUser(user_id) {
        confirm({
            title: 'Você tem certeza?',
            content: 'Esta ação não poderá ser desfeita!',
            okText: 'Deletar',
            okType: 'danger',
            cancelText: 'Cancelar',
            async onOk() {
                let response = await api.delete(`/users/${user_id}`);

                if(response.data.success) {
                    message.success('Usuário deletado com sucesso');
                } else {
                    message.error('Ocorreu um erro, aguarde alguns instantes e tente novamente');
                }

                await loadUsers();
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    let loading_screen = '';
    if(loading){
        loading_screen = <Loading />
    }

    return (
        <Layout className="layout">
            { loading_screen }
            <Topbar />

            <Content className="fbm-main">
                <div id="main">
                    <Row>
                        <Col span={5} style={{ padding: '0 10px 10px 10px' }}>
                            <SettingsSidebar actived="users" />
                        </Col>
                        <Col span={19} style={{ padding: '0 10px 10px 10px' }}>
                            <Row style={{ minHeight:'500px',marginLeft: '-10px', marginRight: '-10px' }}>
                                <Col span={12} style={{ padding: '0 10px 10px 10px', minHeight: '420px' }}>
                                    <Card className="fbm-card">
                                        <Row style={{ paddingBottom: '0' }}>
                                            <Col span={24} style={{ borderBottomWidth: '0.5px', borderBottomColor: '#c9d3dd', borderBottomStyle: 'dashed' }}>
                                                <h5 className="page-title" style={{ fontWeight:'bold' }}>Usuários</h5>
                                            </Col>
                                        </Row>
                                        <Row style={{ paddingBottom: '10px', paddingTop: '20px' }}>
                                            <Col span={24}>
                                                <img draggable="false" src={users_svg} width="100%" alt="Usuários" />
                                            </Col>
                                        </Row>
                                        <Row style={{ paddingTop: '20px' }}>
                                            <Col span={24}>
                                                <Link to="/configuracoes/usuarios/novo">
                                                    <Button 
                                                        type="success" 
                                                        style={{ width: '100%' }}
                                                        disabled={!has_limit}
                                                    >
                                                        Convidar Usuário
                                                    </Button>
                                                </Link>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                { users.map(user => (
                                    <Col key={ user.id } span={12} style={{ padding: '0 10px 10px 10px', minHeight: '420px' }}>
                                        <Card className="fbm-card">
                                            <Row style={{ paddingBottom: '0' }}>
                                                <Col span={24} style={{ borderBottomWidth: '0.5px', borderBottomColor: '#c9d3dd', borderBottomStyle: 'dashed' }}>
                                                    <h3 className="" style={{ fontWeight:'bold', fontSize: '16px', marginBottom: '25px' }}>{ user.name }</h3>
                                                </Col>
                                            </Row>
                                            <Row style={{ paddingTop: '15px' }}>
                                                <Col span={24}>
                                                    <p style={{fontSize:'1em'}}><span style={{ fontWeight: 'bold' }}>Tipo de Usuário</span></p>
                                                    <p style={{fontSize:'0.9em'}}>{ user.role.name }</p>
                                                </Col>
                                                <Col span={24} style={{ paddingTop: '10px' }}>
                                                    <p style={{fontSize:'1em'}}><span style={{ fontWeight: 'bold' }}>Login</span></p>
                                                    <p style={{fontSize:'0.9em'}}>{ user.email }</p>
                                                </Col>
                                                <Col span={24} style={{ paddingTop: '10px' }}>
                                                    <p style={{fontSize:'1em'}}><span style={{ fontWeight: 'bold' }}>Status</span></p>
                                                    <p style={{fontSize:'0.9em'}}>{ user.status }</p>
                                                </Col>
                                                { user.email !== logged_email && 
                                                    <Col span={24} style={{ paddingTop: '17px' }}>
                                                        <Link to={`/configuracoes/usuarios/${user.id}`}>
                                                            <Button 
                                                                type="primary"
                                                                icon={<EditOutlined />}
                                                                style={{ padding: '6px 40px' }}
                                                            >
                                                                Editar
                                                            </Button>
                                                        </Link>
                                                        <Button 
                                                            type="danger" 
                                                            icon={<UserDeleteOutlined />} 
                                                            style={{ float: 'right', padding: '6px 40px' }}
                                                            onClick={event => deleteUser(user.id)}
                                                        >
                                                        Excluir
                                                        </Button>
                                                    </Col>
                                                }
                                                { user.email === logged_email && 
                                                    <Col span={24} style={{ paddingTop: '4px' }}>
                                                        <sub>
                                                            Para alterar seus dados utilize a opção "Meus Dados", clicando no seu nome no menu superior.
                                                        </sub>
                                                    </Col>
                                                }
                                            </Row>
                                        </Card>
                                    </Col>
                                )) }
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Content>

            <Footbar />
        </Layout>
    )
}