import React, { useEffect, useState } from 'react';
import { 
    Modal,
    Button,
    Row,
    Col,
    Form,
    Input,
    message,
    Layout,
    Radio,
    DatePicker,
    Select,
    Spin,
    Checkbox,
    Tooltip,
} from 'antd';
import { 
    PlusOutlined,
    DeleteOutlined
} from '@ant-design/icons';

import CurrencyInput from 'react-currency-input';
import locale from 'antd/es/date-picker/locale/pt_BR';
import InputMask from 'react-input-mask';
import moment from 'moment';
import 'moment/locale/pt-br';
import { parseISO, format } from 'date-fns';

import api from '../../../services/api';

const cleanFloatValue = require('../../../utils/cleanFloatValue');

const { Search, TextArea } = Input;
const { Content } = Layout;
const { Option, OptGroup } = Select;

function BillEdit(props) {
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loading_cost_centers, setLoadingCostCenters] = useState(false);
    const [loading_categories, setLoadingCategories] = useState(false);
    const [loading_contacts, setLoadingContacts] = useState(false);
    const [showCostCenter, setShowCostCenter] = useState(false);
    const [showContact, setShowContact] = useState(false);
    const [showMoreInfo, setShowMoreInfo] = useState(false);
    const [show_plots, setShowPlots] = useState(true);

    
    const [id, setId] = useState('');
    const [categories, setCategories] = useState([]);
    const [cost_centers, setCostCenters] = useState([]);
    const [contacts, setContacts] = useState([]);

    const [category, setCategory] = useState('');
    const [cost_center, setCostCenter] = useState('');
    const [contact, setContact] = useState('');
    const [document_number, setDocumentNumber] = useState('');
    
    const [issue_date, setIssueDate] = useState(format(new Date(), "yyyy-MM-dd"));
    const [competence_date, setCompetenceDate] = useState(format(new Date(), "yyyy-MM-dd"));
    const [due_date, setDueDate] = useState(format(new Date(), "yyyy-MM-dd"));
    const [total_value, setTotalValue] = useState('');
    const [description, setDescription] = useState('');
    const [note_bill, setNoteBill] = useState('');
    
    const blank_plot = { "value": 0, "due_date": format(new Date(), "yyyy-MM-dd"), "note": "" };
    const [plots, setPlots] = useState([{...blank_plot}]);

    const [plots_is_valid, setPlotsIsValid] = useState(false);

    useEffect(() => {
        emptyBill();
        if(props.bill && props.visible){
            setLoading(true);
            showBill(props.bill);
        }
    }, [props.visible]);
    
    async function loadCostCenters(){
        setLoadingCostCenters(true);
        setCostCenters([]);
        let response = await api.get(`/cost_centers`);
        setCostCenters(response.data.data);
        setLoadingCostCenters(false);
    }

    async function loadCategories(){
        setLoadingCategories(true);
        setCategories([]);
        let categories_type = props.type == 'to_pay' ? 'EXPENSE' : 'REVENUE';
        let response = await api.get(`/financial_categories?type=${categories_type}&filter=parents`);

        console.log(response.data.data);

        setCategories(response.data.data);
        setLoadingCategories(false);
    }

    async function loadContacts(){
        setLoadingContacts(true);
        setContacts([]);
        let contact_type = props.type == 'to_pay' ? 'provider' : 'client';
        let response = await api.get(`/contacts?type=${contact_type}`);
        setContacts(response.data.data);
        setLoadingContacts(false);
    }

    function handleTotalValue(new_total_value) {
        setTotalValue(new_total_value);
        calcPlots(plots, new_total_value);
        validatePlots(plots);
    }

    function handlePlotChange(idx, name, value){
        const updatedPlots = [...plots];
        updatedPlots[idx][name] = value;
        setPlots(updatedPlots);
        validatePlots(updatedPlots);
    }

    function deletePlot(idx){
        if(plots.length > 1){
            const updatedPlots = [...plots.filter((plot, key) => key != idx)];
            setPlots(updatedPlots);
            calcPlots(updatedPlots);
            validatePlots(updatedPlots);
        }
    }

    function addPlot(){
        let new_plot = blank_plot;
        new_plot.due_date = moment(plots[plots.length-1].due_date).add(1, 'M').format('YYYY-MM-DD');
        let updatedPlots = [...plots, {...new_plot}];
        setPlots(updatedPlots);
        calcPlots(updatedPlots);
        validatePlots(updatedPlots);
    }

    function calcPlots(plots, new_total_value = total_value){
        let updatedPlots = plots;

        new_total_value = cleanFloatValue(new_total_value);

        let plot_value = new_total_value/updatedPlots.length;
        let diff_value = new_total_value - (plot_value.toFixed(2)*updatedPlots.length);

        updatedPlots.forEach(plot => {
            plot.value = plot_value;
        });
        updatedPlots[updatedPlots.length-1].value = diff_value + updatedPlots[updatedPlots.length-1].value;

        setPlots(updatedPlots);
    }

    async function saveBill() {
        setSaving(true);

        let clean_plots = [];

        if(!props.bill){
            let tmp_plots = plots;
            tmp_plots.map(plot => {
                plot.value = cleanFloatValue(plot.value);
                clean_plots.push(plot);
            });
        }

        let data = {
            cost_center: cost_center ? cost_center : null,
            category,
            document_number,
            issue_date: issue_date,
            competence_date: competence_date,
            contact: contact ? contact : null,
            total_value: cleanFloatValue(total_value),
            description,
            note_bill,
            plots: clean_plots,
            to_pay: props.type == 'to_pay' ? true : false,
            to_receive: props.type == 'to_receive' ? true : false,
        };

        if(props.bill){
            data = {...data, due_date: due_date};
        }

        try {
            let response = null;

            if(props.bill){
                response = await api.put(`/bills/${props.bill.id}`, data);
            } else {
                response = await api.post(`/bills`, data);
            }

            if(response.data.success) {
                message.success('Conta salva com sucesso');
            } else {
                message.error('Ocorreu um erro, aguarde alguns instantes e tente novamente');
            }

            setSaving(false);
            props.afterSave();
            props.setVisibility(false);
        } catch (error) {
            message.error('Ocorreu um erro, aguarde alguns instantes e tente novamente');
            setSaving(false);
        }
    }

    function validatePlots(updatedPlots) {
        let valid = true;
        updatedPlots.forEach(plots => {
            if(!plots.value || !plots.due_date){
                valid = false;
            }
        });
        setPlotsIsValid(valid); 
    }

    

    function emptyBill() {
        setShowContact(false);
        setShowCostCenter(false);
        setShowMoreInfo(false);

        setId('');
        setCategories([]);
        setCostCenters([]);
        setContacts([]);
        setCategory('');
        setCostCenter('');
        setContact('');
        setDocumentNumber('');
    
        setDocumentNumber('');
        setIssueDate(format(new Date(), "yyyy-MM-dd"));
        setCompetenceDate(format(new Date(), "yyyy-MM-dd"));
        setDueDate(format(new Date(), "yyyy-MM-dd"));
        setTotalValue('');
        setDescription('');
        setNoteBill('');
    
        setPlots([{...blank_plot}]);
        setPlotsIsValid(false);
    }

    async function showBill(plot) {
        setLoading(true);
        setShowPlots(false);

        setId(plot.id);
        if(plot.category) {
            await loadCategories();
            setCategory(plot.category.id);
        }
        if(plot.cost_center) {
            setShowCostCenter(true);
            await loadCostCenters();
            setCostCenter(plot.cost_center.id);
        }
        if(plot.contact) {
            setShowContact(true);
            await loadContacts();
            setContact(plot.contact.id);
        }
        setDocumentNumber(plot.document_number);
        setIssueDate(plot.issue_date);
        setCompetenceDate(plot.competence_date);
        setDueDate(plot.due_date);
        setTotalValue(plot.value);
        setDescription(plot.description);
        
        setLoading(false);
    }

    return (
        <Modal
            title={'Informações da Conta'}
            visible={ props.visible }
            footer={[
                <Button
                    type="success"
                    onClick={ () => saveBill() }
                    loading={ saving }
                    disabled={(
                        !issue_date ||
                        !competence_date ||
                        !total_value ||
                        !category ||
                        !description ||
                        (show_plots && !plots_is_valid) ||
                        (props.bill && !due_date)
                    )}
                >
                    Salvar
                </Button>,
            ]}
            onCancel={() => props.setVisibility(false)}
        >
            <Spin spinning={loading}>
                <Form layout='vertical'>
                    <Row style={{paddingTop:'10px'}}>
                        <Col span={17}>
                            <Form.Item
                                className="form-item"
                                label="Descrição:"
                                required={true}
                                style={{ paddingLeft:0 }}
                            >
                                <Input
                                    id="description"
                                    value={ description }
                                    onChange={ event => setDescription(event.target.value) }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={7}>
                            <Form.Item
                                className="form-item"
                                label="Documento:"
                            >
                                <Input
                                    id="document_number"
                                    value={ document_number }
                                    onChange={ event => setDocumentNumber(event.target.value) }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <Form.Item
                                className="form-item"
                                label="Valor:"
                                required={true}
                                style={{ paddingLeft:0 }}
                            >
                                <CurrencyInput
                                    value={total_value}
                                    onChangeEvent={ event => handleTotalValue(event.target.value)}
                                    className="ant-input"
                                    decimalSeparator=","
                                    thousandSeparator=""
                                    prefix="R$ "
                                />
                            </Form.Item>
                        </Col>
                        <Col span={16}>
                            <Form.Item
                                className="form-item"
                                label="Categoria:"
                                required={true}
                            >
                                <Select 
                                    value={category} 
                                    onChange={ async (value) => setCategory(value) }
                                    onFocus={ () => loadCategories() }
                                    loading={ loading_categories }
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => {
                                        if(!Array.isArray(option.props.children) && option.props.children !== undefined){
                                            return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    }}  
                                >
                                    <Option value="" disabled>Selecione</Option>
                                    { categories.map(category => (
                                        <OptGroup
                                            key={category.id}
                                            label={ category.description }
                                        >
                                            { category.children.map(item => (
                                                <Option
                                                    key={item.id}
                                                    value={ item.id }
                                                >
                                                    { item.description }
                                                </Option>
                                            )) }
                                        </OptGroup>
                                    )) }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                className="form-item"
                                label="Emissão:"
                                required={true}
                                style={{ paddingLeft:0 }}
                            >
                                <DatePicker
                                    locale={locale}
                                    showTime={false}
                                    value={issue_date !== '' ? moment(issue_date) : ''}
                                    format="DD/MM/YYYY"
                                    onChange={ (date, dateString) => {
                                        setIssueDate(date !== null ? format(date._d, "yyyy-MM-dd") : '');
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                className="form-item"
                                label="Data de competência:"
                                required={true}
                                hasFeedback
                            >
                                <DatePicker
                                    locale={locale}
                                    showTime={false}
                                    value={competence_date !== '' ? moment(competence_date) : ''}
                                    format="DD/MM/YYYY"
                                    onChange={ (date, dateString) => {
                                        setCompetenceDate(date !== null ? format(date._d, "yyyy-MM-dd") : '');
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    { props.bill && (
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    className="form-item"
                                    label="Data de vencimento:"
                                    required={true}
                                    hasFeedback
                                    style={{ paddingLeft:0 }}
                                >
                                    <DatePicker
                                        locale={locale}
                                        showTime={false}
                                        value={due_date !== '' ? moment(due_date) : ''}
                                        format="DD/MM/YYYY"
                                        onChange={ (date, dateString) => {
                                            setDueDate(date !== null ? format(date._d, "yyyy-MM-dd") : '');
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    )}

                    { showContact && <>
                        <Row style={{paddingTop: '15px'}}>
                            <Col span={24}>
                                <Form.Item
                                    className="form-item"
                                    label={props.type == 'to_pay' ? 'Fornecedor' : 'Cliente'}
                                    style={{ paddingLeft: 0, paddingRight:0 }}
                                >
                                    <Select 
                                        value={contact} 
                                        onChange={ async (value) => setContact(value) }
                                        onFocus={ () => loadContacts() }
                                        loading={ loading_contacts }
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        <Option value="" disabled>Selecione</Option>
                                        { contacts.map(item => (
                                            <Option
                                                key={item.id}
                                                value={ item.id }
                                            >
                                                { item.name }
                                            </Option>
                                        )) }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </>}

                    { showCostCenter && <>
                        <Row style={{ paddingTop: '10px' }}>
                            <Col span={24}>
                                <Form.Item
                                    className="form-item"
                                    label="Centro de custo:"
                                    style={{ paddingLeft: 0, paddingRight:0 }}
                                >
                                    <Select 
                                        value={cost_center} 
                                        onChange={ async (value) => setCostCenter(value) }
                                        onFocus={ () => loadCostCenters() }
                                        loading={ loading_cost_centers }
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        <Option value="" disabled>Selecione</Option>
                                        { cost_centers.map(item => (
                                            <Option
                                                key={item.id}
                                                value={ item.id }
                                            >
                                                { item.name }
                                            </Option>
                                        )) }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </> }


                    { show_plots && plots.map((item, idx) => (
                        <Row>
                            <Col span={4}>
                                <Form.Item
                                    className="form-item"
                                    label="Parcela:"
                                    style={{paddingLeft:0}}
                                >
                                    <Input
                                        disabled={true}
                                        id="amount"
                                        value={ `${idx+1}/${plots.length}` }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    className="form-item"
                                    label="Valor:"
                                    required={true}
                                >
                                    <CurrencyInput
                                        value={item.value}
                                        onChangeEvent={ event => handlePlotChange(idx, "value", event.target.value) }
                                        className="ant-input"
                                        decimalSeparator=","
                                        thousandSeparator=""
                                        name="value"
                                        data-idx={idx}
                                        id={`value-${idx}`}
                                        prefix="R$ "
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={10}>
                                <Form.Item
                                    className="form-item"
                                    label="Data de vencimento:"
                                    required={true}
                                >
                                    <DatePicker
                                        locale={locale}
                                        showTime={false}
                                        value={item.due_date !== '' ? moment(item.due_date) : ''}
                                        format="DD/MM/YYYY"
                                        id={`due_date-${idx}`}
                                        onChange={ (date, dateString) => {
                                            handlePlotChange(idx, "due_date", date !== null ? format(date._d, "yyyy-MM-dd") : '')
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={2} >
                                <Form.Item
                                    className="form-item"
                                    label=""
                                    style={{paddingTop:'39px', paddingLeft:0}}
                                >
                                    <Tooltip title="Deletar" placement="right">
                                        <Button 
                                            type="link"
                                            icon={<DeleteOutlined />}
                                            // disabled={loading_account}
                                            onClick={event => deletePlot(idx)}
                                            style={{
                                                marginLeft: '5px',
                                                paddingTop: '4px',
                                                paddingBottom: '4px',
                                                paddingLeft: 0,
                                                paddingRight: '10px',
                                                width:'auto',
                                                color: '#ff4d4f !important',
                                            }}
                                        ></Button>
                                    </Tooltip>
                                </Form.Item>
                            </Col>
                        </Row>
                    ))}
                    {show_plots && <Row>
                        <Col span={24} style={{paddingRight:'10px', paddingTop:'20px'}}>
                            <Form.Item >
                                <Button 
                                    type="dashed" 
                                    onClick={addPlot}
                                    style={{ width: '100%' }}
                                >
                                    <PlusOutlined /> Nova Parcela
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row> }



                    { showMoreInfo && <>
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    className="form-item"
                                    label="Informações adicionais:"
                                    style={{ paddingLeft: 0, paddingRight: 0 }}
                                >
                                    <TextArea 
                                        rows={3}
                                        id="note_bill"
                                        value={ note_bill }
                                        onChange={ event => setNoteBill(event.target.value) }
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </>}


                    <Row>
                        <Col span={24} style={{paddingTop:'15px'}}>
                            <p>
                                <a
                                    onClick={() => setShowContact(!showContact)}
                                    style={ showContact ? {color: "#9797ad"} : {} }
                                >
                                    + {props.type == 'to_pay' ? 'Fornecedor' : 'Cliente'}
                                </a>
                                <a 
                                    style={ showCostCenter ? {color: "#9797ad", marginLeft:'15px'} : {marginLeft:'15px'} }
                                    onClick={() => setShowCostCenter(!showCostCenter)}
                                >
                                    + Centro de custo
                                </a>
                                <a 
                                    style={ showMoreInfo ? {color: "#9797ad", marginLeft:'15px'} : {marginLeft:'15px'} }
                                    onClick={() => setShowMoreInfo(!showMoreInfo)}
                                >
                                    + Observação
                                </a>
                            </p>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </Modal>
    );
}

export default BillEdit;