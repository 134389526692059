import React, { useEffect, useState } from 'react';
import { 
    Modal,
    Button,
    Row,
    Col,
    Form,
    Input,
    message,
    Layout,
    DatePicker,
    Select,
    Spin,
    Checkbox,
    Tooltip,
    Table,
    Empty,
} from 'antd';
import {
    DeleteOutlined
} from '@ant-design/icons';
import {NumericFormat} from 'react-number-format';
import CurrencyInput from 'react-currency-input';
import locale from 'antd/es/date-picker/locale/pt_BR';
import moment from 'moment';
import 'moment/locale/pt-br';
import { parseISO, format } from 'date-fns';

import api from '../../../services/api';

const cleanFloatValue = require('../../../utils/cleanFloatValue');

const { Option } = Select;
const { confirm } = Modal;

function BillPayments(props) {
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [loading_financial_accounts, setLoadingFinancialAccounts] = useState(false);

    const [data, setData] = useState([]);

    const [financial_accounts, setFinancialAccounts] = useState([]);

    const [id, setId] = useState('');
    const [type, setType] = useState('to_pay');
    const [payment_financial_account, setPaymentFinancialAccount] = useState('');
    const [payment_date, setPaymentDate] = useState(format(new Date(), "yyyy-MM-dd"));
    const [payment_value, setPaymentValue] = useState(0);
    const [payment_discount_value, setPaymentDiscountValue] = useState(0);
    const [payment_interest_value, setPaymentInterestValue] = useState(0);
    const [deleted_payments, setDeletedPayments] = useState(false);

    const [tableLength, setTableLength] = useState(10);
    const [tablePagination, setTablePagination] = useState({current: 1, pageSize: tableLength});
    const [tableFilters, setTableFilters] = useState({});
    const [tableSorter, setTableSorter] = useState({ field: 'payday', order: 'descend' });
    const [search, setSearch] = useState('');
    
    useEffect(() => {
        emptyPayment();
        if(props.bill && props.visible){
            setLoading(true);
            if(props.bill.bill){
                setType(props.bill.bill.to_pay ? 'to_pay' : 'to_receive');
                setPaymentValue(props.bill.open_value);
            }
            setId(props.bill.id);
            showPayments(props.bill);
        }
    }, [props.visible]);

    useEffect(() => {
        if(props.bill && props.visible){
            setLoading(true);
            setId(props.bill.id);
            showPayments(props.bill);
        }
    }, [deleted_payments]);
    
    async function loadFinancialAccounts(){
        setLoadingFinancialAccounts(true);
        setFinancialAccounts([]);
        let response = await api.get(`/financial_accounts`);
        setFinancialAccounts(response.data.data);
        setLoadingFinancialAccounts(false);
    }

    async function savePayment() {
        setSaving(true);

        const data = {
            payment_date: format(new Date(payment_date), "yyyy-MM-dd"),
            payment_financial_account,
            payment_value: cleanFloatValue(payment_value),
            payment_discount_value: cleanFloatValue(payment_discount_value),
            payment_interest_value: cleanFloatValue(payment_interest_value),
        };

        try {
            let response = await api.post(`/bills/plots/${id}/payments`, data);

            if(response.data.success) {
                message.success('Pagamento realizado com sucesso');
            } else {
                message.error('Ocorreu um erro, aguarde alguns instantes e tente novamente');
            }

            setPaymentFinancialAccount('');
            setPaymentDate(new Date());
            setPaymentValue(0);
            setPaymentDiscountValue(0);
            setPaymentInterestValue(0);
            setDeletedPayments(false);

            setSaving(false);

            showPayments(props.bill);
        } catch (error) {
            message.error('Ocorreu um erro, aguarde alguns instantes e tente novamente');
            setSaving(false);
        }
    }

    function deletePayment(payment_id) {
        confirm({
            title: 'Você tem certeza?',
            content: 'Esta ação não poderá ser desfeita!',
            okText: 'Deletar',
            okType: 'danger',
            cancelText: 'Cancelar',
            async onOk() {
                let response = await api.delete(`/bills/plots/${id}/payments/${payment_id}`);

                if(response.data.success) {
                    message.success('Pagamento deletado com sucesso');
                } else {
                    message.error('Ocorreu um erro, aguarde alguns instantes e tente novamente');
                }

                showPayments(props.bill);
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    async function showPayments(
        plot = null,
        pagination = tablePagination,
        filters = tableFilters,
        sorter = tableSorter
    ) {
        setTablePagination(pagination);
        setTableFilters(filters);
        if(!sorter.field){
            sorter = tableSorter
        }
        setTableSorter(sorter);

        let order = sorter.order;
        if(order === undefined) {
            order = 'asc';
        } else if(sorter.order === 'ascend') {
            order = 'asc';
        } else if(sorter.order === 'descend') {
            order = 'desc';
        }

        let uri = `/bills/plots/${plot.id}/payments?page=${pagination.current}&per_page=${tableLength}&order=${order}&order_by=${sorter.field}&search=${search}`;
        uri += `&deleted_payments=${deleted_payments ? 1 : 0}`;
        
        let response = await api.get(uri);

        let payments_list = response.data.data;

        setData(payments_list);
        setTablePagination({
            current: pagination.current,
            pageSize: tableLength,
            total: payments_list.total,
        });

        setLoading(false);
    }

    function emptyPayment() {
        setPaymentFinancialAccount('');
        setPaymentDate(new Date());
        setPaymentValue('');
        setPaymentDiscountValue('');
        setPaymentInterestValue('');
        setDeletedPayments(false);
    }
    
    function fixName(name){
        name = name.split(' ');
        name = name[0];
        return name;
    }

    function formatDate(date){
        if(date !== null && date !== '' && date !== '0000-00-00'){
            date = parseISO(date);
            return format(date, 'dd/MM/yyyy');
        } else {
            return '-';
        }
    }
    function formatTime(date){
        date = parseISO(date);
        return format(date, 'HH:mm');
    }
    function formatDateTime(date){
        if(date !== null && date !== '' && date !== '0000-00-00'){
            date = parseISO(date);
            return format(date, "dd/MM/yyyy 'às' HH:mm");
        } else {
            return '-';
        }
    }
    function firstName(name){
        name = name.split(' ');
        name = name[0];
        return name;
    }
    function registeredBy(payment){
        return <Tooltip title={ payment.user ? payment.user.name : '-' }>
            { `${firstName(payment.user ? payment.user.name : '-')}` }
        </Tooltip>
    }

    function fixDelete(payment){
        if(!payment.deleted_at){
            return <>
                <Tooltip title="Deletar" placement="right">
                    <Button 
                        type="secondary-default" 
                        icon={<DeleteOutlined />}
                        onClick={event => deletePayment(payment.id)}
                        style={{ 
                            marginLeft: '5px',
                            paddingTop: '4px',
                            paddingBottom: '4px',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            width:'auto',
                            color: '#ff4d4f !important',
                        }}
                    ></Button>
                </Tooltip>
            </>
        } else {
            return <>
                <span style={{ fontWeight:'bold' }}>
                    { formatDate(payment.deleted_at) }
                </span>
                <br />
                <span style={{ fontWeight:'bold' }}>
                    { formatTime(payment.deleted_at) }
                </span>
                <br />
                <span style={{ fontWeight:'bold', color:'#f5365c' }}>
                    <Tooltip title={ payment.deleted_by_user.name }>
                        { `[ ${fixName(payment.deleted_by_user.name)} ]` }
                    </Tooltip>
                </span>
            </>
        }
    }

    const columns = [
        {
            title: '',
            render: (payment => (
                <>
                    <NumericFormat 
                        value={payment.value ? payment.value : 0}
                        displayType={'text'}
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={2} 
                        fixedDecimalScale={true}
                        style={{ fontWeight:'bold', color: '#1976ca', marginRight: '5px' }}
                    />
                    (<span style={{ fontWeight:'bold', marginLeft: '5px' }}>
                        - <NumericFormat 
                            value={payment.discount_value ? payment.discount_value : 0}
                            displayType={'text'}
                            thousandSeparator="."
                            decimalSeparator=","
                            decimalScale={2} 
                            fixedDecimalScale={true}
                        />
                    </span>
                    <span style={{ fontWeight:'bold', marginLeft: '5px', marginRight: '5px' }}>
                        + <NumericFormat 
                            value={payment.interest_value ? payment.interest_value : 0}
                            displayType={'text'}
                            thousandSeparator="."
                            decimalSeparator=","
                            decimalScale={2} 
                            fixedDecimalScale={true}
                        />
                    </span>)
                    <br />
                    <span style={{fontWeight: 'bold'}}>{payment.financial_account.name}</span>
                    <br />
                    <p>Lançado por {registeredBy(payment)} em {formatDateTime(payment.created_at ? payment.created_at : '')}</p>
                </>
            )),
            align: 'left',
        }, {
            title: '',
            width: '25%',
            render: (payment => (
                fixDelete(payment)
            )),
        },
    ];

    return (
        <Modal
            title={type == 'to_pay' ? 'Pagamentos' : 'Recebimentos'}
            visible={ props.visible }
            width={630}
            footer={[]}
            onCancel={() => {
                props.afterSave();
                props.setVisibility(false);
            }}
        >
            <Spin spinning={loading}>
                <Form layout='vertical'>
                    <Row>
                        <Col span={10}>
                            <Form.Item
                                className="form-item"
                                label="Data do pagamento:"
                                required={true}
                                style={{ paddingLeft:0 }}
                            >
                                <DatePicker
                                    locale={locale}
                                    showTime={false}
                                    value={payment_date !== '' ? moment(payment_date) : ''}
                                    format="DD/MM/YYYY"
                                    onChange={ (date, dateString) => {
                                        setPaymentDate(date !== null ? format(date._d, "yyyy-MM-dd") : '');
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={14}>
                            <Form.Item
                                className="form-item"
                                label={`Local do ${type == 'to_pay' ? 'pagamento' : 'recebimento'}:`}
                                required={true}
                                style={{ paddingRight: 0 }}
                            >
                                <Select 
                                    value={ payment_financial_account } 
                                    style={{ width: 120 }}
                                    onChange={ (value) => setPaymentFinancialAccount(value) }
                                    onFocus={ () => loadFinancialAccounts() }
                                    loading={ loading_financial_accounts }
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Option value="" disabled>Selecione</Option>
                                    { financial_accounts.map(item => (
                                        <Option
                                            key={item.id}
                                            value={ item.id }
                                        >
                                            { item.name }
                                        </Option>
                                    )) }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={8}>
                            <Form.Item
                                className="form-item"
                                label="Valor:"
                                required={true}
                                style={{ paddingLeft: 0 }}
                            >
                                <CurrencyInput
                                    value={payment_value}
                                    onChangeEvent={ event => setPaymentValue(event.target.value)}
                                    className="ant-input"
                                    decimalSeparator=","
                                    thousandSeparator=""
                                    prefix="R$ "
                                />                                            
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                className="form-item"
                                label="Desconto:"
                            >
                                <CurrencyInput
                                    value={payment_discount_value}
                                    onChangeEvent={ event => setPaymentDiscountValue(event.target.value)}
                                    className="ant-input"
                                    decimalSeparator=","
                                    thousandSeparator=""
                                    prefix="R$ "
                                />                                            
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                className="form-item"
                                label="Juros/Multa:"
                                style={{paddingRight:0}}
                            >
                                <CurrencyInput
                                    value={payment_interest_value}
                                    onChangeEvent={ event => setPaymentInterestValue(event.target.value)}
                                    className="ant-input"
                                    decimalSeparator=","
                                    thousandSeparator=""
                                    prefix="R$ "
                                />                                            
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                className="form-item"
                                style={{ paddingTop: '10px', paddingLeft: 0, paddingRight: 0 }}
                            >
                                <Button 
                                    type="success"
                                    onClick={e => savePayment()}
                                    loading={saving}
                                    style={{ width:'100%' }}
                                    disabled={
                                        !payment_date ||
                                        !payment_financial_account ||
                                        !payment_value
                                    }
                                >
                                    {type == 'to_pay' ? 'Pagar' : 'Receber'}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ paddingTop: '20px', textAlign:'right' }}>
                            <Checkbox
                                value={deleted_payments}
                                onChange={event => { setDeletedPayments(event.target.checked) }}
                            >
                                Exibir {type == 'to_pay' ? 'Pagamentos' : 'Recebimentos'} deletados
                            </Checkbox>
                        </Col>
                    </Row>




                    <Row style={{ marginTop: '20px', paddingTop: '10px', borderTopWidth: '0.5px', borderTopColor: '#c9d3dd', borderTopStyle: 'solid' }}>
                        <Col span={24} >
                            <Table
                                bordered={true}
                                columns={columns}
                                rowKey={record => record.id}
                                dataSource={data.data}
                                pagination={tablePagination}
                                loading={loading}
                                // onChange={showPayments}
                                pageSize={tableLength}
                                locale={{ emptyText: <Empty description={<b>Nenhum registro encontrado...</b>} /> }}
                            />
                        </Col>
                    </Row>

                </Form>
            </Spin>
        </Modal>
    );
}

export default BillPayments;