import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import {
    Layout,
    Form,
    Breadcrumb,
    Input,
    Button,
    Row,
    Col,
    Select,
    DatePicker,
    message,
    Table,
    Modal,
    Tooltip,
    Card,
    Alert,
    Empty,
} from 'antd';
import { 
    PlusOutlined,
    EditOutlined,
    DeleteOutlined
} from '@ant-design/icons';

import locale from 'antd/es/date-picker/locale/pt_BR';
import {NumericFormat} from 'react-number-format';
import { parseISO, format } from 'date-fns';
import moment from 'moment';
import 'moment/locale/pt-br';
import CurrencyInput from 'react-currency-input';

import Topbar from '../components/Topbar';
import Footbar from '../components/Footbar';
import SubscriptionModal from '../components/Modal/Subscription/Details';
// import WithdrawalModal from '../components/Modal/FinancialAccount/Withdrawal';
// import IncrementModal from '../components/Modal/FinancialAccount/Increment';

import 'antd/dist/antd.css';
import './styles/Main.css';

import api from '../services/api.js';

const cleanFloatValue = require('../utils/cleanFloatValue');

const { Search } = Input;
const { Content } = Layout;
const { confirm } = Modal;
const { Option } = Select;

export default function Subscription() {
    const [loadingTable, setLoadingTable] = useState(true);
    const [loading_subscription, setLoadingSubscription] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [saving, setSaving] = useState(false);
    const [show_subscription_modal, setShowSubscriptionModal] = useState(false);
    // const [visible_modal_withdrawal, setVisibleModalWithdrawal] = useState(false);
    // const [visible_modal_increment, setVisibleModalIncrement] = useState(false);

    const [subscription, setSubscription] = useState({});
    
    const [data, setData] = useState([]);
    // const [id, setId] = useState(false);
    // const [visible_modal, setVisibleModal] = useState(false);
    // const [visible_modal_transfer, setVisibleModalTransfer] = useState(false);
    // const [financial_accounts, setFinancialAccounts] = useState([]);
    // const [destiny_account_id, setDestinyAccountId] = useState('');

    
    // const [origin_transfer_account_id, setOriginTransferAccountId] = useState('');
    // const [destiny_transfer_account_id, setDestinyTransferAccountId] = useState('');
    // const [description_transfer, setDescriptionTransfer] = useState('');
    // const [value_transfer, setValueTransfer] = useState('');
    // const [date_transfer, setDateTransfer] = useState(format(new Date(), "yyyy-MM-dd"));

    // const [filter_type, setFilterType] = useState('');
    // const [filter_name, setFilterName] = useState('');
    // const [filter_opening_date_begin, setFilterOpeningDateBegin] = useState('');
    // const [filter_opening_date_end, setFilterOpeningDateEnd] = useState('');

    const [tableLength, setTableLength] = useState(10);
    const [tablePagination, setTablePagination] = useState({current: 1, pageSize: tableLength});
    const [tableFilters, setTableFilters] = useState({});
    const [tableSorter, setTableSorter] = useState({ field: 'description', order: 'ascend' });
    const [search, setSearch] = useState('');

    useEffect( () => {
        loadData();
    }, [tableLength]);

    async function reloadData(){
        loadData({current: 1, pageSize: tableLength});
    }

    async function loadData(
        pagination = tablePagination,
        filters = tableFilters,
        sorter = tableSorter
    ) {
        setLoadingTable(true);

        setTablePagination(pagination);
        setTableFilters(filters);
        if(!sorter.field){
            sorter = tableSorter
        }
        setTableSorter(sorter);

        let order = sorter.order;
        if(order === undefined) {
            order = 'asc';
        } else if(sorter.order === 'ascend') {
            order = 'asc';
        } else if(sorter.order === 'descend') {
            order = 'desc';
        }

        // SUBSCRIPTIONS
        let uri = `/subscriptions?page=${pagination.current}&per_page=${tableLength}&order=${order}&order_by=${sorter.field}&search=${search}`;
        // uri += `&name=${filter_name}`;
        // uri += `&type=${filter_type}`;
        // uri += `&opening_date_begin=${filter_opening_date_begin}`;
        // uri += `&opening_date_end=${filter_opening_date_end}`;
        
        let response = await api.get(uri);

        let subscriptions_list = response.data.data;

        setData(subscriptions_list);
        setTablePagination({
            current: pagination.current,
            pageSize: tableLength,
            total: subscriptions_list.total,
        });

        setLoadingTable(false);
    }

    async function showSubscription(subscription_data = null) {
        setSubscription(subscription_data);
        setShowSubscriptionModal(true);
    }

    async function deleteSubscription(subscription_id) {
        setLoadingSubscription(true);

        confirm({
            title: 'Você deseja deletar a assinatura?',
            content: 'Esta ação não poderá ser desfeita!',
            okText: 'Deletar',
            okType: 'danger',
            cancelText: 'Cancelar',
            async onOk() {
                let response = await api.delete(`/subscriptions/${subscription_id}`);

                if(response.data.success) {
                    message.success('Assinatura deletada com sucesso');
                } else {
                    message.error('Ocorreu um erro, aguarde alguns instantes e tente novamente');
                }
                loadData();
            },
            onCancel() {
                console.log('Cancel');
            },
        });

        setLoadingSubscription(false);
    }

    function formatDate(date){
        if(date !== null && date !== '' && date !== '0000-00-00'){
            date = parseISO(date);
            return format(date, 'dd/MM/yyyy');
        } else {
            return '-';
        }
    }

    function fixInterval(interval){
        switch (interval) {
            case 'biweekly':
                return 'Quinzenal';
            case 'monthly':
                return 'Mensal';
            case 'bimonthly':
                return 'Bimestral';
            case 'quarterly':
                return 'Trimestral';
            case 'semiannual':
                return 'Semestral';
            case 'yearly':
                return 'Anual';
            default:
                return '-';
        }
    }

    const columns = [
        {
            title: 'Descrição',
            render: ( subscription => (
                <>
                    {subscription.description}
                    <br />
                    <span style={{ fontWeight:'bold', color: subscription.type == 'to_pay' ? '#f5222d' : '#38b5a0', fontSize: '12px' }}>
                        {subscription.type == 'to_pay' ? 'A pagar' : 'A receber'}
                    </span>
                </>
            )),
            align: 'left',
            width: '30%',
        }, {
            title: 'Intervalo',
            render: ( subscription => (
                <>
                    {fixInterval(subscription.interval)}
                </>
            )),
            align: 'left',
            width: '20%',
        }, {
            title: 'Última cobrança',
            render: ( subscription => (
                <>
                    {formatDate(subscription.last_due_date)}
                </>
            )),
            align: 'left',
            width: '20%',
        }, {
            title: 'VALOR (R$)',
            align: 'right',
            render: ( subscription => (
                <NumericFormat 
                    value={subscription.value ? subscription.value : 0}
                    displayType={'text'}
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2} 
                    fixedDecimalScale={true}
                    style={{ fontWeight:'bold', color: '#5e72e4' }}
                />
            )),
            width: '10%',
        }, {
            title: 'AÇÕES',
            width: '20%',
            render: (subscription => (
                <>
                    <Tooltip title="Editar" placement="top">
                        <Button
                            type="secondary-default"
                            icon={<EditOutlined />}
                            onClick={event => showSubscription(subscription)}
                            disabled={loading_subscription}
                            style={{
                                marginLeft: '5px',
                                paddingTop: '4px',
                                paddingBottom: '4px',
                                paddingLeft: '10px',
                                paddingRight: '10px',
                                width:'auto',
                            }}
                        ></Button>
                    </Tooltip>
                    <Tooltip title="Deletar" placement="right">
                        <Button
                            type="secondary-default"
                            icon={<DeleteOutlined />}
                            disabled={loading_subscription}
                            onClick={event => deleteSubscription(subscription.id)}
                            style={{ 
                                marginLeft: '5px',
                                paddingTop: '4px',
                                paddingBottom: '4px',
                                paddingLeft: '10px',
                                paddingRight: '10px',
                                width:'auto',
                                color: '#ff4d4f !important',
                            }}
                        ></Button>
                    </Tooltip>
                </>
            )),
        },
    ];

    // let loading_screen = '';
    // if(loading){
    //     loading_screen = <Loading />
    // }

    return (
        <Layout className="layout">
            {/* { loading_screen } */}
            <Topbar />

            <Content className="fbm-main">
                <div id="main">
                    <Row style={{ marginLeft: '-10px', marginRight: '-10px' }}>
                        <Col span={24} style={{ padding: '0 10px 10px 10px' }}>
                            <Card loading={loading} className="fbm-card">
                                <Row style={{ paddingBottom: '15px', borderBottomWidth: '0.5px', borderBottomColor: '#e8e8e8', borderBottomStyle: 'solid' }}>
                                    <Col className="fbm-card-header" span={24}>
                                        <h1 className="big-title">
                                            Recorrência
                                        </h1>
                                        <Button 
                                            type="success"
                                            onClick={() => showSubscription()}
                                            style={{marginLeft: '15px', borderRadius: '50%'}}
                                        >
                                            <PlusOutlined />
                                        </Button>
                                    </Col>
                                    <Col span={24}>
                                        <Alert style={{marginTop:'15px'}} type="info" showIcon description="As contas cadastradas aqui serão lançadas automaticamente pelo sistema de acordo com o intervalo definido no momento do cadastro. Os lançamentos automáticos ocorrem todos os dias às 03h e 22h.
                                            As contas serão lançadas usando como referência a data da 'última cobrança'. Exemplo: Uma conta mensal será lançada exatamente 1 mês após a data da 'última cobrança'." />
                                    </Col>
                                </Row>
                                {/* <Row style={{ paddingBottom: '15px', borderBottomWidth: '0.5px', borderBottomColor: '#c9d3dd', borderBottomStyle: 'dashed' }}>
                                    <Col span={4}>
                                        <Form.Item
                                            className="form-item"
                                            label="Tipo:"
                                            style={{ paddingLeft: '0' }}
                                        >
                                            <Select 
                                                value={ filter_type }
                                                style={{ width: 120 }} 
                                                onChange={ (value) => setFilterType(value) }
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                <Option value="">Todos</Option>
                                                <Option key="CX" value="CX">Caixinha</Option>
                                                <Option key="CC" value="CC">Conta Corrente</Option>
                                                <Option key="PP" value="PP">Poupança</Option>
                                                <Option key="IV" value="IV">Investimento</Option>
                                                <Option key="AP" value="AP">Aplicação</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item
                                            className="form-item"
                                            label="Nome da conta:"
                                        >
                                            <Input
                                                id="name" value={ filter_name }
                                                onChange={ event => setFilterName(event.target.value) }
                                                required
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item
                                            className="form-item"
                                            label="Data de abertura (início):"
                                        >
                                            <DatePicker
                                                locale={locale}
                                                showTime={false}
                                                value={filter_opening_date_begin !== '' ? moment(filter_opening_date_begin) : ''}
                                                format="DD/MM/YYYY"
                                                onChange={ (date, dateString) => {
                                                    setFilterOpeningDateBegin(date !== null ? format(date._d, "yyyy-MM-dd") : '');
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item
                                            className="form-item"
                                            label="Data de abertura (fim):"
                                        >
                                            <DatePicker 
                                                locale={locale}
                                                showTime={false}
                                                value={filter_opening_date_end !== '' ? moment(filter_opening_date_end) : ''}
                                                format="DD/MM/YYYY"
                                                onChange={ (date, dateString) => {
                                                    setFilterOpeningDateEnd(date !== null ? format(date._d, "yyyy-MM-dd") : '');
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item
                                            className="form-item"
                                            style={{ marginTop: '38px', paddingRight: '0' }}
                                        >
                                            <Button 
                                                key="submit"
                                                type="primary"
                                                loading={loadingTable}
                                                onClick={ () => reloadData() }
                                                style={{ width: '100%' }}
                                            >
                                                Filtrar
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row> */}
                                <Row style={{ paddingTop:'20px',paddingBottom: '0' }}>
                                    <Col span={24}>
                                        <div style={{ overflow: 'auto', marginBottom:'15px' }}>
                                            <Form.Item
                                                className="form-item"
                                                style={{ 
                                                    paddingTop: 0, 
                                                    paddingRight: 0,
                                                    paddingLeft: 0,
                                                    paddingBottom: 0,
                                                    width: '100px',
                                                    float: 'left',
                                                }}
                                            >
                                                <Select 
                                                    value={tableLength} 
                                                    onChange={ async (value) => setTableLength(value) }
                                                >
                                                    <Option key="10" value="10">10</Option>
                                                    <Option key="25" value="25">25</Option>
                                                    <Option key="50" value="50">50</Option>
                                                    <Option key="100" value="100">100</Option>
                                                </Select>
                                            </Form.Item>
                                            <Form.Item
                                                className="form-item"
                                                style={{ 
                                                    paddingTop: 0,
                                                    paddingRight: 0,
                                                    paddingLeft: 0,
                                                    paddingBottom: 0,
                                                    width: '200px',
                                                    float: 'right',
                                                }}
                                            >
                                                <Search
                                                    id="search"
                                                    placeholder="Pesquisar"
                                                    value={search}
                                                    onChange={ event => setSearch(event.target.value) }
                                                    onSearch={ value => reloadData() }
                                                    loading={ loadingTable }
                                                />
                                            </Form.Item>
                                        </div>
                                    </Col>
                                    <Col span={24} >
                                        <Table
                                            bordered={true}
                                            columns={columns}
                                            rowKey={record => record.id}
                                            dataSource={data.data}
                                            pagination={tablePagination}
                                            loading={loadingTable}
                                            onChange={loadData}
                                            pageSize={tableLength}
                                            locale={{ emptyText: <Empty description={<b>Nenhum registro encontrado...</b>} /> }}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Content>

            <Footbar />

            {/* START MODAL SUBSCRIPTION */}
            <SubscriptionModal subscription={subscription} afterSave={loadData} visible={show_subscription_modal} setVisibility={setShowSubscriptionModal} />
            {/* END MODAL SUBSCRIPTION */}

        </Layout>
    )
}