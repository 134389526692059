import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Layout, Tooltip } from 'antd';
import { SecurityScanOutlined, ExportOutlined } from '@ant-design/icons';

import { logout, isAuthenticated} from '../../services/auth';

import Alertbar from '../Alertbar';
import Menubar from '../Menubar';
import MySettings from '../MySettings';

import './style.css';

import logo from '../../assets/logo_white.png';

const { Header } = Layout;

function Topbar() {
    const [user, setUser] = useState([]);
    const [visible_modal, setVisibleModal] = useState(false);

    let params   = useParams();
    let navigate = useNavigate();

    useEffect(() => {
        let user_logged = localStorage.getItem('@semzebra/user');

        user_logged = JSON.parse(user_logged);

        user_logged.name = user_logged.name.split(/(\s).+\s/).join("");

        setUser(user_logged);
    }, []);

    function updateUsername () {
        let user_logged = localStorage.getItem('@semzebra/user');

        user_logged = JSON.parse(user_logged);

        user_logged.name = user_logged.name.split(/(\s).+\s/).join("");

        setUser(user_logged);
    }

    async function handleLogout() {
        logout();

        let isLogged = setInterval(() => {
            if(!isAuthenticated()) {
                clearInterval(isLogged);

                navigate('/entrar');
            }
        }, 1000);
    }

    return (
        <>
            <Alertbar />
            
            <Header className="topbar topbar-purple" style={{ padding: '0 120px' }}>
                <div className="company-name logo" style={{ textAlign: 'left' }}>
                    <Link to="/monitoramento">
                        <img src={logo} alt=""/>
                    </Link>
                </div>
                <div className="logo"></div>
                <div className="tools">
                    <Tooltip title="Meus Dados">
                        <span className='topbar-username' style={{cursor:'pointer'}} onClick={() => setVisibleModal(true)}>{ user.name }</span>
                    </Tooltip>
                    <Link to="/configuracoes/historico-login">
                        <Tooltip title="Segurança">
                            <SecurityScanOutlined className='icon' />
                        </Tooltip>
                    </Link>
                    <Tooltip title="Sair">
                        <ExportOutlined
                            className='icon'
                            onClick={ handleLogout } 
                        />
                    </Tooltip>
                </div>
            </Header>
            <Menubar />

            <MySettings user={user} afterSave={updateUsername} visible={visible_modal} setVisibility={setVisibleModal} />
        </>
    );
}

export default Topbar;