import React, { useEffect, useState } from 'react';
import { 
    Modal,
    Input,
    Layout,
    Select,
    Spin,
    Descriptions,
    Row,
    Col,
    Tooltip,
} from 'antd';
import {NumericFormat} from 'react-number-format';
import CurrencyInput from 'react-currency-input';
import locale from 'antd/es/date-picker/locale/pt_BR';
import InputMask from 'react-input-mask';
import moment from 'moment';
import 'moment/locale/pt-br';
import { parseISO, format } from 'date-fns';

import api from '../../../services/api';

import './style.css';

const cleanFloatValue = require('../../../utils/cleanFloatValue');

const { Search, TextArea } = Input;
const { Content } = Layout;
const { Option, OptGroup } = Select;

function BillDetails(props) {
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loading_cost_centers, setLoadingCostCenters] = useState(false);
    const [loading_categories, setLoadingCategories] = useState(false);
    const [loading_contacts, setLoadingContacts] = useState(false);
    const [showCostCenter, setShowCostCenter] = useState(false);
    const [showContact, setShowContact] = useState(false);
    const [showMoreInfo, setShowMoreInfo] = useState(false);
    const [bill, setBill] = useState({});
    const [billTo, setBillTo] = useState({});

    useEffect(() => {
        if(props.visible){
            setLoading(true);
            showBill(props.bill);
        }
    }, [props.visible]);
    
    function formatDate(date){
        if(date !== null && date !== '' && date !== '0000-00-00'){
            date = parseISO(date);
            return format(date, 'dd/MM/yyyy');
        } else {
            return '-';
        }
    }

    function formatDateTime(date){
        if(date !== null && date !== '' && date !== '0000-00-00'){
            date = parseISO(date);
            return format(date, "dd/MM/yyyy 'às' HH:mm");
        } else {
            return '-';
        }
    }

    function fixStatus(status){
        switch (status) {
            case 'A':
                return <span style={{fontWeight:'bold',color:'#ff4d4f'}}>Em Aberto</span>;
            case 'P':
                return <span style={{fontWeight:'bold',color:'#2dce89'}}>{props.type == 'to_pay' ? 'Pago' : 'Recebido'}</span>;
            case 'PP':
                return <span style={{fontWeight:'bold',color:'#ffc107'}}>{props.type == 'to_pay' ? 'Parcialmente Pago' : 'Parcialmente Recebido'}</span>;
            default:
                return '-';
        }
    }

    function firstName(name){
        name = name.split(' ');
        name = name[0];
        return name;
    }

    function registeredBy(){
        return <Tooltip title={ billTo.user ? billTo.user.name : '-' }>
            { `${firstName(billTo.user ? billTo.user.name : '-')}` }
        </Tooltip>
    }

    async function showBill(bill) {
        setBill(bill);
        if(bill.bill){
            setBillTo(bill.bill);
        }
        setLoading(false);
    }

    return (
        <Modal
            wrapClassName="fbm-modal"
            title={<div className="bill_modal_title" style={props.type == 'to_pay' ? {background: '#f35b5b'} : {background: '#25ba6e'}}>
                <h3 className="bill_description">{bill.description}</h3>
                <h1 className="bill_value">
                    <NumericFormat 
                        value={bill.value ? bill.value : 0}
                        displayType={'text'}
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={2}
                        fixedDecimalScale={true}
                    />
                </h1>
                <p className="bill_sub">Lançada por {registeredBy()} em {formatDateTime(bill.created_at ? bill.created_at : '')}</p>
            </div>}
            visible={ props.visible }
            footer=""
            onCancel={() => props.setVisibility(false)}
        >
            <Spin spinning={loading}>
                <Row>
                    <Col span={24}>
                        <Descriptions title="" layout="vertical" column={2}>
                            <Descriptions.Item label="Emissão">
                                <span style={{fontWeight:'700', fontSize:'1rem'}}>
                                    { formatDate(bill.issue_date ? bill.issue_date : '') }
                                </span>
                            </Descriptions.Item>
                            <Descriptions.Item label="Competência">
                                <span style={{fontWeight:'700', fontSize:'1rem'}}>
                                    { formatDate(billTo.competence_date ? billTo.competence_date : '') }
                                </span>
                            </Descriptions.Item>
                            <Descriptions.Item label="Vencimento">
                                <span style={{fontWeight:'700', fontSize:'1rem'}}>
                                    { formatDate(bill.due_date ? bill.due_date : '') }
                                </span>
                            </Descriptions.Item>
                            <Descriptions.Item label="Pagamento">
                                <span style={{fontWeight:'700', fontSize:'1rem'}}>
                                    { fixStatus(bill.status) }
                                </span>
                            </Descriptions.Item>
                            <Descriptions.Item label="Centro de custos">
                                <span style={{fontWeight:'700', fontSize:'1rem'}}>
                                    { bill.cost_center ? bill.cost_center.name : '-' }
                                </span>
                            </Descriptions.Item>
                            <Descriptions.Item label="Categoria">
                                <span style={{fontWeight:'700', fontSize:'1rem'}}>
                                    { bill.category ? bill.category.description : '-' }
                                </span>
                            </Descriptions.Item>
                            <Descriptions.Item label={props.type == 'to_pay' ? 'Fornecedor' : 'Cliente'}>
                                <span style={{fontWeight:'700', fontSize:'1rem'}}>
                                    { bill.contact ? bill.contact.name : '-' }
                                </span>
                            </Descriptions.Item>
                            <Descriptions.Item label="Documento">
                                <span style={{fontWeight:'700', fontSize:'1rem'}}>
                                    { bill.bill ? bill.document_number : '-' }
                                </span>
                            </Descriptions.Item>
                        </Descriptions>
                        <Descriptions title="" layout="vertical" column={1}>
                            <Descriptions.Item label="Observação">
                                <span style={{fontWeight:'700', fontSize:'1rem'}}>
                                    { bill.note ? bill.note : '--' }
                                </span>
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Descriptions title="" layout="vertical" column={3}>
                            <Descriptions.Item label="Parcela">
                                <span style={{fontWeight:'700', fontSize:'1rem'}}>
                                    { bill.plot_number+'/'+billTo.total_plots }
                                </span>
                            </Descriptions.Item>
                            <Descriptions.Item label={`Já ${props.type == 'to_pay' ? 'paguei' : 'recebi'}`}>
                                <span style={{fontWeight:'700', fontSize:'1rem'}}>
                                    <NumericFormat 
                                        value={bill.amount_paid ? bill.amount_paid : 0}
                                        displayType={'text'}
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                    />
                                </span>
                            </Descriptions.Item>
                            <Descriptions.Item label={`Resta ${props.type == 'to_pay' ? 'pagar' : 'receber'}`}>
                                <span style={{fontWeight:'700', fontSize:'1rem'}}>
                                    <NumericFormat 
                                        value={bill.open_value ? bill.open_value : 0}
                                        displayType={'text'}
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                    />
                                </span>
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>
            </Spin>
        </Modal>
    );
}

export default BillDetails;